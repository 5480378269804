import { castArray, findIndex, isEmpty, some } from 'lodash';
import { SkipPagination } from 'wonderland-ui-discovery';
import classnames from 'classnames';
import List from '@material-ui/core/List';
import NoResults from 'app/shared/NoResults';
import PreviousOrNextResults from './PreviousOrNextResults';
import PropTypes from 'prop-types';
import React from 'react';
import ResultsListItem from './ResultsListItem';
import withStyles from '@material-ui/core/styles/withStyles';
/* istanbul ignore next */
const sheet = ({ spacing }) => ({
    resultsList: {
        paddingBottom: spacing.unit * 2,
        overflow: 'hidden',
        overflowY: 'scroll',
        height: 'calc(100vh - 230px)'
    },
    container: {
        maxWidth: spacing.unit * 54,
        overflow: 'hidden',
        '&.modal': {
            maxWidth: spacing.unit * 92
        }
    }
});

@withStyles(sheet)
export default class ResultsList extends React.PureComponent {
    static propTypes = {
        classes: PropTypes.object,
        drillIntoFolder: PropTypes.func,
        enableMultiSelect: PropTypes.bool.isRequired,
        loading: PropTypes.bool,
        modal: PropTypes.bool,
        moreItems: PropTypes.bool,
        onSelectFilesOrFolder: PropTypes.func,
        previousItems: PropTypes.bool,
        results: PropTypes.arrayOf(PropTypes.object),
        seeNextResults: PropTypes.func,
        seePreviousResults: PropTypes.func,
        selected: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
        variant: PropTypes.string
    };

    state = {
        prevFileOrFolder: {
            name: '',
            location: ''
        }
    };

    includesSelection(fileOrFolder) {
        const { selected = [] } = this.props;
        return some(
            castArray(selected),
            item => item.name === fileOrFolder.name && item.location === fileOrFolder.location
        );
    }

    isFileOrFolderDisabled(fileOrFolder) {
        const { variant } = this.props;
        return (
            (variant === 'file' && fileOrFolder.folder) ||
            ((variant === 'folder' || variant === 'imf') && !fileOrFolder.folder)
        );
    }

    getSelectedValidElements(prevIndex, index) {
        const { results = [] } = this.props;
        const initialIndex = prevIndex < index ? prevIndex : index;
        const finalIndex = prevIndex > index ? prevIndex : index;
        return results.filter((el, i) => i >= initialIndex && i <= finalIndex && !this.isFileOrFolderDisabled(el));
    }

    onSelectFilesOrFolder(e, fileOrFolder, fileOrFolderIndex) {
        const { enableMultiSelect, onSelectFilesOrFolder, results = [] } = this.props;
        const { prevFileOrFolder } = this.state;
        let removeSelected = this.includesSelection(fileOrFolder);
        let selectedFilesOrFolders = [fileOrFolder];
        if (enableMultiSelect && e.shiftKey) {
            const prevFileOrFolderIndex = findIndex(results, prevFileOrFolder);
            if (prevFileOrFolderIndex !== -1) {
                selectedFilesOrFolders = this.getSelectedValidElements(prevFileOrFolderIndex, fileOrFolderIndex);
                removeSelected = this.includesSelection(prevFileOrFolder) && this.includesSelection(fileOrFolder);
            }
        }
        this.setState({ prevFileOrFolder: { name: fileOrFolder.name, location: fileOrFolder.location } });
        onSelectFilesOrFolder(selectedFilesOrFolders, removeSelected);
    }

    render() {
        const {
            classes,
            drillIntoFolder,
            loading,
            modal,
            moreItems,
            previousItems,
            results,
            seeNextResults,
            seePreviousResults
        } = this.props;

        return loading ? null : (
            <div className={classnames(classes.container, { modal })}>
                <List className={classes.resultsList}>
                    {!isEmpty(results) ? (
                        results.map((fileOrFolder, i) => (
                            <ResultsListItem
                                onSelectFileOrFolder={e => this.onSelectFilesOrFolder(e, fileOrFolder, i)}
                                drillIntoFolder={drillIntoFolder}
                                key={i}
                                fileOrFolder={fileOrFolder}
                                isFolder={fileOrFolder.folder}
                                isDisabled={this.isFileOrFolderDisabled(fileOrFolder)}
                                isSelected={this.includesSelection(fileOrFolder)}
                            />
                        ))
                    ) : (
                        <NoResults />
                    )}
                    <PreviousOrNextResults
                        previousItems={previousItems}
                        moreItems={moreItems}
                        seeNextResults={seeNextResults}
                        seePreviousResults={seePreviousResults}
                    />
                </List>
                <SkipPagination />
            </div>
        );
    }
}
