import { actionDispatcher } from 'react-redux-boilerout';

@actionDispatcher({
    namespace: 'NotificationRecipients',
    actions: [
        'addNewDistributionLists',
        'addNotificationRecipient',
        'deleteNotificationRecipient',
        'onSelectDistributionList',
        'onFilterContacts',
        'onSearchInput',
        'onExcludeContactToggle',
        'setShowExcluded',
        'reset'
    ]
})
export default class NotificationRecipientsActions {}
