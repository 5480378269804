import { actionDispatcher } from 'react-redux-boilerout';

@actionDispatcher({
    namespace: 'WonderlandDomain',
    actions: [
        'bulkDownloadRequested',
        'startBulkPublishRequested',
        'startBulkPublishCompleted',
        'startBulkPublishFailed',
        'downloadArchiveFilesRequested',
        'downloadArchiveFilesCompleted',
        'downloadArchiveFilesFailed',
        'fetchServiceStatusRequested',
        'fetchServiceStatusCompleted',
        'fetchServiceStatusFailed',
        'removeAssetRequested',
        'removeAssetCompleted',
        'removeAssetFailed',
        'fetchAssetRequested',
        'fetchAssetCompleted',
        'fetchAssetFailed',
        'fetchAssetBaseRequested',
        'fetchAssetBaseCompleted',
        'fetchAssetBaseFailed',
        'removeFromCollectionRequested',
        'removeFromCollectionFailed'
    ]
})
export default class WonderlandDomainActions {}
