import { compose, withHandlers, withProps } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import DistributionListContactsItemComponent from './DistributionListContactsItemComponent';

export default compose(
    withHandlers({
        handleUpdate:
            ({ update }) =>
            event => {
                update(event.target.id);
            }
    }),
    withStyles(() => ({
        ListItemTextRoot: {
            '& span': {
                fontSize: 'inherit',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '95%'
            },
            width: 336
        }
    })),
    withProps(({ checked }) => ({
        icon: checked ? 'toggle_on' : 'toggle_off',
        iconColor: checked ? 'secondary' : 'disabled'
    }))
)(DistributionListContactsItemComponent);
