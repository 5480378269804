export const CRITERIA_TYPE = {
    one_of: { operation: 'is_one_of_keyword', type: 'filter' },
    term: { type: 'term' }
};

export const DELIMITER_TYPE = {
    replacement: ',',
    id: /\s+/g,
    standard: /[,;]+/g,
    text: /\s{2,6}/g
};

export const FIELD_LABEL = {
    asset_language: 'Asset Language',
    file_name: 'File Name',
    fox_financial_title_id: 'Fox Financial Title ID',
    id: 'Asset ID',
    name: 'Asset Name',
    radar_title_id: 'RADAR Product ID',
    short_id: 'Short ID',
    source_system_asset_id: 'Source System Asset ID',
    term: 'All',
    twdc_version_id: 'Version ID',
    media_depot_legacy_file_path: 'Media Depot Legacy File Path',
    legacy_media_depot_path: 'Legacy Media Depot Path'
};
