import { AssetStoreAPI, WonderlandDomainAPI } from './apis';
import { compose, withHandlers } from 'recompose';
import { delay, get } from 'lodash';
import { ToastController, withProgressDecorator } from 'wonderland-ui-commons';
import { withFeatureFlagsLite } from 'core/Cache/withFeatureFlags';
import withAsperaOnlyUpload from './withAsperaOnlyUpload';

const { getUploadTransferSpecForIMP } = AssetStoreAPI;
const { validateIMF, validateIngestOrPropagateIMF } = WonderlandDomainAPI;
const getAssetMapName = folder => {
    return `${get(folder, '[0].name')}/ASSETMAP.xml`;
};

export default () =>
    compose(
        withAsperaOnlyUpload({
            uploadTransferSpecProvider: getUploadTransferSpecForIMP,
            manageTransfer: validateIMF
        }),
        withFeatureFlagsLite(),
        withHandlers({
            uploadAssetMap: ({ upload }) =>
                withProgressDecorator('get_xml_files', { type: 'determinate' })(async function (folder) {
                    const assetMap = getAssetMapName(folder);
                    try {
                        return upload({
                            progressFn: this.progressFn,
                            sources: [{ path: assetMap }],
                            transferData: {},
                            assetmapOnly: true
                        }).then(result => {
                            delay(() => ToastController.hide(), 1500);
                            return result;
                        }, ToastController.showError);
                    } catch (e) {
                        ToastController.showError(
                            'Unable to determine file names. Check a valid ASSETMAP.xml is included.',
                            e
                        );
                    }
                }),
            uploadXMLFilesToValidate: ({ upload }) =>
                withProgressDecorator('validate_xml_files', { type: 'determinate' })(async function (fileNames) {
                    try {
                        return upload({
                            progressFn: this.progressFn,
                            sources: fileNames.map(path => ({ path })),
                            transferData: {},
                            assetmapOnly: false
                        }).then(
                            delay(() => ToastController.hide(), 1500),
                            ToastController.showError
                        );
                    } catch (e) {
                        ToastController.showError('Unable to validate IMF package files.', e);
                    }
                }),
            uploadXMLResourcesToValidate: ({ isFlagEnabled }) =>
                withProgressDecorator('validate_xml_resources', { type: 'determinate' })(
                    async function (resources, essenceFileNames, assetId, ingestData) {
                        try {
                            const validationData = {
                                assetmapOnly: false,
                                essenceFileNames,
                                progressFn: this.progressFn,
                                ...(isFlagEnabled('IMF_PROPAGATE') && { replaceAssetId: assetId }),
                                resources
                            };

                            const validate = await validateIngestOrPropagateIMF({ ingestData, validationData });

                            return validate;
                        } catch (e) {
                            ToastController.showError('Unable to validate IMF package files.', e);
                        }
                    }
                )
        })
    );
