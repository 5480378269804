import { compact, isEmpty, last, lowerCase, orderBy, partition } from 'lodash';
import { compose, defaultProps, lifecycle, renameProps, withHandlers, withProps } from 'recompose';
import { connectSlice } from 'wonderland-ui-commons';
import { s3BrowserDefaultCriteria } from './S3FileSelectorUtils';
import S3BrowserSideSheetActions from './S3BrowserSideSheetActions';
import S3BrowserSideSheetComponent from './S3BrowserSideSheetComponent';
import S3BrowserSideSheetController from './S3BrowserSideSheetController';
import S3BrowserSideSheetSlice from './S3BrowserSideSheetSlice';

export default compose(
    renameProps({ addS3FilesOrFolder: 'onSelectFilesOrFolder' }),
    defaultProps({ enableMultiSelect: false }),
    connectSlice({ slice: S3BrowserSideSheetSlice }),
    withProps(({ currentFolderPath, results: unfilteredUnsortedResults, useEnhancedSearch }) => {
        const [folders, files] = partition(unfilteredUnsortedResults, fileOrFolder => fileOrFolder.folder);
        const sortedFolders = orderBy(folders, f => lowerCase(f.name));
        const sortedFiles = orderBy(files, ['lastModified'], ['desc']);
        const displayResults = [...sortedFiles, ...sortedFolders];
        const seeNextResults = useEnhancedSearch
            ? S3BrowserSideSheetActions.seeEnhancedSearchNextResults
            : S3BrowserSideSheetActions.seeNextResults;
        const seePreviousResults = useEnhancedSearch
            ? S3BrowserSideSheetActions.seeEnhancedSearchPreviousResults
            : S3BrowserSideSheetActions.seePreviousResults;

        return {
            breadcrumbs: compact(currentFolderPath.split('/')),
            clearSearchTerm: S3BrowserSideSheetActions.clearSearchTerm,
            defaultCriteria: s3BrowserDefaultCriteria,
            displayResults,
            drillIntoFolder: S3BrowserSideSheetActions.drillIntoFolder,
            reset: S3BrowserSideSheetActions.reset,
            seeNextResults,
            seePreviousResults,
            selectBreadCrumb: S3BrowserSideSheetActions.selectBreadCrumb
        };
    }),
    withHandlers({
        updateSearchTerm: () => event => S3BrowserSideSheetActions.updateSearchTerm(event.target.value),
        resetDrawerAndDestination:
            ({ onSelectFilesOrFolder, clearDestination }) =>
            () => {
                S3BrowserSideSheetActions.reset();
                clearDestination();
                onSelectFilesOrFolder();
            }
    }),
    lifecycle({
        componentDidMount() {
            const { currentFolderPath, destination, limit, nextToken, searchTerm } = this.props;
            !isEmpty(destination) &&
                S3BrowserSideSheetController.getS3BucketContent(
                    destination.id,
                    currentFolderPath,
                    nextToken,
                    limit,
                    searchTerm
                );
        },
        componentDidUpdate({
            currentFolderPath: oldCurrentFolderPath,
            continuationTokens: oldContinuationTokens,
            destination: oldDestination,
            searchTerm: oldSearchTerm,
            enhancedSearchSkip: oldEnhancedSearchSkip
        }) {
            const {
                currentFolderPath,
                continuationTokens,
                destination,
                limit,
                searchTerm,
                enhancedSearchLimit,
                enhancedSearchSkip
            } = this.props;
            if (
                !isEmpty(destination) &&
                destination.enhanced_search === 'Yes' &&
                searchTerm.length > 0 &&
                (searchTerm !== oldSearchTerm || enhancedSearchSkip !== oldEnhancedSearchSkip)
            ) {
                if (searchTerm.length > 3) {
                    S3BrowserSideSheetController.getContainerContent(
                        destination.s3_bucket,
                        currentFolderPath,
                        searchTerm,
                        enhancedSearchLimit,
                        enhancedSearchSkip
                    );
                }
            } else if (
                !isEmpty(destination) &&
                (destination !== oldDestination ||
                    currentFolderPath !== oldCurrentFolderPath ||
                    continuationTokens !== oldContinuationTokens ||
                    searchTerm !== oldSearchTerm)
            ) {
                S3BrowserSideSheetController.getS3BucketContent(
                    destination.id,
                    currentFolderPath,
                    last(continuationTokens),
                    limit,
                    searchTerm
                );
            }
        },
        componentWillUnmount() {
            S3BrowserSideSheetActions.reset();
        }
    })
)(S3BrowserSideSheetComponent);
