import { compose, withHandlers, withProps, withState } from 'recompose';
import { connectSlice } from 'wonderland-ui-commons';
import { getDelimitedList, searchFields } from './SearchFormUtils';
import { SearchCriterionBuilder } from 'wonderland-ui-discovery';
import { withStyles } from 'wonderland-ui-material';
import React from 'react';
import SearchFormComponent from './SearchFormComponent';
import SearchFormSlice from './SearchFormSlice';
import SearchIcon from '@material-ui/icons/Search';
import withDiscoveryState from 'app/Search/withDiscoveryState';

export default compose(
    connectSlice({ slice: SearchFormSlice }),
    withDiscoveryState(({ context: { route, defaultCriteria } }) => ({ contextAware: true, route, defaultCriteria })),
    withState('anchorEl', 'setAnchorEl', null),
    withState('inputTerm', 'setInputTerm', ''),
    withState('selectedField', 'setSelectedField', searchFields[0]),
    withHandlers({
        handleChange:
            ({ setInputTerm }) =>
            event => {
                setInputTerm(event.target.value);
            },
        handleClose:
            ({ setAnchorEl }) =>
            () => {
                setAnchorEl(null);
            },
        handleMenu:
            ({ anchorEl, setAnchorEl }) =>
            event => {
                event.preventDefault();
                if (anchorEl) {
                    setAnchorEl(null);
                } else {
                    setAnchorEl(event.currentTarget);
                }
            },
        handleMenuItem:
            ({ setAnchorEl, setSelectedField }) =>
            selectedField => {
                setSelectedField(selectedField);
                setAnchorEl(null);
            },
        handleSubmit:
            ({
                context: { defaultCriteria = [], route },
                inputTerm,
                onDiscoveryStateChange,
                selectedField: { fieldCriteria, fieldDelimiter },
                setInputTerm
            }) =>
            event => {
                event.preventDefault();
                const curatedInputTerm = inputTerm.toLowerCase().trim();

                if (curatedInputTerm.length < 2) {
                    return;
                }

                const { field } = fieldCriteria;
                let additionalCriteria = {};
                let criteria = [];

                if (field === 'term') {
                    additionalCriteria = SearchCriterionBuilder.buildSearchTermCriterion(curatedInputTerm);
                    criteria = [...defaultCriteria, additionalCriteria];
                } else {
                    const valueList = getDelimitedList(curatedInputTerm, fieldDelimiter);

                    criteria = [...defaultCriteria, fieldCriteria];
                    criteria[0].value = valueList;
                }

                onDiscoveryStateChange({ route, criteria, skip: 0 });
                setInputTerm('');
            }
    }),
    withProps(({ anchorEl, selectedField: { fieldLabel } }) => ({
        open: Boolean(anchorEl),
        searchFields,
        searchLabel: fieldLabel === 'All' ? <SearchIcon /> : fieldLabel
    })),
    withStyles(({ palette }) => ({
        button: { alignItems: 'flex-start', maxHeight: 32 },
        white: { color: palette.common.white, '&:hover': { color: palette.common.white } }
    }))
)(SearchFormComponent);
