import 'app/IMFReplacement/IMFReplacementSlice';
import { autobind, ToastController } from 'wonderland-ui-commons';
import { get, isEmpty } from 'lodash';
import { getErrors } from 'lib/impUtils';
import { listen } from 'lib/events';
import { WonderlandDomainAPI } from 'core/apis';
import BaseAssetEditActions from 'app/shared/BaseAssetEdit/BaseAssetEditActions';
import history from 'lib/history';
import IMFReplacementActions from 'app/IMFReplacement/IMFReplacementActions';

const { propagating, redirecting } = BaseAssetEditActions;
const { init, initComplete, notify, propagateAndIngest, propagateAndIngestFailed } = IMFReplacementActions;
const { validateIngestOrPropagateIMF } = WonderlandDomainAPI;

@autobind
class IMFReplacementController {
    async doPropagateAndIngest(ingestData, propagationData) {
        propagateAndIngest();
        const replaceAssetId = get(ingestData, 'assetId', 0);
        await validateIngestOrPropagateIMF({ ingestData, propagationData, replaceAssetId });
    }

    navigateToHome(message) {
        ToastController.showError(message);
        history.push(`/`);
    }

    navigateToAssetDetail(assetId, isSequential, message, sequentialCallback, isError = false) {
        redirecting();
        isError ? ToastController.showError(message) : ToastController.show(message);
        isSequential ? sequentialCallback(assetId) : history.push(`/asset/${assetId}`);
    }

    navigateToIMFReplacement(assetId, ingestData, isSequential, replaceComparison, sequentialCallback) {
        propagating();
        init(assetId, ingestData, isSequential, replaceComparison, sequentialCallback);
        initComplete();
        history.push(`/imf/replacement`);
    }

    @listen('imfIngest')
    receivedIngestNotification(notification) {
        const { payload } = notification;
        const errors = getErrors(payload);

        if (!isEmpty(errors)) {
            propagateAndIngestFailed();
            errors.forEach(error => ToastController.showError(error));
        }
    }

    @listen('imfPropagation')
    receivedPropagationNotification(notification) {
        const { payload } = notification;
        const errors = getErrors(payload);

        if (!isEmpty(errors)) {
            propagateAndIngestFailed();
            errors.forEach(error => ToastController.showError(error));
        } else {
            notify(notification);
        }
    }
}

export default new IMFReplacementController();
