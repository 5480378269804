import { branch, compose, lifecycle, renderNothing, withHandlers, withProps } from 'recompose';
import { connectSlice, sliceContainer } from 'wonderland-ui-commons';
import { createSelector } from 'reselect';
import { mapValues, memoize, pickBy } from 'lodash';
import config from 'app/config';

import UserDataController from 'core/UserData/UserDataController';

import CacheActions from './CacheActions';
import CacheSlice from './CacheSlice';

import PropTypes from 'prop-types';

import assetTypeToFlag from 'app/shared/FeatureFlagAttr/assetTypeToFlag';
import attrToFlag from 'app/shared/FeatureFlagAttr/attrToFlag';
import facetIdToFlag from 'app/shared/FeatureFlagAttr/facetIdToFlag';
import React from 'react';

const cacheType = 'featureFlags';

export const fetchFeatureFlags = memoize(function _fetchFeatureFlags() {
    CacheActions.loadingRequested(cacheType);
    return UserDataController.fetchFeatureFlags().then(
        data => {
            return data;
        },
        () => {
            fetchFeatureFlags.cache.clear();
        }
    );
});

const isFlagEnabledSelector = createSelector(
    slice => slice.featureFlags,
    featureFlags => key => {
        const flag = featureFlags && featureFlags.find(f => f.key === key);
        return !!(flag && flag.enabled);
    }
);

export default function withFeatureFlags(Component) {
    class Renderer extends Component {
        static propTypes = {
            featureFlags: PropTypes.array
        };

        render() {
            return this.props.featureFlags ? <Component {...this.props} /> : null;
        }
    }
    @sliceContainer({
        slice: CacheSlice,
        component: Renderer
    })
    class WithFeatureFlagsContainer {
        static mapSliceStateToProps(slice) {
            return {
                featureFlags: slice.featureFlags,
                isFlagEnabled: isFlagEnabledSelector(slice)
            };
        }

        componentDidMount() {
            fetchFeatureFlags();
        }
    }

    return WithFeatureFlagsContainer;
}

export const withFeatureFlagsLite = () =>
    compose(
        lifecycle({ componentDidMount: () => fetchFeatureFlags() }),
        connectSlice({ slice: CacheSlice }, slice => ({
            featureFlags: slice.featureFlags,
            isFlagEnabled: isFlagEnabledSelector(slice)
        })),
        branch(props => !props.featureFlags, renderNothing)
    );

const isAttributeEnabled = attributeToFlagMap => {
    return ({ isFlagEnabled }) =>
        attr => {
            let flagValue = attributeToFlagMap[attr];
            let negate = false;

            if (flagValue && flagValue.startsWith('!')) {
                flagValue = flagValue.substring(1);
                negate = true;
            }
            return !flagValue || negate !== isFlagEnabled(flagValue);
        };
};

export const withFeatureFlaggedFields = compose(
    withFeatureFlagsLite(),
    withHandlers({ isFieldEnabled: isAttributeEnabled(attrToFlag) })
);

export const withFeatureFlaggedFacets = compose(
    withFeatureFlagsLite(),
    withHandlers({ isFacetEnabled: isAttributeEnabled(facetIdToFlag) })
);

export const withFeatureFlaggedAssetTypes = compose(
    withFeatureFlagsLite(),
    withProps(props => ({
        assetTypes: mapValues(
            pickBy(
                config.assetTypes,
                (val, key) =>
                    isAttributeEnabled(assetTypeToFlag)(props)(key) &&
                    (props.isBulkUpload ? val.bulkUploadEnabled : val.uploadEnabled)
            ),
            ({ label }) => label
        )
    }))
);
