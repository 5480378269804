import Menu from '@material-ui/core/Menu';
import PropTypes from 'prop-types';
import React from 'react';
import SearchFormMenuItem from './SearchFormMenuItemComponent';

const SearchFormMenuComponent = ({ anchorEl, handleClose, handleMenuItem, open, searchFields }) => (
    <Menu anchorEl={anchorEl} onClose={handleClose} open={open}>
        {searchFields.map((searchField, key) => (
            <SearchFormMenuItem handleMenuItem={handleMenuItem} key={key} searchField={searchField} />
        ))}
    </Menu>
);

SearchFormMenuComponent.propTypes = {
    anchorEl: PropTypes.any,
    handleClose: PropTypes.func.isRequired,
    handleMenuItem: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    searchFields: PropTypes.array.isRequired
};

export default SearchFormMenuComponent;
