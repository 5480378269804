import { actionDispatcher } from 'react-redux-boilerout';

@actionDispatcher({
    namespace: 'Cache',
    actions: [
        'fetchControlledVocabulary',
        'fetchGrants',
        'fetchContentCategories',
        'fetchFeatureFlags',
        'fetchSchema',
        'fetchAllSchemas',
        'loadingRequested',
        'loadingFailed'
    ]
})
export default class BulkEditActions {}
