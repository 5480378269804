import { isEmpty, isNumber } from 'lodash';

const labels = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export default function formatBytes(bytes, defaultValue = '', returnIdentityOnNonNumeric = false) {
    let readable = defaultValue;
    if (isNumber(bytes)) {
        const unit = bytes ? Math.floor(Math.log(bytes) / Math.log(1024)) : 0;
        const amount = bytes ? (bytes / Math.pow(1024, unit)).toFixed(!unit ? 0 : 2) : 0;
        readable = `${amount} ${labels[unit]}`;
    } else if (returnIdentityOnNonNumeric && !isEmpty(bytes)) {
        readable = bytes;
    }
    return readable;
}
