import { get, isEmpty } from 'lodash';
export default function getPrimaryTitleString(relatedTitles) {
    if (isEmpty(relatedTitles)) {
        return '';
    }

    const sortedTitles = relatedTitles
        .filter(t => t)
        .sort((titleA, titleB) => (titleA.priority || 5) - (titleB.priority || 5));
    return get(sortedTitles, '0.name', '');
}
