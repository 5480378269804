import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';
import Select from '@material-ui/core/Select';

const RelatedFormComponent = ({ handleSelectRelationshipType, relationshipType, typeNames, types }) => {
    const handleRelationshipType = ({ target: { value } }) => handleSelectRelationshipType(value);

    return (
        <FormControl fullWidth margin="dense">
            <InputLabel>Add Children As</InputLabel>
            <Select
                input={<Input name="type" id="type" />}
                onChange={handleRelationshipType}
                value={relationshipType}
                MenuProps={{
                    style: { zIndex: 1400 }
                }}
            >
                {types.map((type, key) => (
                    <MenuItem key={key} value={type}>
                        {typeNames[type]}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

RelatedFormComponent.propTypes = {
    handleSelectRelationshipType: PropTypes.func.isRequired,
    relationshipType: PropTypes.string,
    typeNames: PropTypes.object.isRequired,
    types: PropTypes.array.isRequired
};

export default RelatedFormComponent;
