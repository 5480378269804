import { omit } from 'lodash';
import config from 'app/config';
import EventsActions from './EventsActions';
import ReactGA from 'react-ga4';

import { autobind, ToastController, withProgress } from 'wonderland-ui-commons';
import { EventsAPI, WonderlandDomainAPI } from 'core/apis';
import { listen } from 'lib/events';

@autobind
class EventsController {
    constructor() {
        this.isInit = false;
        this.events = [];
        this.limit = 25;
    }

    @withProgress({ label: 'fetch_visualizations' })
    getVisualizations() {
        EventsActions.getVisualizationsRequested();

        return EventsAPI.getVisualizations().then(
            r => EventsActions.getVisualizationsCompleted(r),
            e => {
                ToastController.showError(e);
                EventsActions.getVisualizationsFailed(e);
            }
        );
    }

    @withProgress({ label: 'fetch_indexes' })
    getIndexes() {
        EventsActions.getIndexesRequested();

        return EventsAPI.getIndexes().then(
            r => EventsActions.getIndexesCompleted(r),
            e => {
                ToastController.showError(e);
                EventsActions.getIndexesFailed(e);
            }
        );
    }

    @withProgress({ label: 'fetch_event' })
    getEvent(id) {
        EventsActions.getEventRequested();

        return EventsAPI.getEvent(id).then(
            r => EventsActions.getEventCompleted(r),
            e => {
                ToastController.showError(e);
                EventsActions.getEventFailed(e);
            }
        );
    }

    @withProgress({ label: 'fetch_asset_events' })
    getAssetEvents(id, page = 1) {
        /* If the history tab is not lazy loaded or is loaded first by the URL,
        the API call needs to be deferred for it not to collide with the other
        initial API calls happening on the Asset View.
        */
        EventsActions.getAssetEventsRequested.defer();

        const skip = (page - 1) * this.limit;
        return EventsAPI.getAssetEvents(id, { skip, limit: this.limit }).then(
            results => EventsActions.getAssetEventsCompleted(this.limit, results),
            e => {
                ToastController.showError(e);
                EventsActions.getAssetEventsFailed(e);
            }
        );
    }

    @listen('pageView')
    emitPageView(page) {
        this.events.push({
            type: 'ga',
            fn: (path => () => {
                ReactGA.set({ page: path });
                ReactGA.send({ hitType: 'pageview', page: path });
            })(page)
        });

        this.events.push({
            type: 'api',
            action: 'page-view',
            data: { page }
        });
    }

    emitModalView(modal) {
        this.events.push({
            type: 'ga',
            fn: (m => () => {
                ReactGA.send({ hitType: 'pageview', page: `/modal/${m}` });
            })(modal)
        });

        this.events.push({
            type: 'api',
            action: 'modal-view',
            data: { modal }
        });
    }

    @listen('asperaDownloadStarted')
    emitAsperaDownloadStarted(spec) {
        this.emitManagedTransferEvent('managed-download-start', 'aspera', spec);
    }

    @listen('asperaUploadStarted')
    emitAsperaUploadStarted(spec) {
        this.emitManagedTransferEvent('managed-upload-start', 'aspera', spec);
    }

    @listen('s3DownloadStarted')
    emitS3DownloadStarted(spec) {
        this.emitManagedTransferEvent('managed-download-start', 's3', spec);
    }

    @listen('s3UploadStarted')
    emitS3UploadStarted(spec) {
        this.emitManagedTransferEvent('managed-upload-start', 's3', spec);
    }

    emitManagedTransferEvent(action, type, spec) {
        this.events.push({
            type: 'api',
            category: 'transfer',
            action,
            data: {
                type,
                spec
            }
        });
    }

    @listen('error')
    emitError(error, message) {
        this.events.push({
            type: 'ga',
            fn: (m => () => {
                ReactGA.send({
                    hitType: 'exception',
                    exDescription: m,
                    exFatal: false
                });
            })(message)
        });

        this.events.push({
            type: 'api',
            action: 'error',
            data: { error, message }
        });
    }

    emitEvents() {
        if (this.isInit && this.events.length > 0) {
            const gaEvents = this.events.filter(e => e.type === 'ga').map(e => e.fn);

            const apiEvents = this.events.filter(e => e.type === 'api').map(e => omit(e, 'type'));

            if (gaEvents.length > 0) {
                gaEvents.forEach(fn => fn());
            }

            if (apiEvents.length > 0) {
                WonderlandDomainAPI.createEvent(apiEvents).catch(console.error);
            }
            this.events = [];
        }
    }

    init() {
        if (config.googleAnalytics) {
            ReactGA.initialize(config.googleAnalytics.measurement, config.googleAnalytics.options);
            this.isInit = true;
        } else {
            console.error('Google Analytics id missing in config');
        }
        setInterval(() => this.emitEvents(), 5000);
    }
}
const controller = new EventsController();
export default controller;
