import { getSchema } from './SelectLocationForm/selectLocationSchema';
import { withStyles } from 'wonderland-ui-material';
import { withWonderlandForm } from 'wonderland-ui-commons';
import FormDialog from 'app/shared/SchemaFormDialog';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import SelectLocationForm from './SelectLocationForm';

/* istanbul ignore next */
const sheet = ({ marginSizes, textSizes }) => ({
    name: {
        lineHeight: textSizes.large,
        padding: `${marginSizes.small} 0`
    }
});

export const onSubmit = (props, values) => {
    const { assetIdOrIds, destroyForm, hide, selectLocation } = props;
    const {
        locationName: { id, name },
        notes,
        usersToNotify = []
    } = values;
    hide();
    selectLocation(id, name, assetIdOrIds, notes, usersToNotify);
    destroyForm();
};

@withWonderlandForm({
    formName: 'selectLocation',
    schema: getSchema,
    showSubmitButton: false,
    config: {
        destroyOnUnmount: false,
        enableReinitialize: true
    },
    onSubmit
})
@withStyles(sheet)
export default class SelectLocationModalComponent extends PureComponent {
    static propTypes = {
        assetIdOrIds: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
        changeSelection: PropTypes.func,
        classes: PropTypes.object,
        fetchLocations: PropTypes.func,
        formFieldUpdate: PropTypes.func,
        handleUsersInputChange: PropTypes.func,
        hide: PropTypes.func,
        isExistingUser: PropTypes.func,
        notes: PropTypes.string,
        noteChange: PropTypes.func,
        selected: PropTypes.object,
        selectLocation: PropTypes.func,
        users: PropTypes.array,
        usersToNotify: PropTypes.array,
        validateForm: PropTypes.func.isRequired,
        visible: PropTypes.bool,
        WonderlandSchemaForm: PropTypes.func.isRequired
    };

    render() {
        try {
            return this.doRender();
        } catch (e) {
            console.error('SelectLocationModalComponent::render', e);
        }
    }

    validateForm = () => this.props.validateForm({ fireSubmitAutomatically: true });

    doRender() {
        const { hide, visible, WonderlandSchemaForm } = this.props;

        return (
            <FormDialog
                visible={visible}
                hide={hide}
                onSubmit={this.validateForm}
                submitLabel="Service"
                formLabel="Service Details"
            >
                <SelectLocationForm WonderlandSchemaForm={WonderlandSchemaForm} />
            </FormDialog>
        );
    }
}
