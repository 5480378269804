import { AssetStoreAPI } from 'core/apis';
import { sliceContainer } from 'wonderland-ui-commons';

import SelectLocationModalActions from './SelectLocationModalActions';
import SelectLocationModalComponent from './SelectLocationModalComponent';
import SelectLocationModalController from './SelectLocationModalController';
import SelectLocationModalSlice from './SelectLocationModalSlice';
import WonderlandDomainController from 'core/WonderlandDomain';

@sliceContainer({
    slice: SelectLocationModalSlice,
    component: SelectLocationModalComponent
})
export default class SelectLocationModalContainer {
    static fetchLocations(filter) {
        return AssetStoreAPI.fetchLocations({ filter }).then(({ results }) => results.filter(c => c.allowed));
    }

    static selectLocation(locationId, locationName, assetIdOrIds, notes, usersToNotify) {
        const users = usersToNotify.map(({ email, first_name: firstName, last_name: lastName, value }) => ({
            name: email ? `${firstName} ${lastName}` : value,
            email: email || value
        }));
        if (Array.isArray(assetIdOrIds)) {
            WonderlandDomainController.requestService(locationId, assetIdOrIds, locationName, notes, users);
        } else {
            WonderlandDomainController.requestService(locationId, [assetIdOrIds], locationName, notes, users);
        }
    }

    static hide() {
        SelectLocationModalActions.hide();
    }

    static handleUsersInputChange(inputValue) {
        const params = {
            filter: inputValue,
            limit: 20
        };
        return SelectLocationModalController.getUsersPagination(params);
    }

    static mapSliceStateToProps(slice, props) {
        return {
            ...slice,
            fetchLocations: this.fetchLocations,
            handleUsersInputChange: this.handleUsersInputChange,
            hide: this.hide,
            selectLocation: this.selectLocation
        };
    }
}
