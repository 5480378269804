import './ShareSlice';
import { autobind } from 'wonderland-ui-commons';
import history from 'lib/history';
import ShareActions from './ShareActions';

const { reset, setConfig } = ShareActions;

@autobind
class ShareController {
    async navigateToShare(config) {
        reset();
        await setConfig(config);
        history.push(`/share`);
    }
}

export default new ShareController();
