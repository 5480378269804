import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import React from 'react';
import RoleCheck from 'wonderland-ui-security/lib/Components/RoleCheck';
import Tooltip from '@material-ui/core/Tooltip';

const DistributionListContactsItemComponent = ({
    classes: { ListItemTextRoot },
    handleUpdate,
    icon,
    iconColor,
    label,
    id,
    checked
}) => {
    const tooltipText = checked ? 'Exclude from notification' : 'Notify this user';
    try {
        return (
            <ListItem>
                <ListItemText primary={label} className={ListItemTextRoot} />
                <RoleCheck roles={['admin']}>
                    <ListItemSecondaryAction>
                        <Tooltip title={tooltipText} placement="top">
                            <IconButton onClick={handleUpdate}>
                                <Icon color={iconColor} id={id} fontSize="large">
                                    {icon}
                                </Icon>
                            </IconButton>
                        </Tooltip>
                    </ListItemSecondaryAction>
                </RoleCheck>
            </ListItem>
        );
    } catch (error) {
        console.error('DistributionListContactsListItemComponent::render', error);
    }
    return null;
};

DistributionListContactsItemComponent.propTypes = {
    classes: PropTypes.object,
    handleUpdate: PropTypes.func.isRequired,
    icon: PropTypes.string.isRequired,
    iconColor: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired
};

export default DistributionListContactsItemComponent;
