import _ from 'lodash';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import FilterIcon from '@material-ui/icons/Filter';
import ImageIcon from '@material-ui/icons/Image';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import MovieIcon from '@material-ui/icons/Movie';
import PageviewIcon from '@material-ui/icons/Pageview';
import PermCameraMicIcon from '@material-ui/icons/PermCameraMic';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import React from 'react';
import SubtitlesIcon from '@material-ui/icons/Subtitles';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import TheatersIcon from '@material-ui/icons/Theaters';
import ThreeDRotationIcon from '@material-ui/icons/ThreeDRotation';
import VideocamIcon from '@material-ui/icons/Videocam';

const Types = {
    item: { color: 'teal', icon: <TheatersIcon /> },
    '3D': { color: 'teal', icon: <ThreeDRotationIcon /> },
    audio: { color: 'orange', icon: <AudiotrackIcon /> },
    collection: { color: 'amber', icon: <FilterIcon /> },
    composition: { color: 'brown', icon: <PermMediaIcon /> },
    default: { color: 'grey', icon: <PageviewIcon /> },
    document: { color: 'purple', icon: <InsertDriveFileIcon /> },
    essence: { color: 'cyan', icon: <PermCameraMicIcon /> },
    film: { color: 'black', icon: <VideocamIcon /> },
    image: { color: 'green', icon: <ImageIcon /> },
    pdf: { color: 'purple', icon: <PictureAsPdfIcon /> },
    subtitle: { color: 'blue', icon: <SubtitlesIcon /> },
    'timed text': { color: 'amber', icon: <TextFieldsIcon /> },
    video: { color: 'red', icon: <MovieIcon /> }
};

export default {
    getAll: type => _.get(Types, type, Types.default),
    getColor: type => _.get(Types[type], 'color', Types.default.color),
    getIcon: type => _.get(Types[type], 'icon', Types.default.icon)
};
