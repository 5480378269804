import { AsperaService, withAsperaUpload } from 'wonderland-ui-aspera';
import { compose, withHandlers } from 'recompose';
import { ToastController } from 'wonderland-ui-commons';

export default props =>
    compose(
        withAsperaUpload(props),
        withHandlers({
            upload:
                ({ uploadWithAspera }) =>
                async (...args) =>
                    AsperaService.connected
                        ? uploadWithAspera(...args)
                        : ToastController.showError('Aspera must be connected.')
        })
    );
