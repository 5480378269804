import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';

const SearchFormMenuItemComponent = ({ handleMenuItem, searchField }) => {
    const { fieldLabel } = searchField;
    const handleItem = () => handleMenuItem(searchField);

    return <MenuItem onClick={handleItem}>{fieldLabel}</MenuItem>;
};

SearchFormMenuItemComponent.propTypes = {
    handleMenuItem: PropTypes.func.isRequired,
    searchField: PropTypes.object.isRequired
};

export default SearchFormMenuItemComponent;
