// history.js
import { createBrowserHistory } from 'history';
import { parse, stringify } from 'qs';
import AppEventsActions from 'core/AppEvents/AppEventsActions';
import qhistory from 'qhistory';
const history = qhistory(createBrowserHistory(), stringify, parse);
history.listen(() => {
    AppEventsActions.pageView(window.location.pathname);
});

export default history;
