import { branch, defaultProps } from 'recompose';
import { get, identity } from 'lodash';
import config from './config';

export function withConfig(mapConfigToProps = identity) {
    return defaultProps(mapConfigToProps(config));
}

export function enableOnConfig(property, { defaultEnabled = false } = {}) {
    return hoc => branch(() => get(config, property, defaultEnabled), hoc);
}
