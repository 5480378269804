import { ToastController } from 'wonderland-ui-commons';
import { validation } from 'lib/form';
import { ValidatorForm } from 'react-material-ui-form-validator';
import config from 'app/config';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import split from 'lodash/split';

const { share, fileTypeMapping } = config;
const shareTypes = Object.keys(share);

function getTypeAndExtension(option) {
    if (!_.isNil(option) && option.indexOf('/') === -1) {
        const mapping = get(fileTypeMapping, option, option);
        return split(mapping, '/');
    }
    return split(option, '/');
}

function checkFileType(option) {
    const [type, extension] = getTypeAndExtension(option);
    const { profiles = [] } = share[type] || {};
    const includedFileType = shareTypes.find(shareType => share[shareType].fileTypes.includes(option));

    return includedFileType ? includedFileType : isEmpty(profiles) ? extension : type;
}

export const burnInProfiles = shareTypes.filter(type => share[type].burnIn && type);
export const forensicProfiles = shareTypes.filter(type => share[type].forensic && type);

export function addFormValidation(expirationDate, fieldChange, maxDate, minDate) {
    ValidatorForm.addValidationRule('nonEmptyArray', validation.nonEmptyArray);

    if (expirationDate.toISOString() > maxDate.toISOString()) {
        ToastController.showError('available and expiration date have reset because guest recipients have been added');
        fieldChange('expirationDate', maxDate);
        fieldChange('startDate', minDate);
    }
}

export function getBurnInSettings(sliceDownload, sliceForensic, options) {
    const checked = options.flatMap(({ name, profiles }) =>
        profiles.filter(({ checked }) => checked).map(({ type }) => ({ type, label: name }))
    );
    const checkedForensics = checked.filter(({ label }) => forensicProfiles.includes(label)).map(({ type }) => type);
    const disableBurnInForm = !checked.some(({ label }) => burnInProfiles.includes(label));
    const hasOriginal = checkedForensics.includes('original');
    const disableForensic = !(sliceDownload && checkedForensics.length && !hasOriginal);
    const forensic = hasOriginal ? hasOriginal : sliceForensic && disableForensic ? false : sliceForensic;
    const download = forensic ? forensic : sliceDownload;

    return { disableBurnInForm, disableForensic, download, forensic };
}

export function getFilteredOptions(stats) {
    const { options: statsOptions } = stats;
    const { nonTransOptions, transOptions } = Object.values(
        statsOptions
            .map(({ count, option, size }) => ({ count, option: checkFileType(option), size }))
            .reduce((acc, { count, option, size }) => {
                acc[option] = {
                    count: ((acc[option] || {}).count || 0) + count,
                    option,
                    size: ((acc[option] || {}).size || 0) + size
                };

                return acc;
            }, {})
    ).reduce(
        (acc, { option, count }) => {
            const addProfiles = !!shareTypes.includes(option);
            const profiles = addProfiles ? share[option].profiles : [];
            const profileOptions = addProfiles ? { profiles } : null;

            acc[addProfiles ? 'transOptions' : 'nonTransOptions'].push({
                count,
                name: option ? option : 'unknown',
                ...profileOptions
            });

            return acc;
        },
        { nonTransOptions: [], transOptions: [] }
    );
    const options = transOptions.map(item => ({ ...item, name: share[item.name].label || item.name }));

    return { options, nonTransOptions };
}
