import { actionDispatcher } from 'react-redux-boilerout';

@actionDispatcher({
    namespace: 'IMFReplacement',
    actions: [
        'add',
        'addMany',
        'addNote',
        'addUndo',
        'autoMatch',
        'cancel',
        'clear',
        'filter',
        'init',
        'initComplete',
        'match',
        'matchUndo',
        'notify',
        'propagateAndIngest',
        'propagateAndIngestFailed',
        'reset',
        'remove',
        'removeMany',
        'removeUndo',
        'selectNewEssence',
        'selectOriginalEssence',
        'setHideNew',
        'setHideOriginal',
        'toggleHideNew',
        'toggleHideOriginal'
    ]
})
export default class IMFReplacementActions {}
