import { Grid, Icon, IconButton, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const style = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 400
};

const DistributionListContactsHeaderComponent = ({ closeDrawer, distributionListSelected }) => {
    try {
        return (
            <Grid alignItems="center" container justify="space-between">
                <Typography variant="h5" style={style}>
                    {distributionListSelected.name} Users
                </Typography>
                <IconButton onClick={closeDrawer}>
                    <Icon color="secondary">close</Icon>
                </IconButton>
            </Grid>
        );
    } catch (error) {
        console.error('DistributionListContactsHeaderComponent::render', error);
    }
    return null;
};

DistributionListContactsHeaderComponent.propTypes = {
    closeDrawer: PropTypes.func.isRequired,
    distributionListSelected: PropTypes.object.isRequired
};

export default DistributionListContactsHeaderComponent;
