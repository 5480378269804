import { reducerRegistry } from 'lib/redux';
import { registerSliceReducer, sliceReducer, subscribe } from 'react-redux-boilerout';

@registerSliceReducer({ registry: reducerRegistry })
@sliceReducer('AddToCollectionModal')
@subscribe({ namespace: 'AddToCollectionModal' })
export default class AddToCollectionModalSliceReducer {
    initialState() {
        return {
            activeFacets: ['asset_type'],
            assetIdOrIds: null,
            collection: null,
            facetCriteria: [{ name: 'asset_type', type: 'facet', values: ['collection'] }],
            index: 'content',
            limit: 25,
            open: false,
            skip: 0
        };
    }

    onHide(slice) {
        return this.initialState();
    }

    onSetCollection(slice, collection) {
        return { ...slice, collection };
    }

    onShow(slice, assetIdOrIds) {
        return { ...slice, assetIdOrIds, open: true };
    }
}
