import { Grid, Icon, InputAdornment } from '@material-ui/core';
import LookupTextField from 'app/shared/LookupTextField';
import PropTypes from 'prop-types';
import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

const sheet = ({ spacing: { unit } }) => ({
    searchBar: {
        padding: unit * 2
    }
});

const TopBarComponent = ({ classes: { searchBar }, onChange, value }) => (
    <Grid className={searchBar} container>
        <LookupTextField
            autoComplete="off"
            autoFocus
            debounce
            fullWidth
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <Icon fontSize="small">search</Icon>
                    </InputAdornment>
                )
            }}
            name="searchTerm"
            onChange={onChange}
            placeholder="Search"
            value={value}
        />
    </Grid>
);

TopBarComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired
};

export default withStyles(sheet)(TopBarComponent);
