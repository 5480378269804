import { Button, Icon, Snackbar } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const RelatedSnackBarComponent = ({
    classes: { snackBar, snackBarAction },
    handleAddChildren,
    handleAddParents,
    handleRemoveChildren,
    hasSelected: { asset, child, parent },
    relationshipType,
    typeNames
}) => (
    <Snackbar
        action={
            <Fragment>
                {asset && parent && (
                    <Button color="primary" onClick={handleAddChildren} size="large">
                        Add Children As {typeNames[relationshipType]} <Icon>chevron_right</Icon>
                    </Button>
                )}
                {asset && !parent && (
                    <Button color="primary" onClick={handleAddParents} size="large">
                        Add As Parents <Icon>chevron_right</Icon>
                    </Button>
                )}
                {child && (
                    <Button color="primary" onClick={handleRemoveChildren} size="large">
                        Remove Children
                    </Button>
                )}
            </Fragment>
        }
        ContentProps={{ classes: { action: snackBarAction, root: snackBar } }}
        open
    />
);

RelatedSnackBarComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    handleAddChildren: PropTypes.func.isRequired,
    handleAddParents: PropTypes.func.isRequired,
    handleRemoveChildren: PropTypes.func.isRequired,
    hasSelected: PropTypes.object.isRequired,
    relationshipType: PropTypes.string.isRequired,
    typeNames: PropTypes.object.isRequired
};

export default RelatedSnackBarComponent;
