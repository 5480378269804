import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    ListItem,
    ListItemAvatar,
    ListItemText
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import TypeAhead from 'wonderland-ui-commons/lib/Typeahead';

const AddToCollectionModalComponent = ({
    assetCount,
    avatar,
    classes,
    collection,
    disabled,
    handleChange,
    handleClose,
    handleLoadOptions,
    handleNoOptionsMessage,
    handleSubmit,
    open
}) => {
    const { color, icon } = avatar;
    const { listItemRoot } = classes;
    const handleOptionLabel = useCallback(({ date, id, name }) => {
        return (
            <ListItem alignItems="flex-start" classes={{ root: listItemRoot }}>
                <ListItemAvatar>
                    <Avatar classes={{ root: classes[color] }}>{icon}</Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={name}
                    primaryTypographyProps={{ noWrap: true, variant: 'body2' }}
                    secondary={
                        <Grid container justify="space-between">
                            <Grid item>{id}</Grid>
                            <Grid item>{date}</Grid>
                        </Grid>
                    }
                    secondaryTypographyProps={{ noWrap: true, variant: 'caption' }}
                />
            </ListItem>
        );
    }, []);

    return (
        <Dialog aria-labelledby="dialog-title" fullWidth maxWidth="sm" onClose={handleClose} open={open}>
            <DialogTitle id="dialog-title">Add To Collection ({assetCount} Asset/s)</DialogTitle>
            <DialogContent>
                <TypeAhead
                    fullWidth
                    getOptionLabel={handleOptionLabel}
                    isAsync
                    isMulti={false}
                    loadOptions={handleLoadOptions}
                    menuPosition="fixed"
                    minLength={2}
                    name="collectionSearch"
                    noOptionsMessage={handleNoOptionsMessage}
                    onChange={handleChange}
                    placeholder="Collection Search"
                    value={collection}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary" disabled={disabled}>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

AddToCollectionModalComponent.propTypes = {
    assetCount: PropTypes.number.isRequired,
    avatar: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    collection: PropTypes.object,
    disabled: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleLoadOptions: PropTypes.func.isRequired,
    handleNoOptionsMessage: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};

export default AddToCollectionModalComponent;
