export const replaceEmptyStringsWithNull = updatedInChanges => {
    return Object.keys(updatedInChanges).reduce((acc, key) => {
        acc[key] = updatedInChanges[key] === '' ? null : updatedInChanges[key];
        return acc;
    }, {});
};

export const removeFieldsWithEmptyStrings = newAsset => {
    return Object.keys(newAsset).reduce((acc, key) => {
        if (newAsset[key] !== '') {
            acc[key] = newAsset[key];
        }
        return acc;
    }, {});
};
