/*
 * Usage
 *
 * export default {
 *   // Asset fields
 *   // field: 'FLAG'
 *   account: 'CUMULUS_METADATA_FIELDS',
 *
 *   // Asset field groups by asset type
 *   // 'view - type - group': 'FLAG'
 *   'bulkedit - 3D - Data Curator Administrative': 'ALICE_FIELDS'
 *
 * };
 *
 */

const attrToFlag = {
    /*
    Attributes feature flag assignment
    attribute_name: 'FEATURE_FLAG_NAME'
    */
};

export default attrToFlag;
