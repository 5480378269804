import { reducerRegistry } from 'lib/redux';
import { registerSliceReducer, sliceReducer, subscribe } from 'react-redux-boilerout';

@registerSliceReducer({ registry: reducerRegistry })
@sliceReducer('DuplicateAssetModal')
@subscribe({ namespace: 'DuplicateAssetModal' })
export default class DuplicateAssetModalSlice {
    initialState() {
        return {
            assetId: null,
            assetName: '',
            visible: false,
            checked: true
        };
    }

    onShow(slice, assetId, assetName) {
        return {
            ...slice,
            assetId,
            assetName: `Copy of ${assetName}`,
            visible: true,
            checked: true
        };
    }

    onHide(slice) {
        return {
            ...slice,
            assetName: '',
            visible: false
        };
    }
}
