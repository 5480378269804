import { castArray, get, isArray, isEmpty, slice } from 'lodash';
import AttributeChip from './AttributeChip';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

export function chips(asset, path, label, labelValue, tooltip) {
    const desc = get(asset, labelValue, null);
    const attrPath = path.split('.');
    const { prefix, attr } =
        attrPath.length > 1 ? { prefix: attrPath[0], attr: slice(attrPath, 1).join('.') } : { attr: attrPath[0] };
    const assetAttr = prefix ? get(asset, prefix, []).map(asset => get(asset, attr)) : get(asset, attr);
    const attrValues = isArray(assetAttr)
        ? assetAttr.filter(val => {
              const trimmedValue = _.trim(val);
              return !isEmpty(trimmedValue);
          })
        : assetAttr;
    const ignoreValues = ['No Language Identified - Use For Migration Only'];
    const valid =
        !isEmpty(attrValues) &&
        !ignoreValues.includes(attrValues) &&
        !(isArray(attrValues) && attrValues.some(item => ignoreValues.includes(item)));

    return (
        valid &&
        castArray(attrValues).map(
            (value, idx) =>
                value && (
                    <AttributeChip
                        assetType={asset.asset_type}
                        attr={attr}
                        desc={desc}
                        key={attr + idx}
                        label={label}
                        value={value}
                        tooltip={tooltip}
                    />
                )
        )
    );
}

/* istanbul ignore next */
const sheet = theme => ({
    ...theme.palette.assetTypeColor,
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
        paddingBottom: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
        '&.centered': {
            justifyContent: 'center'
        }
    }
});

@withStyles(sheet)
export default class AssetAttributeChipsComponent extends React.Component {
    static propTypes = {
        asset: PropTypes.object.isRequired,
        centered: PropTypes.bool,
        classes: PropTypes.object.isRequired
    };

    doRender() {
        const { asset, centered, classes } = this.props;
        return (
            <div className={classnames(classes.chips, { centered: centered })}>
                {chips(asset, 'content_type', 'Content Type', '', true)}
                {chips(asset, 'content_sub_type', 'Content Sub-Type', '', true)}
                {chips(asset, 'related_titles.episode_number', 'Episode Number', '', true)}
                {chips(asset, 'related_titles.foreign_episode_number', 'Foreign Episode Number', '', true)}
                {chips(asset, 'related_titles.domestic_episode_number', 'Domestic Episode Number', '', true)}
                {chips(asset, 'asset_language', 'Language', '', true)}
                {chips(asset, 'source_system', 'Source System ID', 'source_system_asset_id', true)}
                {chips(asset, 'short_id', 'Short ID', '', true)}
            </div>
        );
    }

    render() {
        try {
            return this.doRender();
        } catch (e) {
            console.error('AttributeChipsComponent::render', e);
        }
        return null;
    }
}
