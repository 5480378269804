import { urlParams, xhr } from 'wonderland-ui-commons';
import config from 'app/config';

const prefix = config.cvSaas.prefix;
const hostname = config.cvSaas.hostname;
const env = config.cvSaas.env;

const notEmpty = x => !!x;

function urlParamsFilterEncoded(data) {
    return Object.keys(data)
        .filter(k => !!data[k])
        .map(k => (k === 'filter' ? `${k}=${encodeURIComponent(data[k])}` : `${k}=${data[k]}`))
        .join('&');
}

function lookup(type, field, params) {
    let url = [hostname, prefix, 'lookup', env, type, field, params ? `?${urlParamsFilterEncoded(params)}` : '']
        .filter(notEmpty)
        .join('/');
    return xhr.json(url, { method: 'get' });
}

function search(criteria = [], skip, limit = config.templates.maxPageSize) {
    criteria.push({
        type: 'recent'
    });
    const query = urlParams({
        skip,
        limit
    });
    return xhr.json(`${hostname}/${prefix}/cv-saas/search?${query}`, {
        method: 'post',
        body: JSON.stringify({ criteria })
    });
}

export default {
    lookup,
    search
};
