const excludedAssetTypes = ['collection'];

export const excludeAsset = ({ asset_type, deleted, disabled }) =>
    excludedAssetTypes.includes(asset_type) || deleted || disabled;

export const typeNames = {
    ancillary_of: 'Ancillary Of',
    conformed_against: 'Conformed Against',
    derived_from: 'Derived From',
    rendition_of: 'Rendition Of'
};

export const types = Object.keys(typeNames);
