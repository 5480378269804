import { find, get } from 'lodash';

export const getTemplateFieldSpec = (templateMetadataSpec, fieldName) =>
    find(templateMetadataSpec, field => field.name === fieldName);

export const getTemplateSchemaFields = templateMetadataSpec =>
    templateMetadataSpec.filter(spec => spec.state !== 'HIDDEN').map(spec => spec.name);

export const getFieldSpecFlag = (fieldSpec, flag, defaultFlag) => get(fieldSpec, `state_flags.${flag}`, defaultFlag);

export const isStrictTemplate = template => template && template.strict;
