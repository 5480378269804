import { compose, withHandlers, withProps } from 'recompose';
import { connectSlice } from 'wonderland-ui-commons';
import { get } from 'lodash';
import classnames from 'classnames';
import DistributionListContactsComponent from './DistributionListContactsComponent';
import NotificationRecipientsActions from '../NotificationRecipientsActions';
import NotificationRecipientsSlice from '../NotificationRecipientsSlice';
import withStyles from '@material-ui/core/styles/withStyles';

const filterContacts = (contacts, showExcluded) => {
    const filterFunc = showExcluded ? id => contacts[id].filter : id => contacts[id].filter && !contacts[id].exclude;
    return Object.keys(contacts)
        .filter(filterFunc)
        .map(id => contacts[id]);
};

export default compose(
    connectSlice({ slice: NotificationRecipientsSlice }, (slice, props) => {
        const selectedDLId = get(slice, 'distributionListSelected.id', -1);
        const contactDetails = get(slice.notifications, `${selectedDLId}.contactDetails`, {});
        return {
            ...slice,
            contacts: filterContacts(contactDetails, slice.showExcluded)
        };
    }),
    withHandlers({
        handleClear: () => () => {
            NotificationRecipientsActions.onSearchInput('');
            NotificationRecipientsActions.onFilterContacts('');
        },
        handleSearch: () => event => {
            const searchTerm = event.target.value || '';
            NotificationRecipientsActions.onSearchInput(searchTerm);
            NotificationRecipientsActions.onFilterContacts(searchTerm);
        },
        handleShowExcluded:
            ({ showExcluded }) =>
            () => {
                NotificationRecipientsActions.setShowExcluded(!showExcluded);
            },
        handleUpdate: () => contactId => {
            NotificationRecipientsActions.onExcludeContactToggle(contactId);
        }
    }),
    withStyles(({ spacing: { unit } }) => ({
        expansionPanelActionsRoot: {
            backgroundColor: 'white',
            position: 'sticky',
            bottom: -`${unit}`
        },
        fieldControls: {
            flexDirection: 'column'
        },
        formControlLabelRoot: {
            alignSelf: 'flex-end',
            margin: 0
        },
        inputAdornmentRoot: {
            '&.hidden': {
                visibility: 'hidden'
            }
        },
        listRoot: {
            minWidth: unit * 44
        }
    })),
    withProps(({ classes, searchQuery }) => ({
        classes: {
            ...classes,
            inputAdornment: classnames(classes.inputAdornmentRoot, { hidden: !searchQuery })
        }
    }))
)(DistributionListContactsComponent);
