import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import withFeatureFlags from 'core/Cache/withFeatureFlags';

@withFeatureFlags
export default class FeatureFlagContainer extends PureComponent {
    static propTypes = {
        isFlagEnabled: PropTypes.func,
        negate: PropTypes.bool,
        feature: PropTypes.string,
        children: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
    };

    static defaultProps = {
        negate: false
    };

    render() {
        try {
            return this.doRender();
        } catch (e) {
            console.error('FeatureFlagContainer::render', e);
        }
        return null;
    }

    isRenderProp() {
        const { children } = this.props;
        return !React.isValidElement(children) && typeof children === 'function';
    }

    defaultRender = enabled => {
        const { children, negate } = this.props;
        return negate !== enabled ? children : null;
    };

    doRender() {
        const { isFlagEnabled, children, feature } = this.props;
        const enabled = isFlagEnabled(feature);
        const renderFn = this.isRenderProp() ? children : this.defaultRender;
        return renderFn(enabled);
    }
}
