import { urlParams, xhr } from 'wonderland-ui-commons';
import config from 'app/config';

const prefix = '/security/v1';

function getUsers({ skip, limit, skipCount, filter, group, role, sort, sortDir, includeInactive } = {}) {
    return xhr
        .json(
            `${config.apiHostname}${prefix}/user?${urlParams({
                skip,
                limit,
                skipCount: String(skipCount),
                includeInactive,
                filter,
                group,
                role,
                sort,
                sortDir
            })}`,
            {
                method: 'get'
            }
        )
        .then(res => {
            if (skipCount) {
                return res;
            } else {
                return xhr
                    .json(
                        `${config.apiHostname}${prefix}/user?${urlParams({
                            skip: 0,
                            limit: 1,
                            skipCount: 'false',
                            includeInactive,
                            filter,
                            group,
                            role
                        })}`,
                        {
                            method: 'get'
                        }
                    )
                    .then(r => ({ ...res, total: r.total }));
            }
        });
}

function getGroups({ skip, limit, filter, includeCount, namespace, sort, sortDir } = {}) {
    return xhr.json(
        `${config.apiHostname}${prefix}/group?${urlParams({
            skip,
            limit,
            filter,
            includeCount,
            namespace,
            sort,
            sortDir
        })}`,
        {
            method: 'get'
        }
    );
}

function getSubjects({ skip, limit, filter } = {}) {
    return xhr.json(`${config.apiHostname}${prefix}/subject?${urlParams({ skip, limit, filter })}`, {
        method: 'get'
    });
}

function createUser(payload) {
    return xhr.json(`${config.apiHostname}${prefix}/user`, {
        method: 'post',
        body: JSON.stringify(payload)
    });
}

function deleteUser(name) {
    return xhr.json(`${config.apiHostname}${prefix}/user/${name}`, {
        method: 'delete'
    });
}

function deleteUsers(ids) {
    return xhr.json(`${config.apiHostname}${prefix}/users`, { method: 'delete', body: JSON.stringify({ ids }) });
}

function editUser(name, payload) {
    return xhr.json(`${config.apiHostname}${prefix}/user/${name}`, {
        method: 'put',
        body: JSON.stringify(payload)
    });
}

function editUsers(payload) {
    return xhr.json(`${config.apiHostname}${prefix}/users`, { method: 'put', body: JSON.stringify(payload) });
}

function createGroup(payload) {
    return xhr.json(`${config.apiHostname}${prefix}/group`, {
        method: 'post',
        body: JSON.stringify(payload)
    });
}

function getGroup(name) {
    return xhr.json(`${config.apiHostname}${prefix}/group/${name}`, {
        method: 'get'
    });
}

function editGroup(name, payload) {
    return xhr.json(`${config.apiHostname}${prefix}/group/${name}`, {
        method: 'put',
        body: JSON.stringify(payload)
    });
}

function deleteGroup(name) {
    return xhr.json(`${config.apiHostname}${prefix}/group/${name}`, {
        method: 'delete'
    });
}

function getGrants() {
    return xhr.json(`${config.apiHostname}${prefix}/grants?${urlParams({ namespace: config.namespace })}`, {
        method: 'get'
    });
}

function getRoles() {
    return xhr.json(`${config.apiHostname}${prefix}/roles?${urlParams({ namespace: config.namespace })}`, {
        method: 'get'
    });
}

function getRolesUnfiltered(sort) {
    return xhr.json(`${config.apiHostname}${prefix}/roles?${urlParams({ sort })}`, {
        method: 'get'
    });
}

function getUser(name) {
    return xhr.json(`${config.apiHostname}${prefix}/user/${name}`, {
        method: 'get'
    });
}

function postPolicy(payload) {
    return xhr.json(`${config.apiHostname}${prefix}/policy`, {
        method: 'post',
        body: JSON.stringify(payload)
    });
}

function getPolicy() {
    return xhr
        .json(`${config.apiHostname}${prefix}/policy?${urlParams({ namespace: config.namespace, limit: 1000 })}`, {
            method: 'get'
        })
        .then(res => res.results);
}

function putPolicy(policyId, payload) {
    return xhr.json(`${config.apiHostname}${prefix}/policy/${policyId}`, {
        method: 'put',
        body: JSON.stringify(payload)
    });
}

function deletePolicy(policyId) {
    return xhr.json(`${config.apiHostname}${prefix}/policy/${policyId}`, {
        method: 'delete'
    });
}

function postPolicyAce(policyId, payload) {
    return xhr.json(`${config.apiHostname}${prefix}/${policyId}/ace`, {
        method: 'post',
        body: JSON.stringify(payload)
    });
}

function getPolicyAce(policyId, aceId) {
    return xhr.json(`${config.apiHostname}${prefix}/${policyId}/ace/${aceId}`, {
        method: 'get'
    });
}

function putPolicyAce(policyId, aceId, payload) {
    return xhr.json(`${config.apiHostname}${prefix}/policy/${policyId}/ace/${aceId}`, {
        method: 'put',
        body: JSON.stringify(payload)
    });
}

function deletePolicyAce(policyId, aceId) {
    return xhr.json(`${config.apiHostname}${prefix}/policy/${policyId}/ace/${aceId}`, {
        method: 'delete'
    });
}

function getDefaultGrants(namespace = 'wonderland') {
    return xhr.json(`${config.apiHostname}${prefix}/grants?${urlParams({ namespace })}`, {
        method: 'get'
    });
}

function getAcl(aclId) {
    return xhr.json(`${config.apiHostname}${prefix}/acl/${aclId}`, {
        method: 'get'
    });
}

function evaluateContentPolicy(type, ids, context) {
    return xhr.json(`${prefix}/contentpolicy/check/${type}`, {
        method: 'post',
        body: JSON.stringify({ ids, context })
    });
}

export default {
    evaluateContentPolicy,
    getUsers,
    getGroups,
    getSubjects,
    createUser,
    deleteUser,
    deleteUsers,
    editUser,
    editUsers,
    createGroup,
    getGroup,
    editGroup,
    deleteGroup,
    getGrants,
    getRoles,
    getRolesUnfiltered,
    getUser,
    getDefaultGrants,
    postPolicy,
    getPolicy,
    putPolicy,
    deletePolicy,
    postPolicyAce,
    getPolicyAce,
    putPolicyAce,
    deletePolicyAce,
    getAcl
};
