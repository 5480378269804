import { compose, withHandlers, withProps } from 'recompose';
import { connectSlice } from 'wonderland-ui-commons';
import { get, set, unionBy } from 'lodash';
import { withDefaultPreferences, withProfile } from 'core/Profile';
import TableListActions from 'app/shared/AssetList/TableList/TableListActions';
import TableListSlice from 'app/shared/AssetList/TableList/TableListSlice';

export default (config = { tablePreferencesKey: 'searchResultPreferences' }) => {
    const { tablePreferencesKey } = config;
    return compose(
        withDefaultPreferences,
        withProfile(({ profile }) => ({
            profileTableFields: get(profile, `preferences.${tablePreferencesKey}.tableFields`),
            tableListSettings: get(profile, `preferences.${tablePreferencesKey}.tableListSettings`, [])
        })),
        withProps(({ defaultPreferences, profileTableFields }) => {
            const defaultTableListFields = get(defaultPreferences, `${tablePreferencesKey}.tableFields`);
            return {
                defaultTableListFields,
                tableFields: unionBy(profileTableFields, defaultTableListFields, field => field.label)
            };
        }),
        connectSlice({ slice: TableListSlice }, (slice, { tableFields, tableListSettings }) => {
            const tableListFields = slice.tableListFields.length ? slice.tableListFields : tableFields;
            const settings = slice.tableListSettings ? slice.tableListSettings : tableListSettings;
            return {
                ...slice,
                tableListFields,
                tableListSettings: settings,
                deliveryApproval: settings.includes('deliveryApproval'),
                fileExists: settings.includes('fileExists'),
                textSmall: settings.includes('textSmall'),
                textWrap: settings.includes('textWrap')
            };
        }),
        withProps(({ defaultTableListFields, tableListFields }) => ({
            selectedTableListFields: tableListFields.filter(field => field.checked) || defaultTableListFields
        })),
        withHandlers({
            updateTableFields:
                ({ saveProfile }) =>
                (nextTableFields, tableListSettings) => {
                    const update = set({}, `preferences.${tablePreferencesKey}.tableFields`, nextTableFields);
                    set(update, `preferences.${tablePreferencesKey}.tableListSettings`, tableListSettings);
                    return saveProfile(update);
                },
            updateTableListFields: () => fields => {
                TableListActions.updateTableListFields(fields); //temp fields for view unsaved
            },
            updateTableListSettings: () => newTableListSettings => {
                TableListActions.updateTableListSettings(newTableListSettings);
            }
        })
    );
};
