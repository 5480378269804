import { autobind, ToastController, withProgress } from 'wonderland-ui-commons';
import { get } from 'lodash';
import { WonderlandDomainAPI } from './apis';
import assert from 'assert';
import config from '../app/config';
import formatBytes from 'lib/formatBytes';
import WonderlandDomainActions from './WonderlandDomain/WonderlandDomainActions';
const maxBytes = config.bulkdownload.downloadMaxByteSizeLimit;

@autobind
class DownloadController {
    @withProgress({ label: 'bulk_download_size_validation' })
    async validateAndBulkDownload(opts, downloadFn, ignoreSizeValidation) {
        if (!ignoreSizeValidation) {
            const { size } = await WonderlandDomainAPI.bulkStats({ type: 'ids', ...opts });
            if (size > maxBytes) {
                ToastController.showError(
                    `Download size (${formatBytes(size)}) exceeds the maximum size limit of ${formatBytes(
                        maxBytes
                    )}. Try switching to Aspera to download large files.`
                );
                return;
            }
        }
        return downloadFn(opts).catch(ToastController.showError);
    }

    validateAndDownload(args, downloadFn, ignoreSizeValidation) {
        return this.validateAndBulkDownload(
            { type: 'ids', asset_ids: [args.assetId], ...args },
            downloadFn,
            ignoreSizeValidation
        );
    }

    multifileResourceDownload(data) {
        const { type, asset_ids, assetId, version } = data;
        assert(type === 'multifileResource', 'invalid bulk download type provided');
        assert(asset_ids.length === 1, 'Resources can only be downloaded from 1 multifile asset at a time');
        WonderlandDomainActions.bulkDownloadRequested(type, asset_ids);
        const jobData = {
            type,
            asset_ids: asset_ids.map(id => get(id, 'id', id)),
            resourceOptions: {
                ...(assetId.resource_ids && { resource_ids: assetId.resource_ids }),
                ...(version && { version })
            }
        };
        return WonderlandDomainAPI.bulkDownload(type, jobData).then(() => {
            ToastController.show(
                "Your files are being prepared for download. You'll be notified when you can start the download."
            );
        });
    }
    downloadThroughZipAssetCreation(data) {
        const { type, assetIds } = data;
        assert(['ids', 'collection', 'lightbox', 'discovery'].includes(type), 'invalid bulk download type provided');
        WonderlandDomainActions.bulkDownloadRequested(type, assetIds);

        return WonderlandDomainAPI.bulkDownload(type, data).then(() => {
            ToastController.show(
                "Your files are being prepared for download. You'll be notified when you can start the download."
            );
        });
    }
}

export default new DownloadController();
