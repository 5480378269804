import { isEqual } from 'lodash';

export default (a, b) =>
    Object.entries(b).reduce((acc, [key, value]) => {
        if (!isEqual(a[key], value)) {
            acc[key] = value;
        }

        return acc;
    }, {});
