import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import BulkSelectionToggleComponent from './BulkSelectionToggleComponent';

export default class BulkSelectionToggleContainer extends PureComponent {
    static propTypes = {
        selectedAssetsCount: PropTypes.number.isRequired,
        totalAssetsCount: PropTypes.number.isRequired,
        deselectCurrentItems: PropTypes.func.isRequired,
        selectCurrentItems: PropTypes.func.isRequired
    };

    onToggle = () => {
        const { selectedAssetsCount, totalAssetsCount, selectCurrentItems, deselectCurrentItems } = this.props;

        if (selectedAssetsCount === totalAssetsCount) {
            deselectCurrentItems();
        } else {
            selectCurrentItems();
        }
    };

    render() {
        const { selectedAssetsCount, totalAssetsCount, ...rest } = this.props;
        let status;
        if (selectedAssetsCount === 0) {
            status = 'unchecked';
        } else if (selectedAssetsCount === totalAssetsCount) {
            status = 'checked';
        } else {
            status = 'indeterminate';
        }

        return <BulkSelectionToggleComponent {...rest} onToggle={this.onToggle} status={status} />;
    }
}
