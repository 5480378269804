import { withStyles } from 'wonderland-ui-material';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';

/* istanbul ignore next */
const sheet = theme => ({
    noResultsText: {
        color: theme.palette.disabledColor
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.spacing.unit * 4
    }
});

@withStyles(sheet)
export default class NoResultsComponent extends React.PureComponent {
    static propTypes = {
        classes: PropTypes.object.isRequired
    };

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.container}>
                <Typography variant={'h4'} align={'center'} className={classes.noResultsText}>
                    No Results
                </Typography>
                <Typography variant={'h6'} align={'center'} className={classes.noResultsText}>
                    Try searching something!
                </Typography>
            </div>
        );
    }
}
