import { actionDispatcher } from 'react-redux-boilerout';

@actionDispatcher({
    namespace: 'Search',
    actions: [
        'clearSelectedItems',
        'deselectCurrentItems',
        'fetchSearchCompleted',
        'reset',
        'selectCurrentItems',
        'tabViewChange',
        'toggleItemSelection'
    ]
})
export default class SearchActions {}
