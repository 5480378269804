import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import RelatedCard from 'app/Relationship/RelatedCard';

const RelatedAssetsComponent = ({
    assets,
    classes,
    handleAddChildren,
    handleAddParents,
    handleSelectAsset,
    imageMode,
    metadataMode,
    relationshipType,
    selectedIds,
    selectedParentId,
    typeNames,
    types
}) => {
    const { gridList } = classes;

    return (
        <Grid alignItems="center" className={gridList} container direction="column" wrap="nowrap">
            {assets.map((asset, idx) => {
                const { id } = asset;
                const selected = selectedIds.includes(id);
                const toggleSelected = () => handleSelectAsset(id);

                return (
                    <Grid item key={idx}>
                        <RelatedCard
                            asset={asset}
                            classes={classes}
                            group={'asset'}
                            handleAddChildren={handleAddChildren}
                            handleAddParents={handleAddParents}
                            imageMode={imageMode}
                            metadataMode={metadataMode}
                            selected={selected}
                            relationshipType={relationshipType}
                            selectedParentId={selectedParentId}
                            toggleSelected={toggleSelected}
                            typeNames={typeNames}
                            types={types}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
};

RelatedAssetsComponent.propTypes = {
    assets: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    handleAddChildren: PropTypes.func.isRequired,
    handleAddParents: PropTypes.func.isRequired,
    handleSelectAsset: PropTypes.func.isRequired,
    imageMode: PropTypes.bool.isRequired,
    metadataMode: PropTypes.bool.isRequired,
    relationshipType: PropTypes.string,
    selectedIds: PropTypes.array,
    selectedParentId: PropTypes.string,
    typeNames: PropTypes.object.isRequired,
    types: PropTypes.array.isRequired
};

export default RelatedAssetsComponent;
