import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    FormControlLabel,
    Grid,
    Icon,
    IconButton,
    InputAdornment,
    Switch,
    TextField
} from '@material-ui/core';
import DistributionListContactsHeader from './DistributionListContactsHeader';
import DistributionListContactsItem from './DistributionListContactsItem';
import List from '@material-ui/core/List';
import PropTypes from 'prop-types';
import React from 'react';

const DistributionListContactsComponent = props => {
    const {
        classes: { fieldControls, formControlLabelRoot, inputAdornment, listRoot },
        closeDrawer,
        handleClear,
        handleSearch,
        handleShowExcluded,
        handleUpdate,
        distributionListSelected,
        searchQuery,
        contacts,
        showExcluded
    } = props;
    try {
        return (
            <Grid>
                <DistributionListContactsHeader
                    closeDrawer={closeDrawer}
                    distributionListSelected={distributionListSelected}
                />
                <ExpansionPanel expanded>
                    <ExpansionPanelSummary classes={{ content: fieldControls }}>
                        <TextField
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment classes={{ root: inputAdornment }} position="end">
                                        <IconButton onClick={handleClear} size="small">
                                            <Icon fontSize="small">clear</Icon>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Icon>search</Icon>
                                    </InputAdornment>
                                )
                            }}
                            label={`Search users in ${distributionListSelected.name}`}
                            onChange={handleSearch}
                            value={searchQuery}
                        />
                        <FormControlLabel
                            classes={{ root: formControlLabelRoot }}
                            control={<Switch checked={showExcluded} color="secondary" onChange={handleShowExcluded} />}
                            label="Show Excluded"
                        />
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <List classes={{ root: listRoot }}>
                            {contacts.map(({ exclude, email, id }) => (
                                <DistributionListContactsItem
                                    checked={!exclude}
                                    showExcluded={showExcluded}
                                    key={id}
                                    id={id}
                                    label={email}
                                    update={handleUpdate}
                                />
                            ))}
                        </List>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </Grid>
        );
    } catch (error) {
        console.error('DistributionListContactsComponent::render', error);
    }
    return null;
};

DistributionListContactsComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    distributionListSelected: PropTypes.object.isRequired,
    contacts: PropTypes.array.isRequired,
    closeDrawer: PropTypes.func.isRequired,
    searchQuery: PropTypes.string,
    handleClear: PropTypes.func.isRequired,
    handleSearch: PropTypes.func.isRequired,
    handleShowExcluded: PropTypes.func.isRequired,
    handleUpdate: PropTypes.func.isRequired,
    showExcluded: PropTypes.bool.isRequired
};

export default DistributionListContactsComponent;
