import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

const MetadataModeComponent = ({ metadataMode, toggleMode }) => {
    try {
        return (
            <Tooltip title={metadataMode ? 'Hide Metadata' : 'Show Metadata'} placement="bottom">
                <IconButton aria-label="Hide Metadata" color="secondary" onClick={toggleMode}>
                    <Icon>{metadataMode ? 'visibility_off' : 'visibility'}</Icon>
                </IconButton>
            </Tooltip>
        );
    } catch (error) {
        console.error('MetadataModeComponent::render', error);
    }
};

MetadataModeComponent.propTypes = {
    metadataMode: PropTypes.bool,
    toggleMode: PropTypes.func
};

export default MetadataModeComponent;
