import { branch, compose } from 'recompose';
import { get } from 'lodash';
import { withProfile } from 'core/Profile';

export default function enableOnProfileOption(profileOptionPath, { defaultEnabled = true } = {}) {
    return hoc =>
        compose(
            withProfile(),
            branch(props => get(props.profile, profileOptionPath, defaultEnabled), hoc)
        );
}
