import { compose, lifecycle, withHandlers, withProps } from 'recompose';
import { withProfile } from 'core/Profile';
import { withUser } from 'wonderland-ui-security';
import AppLayoutComponent from './AppLayoutComponent';
import history from 'lib/history';
import withStyles from '@material-ui/core/styles/withStyles';

export default compose(
    withUser(),
    withProfile(),
    lifecycle({
        componentDidCatch(error) {
            console.error(error);
        }
    }),
    withProps({
        excludes: ['', 'checkedout', 'lightbox', 'relationship', 'search', 'shared']
    }),
    withHandlers({
        showFooter:
            ({ excludes }) =>
            () =>
                !excludes.includes(history.location.pathname.substring(1).split('/', 1).toString())
    }),
    withStyles(({ spacing: { unit } }) => ({
        announcement: {
            height: unit * 6,
            marginLeft: unit * 9,
            marginTop: unit * 7,
            overflow: 'hidden',
            '&:empty': {
                height: 0
            }
        },
        main: {
            minHeight: `calc(100vh - ${unit * 40}px)`,
            paddingLeft: unit * 8,
            paddingRight: unit,
            paddingTop: unit * 2
        }
    }))
)(AppLayoutComponent);
