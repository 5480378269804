import AttributeChips from 'wonderland-ui-commons/lib/AttributeChips';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

const sheet = () => ({
    ruleName: {
        display: 'flex',
        justifyContent: 'space-between'
    }
});

const SmartTagOption = ({ classes, name, tags }) => {
    return (
        <div className={classes.ruleName}>
            <Typography>{name}</Typography>
            <AttributeChips item={{ tags }} pickerKey="tags" defaultPickerChips={[{ key: 'tags', label: 'Tags' }]} />
        </div>
    );
};

SmartTagOption.propTypes = {
    classes: PropTypes.object,
    name: PropTypes.string.isRequired,
    tags: PropTypes.array.isRequired
};
export default withStyles(sheet)(SmartTagOption);
