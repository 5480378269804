import { autobind, ToastController } from 'wonderland-ui-commons';
import { SecurityAPI } from 'core/apis';

const filterSystemUsers = user => {
    const userPatternsToExclude = [/asset-loader/];

    return userPatternsToExclude.reduce((shouldIncludeUser, userPattern) => {
        return shouldIncludeUser && !user.name.match(userPattern);
    }, true);
};

@autobind
class SelectLocationModalController {
    getUsersPagination({ filter = null, limit = 25 }) {
        return SecurityAPI.getUsers({ filter, limit }).then(
            res => res.results.filter(filterSystemUsers),
            e => {
                ToastController.showError(e);
            }
        );
    }
}

export default new SelectLocationModalController();
