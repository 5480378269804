import { urlParams, xhr } from 'wonderland-ui-commons';
import config from 'app/config';

const prefix = '/discovery/v1';

function getVisualizations() {
    return xhr.json(`${config.apiHostname}${prefix}/visualizations`, {
        method: 'get'
    });
}

function getSearch(criteria, activeFacets, skip, limit, index) {
    const query = urlParams({
        skip,
        limit,
        facets: activeFacets.join(',') || 'false'
    });

    return xhr.json(`${config.apiHostname}${prefix}/${index}/search?${query}`, {
        method: 'post',
        body: JSON.stringify({ criteria })
    });
}

function getStats() {
    return xhr.json(`${config.apiHostname}${prefix}/stats`, {
        method: 'get'
    });
}

function lookupComboValues(field, searchText, criteria, index, subField, filter) {
    const json = searchText ? `&json=${encodeURIComponent(JSON.stringify(searchText))}` : '';
    return xhr.json(`${config.apiHostname}${prefix}/${index}/lookup/${field}/${subField}?filter=${filter}${json}`, {
        method: 'post',
        body: JSON.stringify({ criteria })
    });
}

function lookupFacetValues(field, searchText, criteria, index) {
    return xhr.json(`${config.apiHostname}${prefix}/${index}/lookup/${field}?filter=${searchText}`, {
        method: 'post',
        body: JSON.stringify({ criteria })
    });
}

function getIndexConfig(index) {
    return xhr.json(`${config.apiHostname}${prefix}/${index}/config`, {
        method: 'get'
    });
}

export default {
    getSearch,
    getStats,
    lookupComboValues,
    lookupFacetValues,
    getVisualizations,
    getIndexConfig
};
