import {
    Avatar,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Checkbox,
    Collapse,
    Grid,
    Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import AssetAttributeChips from 'app/shared/AssetAttributeChips';
import ButtonBar from 'app/shared/ButtonBar';
import CardStatus from './CardStatus';
import classnames from 'classnames';
import FileSize from 'app/shared/FileSize';
import MetadataList from 'app/shared/AssetList/MetadataList';
import PropTypes from 'prop-types';
import React from 'react';
import StagedListItem from 'app/shared/AssetList/StagedListItem';

const CardListItemComponent = ({
    actions,
    asset,
    avatar,
    chips,
    classes,
    collectionId,
    collectionType,
    date,
    deleteCallback,
    disableCheckbox = false,
    fileType,
    image,
    imageMode,
    linkTo,
    metadataItems,
    metadataMode,
    preferences,
    restrictionStatus,
    selected,
    showActions = true,
    showCheckbox = true,
    showChips = true,
    showStatuses = true,
    statuses,
    title,
    toggle,
    versionName
}) => {
    const { file_name, file_size, name } = asset;
    const { color, icon } = avatar;
    const { card, cardActions, content, ellipsis, header, media, checkbox, restriction } = classes;
    const { hasRestrictions, restrictions } = restrictionStatus;
    const actionBar = actions ? (
        actions
    ) : (
        <CardActions classes={{ root: cardActions }} disableActionSpacing>
            <ButtonBar
                asset={asset}
                collectionId={collectionId}
                collectionType={collectionType}
                deleteCallback={deleteCallback}
            />
        </CardActions>
    );
    const chipBar = chips ? chips : <AssetAttributeChips asset={asset} centered />;
    const statusBar = statuses ? statuses : <CardStatus asset={asset} classes={classes} restrictions={restrictions} />;
    const cardItem = classnames({ [card]: true, [restriction]: hasRestrictions });

    return (
        <Card classes={{ root: cardItem }}>
            <CardHeader
                action={
                    showCheckbox && (
                        <Checkbox
                            classes={{ root: checkbox }}
                            disabled={disableCheckbox}
                            onChange={toggle}
                            checked={selected}
                        />
                    )
                }
                avatar={
                    <Grid alignItems="center" container justify="center" direction="column">
                        <Grid item>
                            <Link to={linkTo}>
                                <Avatar classes={{ root: classes[color] }}>{icon}</Avatar>
                            </Link>
                            <Typography align="center" color="textSecondary" variant="caption">
                                {fileType}
                            </Typography>
                        </Grid>
                    </Grid>
                }
                classes={{ root: header }}
                title={
                    <Grid container direction="column">
                        <Grid item>
                            <Typography variant="subtitle1">{title}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={ellipsis} variant="body2">
                                {versionName}
                            </Typography>
                        </Grid>
                    </Grid>
                }
                subheader={
                    <Typography color="textSecondary" variant="caption">
                        {date}
                    </Typography>
                }
            />
            <Link to={linkTo}>
                {showStatuses && statusBar}
                <Collapse in={imageMode} timeout="auto" unmountOnExit>
                    <CardMedia className={media} image={image} style={preferences} title={file_name} />
                </Collapse>
            </Link>
            <CardContent classes={{ root: content }}>
                <Link to={linkTo}>
                    <Typography align="center" gutterBottom variant="overline">
                        {name}
                    </Typography>
                    <Typography align="center" gutterBottom variant="caption">
                        <FileSize bytes={file_size} />
                    </Typography>
                </Link>
                {showChips && chipBar}
                <Collapse in={metadataMode} timeout="auto" unmountOnExit>
                    <MetadataList metadataItems={metadataItems} />
                </Collapse>
                <StagedListItem asset={asset} collectionType={collectionType} />
            </CardContent>
            {showActions && actionBar}
        </Card>
    );
};

CardListItemComponent.propTypes = {
    actions: PropTypes.node,
    asset: PropTypes.object.isRequired,
    avatar: PropTypes.object.isRequired,
    chips: PropTypes.node,
    classes: PropTypes.object.isRequired,
    collectionId: PropTypes.string,
    collectionType: PropTypes.string,
    date: PropTypes.string.isRequired,
    deleteCallback: PropTypes.func,
    disableCheckbox: PropTypes.bool,
    fileType: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    imageMode: PropTypes.bool,
    linkTo: PropTypes.string.isRequired,
    metadataIcons: PropTypes.object.isRequired,
    metadataItems: PropTypes.array.isRequired,
    metadataMode: PropTypes.bool,
    preferences: PropTypes.object,
    restrictionStatus: PropTypes.object.isRequired,
    selected: PropTypes.bool,
    showActions: PropTypes.bool,
    showCheckbox: PropTypes.bool,
    showChips: PropTypes.bool,
    showStatuses: PropTypes.bool,
    showImage: PropTypes.bool,
    statuses: PropTypes.node,
    title: PropTypes.string.isRequired,
    toggle: PropTypes.func.isRequired,
    versionName: PropTypes.string
};

export default CardListItemComponent;
