import { compose, withProps } from 'recompose';
import { first, get, isNil, isObject, pick, split } from 'lodash';
import { red } from '@material-ui/core/colors';
import config from 'app/config';
import getPrimaryTitleString from 'lib/getPrimaryTitle';

const {
    assetStatuses: {
        assetAvailable: { statuses: availableStatuses, ...assetAvailable },
        assetUnavailable
    },
    deliveryStatuses: { deliveryAvailable, deliveryWithApproval, deliveryUnavailable },
    flagAttributes,
    maxFileTypeLength
} = config;

function getTitle(asset = {}) {
    const { related_titles } = asset;

    return getPrimaryTitleString(related_titles);
}

export const getActionEnabled = ({
    asset_type,
    downloadable,
    collection_id,
    editable,
    grants = [],
    serviceable,
    valid,
    restorable,
    ...asset
}) => ({
    collection: editable && asset_type !== 'collection' && !collection_id,
    collectionAssets: editable && asset_type === 'collection',
    deleteAsset: editable && grants.includes('delete'),
    deliver: grants.includes('delivery_with_approvals') || grants.includes('delivery'),
    download: downloadable && grants.includes('download'),
    duplicate: editable && grants.includes('download'),
    edit: editable && grants.includes('update'),
    export: downloadable && grants.includes('export'),
    extract: editable && asset_type === 'collection',
    lightbox: editable && grants.includes('read'),
    lock: editable && grants.includes('update'),
    publish: valid,
    restorable: restorable,
    revision: grants.includes('revision'),
    service: serviceable && grants.includes('download'),
    share: downloadable && grants.includes('download') && asset_type !== 'collection'
});

export function getAssetStatus({ asset_status, composition_status }) {
    const assetStatus = isObject(asset_status) ? asset_status.value : asset_status || 'N/A';
    const isComposition = !!composition_status;
    const isCompleted = isComposition && composition_status === 'Complete';
    const sourceStatus = !isComposition ? assetStatus : isCompleted ? assetStatus : composition_status;
    const isAvailable = availableStatuses.includes(sourceStatus);
    const status = isAvailable ? { ...assetAvailable } : assetUnavailable;

    return { ...status, title: sourceStatus };
}

export function getDeliveryStatus({ grants = [] }) {
    const canDeliver = grants.includes('delivery');
    const hasApproval = grants.includes('delivery_with_approvals');
    const status = canDeliver ? deliveryAvailable : hasApproval ? deliveryWithApproval : deliveryUnavailable;

    return status;
}

export function getMetadataIcons() {
    return { 'bonus title': 'card_membership', id: 'picture_in_picture' };
}

export function getRestrictionStatus({ restrictions }) {
    if (!isNil(first(restrictions))) {
        const restriction = restrictions.filter(({ restriction_type = '' }) => restriction_type === 'visual_indicator');
        const hasRestrictions = !!restriction.length;
        const restrict = hasRestrictions
            ? {
                  restrictionBGColor: red[900],
                  restrictionColor: 'white',
                  restrictionIcon: 'priority_high',
                  restrictionTitle: restriction[0].message
              }
            : { hasRestrictions: false, restrictions: {} };

        return { hasRestrictions, restrictions: restrict };
    } else {
        return { hasRestrictions: false, restrictions: {} };
    }
}

export function getFileType({ asset_type, file_name = '', physical_or_digital } = {}) {
    if (physical_or_digital === 'Physical') {
        return 'PHY';
    } else if (file_name === '' && asset_type === 'composition') {
        return 'XML';
    } else {
        const fileParts = split(file_name, '.');
        const fileType = fileParts.length > 1 ? fileParts.pop().toUpperCase() : 'N/A';
        return fileType.slice(0, maxFileTypeLength);
    }
}

export function getVersionName(asset) {
    const { related_titles = [] } = asset;
    const title = getTitle(asset);
    const titleName = title.length ? title : asset.name;
    const versionName = related_titles
        .filter(({ name }) => name === titleName)
        .find(({ version_name }) => version_name);

    return get(versionName, 'version_name', '');
}

export function updateFlags(current, next, excludedAttributes = []) {
    return {
        ...current,
        ...pick(
            next,
            flagAttributes.filter(f => !excludedAttributes.includes(f))
        )
    };
}

export const withTitleName = compose(
    withProps(({ asset = {} }) => ({
        titleName: getTitle(asset)
    }))
);

export default {
    getActionEnabled,
    getAssetStatus,
    getDeliveryStatus,
    getFileType,
    getMetadataIcons,
    getRestrictionStatus,
    updateFlags,
    withTitleName
};
