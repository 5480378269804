import { cloneDeep } from 'lodash';
import { reducerRegistry } from 'lib/redux';
import { registerSliceReducer, sliceReducer, subscribe } from 'react-redux-boilerout';

@registerSliceReducer({ registry: reducerRegistry })
@sliceReducer('TableList')
@subscribe({ namespace: 'TableList' })
export default class TableListSlice {
    initialState() {
        return {
            tableListSettings: null,
            tableListFields: []
        };
    }

    onReset() {
        return this.initialState();
    }

    onUpdateTableListFields(slice, tableListFields) {
        return {
            ...slice,
            tableListFields: cloneDeep(tableListFields)
        };
    }

    onUpdateTableListSettings(slice, tableListSettings) {
        return {
            ...slice,
            tableListSettings: cloneDeep(tableListSettings)
        };
    }
}
