import { sliceContainer } from 'wonderland-ui-commons';
import DuplicateAssetModalActions from './DuplicateAssetModalActions';
import DuplicateAssetModalComponent from './DuplicateAssetModalComponent';
import DuplicateAssetModalSlice from './DuplicateAssetModalSlice';
import WonderlandDomainController from 'core/WonderlandDomain';

@sliceContainer({
    slice: DuplicateAssetModalSlice,
    actions: DuplicateAssetModalActions,
    component: DuplicateAssetModalComponent
})
class DuplicateAssetModalContainer {
    static mapSliceStateToProps(slice, props) {
        return {
            ...slice,
            duplicateAsset: WonderlandDomainController.duplicateAsset
        };
    }
}

export default DuplicateAssetModalContainer;
