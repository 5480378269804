import { urlParams, xhr } from 'wonderland-ui-commons';
import config from 'app/config';
const prefix = '/compositions/v1';

function getCompositionLocked(id) {
    return xhr.json(`${config.apiHostname}${prefix}/composition/${id}/locked`, {
        method: 'get'
    });
}

function getCompositionId(assetId) {
    const query = urlParams({ multi: true });

    return xhr.json(`${config.apiHostname}${prefix}/asset/${assetId}/composition?${query}`, {
        method: 'get'
    });
}

function getCompositions(essenceId, limit, skip) {
    const query = urlParams({ limit, skip });

    return xhr.json(`${config.apiHostname}${prefix}/essence/${essenceId}/compositions?${query}`, {
        method: 'get'
    });
}

function getEssenceId(assetId) {
    return xhr.json(`${config.apiHostname}${prefix}/asset/${assetId}/essence`, {
        method: 'get'
    });
}

function getEssences(compositionId, limit, skip) {
    const query = urlParams({ limit, skip });

    return xhr.json(`${config.apiHostname}${prefix}/composition/${compositionId}/essences?${query}`, {
        method: 'get'
    });
}

export default {
    getCompositionLocked,
    getCompositionId,
    getCompositions,
    getEssenceId,
    getEssences
};
