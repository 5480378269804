import { ToastController } from 'wonderland-ui-commons';
import { withStyles } from 'wonderland-ui-material';
import AttributeChips from 'app/shared/AttributeChips';
import ChevronRight from '@material-ui/icons/ChevronRight';
import classnames from 'classnames';
import config from 'app/config';
import DestinationTypeAvatar from 'app/shared/DestinationTypeAvatar';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

/* istanbul ignore next */
const sheet = ({ palette }) => ({
    resultsListItem: {
        border: `1px solid ${palette.disabledColor}`,
        padding: '8px',
        display: 'flex',
        '&.dense': {
            padding: 0
        }
    },
    resultsListItemSelected: {
        border: `1px solid ${palette.disabledColor}`,
        padding: '8px',
        display: 'flex',
        backgroundColor: palette.alternateCanvasColor,
        '&.dense': {
            padding: 0
        }
    },
    listItemTextPrimary: {
        display: 'flex'
    },
    listItemTextRoot: {
        padding: 0
    },
    listItemTitle: {
        marginTop: 'auto',
        marginBottom: 'auto'
    }
});

@withStyles(sheet)
export default class ResultsListItemComponent extends React.PureComponent {
    static propTypes = {
        onSelectDestination: PropTypes.func.isRequired,
        classes: PropTypes.object.isRequired,
        isDestinationSelected: PropTypes.func.isRequired,
        dense: PropTypes.bool,
        drillIntoItem: PropTypes.func.isRequired,
        openFacets: PropTypes.func.isRequired,
        item: PropTypes.object.isRequired
    };

    addItem = item => () => {
        if (item.type === 'Destination') {
            this.props.onSelectDestination(item);
        } else {
            ToastController.showError(
                'Click the > button to see children.  Only destinations can be added to the form.'
            );
        }
    };

    render() {
        const { isDestinationSelected, drillIntoItem, item, dense, classes } = this.props;
        return (
            <ListItem
                button
                className={classnames(
                    isDestinationSelected(item) ? classes.resultsListItemSelected : classes.resultsListItem,
                    { dense }
                )}
            >
                <ListItemText
                    classes={{
                        primary: classes.listItemTextPrimary,
                        root: classes.listItemTextRoot
                    }}
                    disableTypography={true}
                    onClick={this.addItem(item)}
                    disabled={isDestinationSelected(item)}
                    primary={
                        <div className={classes.listItemTextPrimary}>
                            <DestinationTypeAvatar destinationType={item.type} />
                            <h6 className={classes.listItemTitle}>{item.name}</h6>
                        </div>
                    }
                    secondary={
                        !dense && (
                            <AttributeChips
                                item={item}
                                pickerKey={'type'}
                                defaultPickerChips={config.defaultDestinationPickerChips}
                                pickerChips={config.destinationPickerChips}
                            />
                        )
                    }
                />
                {['Provider', 'Location'].includes(item.type) && (
                    <Tooltip title={'See Children'}>
                        <IconButton onClick={() => drillIntoItem(item)} data-qa={'see-children-button'}>
                            <ChevronRight />
                        </IconButton>
                    </Tooltip>
                )}
            </ListItem>
        );
    }
}
