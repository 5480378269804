import { xhr } from 'wonderland-ui-commons';
import config from 'app/config';

const prefix = '/meta/v1/schemas';

function getSchemaByType(type) {
    return xhr.json(`${config.apiHostname}${prefix}/${type}`, { method: 'get' });
}

function getAllSchemas() {
    return xhr.json(`${config.apiHostname}${prefix}`, { method: 'get' });
}

export default {
    getAllSchemas,
    getSchemaByType
};
