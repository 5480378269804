import { AsperaService, withAsperaDownload } from 'wonderland-ui-aspera';
import { AssetStoreAPI } from './apis';
import { compose, mapProps, withHandlers } from 'recompose';
import { omit } from 'lodash';
import DownloadController from './DownloadController';
import withFallback from './withFallback';

export default ({ key = 'bulkDownload', disableAsperaForRequest } = {}) =>
    compose(
        withAsperaDownload({
            downloadTransferSpecProvider: AssetStoreAPI.getManagedBulkDownloadTransferSpec
        }),

        withHandlers({
            [key]:
                ({ downloadWithAspera }) =>
                ({ assetIds = [], criteria, type, assetNames = {} }) => {
                    const supportedBulkTypesWithAspera = ['ids'];
                    const useAspera =
                        !disableAsperaForRequest &&
                        supportedBulkTypesWithAspera.includes(type) &&
                        AsperaService.connected;
                    const bulkDownloadWithFallback = withFallback(
                        useAspera,
                        downloadWithAspera,
                        DownloadController.downloadThroughZipAssetCreation
                    );
                    return DownloadController.validateAndBulkDownload(
                        {
                            type,
                            asset_ids: assetIds,
                            criteria,
                            assetNames
                        },
                        bulkDownloadWithFallback,
                        useAspera
                    );
                }
        }),

        mapProps(props => omit(props, ['downloadWithAspera']))
    );
