import { CardActions } from '@material-ui/core';
import CardListItem from 'app/shared/AssetList/CardList/CardListItem';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import RelatedCardActions from 'app/Relationship/RelatedCard/RelatedCardActionsComponent';

const RelatedCardComponent = ({
    asset,
    classes: { cardActions },
    disableAction = false,
    group,
    handleAddChildren,
    handleAddParents,
    handleRemoveChildren,
    handleRemoveParent,
    handleSelectRelationshipType,
    imageMode,
    metadataMode,
    relationshipType,
    selected,
    selectedParentId,
    toggleSelected,
    typeNames,
    types
}) => (
    <CardListItem
        actions={
            <CardActions classes={{ root: classnames(cardActions, { asset: group === 'asset' }) }} disableActionSpacing>
                <RelatedCardActions
                    asset={asset}
                    disableAction={disableAction}
                    group={group}
                    handleAddChildren={handleAddChildren}
                    handleAddParents={handleAddParents}
                    handleRemoveChildren={handleRemoveChildren}
                    handleRemoveParent={handleRemoveParent}
                    handleSelectRelationshipType={handleSelectRelationshipType}
                    relationshipType={relationshipType}
                    selected={selected}
                    selectedParentId={selectedParentId}
                    typeNames={typeNames}
                    types={types}
                />
            </CardActions>
        }
        asset={asset}
        disableCheckbox={disableAction}
        imageMode={imageMode}
        metadataMode={metadataMode}
        selected={selected}
        showActions={true}
        toggleSelected={toggleSelected}
    />
);

RelatedCardComponent.propTypes = {
    asset: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    disableAction: PropTypes.bool,
    group: PropTypes.string.isRequired,
    handleAddChildren: PropTypes.func,
    handleAddParents: PropTypes.func,
    handleRemoveChildren: PropTypes.func,
    handleRemoveParent: PropTypes.func,
    handleSelectRelationshipType: PropTypes.func,
    imageMode: PropTypes.bool.isRequired,
    metadataMode: PropTypes.bool.isRequired,
    relationshipType: PropTypes.string,
    selected: PropTypes.bool,
    selectedParentId: PropTypes.string,
    toggleSelected: PropTypes.func,
    typeNames: PropTypes.object.isRequired,
    types: PropTypes.array.isRequired
};

export default RelatedCardComponent;
