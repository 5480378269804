import { reducerRegistry } from 'lib/redux';
import { registerSliceReducer, sliceReducer, subscribe } from 'react-redux-boilerout';

@registerSliceReducer({ registry: reducerRegistry })
@sliceReducer('SearchForm')
@subscribe({ namespace: 'SearchForm' })
export default class SearchSlice {
    initialState() {
        return {
            context: {
                route: 'search'
            }
        };
    }

    onReset() {
        return this.initialState();
    }

    onSetRoute(slice, route) {
        return {
            ...slice,
            context: {
                ...slice.context,
                route
            }
        };
    }

    onSetDefaultCriteria(slice, defaultCriteria) {
        return {
            ...slice,
            context: {
                ...slice.context,
                defaultCriteria
            }
        };
    }
}
