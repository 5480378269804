import { Footer } from 'wonderland-ui-commons';
import { withStyles } from 'wonderland-ui-material';
import { withUser } from 'wonderland-ui-security';
import HeaderLite from 'app/shared/HeaderLite';
import IE11Flexbug3Fix from 'app/shared/IE11Flexbug3Fix';
import LinearProgress from 'app/shared/LinearProgress';
import PropTypes from 'prop-types';
import React from 'react';
import Toast from 'app/shared/Toast';

/* istanbul ignore next */
const sheet = theme => ({
    main: {
        padding: theme.spacing.unit * 3
    }
});

@withUser()
@withStyles(sheet)
export default class AppLayoutLiteComponent extends React.PureComponent {
    static propTypes = {
        children: PropTypes.any,
        classes: PropTypes.object.isRequired,
        userHasRole: PropTypes.func
    };

    doRender() {
        const { classes, children, userHasRole } = this.props;

        return (
            <IE11Flexbug3Fix>
                <HeaderLite />
                <LinearProgress />
                <Toast />
                <main className={classes.main}>{children}</main>
                <Footer userHasRole={userHasRole} />
            </IE11Flexbug3Fix>
        );
    }

    render() {
        try {
            return this.doRender();
        } catch (e) {
            console.error('AppLayoutLiteComponent::render', e);
        }
        return null;
    }
}
