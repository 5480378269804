import { urlParams, xhr } from 'wonderland-ui-commons';
import config from 'app/config';

const prefix = '/events/v1';

function getVisualizations() {
    return xhr.json(`${config.apiHostname}${prefix}/visualizations`, {
        method: 'get'
    });
}

function getIndexes() {
    return xhr.json(`${config.apiHostname}${prefix}/indexes`, {
        method: 'get'
    });
}

function getAssetEvents(id, { skip, limit } = {}) {
    return xhr.json(`${config.apiHostname}${prefix}/asset/${id}/events?${urlParams({ skip, limit })}`, {
        method: 'get'
    });
}

function getEvent(id) {
    return xhr.json(`${config.apiHostname}${prefix}/event/${id}`, {
        method: 'get'
    });
}

export default {
    getVisualizations,
    getAssetEvents,
    getIndexes,
    getEvent
};
