import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import RelatedForm from 'app/Relationship/RelatedForm';

const RelatedCardActionsComponent = ({
    asset: { id, type },
    disableAction,
    group,
    handleAddChildren,
    handleAddParents,
    handleRemoveChildren,
    handleRemoveParent,
    handleSelectRelationshipType,
    relationshipType,
    selected,
    selectedParentId,
    typeNames,
    types
}) => {
    switch (group) {
        case 'parent':
            return selected ? (
                <RelatedForm
                    handleSelectRelationshipType={handleSelectRelationshipType}
                    relationshipType={relationshipType}
                    typeNames={typeNames}
                    types={types}
                />
            ) : (
                <Button onClick={handleRemoveParent} size="small">
                    <Icon>chevron_left</Icon> Remove Parent
                </Button>
            );
        case 'asset':
            return !!selectedParentId ? (
                <Button disabled={disableAction} onClick={handleAddChildren} size="small" value={id}>
                    Add Child As {typeNames[relationshipType]} <Icon>chevron_right</Icon>
                </Button>
            ) : (
                <Button onClick={handleAddParents} size="small" value={id}>
                    Add As Parent <Icon>chevron_right</Icon>
                </Button>
            );
        case 'child':
            return (
                <Button disabled={false} onClick={handleRemoveChildren} size="small" data-type={type} value={id}>
                    <Icon>chevron_left</Icon> Remove Child
                </Button>
            );
        default:
            return null;
    }
};

RelatedCardActionsComponent.propTypes = {
    asset: PropTypes.object.isRequired,
    disableAction: PropTypes.bool,
    group: PropTypes.string.isRequired,
    handleAddChildren: PropTypes.func,
    handleAddParents: PropTypes.func,
    handleRemoveChildren: PropTypes.func,
    handleRemoveParent: PropTypes.func,
    handleSelectRelationshipType: PropTypes.func,
    relationshipType: PropTypes.string,
    selected: PropTypes.bool,
    selectedParentId: PropTypes.string,
    typeNames: PropTypes.object.isRequired,
    types: PropTypes.array.isRequired
};

export default RelatedCardActionsComponent;
