import { get } from 'lodash';

class AssetValidationContext {
    asset = null;

    save(asset) {
        this.asset = asset;
    }

    get(field) {
        return get(this.asset, field, true);
    }
}

export default new AssetValidationContext();
