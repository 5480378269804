import { withStyles } from 'wonderland-ui-material';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import SecurityIcon from '@material-ui/icons/Security';
import Tooltip from '@material-ui/core/Tooltip';
import WarningIcon from '@material-ui/icons/Warning';

/* istanbul ignore next */
const sheet = theme => ({
    status: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    success: {
        color: theme.palette.alert.success
    }
});

@withStyles(sheet)
export default class StagedListItemComponent extends React.PureComponent {
    static propTypes = {
        asset: PropTypes.object.isRequired,
        classes: PropTypes.object.isRequired,
        collectionType: PropTypes.string
    };

    render() {
        try {
            return this.doRender();
        } catch (e) {
            console.error('GridListItemComponent::render', e);
        }
        return null;
    }

    doRender() {
        const { asset, classes, collectionType } = this.props;
        const isSecured =
            collectionType === 'staged'
                ? asset.hasOwnProperty('acl') && !!asset.acl.policies.length //when compoennt is shared by used by bulk publish list view
                : asset.hasOwnProperty('content_categories') && !!!!asset.content_categories.length; //when component is shared by home page default asset list view

        const isValid = asset.valid;
        const isNotIngested = get(asset, 'ingest.errors');

        if (collectionType !== 'staged') {
            return null;
        }

        return (
            <div className={classes.status}>
                {isNotIngested && (
                    <Tooltip title="asset failed to ingest" placement={'bottom-start'}>
                        <ErrorIcon color="error" />
                    </Tooltip>
                )}
                {isValid ? (
                    <Tooltip title="asset metadata is valid">
                        <CheckIcon className={classes.success} />
                    </Tooltip>
                ) : (
                    <Tooltip title="asset metadata is invalid">
                        <WarningIcon color="error" />
                    </Tooltip>
                )}
                {isSecured ? (
                    <Tooltip title="asset security is valid">
                        <SecurityIcon className={classes.success} />
                    </Tooltip>
                ) : (
                    <Tooltip title="asset is not secured">
                        <SecurityIcon color="error" />
                    </Tooltip>
                )}
            </div>
        );
    }
}
