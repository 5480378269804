import { convertAssetToFormFields, resolveInitialAssetValues } from 'lib/assetEditUtils';
import { formatRules, isSmartCollection } from 'lib/smartCollectionUtils';
import { getMetaFromOrderedMeta, getNewOrderedMeta } from 'lib/assetUploadUtils';
import { reducerRegistry } from 'lib/redux';
import { registerSliceReducer, sliceReducer, subscribe } from 'react-redux-boilerout';
import BaseIMPFileSelectionSlice from 'lib/BaseIMPFileSelectionSlice';

@registerSliceReducer({ registry: reducerRegistry })
@sliceReducer('BaseAssetEdit')
@subscribe({ namespace: 'BaseAssetEdit' })
export default class BaseAssetEditSlice extends BaseIMPFileSelectionSlice {
    initialState() {
        return {
            meta: {},
            security: {},
            collectionRules: [],
            collectionTitleOptions: {},
            orderedMeta: [],
            errorDismissed: false,

            ...super.initialState()
        };
    }

    onReset() {
        return this.initialState();
    }

    onSetAsset(slice, asset) {
        const convertedAsset = convertAssetToFormFields(asset);
        const orderedMeta = getNewOrderedMeta(convertedAsset, []);
        const meta = getMetaFromOrderedMeta(orderedMeta);
        return {
            ...slice,
            initialAsset: convertedAsset,
            meta,
            orderedMeta,
            ...(isSmartCollection(asset) && {
                collectionTitleOptions: asset.titleOptions,
                collectionRules: asset.rules
            })
        };
    }

    onUpdateMeta(slice, meta) {
        const updatedMeta = resolveInitialAssetValues(slice.meta, meta, slice.initialAsset);
        return {
            ...slice,
            meta: updatedMeta,
            orderedMeta: getNewOrderedMeta(updatedMeta, slice.orderedMeta)
        };
    }

    onDismissError(slice) {
        const update = {
            ...slice.meta,
            ingest: {
                ...slice.meta.ingest,
                value: {
                    ...slice.meta.ingest.value,
                    status: 'VERIFIED',
                    errors: []
                }
            }
        };
        return {
            ...this.onUpdateMeta(slice, update),
            errorDismissed: true
        };
    }

    onUpdatePolicies(slice, policies) {
        return {
            ...slice,
            security: {
                ...slice.security,
                policies
            }
        };
    }

    onUpdateAces(slice, aces) {
        return {
            ...slice,
            security: {
                ...slice.security,
                aces
            }
        };
    }
    onUpdateRules(slice, rules) {
        return {
            ...slice,
            security: {
                ...slice.security,
                rules
            }
        };
    }

    @subscribe({ namespace: 'WonderlandDomain' })
    onFetchAssetRequested(slice) {
        return {
            ...slice,
            asset: null
        };
    }

    @subscribe({ namespace: 'WonderlandDomain' })
    onFetchAssetBaseCompleted(slice, asset) {
        if (asset.asset_type === 'collection' && !isSmartCollection(asset)) {
            return slice;
        }

        return {
            ...slice,
            asset
        };
    }

    onResetSelection(slice) {
        return {
            ...slice,
            ...super.initialState()
        };
    }

    onUpdateFolderSelection(slice, isFolder) {
        return super.onUpdateFolderSelection(slice, isFolder);
    }

    onUpdateRemoteOrigin(slice, remoteOrigin) {
        return super.onUpdateRemoteOrigin(slice, remoteOrigin);
    }

    onUpdateSelected(slice, selected) {
        return super.onUpdateSelected(slice, selected);
    }

    onUpdateDestination(slice, destination) {
        return super.onUpdateDestination(slice, destination);
    }

    onClearDestination(slice) {
        return super.onClearDestination(slice);
    }

    onReceivedIngestNotification(slice, notification) {
        return super.onReceivedIngestNotification(slice, notification);
    }

    onReceivedPropagationNotification(slice, notification) {
        return super.onReceivedPropagationNotification(slice, notification);
    }

    onReceivedValidationNotification(slice, notification) {
        return super.onReceivedValidationNotification(slice, notification);
    }

    onRedirecting(slice) {
        return super.onRedirecting(slice);
    }

    onUpdateFilenames(slice, filenames) {
        return super.onUpdateFilenames(slice, filenames);
    }
    onUpdateFilenamesAndAdvanceStep(slice, filenames) {
        return super.onUpdateFilenamesAndAdvanceStep(slice, filenames);
    }

    onUpdateJobId(slice, jobId) {
        return super.onUpdateJobId(slice, jobId);
    }

    onUpdateImpStep(slice, impStep) {
        return super.onUpdateImpStep(slice, impStep);
    }

    onUploading(slice) {
        return super.onUploading(slice);
    }

    onValidating(slice) {
        return super.onValidating(slice);
    }

    onPropagating(slice) {
        return super.onPropagating(slice);
    }

    onUpdateCollectionRules(slice, collectionRules) {
        const formattedCollectionRules = formatRules(collectionRules);

        return { ...slice, collectionRules: formattedCollectionRules };
    }
    onUpdateCollectionTitleOptions(slice, collectionTitleOptions) {
        return { ...slice, collectionTitleOptions: { ...slice.collectionTitleOptions, ...collectionTitleOptions } };
    }
}
