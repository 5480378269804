import { getDeliveryStatus } from 'lib/assetUtils';
import Icon from '@material-ui/core/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

const DeliveryStatusComponent = ({ asset, placement = 'bottom', size = 'small' }) => {
    const { asset_type } = asset;
    const isAsset = asset_type !== 'collection';
    const { color, icon, title } = getDeliveryStatus(asset);

    return isAsset ? (
        <Tooltip placement={placement} title={title}>
            <Icon fontSize={size} style={{ color }}>
                {icon}
            </Icon>
        </Tooltip>
    ) : null;
};

DeliveryStatusComponent.propTypes = {
    asset: PropTypes.object.isRequired,
    placement: PropTypes.oneOf(['bottom', 'left', 'right', 'top']),
    size: PropTypes.oneOf(['default', 'large', 'small'])
};

export default DeliveryStatusComponent;
