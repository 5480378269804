import { compose, withHandlers, withProps } from 'recompose';
import { SecurityController, withUser } from 'wonderland-ui-security';
import { withPageDrawer } from 'wonderland-ui-commons';
import AppHeaderComponent from './AppHeaderComponent';
import AppHeaderDrawer from './AppHeaderDrawer';
import history from 'lib/history';
import withCart from 'core/Cart/withCart';
import withStyles from '@material-ui/core/styles/withStyles';

export default compose(
    withUser(),
    withCart(),
    withPageDrawer([
        {
            name: 'appHeaderDrawer',
            Component: AppHeaderDrawer,
            mapProps: ({ closeDrawer }) => ({ anchor: 'left', closeDrawer, modal: true })
        }
    ]),
    withHandlers({
        handleDrawer:
            ({ openDrawer }) =>
            () =>
                openDrawer('appHeaderDrawer'),
        handleHelp: () => () => window.open('/help'),
        handleLogout: () => () => SecurityController.logout(),
        handlePreferences: () => () => history.push('/preferences')
    }),
    withProps(({ user }) => ({ logoutMessage: `Logout ${user && user.name}` })),
    withStyles(() => ({
        appBar: {
            alignItems: 'center',
            backgroundColor: 'dodgerblue',
            display: 'flex',
            justifyContent: 'space-between',
            position: 'fixed',
            top: 0,
            zIndex: 1100,
            width: '100%'
        },
        icon: {
            color: 'white'
        },
        logo: {
            display: 'flex',
            paddingLeft: 4
        },
        search: {
            borderRadius: 4,
            backgroundColor: 'rgba(255, 255, 255, 0.15)',
            width: '50%'
        },
        toolBar: {
            display: 'flex'
        }
    }))
)(AppHeaderComponent);
