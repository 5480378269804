import { some } from 'lodash';
import config from '../app/config';
import path from 'path';
import template from '@silvermine/undertemplate';

const sidecarTemplates = config.sidecar.namingTemplates.map(template);

function assetMatchesSidecar(assetFile, sidecarFile) {
    const file_extension = path.extname(assetFile.name);
    const file_name = path.basename(assetFile.name, file_extension);
    return some(
        sidecarTemplates,
        templateFn => templateFn({ file_name, file_extension }) === path.basename(sidecarFile.name)
    );
}

function hasMatch(file, files) {
    return some(files, f => f !== file && (assetMatchesSidecar(f, file) || assetMatchesSidecar(file, f)));
}

export { assetMatchesSidecar, hasMatch };
