import { withStyles } from 'wonderland-ui-material';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

const sheet = theme => ({
    checkBoxRoot: {
        color: theme.palette.common.black,
        margin: '-8px'
    },
    checkBoxChecked: {
        //TODO: idealy  !important should not be necessary but the resulting element on the Checked Checkbox has applied style attributes. Not sure where they come from.
        color: `${theme.palette.accent1Color} !important`
    },
    checkBoxIndeterminate: {
        color: theme.palette.accent1Color
    }
});

@withStyles(sheet)
export default class BulkselectionToggleComponent extends PureComponent {
    static propTypes = {
        status: PropTypes.oneOf(['checked', 'indeterminate', 'unchecked']).isRequired,
        onToggle: PropTypes.func.isRequired,
        bulkSelectionCheckboxRootClass: PropTypes.string,
        classes: PropTypes.object
    };

    get isSelected() {
        return this.props.status === 'checked';
    }

    render() {
        const { status, onToggle, classes, bulkSelectionCheckboxRootClass } = this.props;

        const checked = status === 'checked';
        const indeterminate = status === 'indeterminate';
        return (
            <div>
                <Checkbox
                    classes={{
                        root: bulkSelectionCheckboxRootClass || classes.checkBoxRoot,
                        checked: classes.checkBoxChecked,
                        indeterminate: classes.checkBoxIndeterminate
                    }}
                    disableRipple
                    onChange={onToggle}
                    checked={checked}
                    indeterminate={indeterminate}
                />
            </div>
        );
    }
}
