import { differenceWith, endsWith, isEqual } from 'lodash';

export const STEPS = {
    FETCH_FILENAMES: 'fetchFilenames',
    FETCH_FILENAMES_COMPLETE: 'fetchFilenamesComplete',
    INGESTING: 'ingesting',
    INGEST_FAILED: 'ingestFailed',
    PROPAGATING: 'propagating',
    PROPAGATION_COMPLETE: 'propagationComplete',
    PROPAGATION_FAILED: 'propagationFailed',
    SELECT_FOLDER: 'selectFolder',
    UPLOADING: 'uploading',
    UPLOAD_COMPLETE: 'uploadComplete',
    VALIDATING: 'validating',
    VALIDATION_COMPLETE: 'validationComplete',
    VALIDATION_FAILED: 'validationFailed'
};

export const MESSAGES = {
    INGEST: {
        COMPARISON_RETURNED: 'Returned essence comparison',
        FAILED: 'IMF ingest failed.',
        SUCCESS: 'IMF ingest started successfully.'
    },
    PROPAGATION: {
        COMPLETE: 'Propagation Request not needed. There are no compositions affected by this replacement.',
        APPROVAL: 'IMF Propagation has been submitted for approval.'
    }
};

export const fileTypeDescriptions = {
    'IMF Audio': {
        fieldLabels: {
            audioBitDepth: 'Bit Depth',
            channelConfiguration: 'Channels',
            duration: 'Duration',
            editRate: 'Edit Rate',
            sampleRate: 'Sample Rate'
        },
        label: 'AUDIO',
        type: 'audio'
    },
    'IMF Subtitle': {
        fieldLabels: {
            duration: 'Duration',
            editRate: 'Edit Rate'
        },
        label: 'SUBTITLE',
        type: 'subtitle'
    },
    'IMF Timed Text': {
        fieldLabels: {
            duration: 'Duration',
            frameRate: 'Frame Rate'
        },
        label: 'TEXT',
        type: 'timed text'
    },
    'IMF Video': {
        fieldLabels: {
            aspectRatio: 'Aspect Ratio',
            chromaSubsampling: 'Chroma',
            duration: 'Duration',
            frameRate: 'Frame Rate',
            videoBitDepth: 'Bit Depth'
        },
        label: 'VIDEO',
        type: 'video'
    },
    'IMF Unknown': {
        fieldLabels: {},
        label: 'Unknown',
        type: 'unknown'
    }
};

export function getErrors(payload) {
    const { error, errors } = payload;
    let errorArr = error ? (error === '' ? [] : [error]) : errors ? errors : [];
    errorArr = errorArr.filter(error => error !== MESSAGES['INGEST']['COMPARISON_RETURNED']);

    return errorArr;
}

export function filterS3ResourcesByExt(resources, ext) {
    return resources.filter(({ name }) => endsWith(name, ext));
}

export function getS3ResourceNamesByExt(resources, ext) {
    return filterS3ResourcesByExt(resources, ext).map(({ name }) => name);
}

export function getEssenceMatchStatus(oldResources, newResources) {
    const oldValid = oldResources.map(({ end, entryPoint, start }) => {
        return { end, entryPoint, start };
    });
    const newValid = newResources.map(({ end, entryPoint, start }) => {
        return { end, entryPoint, start };
    });
    const matchStatus = differenceWith(oldValid, newValid, isEqual);

    return { match: !matchStatus.length, matchStatus };
}
