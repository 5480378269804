import { abortable, ToastController, withProgress } from 'wonderland-ui-commons';
import { DestinationsAPI } from 'core/apis';
import { get } from 'lodash';
import DestinationsActions from './DestinationsActions';

class DestinationsController {
    @abortable()
    @withProgress({ label: 'fetch_destinations_search' })
    getSearch(criteria, facets, skip, limit) {
        DestinationsActions.getSearchRequested();
        return DestinationsAPI.getSearch(criteria, facets, skip, limit).then(
            res => DestinationsActions.getSearchCompleted(criteria, res),
            e => ToastController.showError(e)
        );
    }

    lookupFacetValues(...args) {
        try {
            return DestinationsAPI.lookupFacetValues(...args);
        } catch (e) {
            ToastController.showError(e);
        }
    }

    getIndexConfig(...args) {
        return DestinationsAPI.getIndexConfig(...args);
    }

    async getDestinationDetails(...args) {
        try {
            return await DestinationsAPI.getDestinationDetails(...args);
        } catch (e) {
            ToastController.showError('Unable to fetch destination details.');
        }
    }

    async getDestinationDetailsWithContacts(...args) {
        try {
            return await DestinationsAPI.getDestinationDetailsWithContacts(...args);
        } catch (e) {
            ToastController.showError('Unable to fetch destination details.');
        }
    }

    async getDistributionListContacts(...args) {
        try {
            return await DestinationsAPI.getDistributionListContacts(...args);
        } catch (e) {
            ToastController.showError('Unable to fetch contacts for this Asset.');
        }
    }

    async getDistributionLists(...args) {
        try {
            return await DestinationsAPI.getDistributionLists(...args);
        } catch (e) {
            ToastController.showError('Unable to fetch distribution lists.');
        }
    }
    async lookupDistributionLists(term) {
        return DestinationsAPI.getDistributionLists({ skip: 0, limit: 20, filter: term, includeInactive: false }).then(
            resp => resp.results
        );
    }

    async requestDelivery(...args) {
        try {
            const response = await DestinationsAPI.requestDelivery(...args);
            const deliveryId = get(response, 'id', 'n/a');
            ToastController.show(`Your assets are now being delivered.  The delivery id is: ${deliveryId}`);
        } catch (e) {
            ToastController.showError('Unable to process delivery request.');
        }
    }

    async getDistributionListsByIds(...args) {
        try {
            return await DestinationsAPI.getDistributionListsByIds(...args);
        } catch (e) {
            ToastController.showError('Unable to fetch distribution lists');
        }
    }

    getS3BucketContent(...args) {
        try {
            return DestinationsAPI.getS3BucketContent(...args);
        } catch (e) {
            ToastController.showError(e);
        }
    }

    getContainerContent(...args) {
        try {
            return DestinationsAPI.getContainerContent(...args);
        } catch (e) {
            ToastController.showError(e);
        }
    }

    async getAllResultsInBucket(destinationId, folder) {
        const allResults = [];
        let allFound = false;
        let curToken = '';
        while (!allFound) {
            const { results, moreItems, nextToken } = await this.getS3BucketContent(destinationId, folder, curToken);
            allResults.push(...results);
            if (moreItems) {
                curToken = nextToken;
            } else {
                allFound = true;
            }
        }
        return allResults;
    }
}

export default new DestinationsController();
