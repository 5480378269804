import { omit } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import DeleteAssetDialogComponent from './DeleteAssetDialogComponent';
import WonderlandDomainController from 'core/WonderlandDomain';

import history from 'lib/history';

export default class DeleteAssetDialogContainer extends PureComponent {
    static propTypes = {
        asset: PropTypes.object.isRequired,
        redirectTo: PropTypes.string,
        onDone: PropTypes.func,
        parentId: PropTypes.string
    };

    deleteAsset = () => {
        const { asset, redirectTo, onDone, parentId } = this.props;

        WonderlandDomainController.removeAsset(asset, parentId).then(() => {
            if (onDone) {
                onDone();
            }

            if (redirectTo) {
                history.push(redirectTo);
            }
        });
    };

    render() {
        const passThruProps = omit(this.props, ['redirectTo']);
        return <DeleteAssetDialogComponent {...passThruProps} action={this.deleteAsset} />;
    }
}
