import { actionDispatcher } from 'react-redux-boilerout';

@actionDispatcher({
    namespace: 'BaseAssetEdit',
    actions: [
        'reset',
        'setAsset',
        'updateMeta',
        'updatePolicies',
        'updateAces',
        'updateRules',
        'dismissError',
        'addRelatedTitles',
        'fetchAssetRequested',
        'fetchAssetBaseCompleted',
        'updateRemoteOrigin',
        'updateSelected',
        'updateDestination',
        'clearDestination',
        'receivedIngestNotification',
        'receivedPropagationNotification',
        'receivedValidationNotification',
        'redirecting',
        'updateAllFilesAndErrors',
        'updateAllFilesAndErrorsAndAdvanceStep',
        'updateFilenames',
        'updateFilenamesAndAdvanceStep',
        'updateJobId',
        'updateImpStep',
        'uploading',
        'validating',
        'propagating',
        'updateFolderSelection',
        'resetSelection',
        'updateCollectionRules',
        'updateCollectionTitleOptions',
        'getAclCompleted'
    ]
})
export default class BaseAssetEditActions {}
