import { xhr } from 'wonderland-ui-commons';
import config from 'app/config';
const { prefix } = config.itemManager;
const itemsHost = `${config.apiHostname}/${prefix}`;

function getItemsByAssetIds(ids) {
    return xhr.json(`${itemsHost}/items/assets/ids`, {
        method: 'post',
        body: JSON.stringify(ids)
    });
}

export default {
    getItemsByAssetIds
};
