import { AppSwitcher } from 'wonderland-ui-app-switcher';
import { NotificationMenu } from 'wonderland-ui-notifications';
import Badge from '@material-ui/core/Badge';
import FeatureFlag from 'app/shared/FeatureFlag';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Logo from 'app/shared/Logo';
import PropTypes from 'prop-types';
import React from 'react';
import SearchForm from './SearchForm';
import Tooltip from '@material-ui/core/Tooltip';

const AppHeaderComponent = ({
    cartItemCount,
    classes: { appBar, icon, logo, search, toolBar },
    handleCart,
    handleDrawer,
    handleHelp,
    handleLogout,
    handlePreferences,
    logoutMessage
}) => (
    <header className={appBar}>
        <section className={logo}>
            <IconButton onClick={handleDrawer}>
                <Icon className={icon}>menu</Icon>
            </IconButton>
            <Logo />
        </section>
        <section className={search}>
            <SearchForm />
        </section>
        <section className={toolBar}>
            <AppSwitcher />
            <NotificationMenu notificationCenterRoute="/notification-center" />
            <Tooltip placement="bottom" title="Preferences">
                <IconButton onClick={handlePreferences}>
                    <Icon className={icon}>account_circle</Icon>
                </IconButton>
            </Tooltip>
            <Tooltip placement="bottom" title="Help">
                <IconButton onClick={handleHelp}>
                    <Icon className={icon}>help</Icon>
                </IconButton>
            </Tooltip>
            <FeatureFlag feature="CART">
                <Tooltip placement="bottom" title="Cart">
                    <IconButton onClick={handleCart}>
                        <Badge badgeContent={cartItemCount} color="error">
                            <Icon className={icon}>shopping_cart</Icon>
                        </Badge>
                    </IconButton>
                </Tooltip>
            </FeatureFlag>
            <Tooltip placement="bottom" title={logoutMessage}>
                <IconButton onClick={handleLogout}>
                    <Icon className={icon}>power_settings_new</Icon>
                </IconButton>
            </Tooltip>
        </section>
    </header>
);

AppHeaderComponent.propTypes = {
    cartItemCount: PropTypes.number.isRequired,
    classes: PropTypes.object.isRequired,
    handleCart: PropTypes.func.isRequired,
    handleDrawer: PropTypes.func.isRequired,
    handleHelp: PropTypes.func.isRequired,
    handlePreferences: PropTypes.func.isRequired,
    handleLogout: PropTypes.func.isRequired,
    logoutMessage: PropTypes.string.isRequired
};

export default AppHeaderComponent;
