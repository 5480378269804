import { mapValues } from 'lodash';
import { toDetailField } from './getDetailFields';

export default function (fields) {
    return (fields || []).map(field => {
        if (field.name === 'asset_type') {
            return { ...field, type: 'label' };
        }

        if (field.name === 'collection_name') {
            return { ...field, name: 'name' };
        }

        if (field.type === 'tabular') {
            return {
                ...field,
                children: {
                    display: { ...mapValues(field.children, tabularField => toDetailField(tabularField)) },
                    edit: { ...field.children }
                },
                view: 'edit'
            };
        }

        return field;
    });
}
