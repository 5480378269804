export const s3BrowserDefaultCriteria = [
    { type: 'filter', field: 'delivery_type', operation: 'is_one_of_keyword', value: ['s3'] },
    { type: 'filter', field: 'destination_type', operation: 'is_one_of_keyword', value: ['origin'] },
    { type: 'facet', id: 'jMxFTtxkRO', name: 'type', values: ['Destination'] }
];

export const STEPS = {
    SELECT_DESTINATION: 'selectDestination',
    SELECT_FOLDER: 'selectFolder'
};
