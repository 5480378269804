import { AssetStoreAPI } from 'core/apis';
import { compose, withHandlers, withProps } from 'recompose';
import { get } from 'lodash';
import { getFileType, getMetadataIcons, getRestrictionStatus, withTitleName } from 'lib/assetUtils';
import assetTypeAvatar from 'lib/assetTypeAvatar';
import CardListItemComponent from './CardListItemComponent';
import getSearchAssetMetadataItems from 'lib/getSearchAssetMetadataItems';
import moment from 'moment';
import red from '@material-ui/core/colors/red';
import withStyles from '@material-ui/core/styles/withStyles';

export default compose(
    withTitleName,
    withHandlers({
        toggle:
            ({ asset: { id }, toggleSelected }) =>
            () =>
                toggleSelected ? toggleSelected(id) : null
    }),
    withProps(({ asset, collectionType, preferencesThumbnailStyles, titleName }) => ({
        avatar: assetTypeAvatar.getAll(asset.asset_type),
        date: moment(asset.updated_at).format('MMM D YYYY, h:mm a'),
        fileType: getFileType(asset),
        image: AssetStoreAPI.thumbnailUrl(asset),
        linkTo: `/${
            collectionType === 'smart' ? 'smartCollection' : asset.asset_type === 'collection' ? 'collection' : 'asset'
        }/${asset.id}/metadata`,
        metadataIcons: getMetadataIcons(),
        metadataItems: getSearchAssetMetadataItems(asset).filter(item => item.value),
        preferences: preferencesThumbnailStyles,
        restrictionStatus: getRestrictionStatus(asset),
        title: titleName.length ? titleName : asset.name,
        versionName: get(asset, 'version_name[0]', '')
    })),
    withStyles(({ palette: { assetTypeColor }, spacing: { unit } }) => ({
        ...assetTypeColor,
        card: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            margin: unit,
            width: unit * 39
        },
        cardActions: { justifyContent: 'center' },
        checkbox: { padding: 4 },
        content: { padding: '8px 16px 0 16px', wordBreak: 'break-word' },
        ellipsis: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: 198 },
        grid: { padding: '0px 0px 4px 16px' },
        header: {
            alignItems: 'flex-start',
            paddingLeft: unit * 2,
            paddingBottom: 0,
            wordBreak: 'break-word'
        },
        listItem: { fontSize: '0.75rem', padding: 0 },
        media: { backgroundSize: 'contain', height: 0, paddingTop: '57%' },
        restriction: { outline: `solid 3px ${red[900]}` },
        textDense: { lineHeight: 1.1, wordBreak: 'break-word' }
    }))
)(CardListItemComponent);
