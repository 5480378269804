import { autobind, ToastController, withProgress } from 'wonderland-ui-commons';
import { tap } from 'lodash';
import { UserDataAPI } from 'core/apis';
import CacheActions from 'core/Cache/CacheActions';
import UserDataActions from './UserDataActions';

@autobind
class UserDataController {
    @withProgress({ label: 'create_saved_search' })
    createSavedSearch(savedSearch) {
        return UserDataAPI.createSavedSearch(savedSearch).then(r => UserDataActions.createSavedSearchCompleted(r));
    }

    @withProgress({ label: 'create_saved_view' })
    createSavedView(savedView) {
        return UserDataAPI.createSavedView(savedView).then(r => UserDataActions.createSavedViewCompleted(r));
    }

    @withProgress({ label: 'fetch_feature_flags' })
    fetchFeatureFlags() {
        const cacheType = 'featureFlags';
        CacheActions.loadingRequested(cacheType);
        return UserDataAPI.fetchFeatureFlags().then(
            r => tap(r, CacheActions.fetchFeatureFlags),
            e => CacheActions.loadingFailed(cacheType)
        );
    }

    @withProgress({ label: 'fetch_saved_searches' })
    fetchSavedSearches() {
        return UserDataAPI.fetchSavedSearches().then(r => UserDataActions.fetchSavedSearchesCompleted(r));
    }

    @withProgress({ label: 'fetch_saved_views' })
    fetchSavedViews() {
        return UserDataAPI.fetchSavedViews().then(r => UserDataActions.fetchSavedViewsCompleted(r));
    }

    @withProgress({ label: 'remove_saved_search' })
    removeSavedSearch(savedSearch) {
        return UserDataAPI.removeSavedSearch(savedSearch.id).then(r => {
            ToastController.show('Your saved search was deleted.');
            return UserDataActions.removeSavedSearchCompleted(r);
        });
    }

    @withProgress({ label: 'remove_saved_view' })
    removeSavedView(savedView) {
        return UserDataAPI.removeSavedView(savedView.id).then(r => {
            ToastController.show('Your saved view was deleted.');
            return UserDataActions.removeSavedViewCompleted(r);
        });
    }

    @withProgress({ label: 'subscribe_saved_search' })
    subscribeToSavedSearch(savedSearchId, frequency = 1) {
        const subscriptionInfo = {
            saved_search: savedSearchId,
            frequency
        };

        return UserDataAPI.subscribeToSavedSearch(subscriptionInfo).then(
            r => {
                ToastController.show('You are now subscribed to this saved search.');
                UserDataActions.subscribeToSavedSearchCompleted(r);
            },
            e => {
                ToastController.showError('Failed to subscribe to saved search.');
            }
        );
    }

    @withProgress({ label: 'unsubscribe_saved_search' })
    unsubscribeToSavedSearch(savedSearchId) {
        return UserDataAPI.unsubscribeToSavedSearch(savedSearchId).then(
            () => {
                ToastController.show('You are now unsubscribed from this saved search.');
                UserDataActions.unsubscribeToSavedSearchCompleted(savedSearchId);
            },
            e => {
                ToastController.showError('Failed to unsubscribe from saved search');
            }
        );
    }
}

export default new UserDataController();
