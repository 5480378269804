import FeatureFlag from 'app/shared/FeatureFlag';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Logo from 'app/shared/Logo';
import PropTypes from 'prop-types';
import React from 'react';

const AppHeaderDrawerComponent = ({
    classes,
    closeDrawer,
    handleCheckedOut,
    handleHome,
    handleLightbox,
    handleShared,
    handleSmartCollections
}) => (
    <Grid className={classes.drawerOverride} container direction="column">
        <Grid className={classes.gridRoot}>
            <IconButton onClick={closeDrawer} disableRipple>
                <Icon>menu</Icon>
            </IconButton>
            <Logo />
        </Grid>
        <List>
            <ListItem button key={'HOME'} onClick={handleHome}>
                <ListItemIcon>
                    <Icon>home</Icon>
                </ListItemIcon>
                <ListItemText primary={'Home'} />
            </ListItem>
            <ListItem button key={'LIGHTBOX'} onClick={handleLightbox}>
                <ListItemIcon>
                    <Icon>tv</Icon>
                </ListItemIcon>
                <ListItemText primary={'Lightbox'} />
            </ListItem>
            <ListItem button key={'CHECKED OUT'} onClick={handleCheckedOut}>
                <ListItemIcon>
                    <Icon>checkbox</Icon>
                </ListItemIcon>
                <ListItemText primary={'Checked Out'} />
            </ListItem>
            <ListItem button key={'SHARED'} onClick={handleShared}>
                <ListItemIcon>
                    <Icon>folder_shared</Icon>
                </ListItemIcon>
                <ListItemText primary={'Shared'} />
            </ListItem>
            <FeatureFlag feature="SMART_COLLECTIONS">
                <ListItem button key={'SMART COLLECTIONS'} onClick={handleSmartCollections}>
                    <ListItemIcon>
                        <Icon>folder_special</Icon>
                    </ListItemIcon>
                    <ListItemText primary={'Smart Collections'} />
                </ListItem>
            </FeatureFlag>
        </List>
    </Grid>
);

AppHeaderDrawerComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    closeDrawer: PropTypes.func.isRequired,
    handleCheckedOut: PropTypes.func.isRequired,
    handleHome: PropTypes.func.isRequired,
    handleLightbox: PropTypes.func.isRequired,
    handleSmartCollections: PropTypes.func.isRequired,
    handleShared: PropTypes.func.isRequired
};

export default AppHeaderDrawerComponent;
