import { actionDispatcher } from 'react-redux-boilerout';

@actionDispatcher({
    namespace: 'Meta',
    actions: [
        'updateFlags',
        'addManyToLightboxByIdCompleted',
        'addManyToCollectionCompleted',
        'fetchCheckoutsRequested',
        'fetchCheckoutsCompleted',
        'fetchCheckoutsFailed',
        'fetchCollectionAssetsRequested',
        'fetchCollectionAssetsCompleted',
        'fetchCollectionAssetsFailed',
        'removeFromCheckoutsCompleted',
        'fetchLightboxRequested',
        'fetchLightboxCompleted',
        'fetchLightboxFailed',
        'removeFromLightboxCompleted',
        'removeFromSubscribesCompleted',
        'removeManyFromCollection'
    ]
})
export default class MetaActions {}
