import { reducerRegistry } from 'lib/redux';
import { registerSliceReducer, sliceReducer, subscribe } from 'react-redux-boilerout';

export function getControlledVocabulary(slice, assetType) {
    return slice.controlledVocabulary[assetType];
}

@registerSliceReducer({ registry: reducerRegistry })
@sliceReducer('Cache')
@subscribe({ namespace: 'Cache' })
export default class CacheSlice {
    initialState() {
        return {
            contentCategories: [],
            controlledVocabulary: {},
            featureFlags: null,
            grants: [],
            loading: {},
            schemas: {}
        };
    }

    onLoadingRequested(slice, type) {
        return {
            ...slice,
            loading: {
                ...slice.loading,
                [type]: true
            }
        };
    }

    onLoadingFailed(slice, type) {
        return {
            ...slice,
            loading: {
                ...slice.loading,
                [type]: false
            }
        };
    }

    onFetchContentCategories(slice, data) {
        return {
            ...slice,
            loading: {
                ...slice.loading,
                contentCategories: false
            },
            contentCategories: data
        };
    }

    onFetchControlledVocabulary(slice, type, data) {
        return {
            ...slice,
            loading: {
                ...slice.loading,
                controlledVocabulary: false
            },
            controlledVocabulary: {
                ...slice.controlledVocabulary,
                [type]: data
            }
        };
    }

    onFetchFeatureFlags(slice, data) {
        return {
            ...slice,
            loading: {
                ...slice.loading,
                featureFlags: false
            },
            featureFlags: data
        };
    }

    onFetchGrants(slice, data) {
        return {
            ...slice,
            loading: {
                ...slice.loading,
                grants: false
            },
            grants: data
        };
    }

    onFetchSchema(slice, schema, type) {
        return {
            ...slice,
            loading: {
                ...slice.loading,
                schemas: false
            },
            schemas: {
                ...slice.schemas,
                [type]: schema
            }
        };
    }

    onFetchAllSchemas(slice, data) {
        return {
            ...slice,
            loading: {
                ...slice.loading,
                schemas: false
            },
            schemas: {
                ...slice.schemas,
                ...data.reduce((acc, { name, schema }) => ({ ...acc, [name]: schema }), {})
            }
        };
    }
}
