import { compact, flatten, get, isEmpty } from 'lodash';
import { withPropsOnChange } from 'recompose';
import config from 'app/config';
import SmartTagPriorityRuleCard from 'wonderland-ui-commons/lib/CollectionRulesCards/CollectionRuleCard/SmartTagPriorityRule/SmartTagPriorityRuleCardComponent';

const envSpecificLink = get(
    config,
    ['cvSaas', 'smartCollection', 'envSpecificLink'],
    'https://vocab.test-wonderland.studio.disney.com'
);

export default withPropsOnChange(
    ['id', 'tags', 'priorities'],
    ({ id, tags, baseCriteriaSmartTag = {}, priorities }) => {
        const link = `${envSpecificLink}/smartTagPriorityRule/${id}`;
        let ruleTags = !isEmpty(tags)
            ? tags
            : compact([
                  baseCriteriaSmartTag.tag,
                  ...flatten(priorities.map(({ smartTags }) => smartTags.map(smartTag => get(smartTag, 'tag', ''))))
              ]);
        return {
            link,
            tags: ruleTags
        };
    }
)(SmartTagPriorityRuleCard);
