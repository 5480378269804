import formatBytes from 'lib/formatBytes';
import PropTypes from 'prop-types';
import React from 'react';

export default class FileSizeComponent extends React.Component {
    static propTypes = {
        bytes: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        defaultValue: PropTypes.string,
        returnIdentityOnNonNumeric: PropTypes.bool
    };

    render() {
        try {
            return this.doRender();
        } catch (e) {
            /* istanbul ignore next */
            console.error('FileSizeComponent::render', e);
        }
        /* istanbul ignore next */
        return null;
    }

    doRender() {
        const { bytes, defaultValue, returnIdentityOnNonNumeric, ...rest } = this.props;
        return <span {...rest}>{formatBytes(bytes, defaultValue, returnIdentityOnNonNumeric)}</span>;
    }
}
