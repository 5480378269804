import { actionDispatcher } from 'react-redux-boilerout';

@actionDispatcher({
    namespace: 'Events',
    actions: [
        'getAssetEventsRequested',
        'getAssetEventsCompleted',
        'getAssetEventsFailed',
        'getVisualizationsRequested',
        'getVisualizationsCompleted',
        'getVisualizationsFailed',
        'getIndexesRequested',
        'getIndexesCompleted',
        'getIndexesFailed',
        'getEventRequested',
        'getEventCompleted',
        'getEventFailed'
    ]
})
export default class EventsActions {}
