const h0 = theme => ({
    fontWeight: 500,
    lineHeight: 1.1,
    margin: `${theme.marginSizes.default} 0 ${theme.marginSizes.small} 0`
});

const h456 = theme => ({
    ...h0(theme),
    margin: `${theme.marginSizes.small} 0`
});

const inputShared = () => ({
    outline: '5px auto -webkit-focus-ring-color',
    outlineOffset: '-2px',
    outlineStyle: 'dotted',
    outlineWidth: 'thin'
});

const inputCommon = () => ({
    fontFamily: 'inherit',
    fontSize: 'inherit',
    lineHeight: 'inherit'
});

const reset = theme => ({
    /** NORMALIZE VALUES WITH CUSTOM WL VALUES **/
    '@global': {
        '*::before': {
            boxSizing: 'border-box'
        },
        '*::after': {
            boxSizing: 'border-box'
        },
        /**
         * 1. Change the default font family in all browsers (opinionated).
         * 2. Correct the line height in all browsers.
         * 3. Prevent adjustments of font size after orientation changes in IE and iOS.
         */
        html: {
            fontFamily: 'sans-serif' /* 1 */,
            lineHeight: '1.15' /* 2 */,
            textSizeAdjust: '100%' /* 3 */,
            '-webkit-tap-highlight-color': 'rgba(0, 0, 0, 0)'
        },

        /**
         * Remove the margin in all browsers (opinionated).
         */
        body: {
            margin: 0,
            color: theme.palette.textColor,
            fontFamily: `'Roboto', sans-serif`,
            fontSize: theme.textSizes.default,
            backgroundColor: theme.palette.alternateCanvasColor,
            lineHeight: '1.42857143'
        },

        /**
         * Add the correct display in IE9
         */
        'article, aside, footer, header, nav, section, figcaption, figure, main': {
            display: 'block'
        },

        /**
         * Correct the font size and margin on `h1` elements within `section` and
         * `article` contexts in Chrome, Firefox, and Safari.
         */
        h1: {
            ...h0(theme),
            fontSize: theme.textSizes.xxLarge,
            margin: [['0.67em', 0]]
        },

        /**
         * 1. Add the correct box sizing in Firefox.
         * 2. Show the overflow in Edge and IE.
         */
        hr: {
            backgroundColor: `${theme.palette.disabledColor}`,
            border: 0,
            boxSizing: 'content-box' /* 1 */,
            height: 0 /* 1 */,
            overflow: 'visible' /* 2 */,
            marginBottom: theme.marginSizes.default,
            marginTop: theme.marginSizes.default
        },

        /**
         * 1. Correct the inheritance and scaling of font size in all browsers.
         * 2. Correct the odd `em` font sizing in all browsers.
         */
        pre: {
            fontFamily: 'monospace, monospace' /* 1 */,
            fontSize: '1em' /* 2 */
        },

        /**
         * 1. Remove the gray background on active links in IE 10.
         * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
         * 3. Remove the outline on focused links when they are also
         *    active or hovered in all browsers (opinionated).
         */
        a: {
            background: 'transparent' /* 1 */,
            textDecorationSkip: 'objects' /* 2 */,
            color: theme.palette.accent1Color,
            textDecoration: 'none',
            '&:hover': {
                color: theme.palette.accent2Color,
                textDecoration: 'none'
            },
            '&:focus': {
                color: theme.palette.accent2Color,
                outline: '5px auto -webkit-focus-ring-color',
                outlineOffset: '-2px',
                outlineStyle: 'none',
                outlineWidth: 0,
                textDecoration: 'none'
            },
            '&[role="button"]': {
                cursor: 'pointer'
            }
        },
        'a:active, a:hover': {
            outlineWidth: 0 /* 3 */
        },

        /**
         * 1. Remove the bottom border in Firefox 39-.
         * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
         */
        'abbr[title]': {
            borderBottom: 'none' /* 1 */,
            textDecoration: 'underline' /* 2 */
        },

        /**
         * Add the correct font weight in Chrome, Edge, and Safari.
         */
        'b, strong': {
            fontWeight: 'bolder'
        },

        /**
         * 1. Correct the inheritance and scaling of font size in all browsers.
         * 2. Correct the odd `em` font sizing in all browsers.
         */
        'code, kbp, samp': {
            fontFamily: 'monospace, monospace' /* 1 */,
            fontSize: '1em' /* 2 */
        },

        /**
         * Add the correct font style in Android 4.3-.
         */
        dfn: {
            fontStyle: 'italic'
        },

        /**
         * Add the correct background and color in IE 9-.
         */
        mark: {
            backgroundColor: '#ff0',
            color: '#000'
        },

        /**
         * Add the correct font size in all browsers.
         */
        small: {
            fontSize: '85%'
        },

        /**
         * Prevent `sub` and `sup` elements from affecting the line height in
         * all browsers.
         */
        'sub, sup': {
            fontSize: '75%',
            lineHeight: 0,
            position: 'relative',
            verticalAlign: 'baseline'
        },
        sup: {
            top: '-0.5em'
        },
        sub: {
            bottom: '-0.25em'
        },

        /**
         * Add the correct display in IE 9-.
         */
        'audio, video': {
            display: 'inline-block'
        },

        /**
         * Add the correct display in iOS 4-7.
         */
        'audio:not([controls])': {
            display: 'none',
            height: 0
        },

        /**
         * 1. Remove the border on images inside links in IE 10-.
         * 2. Remove extra space in all browsers
         */
        img: {
            border: 0,
            borderStyle: 'none' /* 1 */,
            verticalAlign: 'middle' /* 2 */
        },

        /**
         * Hide the overflow in IE.
         */
        'svg:not(:root)': {
            overflow: 'hidden'
        },

        /**
         * 1. Change the font styles in all browsers (opinionated).
         * 2. Remove the margin in Firefox and Safari.
         */
        'button, input, optgroup, select, textarea': {
            fontFamily: 'sans-serif' /* 1 */,
            fontSize: '100%' /* 1 */,
            lineHeight: '1.15' /* 1 */,
            margin: 0 /* 2 */
        },

        /**
         * Show the overflow in IE & Edge.
         */
        'button, input': {
            overflow: 'visible'
        },

        /**
         * Remove the inheritance of text transform in Edge, Firefox, and IE.
         */
        'button, select': {
            textTransform: 'none'
        },

        /**
         * Correct the inability to style clickable types in iOS and Safari.
         */
        'button, [type=reset], [type=submit]': {
            '-webkit-appearance': 'button'
        },

        /**
         * Remove the inner border and padding in Firefox.
         */
        'button::-moz-focus-inner, [type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner':
            {
                borderStyle: 'none',
                padding: 0
            },

        /**
         * Restore the focus styles unset by the previous rule.
         */
        'button:-moz-focusring, [type=button]:-moz-focusring, [type=reset]:-moz-focusring, [type=submit]:-moz-focusring':
            {
                outline: '1px dotted ButtonText'
            },

        /**
         * Change the border, margin, and padding in all browsers (opinionated).
         */
        fieldset: {
            padding: 0,
            margin: 0,
            border: 0,
            minWidth: 0,
            '&[disabled] input[type="radio"]': {
                cursor: 'not-allowed'
            },
            '&[disabled] input[type="checkbox"]': {
                cursor: 'not-allowed'
            }
        },
        input: {
            ...inputCommon(theme),
            '&[type="checkbox"]': {
                margin: '4px 0 0',
                marginTop: '1px',
                lineHeight: 'normal'
            },
            '&[type="checkbox"][disabled]': {
                cursor: 'not-allowed'
            },
            '&[type="checkbox"]:focus': {
                ...inputShared(theme)
            },
            '&[type="file"]': {
                display: 'block'
            },
            '&[type="file"]:focus': {
                ...inputShared(theme)
            },
            '&[type="radio"]': {
                margin: '4px 0 0',
                marginTop: '1px',
                lineHeight: 'normal'
            },
            '&[type="radio"][disabled]': {
                cursor: 'not-allowed'
            },
            '&[type="radio"]:focus': {
                ...inputShared(theme)
            },
            '&[type="range"]': {
                display: 'block',
                width: '100%'
            },
            '&[type="search"]': {
                boxSizing: 'border-box',
                '-webkit-appearance': 'none'
            }
        },
        inputShared: {
            outline: '5px auto -webkit-focus-ring-color',
            outlineOffset: '-2px',
            outlineStyle: 'dotted',
            outlineWidth: 'thin'
        },

        /**
         * 1. Correct the text wrapping in Edge and IE.
         * 2. Correct the color inheritance from `fieldset` elements in IE.
         * 3. Remove the padding so developers are not caught out when they zero out
         *    `fieldset` elements in all browsers.
         */
        legend: {
            border: 0,
            borderBottom: `1px solid ${theme.palette.disabledColor}`,
            boxSizing: 'border-box' /* 1 */,
            color: 'inherit' /* 2 */,
            display: 'block',
            fontSize: '21px',
            lineHeight: 'inherit',
            marginBottom: theme.marginSizes.default,
            maxWidth: '100%' /* 1 */,
            padding: 0 /* 3 */,
            whiteSpace: 'normal' /* 1 */,
            width: '100%'
        },

        /**
         * 1. Add the correct display in IE 9-.
         * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
         */
        progress: {
            display: 'inline-block' /* 1 */,
            verticalAlign: 'baseline' /* 2 */
        },

        /**
         * Remove the default vertical scrollbar in IE.
         */
        textarea: {
            ...inputCommon(theme),
            overflow: 'auto'
        },

        /**
         * 1. Add the correct box sizing in IE 10-.
         * 2. Remove the padding in IE 10-.
         */
        '[type=checkbox], [type=radio]': {
            boxSizing: 'border-box' /* 1 */,
            padding: 0 /* 2 */
        },

        /**
         * Correct the cursor style of increment and decrement buttons in Chrome.
         */
        '[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button': {
            height: 'auto'
        },

        /**
         * 1. Correct the odd appearance in Chrome and Safari.
         * 2. Correct the outline style in Safari.
         */
        '[type=search]': {
            appearance: 'textfield' /* 1 */,
            outlineOffset: '-2px' /* 2 */
        },

        /**
         * Remove the inner padding and cancel buttons in Chrome and Safari on OS X.
         */
        '[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration': {
            appearance: 'none'
        },

        /**
         * 1. Correct the inability to style clickable types in iOS and Safari.
         * 2. Change font properties to `inherit` in Safari.
         */
        '::-webkit-file-upload-button': {
            appearance: 'button' /* 1 */,
            font: 'inherit' /* 2 */
        },

        /**
         * Add the correct display in Edge, IE, and Firefox.
         */
        'details, menu': {
            display: 'block'
        },

        /**
         * Add the correct display in all browsers.
         */
        summary: {
            display: 'list-item'
        },

        /**
         * Add the correct display in IE 9-.
         */
        canvas: {
            display: 'inline-block'
        },

        /**
         * Add the correct display in IE.
         */
        template: {
            display: 'none'
        },

        /**
         * Add the correct display in IE 10-.
         */
        '[hidden]': {
            display: 'none'
        },

        /** BELOW ARE GLOBAL VALUES SET ONLY IN WL **/

        b: {
            fontWeight: 500
        },
        figure: {
            margin: 0
        },
        form: {
            margin: 0
        },
        /* h1: {
            ...h0(theme),
            fontSize: theme.textSizes.xxLarge
        }, */
        h2: {
            ...h0(theme),
            fontSize: theme.textSizes.xLarge
        },
        h3: {
            ...h0(theme),
            fontSize: theme.textSizes.large
        },
        h4: {
            ...h456(theme),
            fontSize: theme.textSizes.medium
        },
        h5: {
            ...h456(theme),
            fontSize: theme.textSizes.small
        },
        h6: {
            ...h456(theme),
            fontSize: theme.textSizes.tiny
        },
        label: {
            display: 'inline-block',
            maxWidth: '100%',
            marginBottom: theme.marginSizes.tiny,
            fontWeight: 'normal'
        },
        p: {
            margin: `0 0 ${theme.marginSizes.small}`
        },
        ul: {
            margin: `0 0 ${theme.marginSizes.small} 0`
        },
        select: {
            ...inputCommon(theme),
            '&[multiple]': {
                height: 'auto'
            },
            '&[size]': {
                height: 'auto'
            }
        },
        strong: {
            fontWeight: 500
        },
        table: {
            backgroundColor: 'transparent'
        },
        th: {
            textAlign: 'left'
        }
    }
});

export default reset;
