import { get, split, startCase } from 'lodash';
import assetTypeAvatar from 'lib/assetTypeAvatar';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import withStyles from '@material-ui/core/styles/withStyles';

/* istanbul ignore next */
const sheet = theme => ({
    ...theme.palette.assetTypeColor,
    chipLabel: {
        fontSize: '0.625rem',
        lineHeight: 1,
        maxWidth: theme.spacing.unit * 34,
        overflow: 'hidden',
        padding: theme.spacing.unit / 2,
        textTransform: 'uppercase'
    },
    chipRoot: {
        borderRadius: theme.spacing.unit / 2,
        height: 'auto',
        marginBottom: theme.spacing.unit / 2,
        marginRight: theme.spacing.unit / 2
    }
});

@withStyles(sheet)
export default class AttributeChipComponent extends React.Component {
    static propTypes = {
        defaultColor: PropTypes.string,
        attr: PropTypes.string.isRequired,
        label: PropTypes.string,
        value: PropTypes.any.isRequired,
        classes: PropTypes.object.isRequired
    };

    render() {
        const { attr, label, classes, value, defaultColor } = this.props;
        const newValue = get(value, 'value', value);
        const valueWithAttribute = startCase(attr).includes(' ') ? newValue : `${startCase(attr)}: ${newValue}`;
        const newAttr = split(attr, '.').shift();
        const color = assetTypeAvatar.getColor(defaultColor);

        return (
            <Tooltip title={label || startCase(newAttr)}>
                <Chip
                    classes={{
                        label: classes.chipLabel,
                        root: classes.chipRoot
                    }}
                    className={classes[color]}
                    color="primary"
                    key={`${attr}-${newValue}`}
                    label={valueWithAttribute}
                />
            </Tooltip>
        );
    }
}
