import { FILETYPE } from 'lib/fileTypes';
import { reducerRegistry } from 'lib/redux';
import { registerSliceReducer, sliceReducer, subscribe } from 'react-redux-boilerout';

@registerSliceReducer({ registry: reducerRegistry })
@sliceReducer('UploadAsset')
@subscribe({ namespace: 'UploadAsset' })
export default class UploadAssetSlice {
    initialState() {
        return {
            asset: {},
            files: [],
            isModal: true,
            attachmentMetadata: {},
            replacementMetadata: {},
            fileType: FILETYPE.ORIGINAL,
            visible: false
        };
    }

    onReset() {
        return this.initialState();
    }

    onChangeType(slice, fileType) {
        return {
            ...slice,
            fileType
        };
    }

    onFileRemoved(slice) {
        return {
            ...slice,
            files: []
        };
    }

    onFileSelected(slice, file) {
        return {
            ...slice,
            files: file
        };
    }

    onChangeAttachmentMeta(slice, key, attachmentMetadata) {
        return { ...slice, attachmentMetadata: { ...slice.attachmentMetadata, [key]: attachmentMetadata } };
    }

    onChangeReplacementMeta(slice, key, replacementMetadata) {
        return {
            ...slice,
            replacementMetadata: { ...slice.replacementMetadata, [key]: replacementMetadata }
        };
    }

    onShow(slice, asset, isModal = false) {
        return {
            ...slice,
            asset,
            isModal,
            visible: true
        };
    }

    onHide() {
        return this.initialState();
    }
}
