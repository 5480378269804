import { createContextWithHoC } from 'wonderland-ui-commons';
import React from 'react';

const formRef = React.createRef();

function assertForm() {
    if (!formRef.current) {
        throw new Error('No form attached');
    }
}

async function isFormValid() {
    assertForm();
    return formRef.current.isFormValid();
}

async function submit() {
    assertForm();
    return formRef.current.submit();
}

export const { mapContextToProps: withFormHooks } = createContextWithHoC({
    formRef,
    isFormValid,
    submit
});
