import { compact, filter, flow, get, map, mapValues, pickBy, sortBy } from 'lodash';
import { compose, mapProps } from 'recompose';
import { DiscoveryAPI } from 'core/apis';
import { palette } from 'lib/mui';
import { withFeatureFlaggedAssetTypes } from 'core/Cache/withFeatureFlags';
import { withIndexConfig } from 'wonderland-ui-discovery';
import config from 'app/config';
import defaultAssetUploadPreferences from 'lib/defaultAssetUploadPreferences';
import tableFieldDefaults, { smartCollectionAssetTagTableFieldDefaults } from 'lib/tableFieldDefaults';

function getDefaultAssetUploadPreferences(assetTypes) {
    return mapValues(assetTypes, () =>
        defaultAssetUploadPreferences.map(name => ({ name, value: null, operation: 'replace' }))
    );
}

function getDefaultBulkUploadPreferences(featureFlaggedAssetTypes) {
    return mapValues(
        pickBy(featureFlaggedAssetTypes, (val, key) => get(config.assetTypes, `${key}.bulkUploadEnabled`)),
        () => []
    );
}

function getTableFields(filterMappings, defaultFields = tableFieldDefaults) {
    return filterMappings
        ? Object.entries(filterMappings).map(([property, other]) => ({
              checked: defaultFields.includes(property),
              format: other.format,
              label: other.label,
              property,
              width: null
          }))
        : [];
}

function getTableFieldsDefaultOrderedFirst(filterMappings, defaultFields = tableFieldDefaults) {
    const defaultMappings = defaultFields.map(field => {
        const filterMap = get(filterMappings, field);
        return filterMap
            ? {
                  checked: true,
                  format: get(filterMap, 'format'),
                  label: get(filterMap, 'label'),
                  property: field,
                  width: null
              }
            : {};
    });

    const restMappings = filterMappings
        ? Object.entries(filterMappings).reduce(
              (acc, [property, other]) =>
                  !defaultFields.includes(property)
                      ? [
                            ...acc,
                            {
                                checked: false,
                                format: other.format,
                                label: other.label,
                                property,
                                width: null
                            }
                        ]
                      : acc,
              []
          )
        : [];

    return compact([...defaultMappings, ...restMappings]);
}

function getDefaultFacetsPreferences(facetMappings) {
    return flow(
        t => map(t, (v, k) => ({ ...v, name: k })),
        t => filter(t, facetMapping => config.defaultFacetPreferences.includes(facetMapping.name)),
        t => sortBy(t, 'label'),
        t =>
            map(t, facetMapping => ({
                facetId: facetMapping.name,
                expanded: config.defaultExpandedFacets.includes(facetMapping.name)
            }))
    )(facetMappings);
}

export default compose(
    withIndexConfig({ DiscoveryAPI, indexType: config.searchIndexType }),
    withIndexConfig({
        defaultIndexConfig: false,
        DiscoveryAPI,
        indexType: 'smart_collection_asset_tag',
        indexConfigKey: 'smartCollectionAssetTagIndexConfig'
    }),
    withIndexConfig({
        defaultIndexConfig: false,
        DiscoveryAPI,
        indexType: 'smart_collection',
        indexConfigKey: 'smartCollectionIndexConfig'
    }),
    withFeatureFlaggedAssetTypes,
    mapProps(({ DiscoveryAPI, indexType, assetTypes, ...props }) => {
        const facetMappings = get(props, 'indexConfig.facetConfig.facetMappings');
        const filterMappings = get(props, 'indexConfig.filterConfig.filterMappings');
        const smartCollectionFacetMappings = get(props, 'smartCollectionIndexConfig.facetConfig.facetMappings');
        const smartCollectionAssetTagFacetMappings = get(
            props,
            'smartCollectionAssetTagIndexConfig.facetConfig.facetMappings'
        );
        const smartCollectionAssetTagFilterMappings = get(
            props,
            'smartCollectionAssetTagIndexConfig.filterConfig.filterMappings'
        );
        const tableFields = getTableFieldsDefaultOrderedFirst(filterMappings);
        const smartCollectionAssetTagTableFields = getTableFields(
            smartCollectionAssetTagFilterMappings,
            smartCollectionAssetTagTableFieldDefaults
        );

        return {
            ...props,
            defaultPreferences: {
                cart: [],
                facetsPreferences: getDefaultFacetsPreferences(facetMappings),
                smartCollection: {
                    facetsPreferences: getDefaultFacetsPreferences(smartCollectionFacetMappings),
                    assetTagFacetsPreferences: getDefaultFacetsPreferences(smartCollectionAssetTagFacetMappings),
                    viewSearch: {
                        tableFields: smartCollectionAssetTagTableFields
                    },
                    titleCollectionsFacetsPreferences: config.smartCollectionsFacets.title.map(facet => ({
                        facetId: facet,
                        expanded: true
                    })),
                    titleSearchFacetsPreferences: config.titleFacets.map(facet => ({
                        facetId: facet,
                        expanded: true
                    }))
                },
                assetUploadPreferences: getDefaultAssetUploadPreferences(assetTypes),
                bulkUploadPreferences: getDefaultBulkUploadPreferences(assetTypes),
                transferPreferences: {
                    asperaEnabled: config.asperaPreferenceDefaultEnabled,
                    s3SignedUrlsEnabled: config.s3SignedUrlsPreferenceDefaultEnabled
                },
                searchResultPreferences: {
                    imageMode: true,
                    limit: 25,
                    listViewMode: 'Card',
                    sideSheetMode: true,
                    metadataMode: true,
                    sort: 'updated_at',
                    tableFields: tableFields
                },
                imagePreviewPreferences: {
                    backgroundColor: palette.backgroundImage
                }
            }
        };
    })
);
