import { compose, lifecycle, withProps } from 'recompose';
import { decode, encode } from 'wonderland-ui-commons';
import { get, identity, isUndefined } from 'lodash';
import { withFacetsPreferences, withLimit } from 'core/Profile';
import { withFeatureFlaggedFacets } from 'core/Cache/withFeatureFlags';
import { withRouteParams } from 'wonderland-ui-router';
import history from 'lib/history';
import SearchFormActions from 'app/shared/AppHeader/SearchForm/SearchFormActions';

function onDiscoveryStateChange({ route = 'search' }) {
    return function onChange({ criteria, activeFacets, skip }) {
        const updatedEncodedDiscoveryState = encode({
            criteria,
            facets: activeFacets,
            skip
        });
        history.replace(`/${route}/${updatedEncodedDiscoveryState}`);
    };
}

export default function withDiscoveryState(propsMapper = identity, args) {
    const facetsPreferencesPath = get(args, 'facetsPreferencesPath');
    return compose(
        withLimit(),
        withFacetsPreferences(facetsPreferencesPath),
        withFeatureFlaggedFacets,
        withRouteParams(['encodedDiscoveryState']),
        withProps(props => {
            const { encodedDiscoveryState, facetsPreferences, isFacetEnabled } = props;
            const preferenceState = facetsPreferences
                .filter(({ facetId }) => isFacetEnabled(facetId))
                .map(({ facetId, expanded }) => ({
                    name: facetId,
                    active: expanded
                }));
            const { contextAware, route = 'search', defaultCriteria } = propsMapper(props);
            const {
                criteria = defaultCriteria,
                facets: activeFacets,
                skip
            } = encodedDiscoveryState ? decode(encodedDiscoveryState) : {};
            return {
                contextAware,
                criteria,
                onDiscoveryStateChange: onDiscoveryStateChange({ route }),
                route,
                skip,
                facetsState: !isUndefined(activeFacets)
                    ? preferenceState.map(fs => ({ ...fs, active: activeFacets.includes(fs.name) }))
                    : preferenceState
            };
        }),
        lifecycle({
            componentDidMount() {
                const { contextAware, defaultCriteria, route } = this.props;
                !contextAware && SearchFormActions.setRoute(route);
                !contextAware && SearchFormActions.setDefaultCriteria(defaultCriteria);
            },
            componentDidUpdate({ route: prevRoute }) {
                const { contextAware, defaultCriteria, route } = this.props;
                if (prevRoute !== route && !contextAware) {
                    SearchFormActions.setRoute(route);
                    SearchFormActions.setDefaultCriteria(defaultCriteria);
                }
            },
            componentWillUnmount() {
                SearchFormActions.reset();
            }
        })
    );
}
