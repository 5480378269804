import { concat, get, isBoolean, pick } from 'lodash';
import { ErrorBoundary } from 'wonderland-ui-commons';
import { FILETYPE } from 'lib/fileTypes';
import { Paper, Typography } from '@material-ui/core';
import { SelectValidator, TextValidator } from 'react-material-ui-form-validator';
import config from 'app/config';
import FilePickerValidator from 'app/shared/FilePickerValidator';
import GetRequiredLabel from 'wonderland-ui-material/lib/GetRequiredLabel';
import IMPFolderPicker from 'wonderland-ui-commons/lib/Forms/FormFields/IMPFolderPickerFormField';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';
import VocabDropdown from 'app/shared/VocabularyDropdown';

const { filePickerFormFields, impFilePickerFormFields, apiHostname } = config;

const UploadAssetFormComponent = props => {
    const {
        assetType,
        attachmentCv,
        attachmentMetadata,
        changeAttachmentMeta,
        changeReplacementMeta,
        classes: { paper },
        classes,
        controlledVocabulary,
        files,
        isAttachment,
        isComposition,
        isFlagEnabled,
        onChangeType,
        originalUploadAvailable,
        replacementMetadata,
        revisionJustification,
        singleFolderValidator
    } = props;

    const filePickerProps = [
        ...filePickerFormFields,
        'canMultiFileUpload',
        'drawerVariant',
        'errorMessages',
        'filePickerVariant',
        'openDrawer',
        'renderFilesOrFolder',
        'validators'
    ];
    const impFilePickerProps = [...impFilePickerFormFields, 'input'];
    const additionalProps = {
        available: true,
        value: files,
        input: { value: files }
    };

    const handleChange = (event, payload) => onChangeType(get(payload, 'props.value'));
    const handleNoteChange = event => changeReplacementMeta('replacement_justification', event.target.value);
    const handleReplacementMandatory = e => {
        changeReplacementMeta('replacement_mandatory', e.target.value);
    };
    const handleReplacementReason = (event, payload) => {
        changeReplacementMeta('replacement_reason', payload);
    };
    const handleAttachmentType = (event, payload) => changeAttachmentMeta('attachment_type', payload);
    const handleRemarksChange = ({ target: { value } }) => changeAttachmentMeta('remarks', value);

    return (
        <ErrorBoundary>
            <Typography gutterBottom variant="h5">
                Metadata
            </Typography>
            <Paper classes={{ root: paper }} elevation={0}>
                <SelectValidator
                    errorMessages={['An upload type is required.']}
                    label={<GetRequiredLabel label={'Upload Type'} />}
                    fullWidth
                    name="type"
                    onChange={handleChange}
                    validators={['required']}
                    value={assetType}
                >
                    {originalUploadAvailable && (
                        <MenuItem key="original" value="original">
                            New Version
                        </MenuItem>
                    )}
                    <MenuItem key="preview" value="preview">
                        Custom Preview
                    </MenuItem>
                    <MenuItem key="thumbnail" value="thumbnail">
                        Custom Thumbnail
                    </MenuItem>
                    {isFlagEnabled('ATTACHMENTS') && (
                        <MenuItem key="attachment" value="attachment">
                            Attachment
                        </MenuItem>
                    )}
                </SelectValidator>
                {revisionJustification && (
                    <div>
                        <VocabDropdown
                            errorMessages={['A replacement reason is required.']}
                            fullWidth
                            floatingLabelText="Replacement Reason"
                            multi
                            name="Replacement Reason"
                            onChange={handleReplacementReason}
                            vocabulary={get(controlledVocabulary, 'replacement_reason')}
                            validators={['required']}
                            value={get(replacementMetadata, 'replacement_reason')}
                        />
                        <SelectValidator
                            errorMessages={['Select if replacement is mandatory or not']}
                            label={<GetRequiredLabel label={'Is Replacement Mandatory?'} />}
                            fullWidth
                            InputLabelProps={{ shrink: isBoolean(get(replacementMetadata, 'replacement_mandatory')) }}
                            name="Replacement Mandatory"
                            onChange={handleReplacementMandatory}
                            validators={['required']}
                            value={get(replacementMetadata, 'replacement_mandatory')}
                        >
                            <MenuItem key="mandatory" value={true}>
                                Yes
                            </MenuItem>
                            <MenuItem key="not_mandatory" value={false}>
                                No
                            </MenuItem>
                        </SelectValidator>
                        <TextValidator
                            label={<GetRequiredLabel label={'Justification / Note:'} />}
                            fullWidth
                            multiline={true}
                            name="notes"
                            onChange={handleNoteChange}
                            value={get(replacementMetadata, 'replacement_justification', '')}
                            validators={['required']}
                            errorMessages={['A justification note is required.']}
                        />
                    </div>
                )}
                {isAttachment && (
                    <div>
                        <VocabDropdown
                            errorMessages={['An attachment type is required.']}
                            fullWidth
                            floatingLabelText="Attachment Type"
                            name="Attachment Type"
                            onChange={handleAttachmentType}
                            vocabulary={get(attachmentCv, 'attachment_type')}
                            validators={['required']}
                            value={get(attachmentMetadata, 'attachment_type')}
                        />
                        <TextValidator
                            label="Remarks"
                            fullWidth
                            multiline
                            name="remarks"
                            onChange={handleRemarksChange}
                            value={get(attachmentMetadata, 'remarks', '')}
                        />
                    </div>
                )}
            </Paper>
            <div className={classes.filePicker}>
                {isComposition && assetType === FILETYPE.ORIGINAL ? (
                    <IMPFolderPicker
                        name="imf_upload"
                        {...pick(props, concat(filePickerProps, impFilePickerProps))}
                        {...additionalProps}
                        isFolderValid={singleFolderValidator}
                        apiHostName={apiHostname}
                    />
                ) : (
                    <FilePickerValidator
                        name="original_thumb_or_preview"
                        {...pick(props, filePickerProps)}
                        {...additionalProps}
                    />
                )}
            </div>
        </ErrorBoundary>
    );
};

UploadAssetFormComponent.propTypes = {
    assetmap: PropTypes.string,
    assetType: PropTypes.string,
    attachmentCv: PropTypes.object,
    attachmentMetadata: PropTypes.object,
    changeAttachmentMeta: PropTypes.func,
    changeReplacementMeta: PropTypes.func,
    classes: PropTypes.object.isRequired,
    cpl: PropTypes.string,
    controlledVocabulary: PropTypes.object,
    destination: PropTypes.object,
    Dropzone: PropTypes.func,
    errorMessages: PropTypes.array,
    errors: PropTypes.array,
    essences: PropTypes.array,
    filenames: PropTypes.array,
    files: PropTypes.array,
    folderSelectionAvailable: PropTypes.bool,
    folderSelectionText: PropTypes.string,
    impStep: PropTypes.string,
    input: PropTypes.string,
    isAttachment: PropTypes.bool,
    isComposition: PropTypes.bool,
    isFlagEnabled: PropTypes.func,
    isFolder: PropTypes.bool,
    onChangeType: PropTypes.func,
    originalUploadAvailable: PropTypes.bool,
    pkl: PropTypes.string,
    remoteOrigin: PropTypes.bool,
    replacementMetadata: PropTypes.object,
    revisionJustification: PropTypes.bool,
    singleFileValidator: PropTypes.func,
    singleFolderValidator: PropTypes.func,
    startUploading: PropTypes.bool,
    startValidating: PropTypes.bool,
    updateFolderSelection: PropTypes.func,
    validators: PropTypes.array
};

export default UploadAssetFormComponent;
