import { getSelectedItemsSize } from './CartUtils';
import { registerSliceReducer, sliceReducer, subscribe } from 'react-redux-boilerout';
import { reducerRegistry as registry, store } from 'lib/redux';

@registerSliceReducer({ store, registry })
@sliceReducer('Cart')
@subscribe({ namespace: 'Cart' })
export default class CartSlice {
    initialState() {
        return {
            cartAssets: {},
            cartIds: [],
            cartLoading: false,
            selectedIds: [],
            selectedItemsSize: 0
        };
    }

    onReceivedCartAssets(slice, cartAssets) {
        return { ...slice, cartAssets, cartLoading: false };
    }

    onRequestCart(slice) {
        return { ...slice, cartLoading: true };
    }

    onSelectClear(slice, ids) {
        const { selectedIds: oldSelectedIds } = slice;
        const selectedIds = oldSelectedIds.filter(id => !ids.includes(id));

        return { ...slice, selectedIds };
    }

    onSelectToggle(slice, selectedId) {
        const { cartAssets, selectedIds: oldSelectedIds } = slice;
        const { results } = cartAssets;
        const selectedIds = oldSelectedIds.includes(selectedId)
            ? oldSelectedIds.filter(id => id !== selectedId)
            : [...oldSelectedIds, selectedId];

        const selectedItemsSize = getSelectedItemsSize(results, selectedIds);

        return { ...slice, selectedIds, selectedItemsSize };
    }

    onSelectAll(slice, ids) {
        const selectedIds = ids;

        return { ...slice, selectedIds };
    }

    onDeselectAll(slice) {
        const selectedIds = [];

        return { ...slice, selectedIds };
    }

    reset() {
        return this.initialState();
    }
}
