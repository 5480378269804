import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const RestrictionStatusComponent = ({ iconOnly = false, restrictions = {}, styles = {} }) => {
    const { restrictionBGColor, restrictionColor, restrictionIcon, restrictionTitle } = restrictions;
    const style = {
        backgroundColor: restrictionBGColor,
        borderRadius: '4px 0 0 4px',
        color: restrictionColor,
        ...styles
    };

    return iconOnly ? (
        <Tooltip placement="bottom" title={restrictionTitle}>
            <Icon style={{ color: restrictionBGColor }}>{restrictionIcon}</Icon>
        </Tooltip>
    ) : (
        <Grid alignItems="center" container style={style} wrap="nowrap">
            <Grid item>
                <Icon fontSize="large">{restrictionIcon}</Icon>
            </Grid>
            <Grid item>
                <Typography color="inherit" variant="overline">
                    {restrictionTitle}
                </Typography>
            </Grid>
        </Grid>
    );
};

RestrictionStatusComponent.propTypes = {
    iconOnly: PropTypes.bool,
    restrictions: PropTypes.object.isRequired,
    styles: PropTypes.object
};

export default RestrictionStatusComponent;
