import { displayNames, recipientTypes } from 'core/Destinations/DeliveryFormConstants';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import PropTypes from 'prop-types';
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

const { dl } = recipientTypes;

/* istanbul ignore next */
const sheet = theme => ({
    TableRoot: {
        border: '1px solid lightgrey'
    },
    TableCellRoot: {
        borderBottom: '1px solid lightgrey'
    }
});

@withStyles(sheet)
export default class NotificationRecipientsComponent extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        deleteNotificationRecipient: PropTypes.func,
        onToggleContacts: PropTypes.func,
        notifications: PropTypes.object.isRequired,
        user: PropTypes.object
    };

    disableDelete = (type, userAdded) => {
        return !userAdded && type === dl;
    };

    doRender() {
        const { deleteNotificationRecipient, notifications, classes, onToggleContacts } = this.props;

        return (
            <React.Fragment>
                {Object.keys(notifications).length === 0 && (
                    <Typography variant="overline">No recipients currently added.</Typography>
                )}
                {Object.keys(notifications).length > 0 && (
                    <Table classes={{ root: classes.TableRoot }}>
                        <TableHead>
                            <TableRow>
                                <TableCell classes={{ root: classes.TableCellRoot }}>Type</TableCell>
                                <TableCell classes={{ root: classes.TableCellRoot }}>Recipient</TableCell>
                                <TableCell classes={{ root: classes.TableCellRoot }}>Remove</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(notifications).map(key => {
                                const { type, name, email, userAdded } = notifications[key];
                                const onDeleteItem = () => deleteNotificationRecipient(key);
                                const onToggleContactList = () => onToggleContacts(notifications[key]);
                                return (
                                    <TableRow key={key}>
                                        <TableCell classes={{ root: classes.TableCellRoot }}>
                                            {displayNames[type]}
                                        </TableCell>
                                        {type === dl && (
                                            <TableCell classes={{ root: classes.TableCellRoot }}>
                                                {name}
                                                <Tooltip title="View/Exclude Contacts" placement="top">
                                                    <IconButton onClick={onToggleContactList}>
                                                        <KeyboardArrowDownIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        )}
                                        {type !== dl && (
                                            <TableCell classes={{ root: classes.TableCellRoot }}>{email}</TableCell>
                                        )}
                                        <TableCell classes={{ root: classes.TableCellRoot }}>
                                            <Tooltip
                                                component="span"
                                                title={`Remove ${displayNames[type]}`}
                                                placement="top"
                                            >
                                                <IconButton
                                                    onClick={onDeleteItem}
                                                    disabled={this.disableDelete(type, userAdded)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                )}
            </React.Fragment>
        );
    }

    render() {
        try {
            return this.doRender();
        } catch (e) {
            console.error('NotificationRecipientsComponent::render', e);
        }
        return null;
    }
}
