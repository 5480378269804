import { AsperaDropzone, AsperaService, withAsperaUpload } from 'wonderland-ui-aspera';
import { AssetStoreAPI, WonderlandDomainAPI } from './apis';
import { branch, compose, withHandlers, withProps } from 'recompose';
import { get } from 'lodash';
import { withHttpUpload } from 'wonderland-ui-commons';
import { withS3Upload } from 'wonderland-ui-s3';
import React from 'react';
import RegularDropzone from 'app/shared/RegularDropzone';

import { withFeatureFlagsLite } from './Cache/withFeatureFlags';
import withFallback from './withFallback';
import WonderlandDomainController from 'core/WonderlandDomain';

export default opts =>
    compose(
        withFeatureFlagsLite(),
        branch(
            ({ isFlagEnabled }) => {
                if (
                    isFlagEnabled('UNIFIED_INGEST_ASPERA') &&
                    (get(opts, 'isBulkUpload') || get(opts, 'isSidecarBulkUpload'))
                ) {
                    return isFlagEnabled('UNIFIED_INGEST_BULK');
                } else {
                    return isFlagEnabled('UNIFIED_INGEST_ASPERA');
                }
            },
            withAsperaUpload({
                uploadTransferSpecProvider: AssetStoreAPI.getUploadTransferSpec,
                manageTransfer: data => WonderlandDomainController.unifiedManagedIngest({ ...data, ...opts })
            }),
            withAsperaUpload({
                uploadTransferSpecProvider: AssetStoreAPI.getUploadTransferSpec,
                manageTransfer: WonderlandDomainAPI.managedUpload
            })
        ),
        branch(
            ({ isFlagEnabled }) => {
                if (
                    isFlagEnabled('UNIFIED_INGEST_SIGNED') &&
                    (get(opts, 'isBulkUpload') || get(opts, 'isSidecarBulkUpload'))
                ) {
                    return isFlagEnabled('UNIFIED_INGEST_BULK');
                } else {
                    return isFlagEnabled('UNIFIED_INGEST_SIGNED');
                }
            },
            withS3Upload({
                uploadTransferSpecProvider: AssetStoreAPI.getUploadTransferSpec,
                manageTransfer: data => WonderlandDomainController.unifiedManagedIngest({ ...data, ...opts })
            }),
            withS3Upload({
                uploadTransferSpecProvider: AssetStoreAPI.getUploadTransferSpec,
                manageTransfer: WonderlandDomainAPI.managedUpload
            })
        ),
        withHttpUpload({ uploadTransferSpecProvider: AssetStoreAPI.getHttpUploadTransferSpec }),
        withHandlers({
            upload:
                ({ signedUrlsEnabled, uploadWithAspera, uploadWithS3, uploadWithHttp }) =>
                args => {
                    const uploadWithS3WithFallback = withFallback(signedUrlsEnabled, uploadWithS3, uploadWithHttp);
                    const uploadWithAsperaWithFallback = withFallback(
                        AsperaService.connected,
                        uploadWithAspera,
                        uploadWithS3WithFallback
                    );

                    return uploadWithAsperaWithFallback(args);
                },
            Dropzone: () => props =>
                AsperaService.connected ? <AsperaDropzone {...props} /> : <RegularDropzone {...props} />
        }),
        withProps(({ isFlagEnabled }) => {
            const folderSelectionAvailable = isFlagEnabled('S3_BROWSER') || AsperaService.connected;
            return {
                folderSelectionAvailable,
                folderSelectionText: folderSelectionAvailable
                    ? 'Select Folder (Multifile Asset Upload):'
                    : 'Enable Aspera to upload multifile assets'
            };
        })
    );
