/* eslint-disable */
import { createSelector } from 'reselect';
import { LiteRoute, RequireExternalLoginRoute, RequireLoginRoute, RequireRoleRoute } from 'wonderland-ui-router';
import { withTags } from 'wonderland-ui-commons';
import React from 'react';

const withLiteTagSelector = createSelector(
    c => c,
    c => withTags('lite')(c)
);
const withAuthenticatedTagSelector = createSelector(
    c => c,
    c => withTags('authenticated')(c)
);
const withExternalTagsSelector = createSelector(
    c => c,
    c => withTags('external')(c)
);

export const WonderlandLiteRoute = props => <LiteRoute {...props} Component={withLiteTagSelector(props.Component)} />;
export const WonderlandRequireRoleRoute = props => (
    <RequireRoleRoute {...props} Component={withAuthenticatedTagSelector(props.Component)} />
);
export const WonderlandRequireLoginRoute = props => (
    <RequireLoginRoute {...props} Component={withAuthenticatedTagSelector(props.Component)} />
);
export const WonderlandRequireExternalLoginRoute = props => (
    <RequireExternalLoginRoute {...props} Component={withExternalTagsSelector(props.Component)} />
);
