import PropTypes from 'prop-types';
import React from 'react';
import TypeAhead from 'wonderland-ui-commons/lib/Typeahead';

const RecipientSearch = ({
    handleChange,
    handleOptions,
    handleOptionLabel,
    isMulti = true,
    minLength = 3,
    name = 'recipientSearch',
    noOptionsMessage = 'No Results',
    placeholder = '',
    textFieldProps = { label: 'Search Distribution Lists and Users' },
    value = []
}) => {
    return (
        <TypeAhead
            creatable
            isAsync
            isMulti={isMulti}
            getOptionLabel={handleOptionLabel}
            loadOptions={handleOptions}
            minLength={minLength}
            name={name}
            noOptionsMessage={noOptionsMessage}
            onChange={handleChange}
            placeholder={placeholder}
            textFieldProps={textFieldProps}
            value={value}
        />
    );
};

RecipientSearch.propTypes = {
    handleChange: PropTypes.func.isRequired,
    handleOptions: PropTypes.func.isRequired,
    handleOptionLabel: PropTypes.func.isRequired,
    isMulti: PropTypes.bool,
    minLength: PropTypes.number,
    name: PropTypes.string,
    noOptionsMessage: PropTypes.string,
    placeholder: PropTypes.string,
    textFieldProps: PropTypes.object,
    value: PropTypes.array
};

export default RecipientSearch;
