import { mapValues } from 'lodash';
import { toDetailField } from './getDetailFields';

const defaultAvailableOperations = [
    {
        label: 'Add',
        value: 'add'
    },
    {
        label: 'Replace',
        value: 'replace'
    },
    {
        label: 'Clear',
        value: 'delete'
    },
    {
        label: 'Remove',
        value: 'remove'
    }
];

export default function (fields, assetType, ctx) {
    return (fields || []).map(f => {
        let field = f;
        let availableOperations = defaultAvailableOperations;

        if (!field.multiple && field.type !== 'tags' && field.type !== 'tabular') {
            availableOperations = availableOperations.filter(o => !['add', 'remove'].includes(o.value));
        }

        if (field.required) {
            availableOperations = availableOperations.filter(o => o.value !== 'delete');
        }

        if (field.type === 'tabular') {
            field = {
                ...field,
                children: {
                    display: { ...mapValues(field.children, tabularField => toDetailField(tabularField)) },
                    edit: { ...field.children }
                },
                view: 'edit',
                showTitle: false
            };
        }

        return {
            ...field,
            availableOperations
        };
    });
}
