import { compose, withHandlers, withProps } from 'recompose';
import { dispatchResize } from 'lib/utils';
import FormDialogComponent from './FormDialogComponent';
import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

export default compose(
    withProps({
        formRef: React.createRef()
    }),
    withHandlers({
        onSubmit:
            ({ onSubmit }) =>
            () => {
                onSubmit();
                dispatchResize();
            }
    }),
    withStyles(theme => ({
        dialogPaperRoot: {
            maxHeight: '75%',
            maxWidth: '80%',
            minHeight: '50%',
            minWidth: '80%'
        }
    }))
)(FormDialogComponent);
