import { compose, withHandlers, withState } from 'recompose';
import { get, head, isArray, isString } from 'lodash';
import { recipientMessages, recipientTypes } from 'core/Destinations/DeliveryFormConstants';
import { ToastController } from 'wonderland-ui-commons';
import { validation } from 'lib/form';
import RecipientSearchComponent from './RecipientSearchComponent';
import RecipientSearchController from './RecipientSearchController';

const { invalidMessage, lockedMessage } = recipientMessages;
const { search } = RecipientSearchController;
const { external } = recipientTypes;
const { showError } = ToastController;
const { isEmail } = validation;
const searchParams = { limit: 20, skip: 0 };

export default compose(
    withState('options', 'setOptions', []),
    withHandlers({
        handleChange:
            ({ addNotificationRecipient, options }) =>
            option => {
                const item = isArray(option) ? head(option) : option;
                const type = get(item, 'value.type', external);

                if (type !== external) {
                    addNotificationRecipient(item, type);
                    return;
                }

                const email = get(item, 'value.email', get(item, 'value', '')).toLowerCase();

                if (!isEmail(email)) {
                    showError(invalidMessage);
                } else {
                    const user = options.find(opt => get(opt, 'value.email', '').toLowerCase() === email) || item;
                    const userLocked = get(user, 'locked', false);
                    const userType = get(user, 'value.type', type);

                    !userLocked ? addNotificationRecipient(user, userType) : showError(lockedMessage);
                }
            },
        handleOptions:
            ({ setOptions }) =>
            async filter => {
                const res = await search({ filter, ...searchParams });
                const active = [
                    res[0],
                    { label: res[1].label, options: [...res[1].options.filter(({ locked }) => !locked)] }
                ];

                setOptions([...res[0].options, ...res[1].options]);

                return active;
            },
        handleOptionLabel:
            () =>
            ({ text, value }) =>
                isString(value) && !isEmail(value) ? invalidMessage : text || value
    })
)(RecipientSearchComponent);
