import { compose, withHandlers, withProps } from 'recompose';
import BulkSelectionCtrlComponent from './BulkSelectionCtrlComponent';
import formatBytes from 'lib/formatBytes';
import withStyles from '@material-ui/core/styles/withStyles';

export default compose(
    withHandlers({
        currentlySelectedAssets:
            ({ assets = [], selectedItems }) =>
            () =>
                assets.filter(({ id }) => selectedItems.includes(id))
    }),
    withProps(({ assets = [], currentlySelectedAssets, selectedItems, selectedItemsSize = 0 }) => ({
        currentlySelectedAssetsCount: currentlySelectedAssets().length,
        selectedAssetsCount: selectedItems.length,
        totalAssetsCount: assets.length,
        totalAssetsSize: formatBytes(selectedItemsSize)
    })),
    withStyles(() => ({
        container: { alignItems: 'center', display: 'flex' }
    }))
)(BulkSelectionCtrlComponent);
