import { abortable, autobind, ToastController, withProgress } from 'wonderland-ui-commons';
import { DiscoveryAPI } from 'core/apis';
import DiscoveryActions from './DiscoveryActions';

@autobind
class DiscoveryController {
    @abortable()
    @withProgress({ label: 'fetch_search' })
    getSearch(...args) {
        return DiscoveryAPI.getSearch(...args);
    }

    @withProgress({ label: 'lookup_facet_values' })
    lookupFacetValues(...args) {
        return DiscoveryAPI.lookupFacetValues(...args);
    }

    @withProgress({ label: 'lookup_combo_facet_values' })
    lookupComboValues(...args) {
        return DiscoveryAPI.lookupComboValues(...args);
    }

    @withProgress({ label: 'fetch_search_config' })
    getIndexConfig(...args) {
        return DiscoveryAPI.getIndexConfig(...args);
    }

    getStats() {
        DiscoveryActions.getStatsRequested();

        DiscoveryAPI.getStats().then(
            res => DiscoveryActions.getStatsCompleted(res),
            e => {
                ToastController.showError(e);
                DiscoveryActions.getStatsFailed(e);
            }
        );
    }

    @withProgress({ label: 'fetch_visualizations' })
    getVisualizations() {
        DiscoveryActions.getWonderlandVisualizationsRequested();

        return DiscoveryAPI.getVisualizations().then(
            r => DiscoveryActions.getWonderlandVisualizationsCompleted(r),
            e => {
                ToastController.showError(e);
                DiscoveryActions.getWonderlandVisualizationsFailed(e);
            }
        );
    }
}

export default new DiscoveryController();
