import { autobind, ToastController, withProgress } from 'wonderland-ui-commons';
import { MetaAPI } from 'core/apis';
import MetaActions from './MetaActions';
import SearchActions from 'app/Search/SearchActions';

@autobind
class MetaController {
    fetchCollectionAssets(id, { skip, limit, skipCount } = {}) {
        MetaActions.fetchCollectionAssetsRequested();

        return MetaAPI.getCollectionAssets(id, { skip, limit, skipCount }).then(
            res => MetaActions.fetchCollectionAssetsCompleted(res),
            e => MetaActions.fetchCollectionAssetsFailed(e)
        );
    }

    fetchCollections({ skip, limit, filter } = {}) {
        return MetaAPI.getCollections({ skip, limit, filter }).catch(e => {
            ToastController.showError(e);
        });
    }

    @withProgress({ label: 'lightbox' })
    fetchLightbox({ skip, limit } = {}) {
        MetaActions.fetchLightboxRequested();

        return MetaAPI.getLightbox({ skip, limit }).then(
            res => MetaActions.fetchLightboxCompleted(res),
            e => MetaActions.fetchLightboxFailed(e)
        );
    }

    fetchSubscribes() {
        return MetaAPI.getSubscribe();
    }

    @withProgress({ label: 'checkout' })
    fetchCheckouts({ skip, limit } = {}) {
        MetaActions.fetchCheckoutsRequested();

        return MetaAPI.getCheckout({ skip, limit }).then(
            res => MetaActions.fetchCheckoutsCompleted(res),
            e => MetaActions.fetchCheckoutsFailed(e)
        );
    }

    addToLightbox(asset) {
        return MetaAPI.addToLightbox(asset.id).then(
            res => {
                ToastController.show(`Successfully added '${asset.name}' to lightbox`);
                MetaActions.updateFlags(res);
            },
            e => {
                ToastController.showError(`Failed to add '${asset.name}', ${e.message}`);
            }
        );
    }

    addManyToLightboxById(assetIds) {
        return MetaAPI.addManyToLightbox(assetIds).then(
            res => {
                ToastController.show(`Successfully added ${assetIds.length} assets to lightbox`);
                MetaActions.addManyToLightboxByIdCompleted(res);
            },
            e => {
                ToastController.showError(`Faled to add asset to lightbox`);
            }
        );
    }

    addManyToCollection(collectionId, assetIds, collectionName) {
        return MetaAPI.addManyToCollection(collectionId, assetIds).then(
            () => {
                MetaActions.addManyToCollectionCompleted();
                ToastController.show(`Successfully added ${assetIds.length} assets to collection '${collectionName}'`);
            },
            e => {
                ToastController.showError(e);
            }
        );
    }

    removeFromLightbox(asset) {
        return MetaAPI.removeFromLightbox(asset.id).then(
            res => {
                ToastController.show(`Successfully removed '${asset.name}' from lightbox`);
                MetaActions.updateFlags(res);
            },
            e => {
                ToastController.showError(`Failed to remove '${asset.name}', ${e.message}`);
            }
        );
    }

    removeManyFromCollection(collectionId, assetIds) {
        return MetaAPI.removeManyFromCollection(collectionId, assetIds).then(
            () => {
                ToastController.show(`Removed ${assetIds.length} assets from Collection ${collectionId}`);
                SearchActions.clearSelectedItems();
            },
            e => {
                ToastController.showError(e);
            }
        );
    }

    addToSubscribes(asset) {
        return MetaAPI.addToSubscribes(asset.id).then(
            res => {
                ToastController.show(`Subscribed to '${asset.name}'`);
                MetaActions.updateFlags(res);
            },
            e => {
                ToastController.showError(e);
            }
        );
    }

    removeFromSubscribes(asset) {
        return MetaAPI.removeFromSubscribes(asset.id).then(
            res => {
                ToastController.show(`Unsubscribed from '${asset.name}'`);
                MetaActions.updateFlags(res);
                MetaActions.removeFromSubscribesCompleted(res);
            },
            e => {
                ToastController.showError(e);
            }
        );
    }

    checkout(asset, { auto } = { auto: false }) {
        return MetaAPI.addToCheckouts(asset.id, auto).then(
            res => {
                if (!auto) {
                    ToastController.show(`Successfully checked out '${asset.name}'`);
                }
                MetaActions.updateFlags(res);
            },
            e => {
                ToastController.showError(e);
            }
        );
    }

    autoLock(asset) {
        return this.checkout(asset, { auto: true });
    }

    removeFromCheckouts(asset) {
        return MetaAPI.removeFromCheckouts(asset.id).then(
            res => {
                SearchActions.clearSelectedItems();
                ToastController.show(`Successfully checked in '${asset.name}'`);
                MetaActions.updateFlags(res);
            },
            e => {
                ToastController.showError(e);
            }
        );
    }

    isLockableByUser(asset) {
        return MetaAPI.isLockableByUser(asset.id).then(
            lock => ({
                locked: !!lock && lock.locked,
                autolocked: !!lock && lock.autolocked
            }),
            e => {
                ToastController.showError(e.message);
                throw e;
            }
        );
    }

    lockIfUnlocked(asset) {
        return this.isLockableByUser(asset).then(lock => !lock.locked && this.autoLock(asset));
    }

    toggleLightbox(asset) {
        return asset.lightbox ? this.removeFromLightbox(asset) : this.addToLightbox(asset);
    }

    toggleSubscribe(asset) {
        return asset.subscribed ? this.removeFromSubscribes(asset) : this.addToSubscribes(asset);
    }

    toggleLock(asset) {
        return asset.locked ? this.removeFromCheckouts(asset) : this.checkout(asset);
    }
}

export default new MetaController();
