import { AcceptTermsAndConditions, Login, LoginCallback, LoginExternalUser, Logout } from 'wonderland-ui-security';
import { Help } from 'wonderland-ui-commons';
import { NotificationCenter } from 'wonderland-ui-notifications';
import { Switch } from 'wonderland-ui-router';
import { TermsAndConditionsText } from 'wonderland-ui-material';
import {
    WonderlandLiteRoute,
    WonderlandRequireExternalLoginRoute,
    WonderlandRequireLoginRoute,
    WonderlandRequireRoleRoute
} from 'app/shared/WonderlandRoutes';
import AssetChangeType from 'app/AssetChangeType';
import AssetEdit from 'app/AssetEdit';
import AssetView from 'app/AssetView';
import BulkEdit from 'app/BulkEdit';
import BulkExport from 'app/BulkExport';
import BulkUpload from 'app/BulkUpload';
import Checkout from 'app/Checkout';
import CollectionEdit from 'app/CollectionEdit';
import CollectionView from 'app/CollectionView';
import config from 'app/config';
import DeliverAsset from 'app/DeliverAsset';
import IMFReplacement from 'app/IMFReplacement';
import Lightbox from 'app/Lightbox';
import NewAsset from 'app/NewAsset';
import NewCollection from 'app/NewCollection';
import NewSmartCollection from 'app/NewSmartCollection';
import NotFound from 'app/NotFound';
import Preferences from 'app/Preferences';
import React from 'react';
import Relationship from 'app/Relationship';
import Search from 'app/Search';
import SequentialEdit from 'app/SequentialEdit';
import Share from 'app/Share';
import SharedCollection from 'app/External/SharedCollection';
import SharedCollectionMyId from 'app/External/SharedCollectionMyId';
import SharedView from 'app/SharedView';
import SidecarBulkUpload from 'app/SidecarBulkUpload';
import SmartCollectionSearch from 'app/SmartCollectionSearch';
import SmartCollectionTitle from 'app/SmartCollectionTitle';
import SmartCollectionView from 'app/SmartCollectionView';
import UploadAsset from 'app/UploadAsset';

const AcceptWonderlandTermsAndConditions = () => (
    <AcceptTermsAndConditions text={TermsAndConditionsText} appName="Wonderland" />
);

const ExternalAcceptWonderlandTermsAndConditions = () => (
    <AcceptTermsAndConditions text={TermsAndConditionsText} appName="Wonderland" external={true} />
);

export default (
    <Switch>
        <WonderlandLiteRoute path={config.localLoginURI} exact={true} Component={Login} />
        <WonderlandLiteRoute path={config.loginCallbackURI} Component={LoginCallback} />
        <WonderlandLiteRoute path={config.logoutURI} Component={Logout} />
        <WonderlandLiteRoute path={config.externalLoginURI} exact={true} Component={LoginExternalUser} />
        <WonderlandLiteRoute path={config.termsAndConditionsURI} Component={AcceptWonderlandTermsAndConditions} />
        <WonderlandLiteRoute
            path={config.externalTermsAndConditionsURI}
            Component={ExternalAcceptWonderlandTermsAndConditions}
        />
        <WonderlandRequireLoginRoute path="/help" exact={true} Component={Help} />
        <WonderlandLiteRoute path="/help/terms-and-conditions" Component={TermsAndConditionsText} />
        <WonderlandRequireLoginRoute path="/" exact={true} Component={Search} />
        <WonderlandRequireLoginRoute path="/bulk/upload/:type?" Component={BulkUpload} />
        <WonderlandRequireLoginRoute path="/bulk/sidecar/upload" Component={SidecarBulkUpload} />
        <WonderlandRequireLoginRoute path="/bulk/edit" Component={BulkEdit} />
        <WonderlandRequireLoginRoute path="/bulk/export" Component={BulkExport} />
        <WonderlandRequireLoginRoute path="/sequential/edit/:config/:tab?" Component={SequentialEdit} />
        <WonderlandRequireLoginRoute path="/asset/edit/:id/:tab?" Component={AssetEdit} />
        <WonderlandRequireLoginRoute path="/asset/change-type/:id/:tab?" Component={AssetChangeType} />
        <WonderlandRequireLoginRoute path="/asset/upload/:id" Component={UploadAsset} />
        <WonderlandRequireLoginRoute path="/asset/:id/:tab?" Component={AssetView} />
        <WonderlandRequireLoginRoute path="/similar/:similarId" Component={Search} />
        <WonderlandRequireLoginRoute path="/collection/edit/:id/:tab?" Component={CollectionEdit} />
        <WonderlandRequireLoginRoute path="/collection/:id/:tab?/:encodedDiscoveryState?" Component={CollectionView} />
        <WonderlandRequireLoginRoute
            path="/smartCollection/:id/:tab?/:encodedDiscoveryState?"
            Component={SmartCollectionView}
        />
        <WonderlandRequireLoginRoute path="/checkedout/:encodedDiscoveryState?" Component={Checkout} />
        <WonderlandRequireLoginRoute path="/imf/replacement" Component={IMFReplacement} />
        <WonderlandRequireLoginRoute path="/lightbox/:encodedDiscoveryState?" Component={Lightbox} />
        <WonderlandRequireRoleRoute
            roles="smart_collection_view"
            path="/smartCollections/title/:titleId/:encodedDiscoveryState?"
            Component={SmartCollectionTitle}
        />
        <WonderlandRequireRoleRoute
            roles="smart_collection_view"
            path="/smartCollections/:searchMode?/:encodedDiscoveryState?"
            Component={SmartCollectionSearch}
        />
        <WonderlandRequireLoginRoute path="/search/:encodedDiscoveryState" Component={Search} />
        <WonderlandRequireLoginRoute path="/notification-center" Component={NotificationCenter} />
        <WonderlandRequireLoginRoute path="/new/smartCollection" Component={NewSmartCollection} />
        <WonderlandRequireLoginRoute path="/new/collection" Component={NewCollection} />
        <WonderlandRequireLoginRoute path="/new/asset" Component={NewAsset} />
        <WonderlandRequireLoginRoute path="/deliver/asset" Component={DeliverAsset} />
        <WonderlandRequireLoginRoute path="/relationship" Component={Relationship} />
        <WonderlandRequireLoginRoute path="/share" Component={Share} />
        <WonderlandRequireLoginRoute path="/myid/shared/collection/:id?" Component={SharedCollectionMyId} />
        <WonderlandRequireExternalLoginRoute path="/shared/collection/:id?" Component={SharedCollection} />
        <WonderlandRequireLoginRoute path="/shared" Component={SharedView} />
        <WonderlandRequireLoginRoute path="/preferences/:tab?/:metaTab?" Component={Preferences} />
        <WonderlandLiteRoute Component={NotFound} />
    </Switch>
);
