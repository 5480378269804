import { compose, lifecycle, withProps } from 'recompose';
import { connectSlice } from 'wonderland-ui-commons';
import { createSelector } from 'reselect';
import { get, noop } from 'lodash';
import { withSearch } from 'wonderland-ui-discovery';
import DeliveryFormSlice from '../../DeliverAsset/DeliveryForm/DeliveryFormSlice';
import DestinationPickerSideSheetActions from './DestinationPickerSideSheetActions';
import DestinationPickerSideSheetComponent from './DestinationPickerSideSheetComponent';
import DestinationPickerSideSheetController from './DestinationPickerSideSheetController';
import DestinationPickerSideSheetSlice from './DestinationPickerSideSheetSlice';
import DestinationsController from 'core/Destinations/DestinationsController';

export const onSubmitSearchTermSelector = createSelector(
    (slice, searchModel) => slice.textFieldInputValue,
    (slice, searchModel) => searchModel.updateSearchTerm,
    (searchTerm, updateSearchTerm) => ev => {
        ev.preventDefault();
        updateSearchTerm(searchTerm);
    }
);

export const onChangeTextFieldInputValueSelector = createSelector(
    searchModel => searchModel.updateSearchTerm,
    updateSearchTerm => ev => {
        ev.preventDefault();
        const term = ev.target.value;
        if (term.length) {
            updateSearchTerm(term);
            DestinationPickerSideSheetActions.changeTextFieldInput(term);
        } else {
            DestinationPickerSideSheetActions.clearSearchTermCriteria();
        }
    }
);

export const isSelectedSelector = createSelector(
    props => get(props, 'destinationDigital.id', null),
    props => get(props, 'destinationPhysical.id', null),
    props => props.typeSelected,
    (digitalId, physicalId, typeSelected) => destination => {
        const selectedId = typeSelected === 'destinationDigital' ? digitalId : physicalId;
        return selectedId === get(destination, 'id', null);
    }
);

export const getFormatFacetOptions = facets => {
    const formatFacet = facets && facets.find(({ name }) => name === 'type');
    return get(formatFacet, 'options', []);
};

export default compose(
    connectSlice({ slice: DeliveryFormSlice }),
    connectSlice({ slice: DestinationPickerSideSheetSlice }),
    withProps(({ defaultCriteria, overrideDefaultCriteria }) => ({
        defaultCriteria: overrideDefaultCriteria || defaultCriteria
    })),
    withSearch(
        {
            DiscoveryAPI: DestinationsController,
            onDiscoveryStateChange: DestinationPickerSideSheetActions.discoveryStateChange
        },
        (searchModel, props) => {
            const { addDestination = noop } = props;
            return {
                // From search plugin
                criteria: searchModel.criteria,
                fetchSearch: searchModel.fetchSearch,
                results: searchModel.results,
                formatFacetOptions: getFormatFacetOptions(searchModel.computedFacets),

                // For search bar
                onChangeTextFieldInputValue: onChangeTextFieldInputValueSelector(searchModel),
                submitSearchTerm: onSubmitSearchTermSelector(props, searchModel),

                // For destination actions
                onSelectDestination: addDestination,
                isDestinationSelected: isSelectedSelector(props),
                drillIntoItem: DestinationPickerSideSheetActions.drillIntoItem,

                // Side sheet controls
                clearAllCriteria: DestinationPickerSideSheetActions.clearAllCriteria,
                selectQuickFilter: DestinationPickerSideSheetActions.selectQuickFilter,
                setFacetsDrawer: DestinationPickerSideSheetController.setFacetsDrawer,

                // BreadCrumbs
                popBreadCrumb: DestinationPickerSideSheetActions.popBreadCrumb
            };
        }
    ),
    lifecycle({
        componentWillUnmount() {
            DestinationPickerSideSheetActions.reset();
        }
    })
)(DestinationPickerSideSheetComponent);
