import BulkSelectionCtrl from 'app/shared/BulkSelectionCtrl';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Loader from 'app/shared/Loader';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import RelatedAssets from './RelatedAssets';
import RelatedChildren from './RelatedChildren';
import RelatedOptions from './RelatedOptions';
import RelatedParents from './RelatedParents';
import RelatedSnackBar from './RelatedSnackBar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const RelationshipComponent = ({
    childLoading,
    classes,
    disabledAssets,
    filteredAssets,
    filteredChildAssets,
    filteredParentAssets,
    handleAddChildren,
    handleAddParents,
    handleDeselectAll,
    handleDeselectAllChildren,
    handleRemoveChildren,
    handleRemoveParent,
    handleSelectAll,
    handleSelectAllChildren,
    handleSelectAsset,
    handleSelectChild,
    handleSelectParent,
    handleSelectRelationshipType,
    hasSelected,
    imageMode,
    metadataMode,
    loading,
    relationshipType,
    selectableAssets,
    selectedChildIds,
    selectedChildItemsSize,
    selectedIds,
    selectedItemsSize,
    selectedParentId,
    typeNames,
    types
}) => {
    const { checkbox, column, columnFill, container } = classes;

    return loading ? (
        <Loader classes={classes} icon="hourglass_empty" message={'Loading'} open={loading} />
    ) : (
        <Fragment>
            <Grid container justify="space-between">
                <Grid item>
                    <Typography gutterBottom variant="h4">
                        Relationships
                    </Typography>
                </Grid>
                <Grid item>
                    <RelatedOptions />
                </Grid>
            </Grid>
            <Grid className={container} container>
                <Grid item className={column}>
                    <Grid alignItems="center" container justify="space-between">
                        <Grid item>
                            <Typography align="center" color="secondary" variant="h5">
                                Assets ({filteredAssets.length})
                                <Tooltip
                                    placement="top"
                                    title={`${disabledAssets.length} assets are excluded due to a lack of permissions`}
                                >
                                    <Icon color="secondary" fontSize="small">
                                        info
                                    </Icon>
                                </Tooltip>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <div className={checkbox}>
                                <BulkSelectionCtrl
                                    assets={selectableAssets}
                                    deselectCurrentItems={handleDeselectAll}
                                    selectCurrentItems={handleSelectAll}
                                    selectedItems={selectedIds}
                                    selectedItemsSize={selectedItemsSize}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <RelatedAssets
                        assets={filteredAssets}
                        classes={classes}
                        handleAddChildren={handleAddChildren}
                        handleAddParents={handleAddParents}
                        handleSelectAsset={handleSelectAsset}
                        imageMode={imageMode}
                        metadataMode={metadataMode}
                        relationshipType={relationshipType}
                        selectedIds={selectedIds}
                        selectedParentId={selectedParentId}
                        typeNames={typeNames}
                        types={types}
                    />
                </Grid>
                <Grid item className={column}>
                    <Typography color="secondary" variant="h5">
                        Parents ({filteredParentAssets.length})
                    </Typography>
                    <RelatedParents
                        assets={filteredParentAssets}
                        classes={classes}
                        handleRemoveParent={handleRemoveParent}
                        handleSelectParent={handleSelectParent}
                        handleSelectRelationshipType={handleSelectRelationshipType}
                        imageMode={imageMode}
                        metadataMode={metadataMode}
                        relationshipType={relationshipType}
                        selectedParentId={selectedParentId}
                        typeNames={typeNames}
                        types={types}
                    />
                </Grid>
                <Grid item className={columnFill}>
                    <Grid alignItems="center" container justify="space-between">
                        <Grid item>
                            <Typography color="secondary" variant="h5">
                                Children ({filteredChildAssets.length})
                            </Typography>
                        </Grid>
                        <Grid item>
                            <div className={checkbox}>
                                <BulkSelectionCtrl
                                    assets={filteredChildAssets}
                                    deselectCurrentItems={handleDeselectAllChildren}
                                    disabled={true}
                                    selectCurrentItems={handleSelectAllChildren}
                                    selectedItems={selectedChildIds}
                                    selectedItemsSize={selectedChildItemsSize}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <RelatedChildren
                        assets={filteredChildAssets}
                        childLoading={childLoading}
                        classes={classes}
                        handleRemoveChildren={handleRemoveChildren}
                        handleSelectChild={handleSelectChild}
                        imageMode={imageMode}
                        metadataMode={metadataMode}
                        relationshipType={relationshipType}
                        selectedChildIds={selectedChildIds}
                        selectedParentId={selectedParentId}
                        typeNames={typeNames}
                        types={types}
                    />
                </Grid>
            </Grid>
            {(!!selectedIds.length || !!selectedChildIds.length) && (
                <RelatedSnackBar
                    classes={classes}
                    handleAddChildren={handleAddChildren}
                    handleAddParents={handleAddParents}
                    handleRemoveChildren={handleRemoveChildren}
                    hasSelected={hasSelected}
                    relationshipType={relationshipType}
                    typeNames={typeNames}
                />
            )}
        </Fragment>
    );
};

RelationshipComponent.propTypes = {
    childLoading: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    disabledAssets: PropTypes.array,
    filteredAssets: PropTypes.array.isRequired,
    filteredChildAssets: PropTypes.array,
    filteredParentAssets: PropTypes.array.isRequired,
    handleAddChildren: PropTypes.func.isRequired,
    handleAddParents: PropTypes.func.isRequired,
    handleDeselectAll: PropTypes.func.isRequired,
    handleDeselectAllChildren: PropTypes.func.isRequired,
    handleRemoveChildren: PropTypes.func.isRequired,
    handleRemoveParent: PropTypes.func.isRequired,
    handleSelectAll: PropTypes.func.isRequired,
    handleSelectAllChildren: PropTypes.func.isRequired,
    handleSelectAsset: PropTypes.func.isRequired,
    handleSelectChild: PropTypes.func.isRequired,
    handleSelectParent: PropTypes.func.isRequired,
    handleSelectRelationshipType: PropTypes.func.isRequired,
    hasSelected: PropTypes.object.isRequired,
    imageMode: PropTypes.bool.isRequired,
    metadataMode: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    relationshipType: PropTypes.string,
    selectableAssets: PropTypes.array.isRequired,
    selectedChildIds: PropTypes.array.isRequired,
    selectedChildItemsSize: PropTypes.number.isRequired,
    selectedIds: PropTypes.array.isRequired,
    selectedItemsSize: PropTypes.number.isRequired,
    selectedParentId: PropTypes.string,
    typeNames: PropTypes.object.isRequired,
    types: PropTypes.array.isRequired
};

export default RelationshipComponent;
