import { isEmpty } from 'lodash';
import { SkipPagination } from 'wonderland-ui-discovery';
import { withStyles } from 'wonderland-ui-material';
import BreadCrumbs from 'app/shared/BreadCrumbs';
import List from '@material-ui/core/List';
import NoResults from 'app/shared/NoResults';
import PropTypes from 'prop-types';
import React from 'react';
import ResultsListItem from './ResultsListItem';

/* istanbul ignore next */
const sheet = () => ({
    titleList: {
        paddingBottom: 16
    }
});

@withStyles(sheet)
export default class ResultsList extends React.PureComponent {
    static propTypes = {
        onSelectDestination: PropTypes.func.isRequired,
        breadCrumbs: PropTypes.arrayOf(PropTypes.object).isRequired,
        classes: PropTypes.object.isRequired,
        lastDrilledItem: PropTypes.object.isRequired,
        isDestinationSelected: PropTypes.func.isRequired,
        dense: PropTypes.bool,
        drillIntoItem: PropTypes.func.isRequired,
        loading: PropTypes.bool,
        openFacets: PropTypes.func.isRequired,
        popBreadCrumb: PropTypes.func.isRequired,
        results: PropTypes.arrayOf(PropTypes.object).isRequired
    };

    render() {
        const {
            onSelectDestination,
            breadCrumbs,
            classes,
            isDestinationSelected,
            dense,
            drillIntoItem,
            lastDrilledItem,
            loading,
            openFacets,
            popBreadCrumb,
            results
        } = this.props;

        return loading ? null : (
            <div>
                <BreadCrumbs label={lastDrilledItem.name} breadCrumbs={breadCrumbs} popBreadCrumb={popBreadCrumb} />
                <List className={classes.titleList}>
                    {!isEmpty(results) ? (
                        results.map((item, i) => (
                            <ResultsListItem
                                dense={dense}
                                onSelectDestination={onSelectDestination}
                                drillIntoItem={drillIntoItem}
                                isDestinationSelected={isDestinationSelected}
                                key={i}
                                openFacets={openFacets}
                                item={item}
                            />
                        ))
                    ) : (
                        <NoResults />
                    )}
                </List>
                <SkipPagination />
            </div>
        );
    }
}
