import { autobind, CsrfController as CommonsCsrfController } from 'wonderland-ui-commons';
import { listen } from 'lib/events';
import config from 'app/config';

@autobind
class CsrfController {
    @listen('csrfError')
    onCsrfError(e) {
        console.error('csrf error', e);
        // eslint-disable-next-line no-restricted-globals
        location.reload();
    }

    init() {
        CommonsCsrfController.init(config);
    }
}

const csrf = new CsrfController();
export default csrf;
