import { actionDispatcher } from 'react-redux-boilerout';

@actionDispatcher({
    namespace: 'UserData',
    actions: [
        'createSavedSearchCompleted',
        'createSavedViewCompleted',
        'deleteNotificationCompleted',
        'deleteNotificationFailed',
        'deleteNotificationRequested',
        'fetchNotificationsCompleted',
        'fetchNotificationsFailed',
        'fetchNotificationsRequested',
        'fetchSavedSearchesCompleted',
        'fetchSavedViewsCompleted',
        'removeSavedSearchCompleted',
        'removeSavedViewCompleted',
        'subscribeToSavedSearchCompleted',
        'unsubscribeToSavedSearchCompleted'
    ]
})
export default class UserDataActions {}
