export default function withFallback(cond, left, right, { fallbackOnError = false } = {}) {
    return async args => {
        if (cond) {
            try {
                return await left(args);
            } catch (e) {
                console.error(e);
                if (fallbackOnError && e.status !== 403) {
                    return right(args);
                }
                throw e;
            }
        }
        return right(args);
    };
}
