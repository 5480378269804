import { reducerRegistry } from 'lib/redux';
import { registerSliceReducer, sliceReducer, subscribe } from 'react-redux-boilerout';

@registerSliceReducer({ registry: reducerRegistry })
@sliceReducer('SelectLocationModal')
@subscribe({ namespace: 'SelectLocationModal' })
export default class SelectLocationModalSliceReducer {
    initialState() {
        return {
            assetIdOrIds: null,
            visible: false
        };
    }

    onShow(slice, assetIdOrIds, notes) {
        return { ...slice, assetIdOrIds: assetIdOrIds, visible: true };
    }

    onHide(slice) {
        return this.initialState();
    }
}
