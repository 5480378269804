import { withStyles } from 'wonderland-ui-material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

/* istanbul ignore next */
const sheet = () => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column'
    }
});

// Wrapper to fix flexbox issue in IE11 as described in https://github.com/philipwalton/flexbugs#flexbug-3
// > min-height on a flex container won't apply to its flex items

@withStyles(sheet)
export default class IE11Flexbug3FixComponent extends Component {
    static propTypes = {
        children: PropTypes.node,
        classes: PropTypes.object.isRequired
    };

    render() {
        const { children, classes } = this.props;

        return <div className={classes.wrapper}>{children}</div>;
    }
}
