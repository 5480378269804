export const smartCollectionAssetTagTableFieldDefaults = [
    'rule',
    'tags',
    'column_category',
    'priority',
    'rule_priority',
    'asset_id',
    'asset_language',
    'asset_type',
    'audio_configuration',
    'broadcast_standard',
    'collection_category',
    'content_sub_type',
    'content_type',
    'dynamic_range',
    'element_type',
    'file_type',
    'file_type_description',
    'width',
    'height',
    'physical_or_digital',
    'source_business_unit',
    'wip_or_final',
    'content_categories',
    'eidrl1',
    'eidrl2',
    'episode_title',
    'feature_series_title',
    'season_title',
    'title_format',
    'title_name',
    'version_name',
    'version_title'
];

export const smartCollectionTableFieldDefaults = [
    'asset_id',
    'source_system',
    'souce_system_asset_id',
    'title_format',
    'title_name',
    'content_version',
    'format_version',
    'remarks',
    'account',
    'market',
    'country',
    'asset_languages',
    'subtitle_language',
    'asset_type',
    'content_type',
    'content_sub_type',
    'broadcast_standard',
    'screen_aspect_ratio',
    'image_aspect_ratio',
    'frame_rate',
    'dynamic_range',
    'height',
    'width',
    'audio_configuration',
    'keywords',
    'asset_name',
    'file_name',
    'file_type',
    'file_format',
    'collection_category',
    'content_categories'
];

export const tableFieldDefaults = [
    'short_id',
    'feature_series_title',
    'title_notes',
    'radar_episode_number',
    'content_version',
    'version_title',
    'asset_status',
    'name',
    'asset_language',
    'content_type',
    'content_sub_type',
    'element_type',
    'file_type_description',
    'broadcast_standard',
    'dynamic_range',
    'frame_rate',
    'image_aspect_ratio',
    'audio_configuration',
    'asset_type',
    'height',
    'width',
    'conformance',
    'keywords',
    'file_name',
    'created_at',
    'ingest_date',
    'source_system',
    'source_system_asset_id'
];
export default tableFieldDefaults;
