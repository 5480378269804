import { withStyles } from 'wonderland-ui-material';
import logo from 'images/wl-logo-y-h.png';
import PropTypes from 'prop-types';
import React from 'react';

/* istanbul ignore next */
const sheet = () => ({
    wlLogo: {
        height: 60,
        marginRight: 15,
        position: 'relative',
        top: 7,
        width: 155
    }
});

const LogoComponent = ({ classes: { wlLogo } }) => (
    <a data-qa="wonderland-logo" href="/">
        <img alt="A Wonderland of Asset Management" className={wlLogo} src={logo} />
    </a>
);

LogoComponent.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(sheet)(LogoComponent);
