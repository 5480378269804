import { compose, lifecycle, withHandlers } from 'recompose';
import { connectSlice, withPageDrawer } from 'wonderland-ui-commons';
import DistributionListContacts from './DistributionListContacts';
import NotificationRecipientsActions from './NotificationRecipientsActions';
import NotificationRecipientsComponent from './NotificationRecipientsComponent';
import NotificationRecipientsSlice from './NotificationRecipientsSlice';

export default compose(
    connectSlice({ slice: NotificationRecipientsSlice }),
    withPageDrawer([
        {
            name: 'DistributionListContactsDrawer',
            Component: DistributionListContacts,
            mapProps: ({ closeDrawer, handleFileExists, handleTextSmall, handleTextWrap }) => {
                return {
                    anchor: 'right',
                    closeDrawer,
                    handleFileExists,
                    handleTextWrap,
                    handleTextSmall,
                    modal: true
                };
            }
        }
    ]),
    withHandlers({
        deleteNotificationRecipient: () => (itemId, itemType, parentId) => {
            NotificationRecipientsActions.deleteNotificationRecipient(itemId, itemType, parentId);
        },
        onToggleContacts: props => distributionList => {
            const { openDrawer } = props;
            NotificationRecipientsActions.onSelectDistributionList(distributionList);
            openDrawer('DistributionListContactsDrawer');
        }
    }),
    lifecycle({
        componentWillUnmount() {
            this.props.closeDrawer();
            NotificationRecipientsActions.reset();
        }
    })
)(NotificationRecipientsComponent);
