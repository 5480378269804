import { castArray, first, get, mapValues } from 'lodash';
import { extractFields } from './assetFields';
import { toDateString } from 'lib/formatter';

export function toDetailField(field, assetType, ctx, isTitleRelated) {
    if (field.name === 'keywords') {
        return {
            ...field,
            type: 'facetlink'
        };
    }

    if (field.type === 'links') {
        return first(extractFields([field.name], assetType, ctx));
    }

    if (field.type === 'tags') {
        return { ...field, type: 'label', formatter: v => v && castArray(v).join(', ') };
    }

    if (field.type === 'cvdropdown' || field.type === 'cvtypeahead' || field.type === 'typeahead') {
        return { ...field, type: 'label', formatter: v => v && get(v, 'value', v) };
    }

    if (field.name === 'destination') {
        return { ...field, type: 'label', formatter: v => v && v.name };
    }

    if (field.name === 'asset_type' || field.type === 'text' || field.type === 'textarea') {
        // This fieldInConfig logic is needed for certain fields that have dynamic labels, formatters, etc.
        // This can be removed once these fields (id, name, asset_type, collections) are updated with that logic in DB
        const fieldInConfig = first(extractFields([field.name], assetType, ctx));
        if (fieldInConfig && !isTitleRelated) {
            return fieldInConfig;
        }
        return { ...field, type: 'label' };
    }

    if (field.type === 'datepicker') {
        return { ...field, type: 'label', formatter: toDateString };
    }

    if (field.type === 'toggle') {
        return {
            ...field,
            type: 'label',
            formatter: v => {
                if (v === true) {
                    return 'Yes';
                }
                if (v === false) {
                    return 'No';
                }
                return '';
            }
        };
    }

    return field;
}

export default function (fields, assetType, ctx) {
    return (fields || []).map(field => {
        const isTitles = field.type === 'titles';
        if (field.type === 'tabular' || isTitles) {
            const titleOrTabularField = isTitles ? first(extractFields([field.name], assetType, ctx)) : field;
            return {
                ...field,
                children: {
                    display: {
                        ...mapValues(titleOrTabularField.schema, tabularField =>
                            toDetailField(tabularField, assetType, ctx, isTitles)
                        )
                    }
                },
                view: 'detail'
            };
        }

        return toDetailField(field, assetType, ctx);
    });
}
