import Icon from '@material-ui/core/Icon';
import Modal from '@material-ui/core/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';

const LoaderComponent = ({
    classes: { loader = {} },
    ellipsis = true,
    icon = '',
    iconNode,
    message = '',
    open = false
}) => (
    <Modal open={open}>
        <div className={loader}>
            {iconNode ? iconNode : <Icon fontSize="large">{icon}</Icon>}
            <Typography color="inherit" variant="h4">
                {message}
                {ellipsis && '...'}
            </Typography>
        </div>
    </Modal>
);

LoaderComponent.propTypes = {
    classes: PropTypes.object,
    ellipsis: PropTypes.bool,
    icon: PropTypes.string,
    iconNode: PropTypes.node,
    message: PropTypes.string,
    open: PropTypes.bool.isRequired
};

export default LoaderComponent;
