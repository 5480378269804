import LinearProgress from '@material-ui/core/LinearProgress';
import PropTypes from 'prop-types';
import React from 'react';

const LinearProgressComponent = ({ classes, mode, progress }) => {
    try {
        return (
            <div className={classes.progress}>
                {!!mode && (
                    <LinearProgress classes={{ root: classes.linearProgressRoot }} value={progress} variant={mode} />
                )}
            </div>
        );
    } catch (error) {
        console.error('LinearProgressComponent::error', error);
    }
    return null;
};

LinearProgressComponent.propTypes = {
    classes: PropTypes.object,
    mode: PropTypes.string,
    progress: PropTypes.number
};

export default LinearProgressComponent;
