import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from 'wonderland-ui-material';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/* istanbul ignore next */
const sheet = theme => {
    let updatedColors = {};
    const colors = theme.palette.assetTypeColor;
    Object.keys(colors).forEach(
        key =>
            (updatedColors[key] = {
                ...colors[key],
                '&:hover': {
                    backgroundColor: fade(colors[key].backgroundColor, 0.9)
                }
            })
    );

    return {
        ...updatedColors,
        container: {
            display: 'flex',
            alignSelf: 'center'
        },
        filterButton: {
            margin: '0 16px'
        }
    };
};

@withStyles(sheet)
export default class QuickFilterButtonComponent extends React.PureComponent {
    static propTypes = {
        children: PropTypes.node,
        criteria: PropTypes.array.isRequired,
        classes: PropTypes.object.isRequired,
        color: PropTypes.string,
        facetByItemFormat: PropTypes.func.isRequired,
        selected: PropTypes.bool,
        value: PropTypes.string.isRequired
    };

    onClick = () => {
        const { criteria, facetByItemFormat, value } = this.props;
        facetByItemFormat(criteria, value);
    };

    render() {
        const { children, selected, color, classes } = this.props;
        return (
            <Button
                className={classNames(classes.filterButton, {
                    [classes[color]]: selected,
                    [classes.hover]: selected
                })}
                onClick={this.onClick}
                size="small"
                variant={selected ? 'contained' : 'text'}
            >
                {children}
            </Button>
        );
    }
}
