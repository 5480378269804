import { isEmpty } from 'lodash';
import BreadCrumbsComponent from './BreadCrumbsComponent';
import PropTypes from 'prop-types';
import React from 'react';

export default class BreadCrumbsContainer extends React.PureComponent {
    static propTypes = {
        breadCrumbs: PropTypes.array.isRequired,
        popBreadCrumb: PropTypes.func.isRequired,
        label: PropTypes.string
    };

    render() {
        const { breadCrumbs, label, ...rest } = this.props;
        return !isEmpty(breadCrumbs) && label ? (
            <BreadCrumbsComponent {...rest} label={label} onClick={this.props.popBreadCrumb} />
        ) : (
            <div />
        );
    }
}
