import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

const ImageModeComponent = ({ imageMode, toggleMode }) => (
    <Tooltip title={imageMode ? 'Hide Images' : 'Show Images'} placement="bottom">
        <IconButton aria-label="Hide Images" color="secondary" onClick={toggleMode}>
            <Icon>{imageMode ? 'hide_image' : 'image'}</Icon>
        </IconButton>
    </Tooltip>
);

ImageModeComponent.propTypes = {
    imageMode: PropTypes.bool,
    toggleMode: PropTypes.func.isRequired
};

export default ImageModeComponent;
