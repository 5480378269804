import PropTypes from 'prop-types';
import React from 'react';
import RelatedCard from 'app/Relationship/RelatedCard';

const RelatedChildComponent = ({
    asset,
    classes,
    handleRemoveChildren,
    handleSelectChild,
    imageMode,
    metadataMode,
    relationshipType,
    selectedChildIds,
    typeNames,
    types
}) => {
    const { id } = asset;
    const selected = selectedChildIds.includes(id);
    const toggleSelected = () => handleSelectChild(id);

    return (
        <RelatedCard
            asset={asset}
            classes={classes}
            group={'child'}
            handleRemoveChildren={handleRemoveChildren}
            imageMode={imageMode}
            metadataMode={metadataMode}
            relationshipType={relationshipType}
            selected={selected}
            toggleSelected={toggleSelected}
            typeNames={typeNames}
            types={types}
        />
    );
};

RelatedChildComponent.propTypes = {
    asset: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    handleRemoveChildren: PropTypes.func.isRequired,
    handleSelectChild: PropTypes.func.isRequired,
    imageMode: PropTypes.bool.isRequired,
    metadataMode: PropTypes.bool.isRequired,
    selectedChildIds: PropTypes.array,
    relationshipType: PropTypes.string,
    typeNames: PropTypes.object.isRequired,
    types: PropTypes.array.isRequired
};

export default RelatedChildComponent;
