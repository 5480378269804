import { every, get } from 'lodash';

const fileEqualityProps = ['lastModified', 'name', 'size', 'type', 'folder', 'location', 'source_type'];

function filesAreEqual(file1, file2) {
    return every(fileEqualityProps, prop => get(file1, prop) === get(file2, prop));
}

function isFolder(file) {
    return file.type === 'inode/directory' || file.type === '';
}

export { filesAreEqual, isFolder };
