import { compose } from 'recompose';
import AppHeaderMenuComponent from './AppHeaderMenuComponent';
import withStyles from '@material-ui/core/styles/withStyles';

export default compose(
    withStyles(() => ({
        listItemRoot: {
            borderRadius: '0 4px 4px 0',
            paddingRight: 0
        },
        listRoot: {
            left: 0,
            maxWidth: 56,
            top: 72,
            padding: 0,
            position: 'fixed'
        }
    }))
)(AppHeaderMenuComponent);
