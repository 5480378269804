import { actionDispatcher } from 'react-redux-boilerout';
import { store } from 'lib/redux';

@actionDispatcher({
    actions: [
        'addParents',
        'deselectAll',
        'deselectAllChildren',
        'removeParent',
        'requestChildren',
        'reset',
        'selectAll',
        'selectAllChildren',
        'selectAsset',
        'selectChild',
        'selectParent',
        'selectRelationshipType',
        'setConfig',
        'updateChildren'
    ],
    dispatch: store.dispatch,
    namespace: 'Relationship'
})
export default class RelationshipActions {}
