import { compose } from 'recompose';
import { withProgressData } from 'wonderland-ui-commons';
import LinearProgressComponent from './LinearProgressComponent';
import withStyles from '@material-ui/core/styles/withStyles';

export default compose(
    withProgressData(),
    withStyles(({ spacing, zIndex }) => ({
        linearProgressRoot: {
            backgroundColor: 'inherit',
            height: spacing.unit / 4,
            position: 'sticky',
            top: spacing.unit * 7,
            zIndex: zIndex.drawer
        },
        progress: {
            minHeight: spacing.unit / 4
        }
    }))
)(LinearProgressComponent);
