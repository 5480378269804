import Grid from '@material-ui/core/Grid';
import PreferencesImageMode from 'app/shared/PreferencesImageMode';
import PreferencesMetadataMode from 'app/shared/PreferencesMetadataMode';
import React from 'react';

const RelatedOptionsComponent = () => (
    <Grid alignItems="flex-start" container justify="flex-end">
        <Grid item>
            <PreferencesImageMode />
            <PreferencesMetadataMode />
        </Grid>
    </Grid>
);

export default RelatedOptionsComponent;
