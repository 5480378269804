import DestinationsController from 'core/Destinations';
import S3BrowserSideSheetActions from './S3BrowserSideSheetActions';

class S3BrowserSideSheetController {
    getS3BucketContent(...args) {
        S3BrowserSideSheetActions.getS3BucketContentRequested();
        DestinationsController.getS3BucketContent(...args)
            .then(S3BrowserSideSheetActions.getS3BucketContentCompleted)
            .catch(error => {
                if (error.status !== 403) {
                    throw error;
                }
                S3BrowserSideSheetActions.getS3BucketContentNotAuthorized(error);
            });
    }

    getContainerContent(...args) {
        S3BrowserSideSheetActions.getContainerContentRequested();
        DestinationsController.getContainerContent(...args)
            .then(S3BrowserSideSheetActions.getContainerContentCompleted)
            .catch(e => ToastController.showError(e));
    }
}

export default new S3BrowserSideSheetController();
