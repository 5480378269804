import { AssetStoreAPI } from 'core/apis';
import { withCartItems } from 'core/Profile';
import AddToCollectionModalActions from 'modals/AddToCollection/AddToCollectionModalActions';
import BulkExportController from 'app/BulkExport/BulkExportController';
import ButtonBarComponent from './ButtonBarComponent';
import DuplicateAssetModalActions from 'modals/DuplicateAsset/DuplicateAssetModalActions';
import MetaController from 'core/Meta';
import React, { PureComponent } from 'react';
import SelectLocationModalActions from 'modals/SelectLocation/SelectLocationModalActions';
import ShareController from 'core/Share/ShareController';
import withBulkDownload from 'core/withBulkDownload';
import withDownload from 'core/withDownload';

@withBulkDownload()
@withCartItems()
@withDownload({
    key: 'downloadAsset',
    httpDownloadTransferSpecProvider: AssetStoreAPI.getHttpDownloadTransferSpec,
    managedDownloadTransferSpecProvider: AssetStoreAPI.getManagedDownloadTransferSpec
})
export default class ButtonBarContainer extends PureComponent {
    render() {
        return (
            <ButtonBarComponent
                {...this.props}
                navigateToBulkExport={BulkExportController.navigateToBulkExport}
                navigateToShare={ShareController.navigateToShare}
                showDuplicateAssetModal={DuplicateAssetModalActions.show}
                showAddToCollectionModal={AddToCollectionModalActions.show}
                showSelectLocationModal={SelectLocationModalActions.show}
                toggleLightbox={MetaController.toggleLightbox}
                toggleSubscribe={MetaController.toggleSubscribe}
                toggleLock={MetaController.toggleLock}
                removeFromCollection={MetaController.removeManyFromCollection}
                isLockableByUser={MetaController.isLockableByUser}
            />
        );
    }
}
