export const getSelectedItemsSize = (items, selectedIds) => {
    const selected = items.filter(({ id }) => selectedIds.includes(id));
    const itemSize = selected.reduce(function (acc, { file_size = 0 }) {
        return acc + file_size;
    }, 0);

    return itemSize;
};

export default {
    getSelectedItemsSize
};
