import _, { debounce, throttle } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import TextField from '@material-ui/core/TextField';

export default class LookupTextFieldComponent extends TextField {
    static propTypes = {
        debounceTimeout: PropTypes.number,
        debounce: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
        throttleTimeout: PropTypes.number,
        throttle: PropTypes.bool,
        value: PropTypes.string.isRequired
    };

    static defaultProps = {
        debounceTimeout: 500,
        throttleTimeout: 500
    };

    constructor(props) {
        super(props);
    }

    state = { debounceValue: this.props.value };

    doDebounce = debounce(event => this.props.onChange(event), this.props.debounceTimeout);
    doThrottle = throttle(event => this.props.onChange(event), this.props.throttleTimeout);

    handleChange = event => {
        this.setState({ debounceValue: event.target.value });
        event.persist();
        if (this.props.debounce) {
            this.doDebounce(event);
        }
        if (this.props.throttle) {
            this.doThrottle(event);
        }
    };

    render() {
        const textFieldProps = _.omit(this.props, ['debounceTimeout', 'throttleTimeout', 'debounce']);
        const { debounceValue } = this.state;
        return <TextField {...textFieldProps} onChange={this.handleChange} value={debounceValue} />;
    }
}
