import './BulkExportSlice';
import { autobind, ToastController, withProgress } from 'wonderland-ui-commons';
import { omit } from 'lodash';
import { WonderlandDomainAPI } from 'core/apis';
import BulkExportActions from './BulkExportActions';
import history from 'lib/history';

@autobind
class BulkExportController {
    navigateToBulkExport(config) {
        BulkExportActions.setConfig(config);
        setTimeout(() => history.push(`/bulk/export`), 0);
    }

    checkConfig(config) {
        if (!config) {
            ToastController.showError('Selected values are invalid');
            history.goBack();
            return false;
        }

        return true;
    }

    @withProgress({ label: 'fetch_bulk_export_dependencies' })
    fetchDependencies(config) {
        const configWithoutGrants = omit(config, ['asset_grants']);
        return WonderlandDomainAPI.bulkStats(configWithoutGrants).then(bulkStats =>
            BulkExportActions.loadStatsCompleted(bulkStats)
        );
    }

    cancel() {
        history.goBack();
    }

    clear() {
        BulkExportActions.clear();
    }

    submit(selections, transcodingOptions, exportOriginalsForNonTranscodableAssets, watermark, removeWatermark) {
        const selectionsWithoutGrants = omit(selections, ['asset_grants']);
        if (removeWatermark === false && watermark.trim() === '') {
            ToastController.showError(
                'The Watermark Text value cannot be an empty string if Remove Watermark is false.'
            );
        } else {
            return WonderlandDomainAPI.exportAssets(
                selectionsWithoutGrants,
                transcodingOptions,
                exportOriginalsForNonTranscodableAssets,
                watermark,
                removeWatermark
            )
                .then(
                    () => {},
                    e => {
                        ToastController.showError('We were unable to process your export request. Please try again.');
                    }
                )
                .finally(() => history.goBack());
        }
    }
}

export default new BulkExportController();
