import { compose, withHandlers, withProps } from 'recompose';
import { get, set } from 'lodash';
import { withProfile } from 'core/Profile';

const cartPath = 'preferences.cartItems';

export default () =>
    compose(
        withProfile(({ profile }) => ({
            cartItems: get(profile, cartPath, [])
        })),
        withProps(({ cartItems }) => ({ cartItemCount: cartItems.length })),
        withHandlers({
            addCartItems:
                ({ saveProfile, cartItems }) =>
                addCartItems => {
                    const newCartItems = [...new Set([...cartItems, ...addCartItems])];

                    return saveProfile(set({}, cartPath, newCartItems));
                },
            removeCartItems:
                ({ saveProfile, cartItems: prevCartItems }) =>
                removeCartItems => {
                    const removeItems = Array.isArray(removeCartItems) ? removeCartItems : [removeCartItems];
                    const cartItems = prevCartItems.filter(id => !removeItems.includes(id));

                    return saveProfile(set({}, cartPath, cartItems));
                }
        })
    );
