import { DestructiveAction } from 'wonderland-ui-commons';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class DeleteAssetDialogComponent extends PureComponent {
    static propTypes = {
        asset: PropTypes.object.isRequired
    };

    render() {
        const { asset, ...passThruProps } = this.props;
        const name = asset.name || get(asset, 'attachment_type.value');
        let msg, dialogTitle;
        switch (asset.asset_type) {
            case 'collection':
                msg = 'This action removes the collection but does not remove the assets contained within it.';
                dialogTitle = 'Delete Collection';
                break;
            case 'attachment':
                msg = 'This action removes the attachment and the metadata associated with it.';
                dialogTitle = 'Delete Attachment';
                break;
            default:
                msg = 'This action removes all metadata and the original asset.';
                dialogTitle = 'Delete Asset';
                break;
        }

        return (
            <DestructiveAction
                {...passThruProps}
                dialogMessage={
                    <div>
                        <p>Are you sure you want to delete {name}? </p>
                        <p>{msg}</p>
                    </div>
                }
                dialogSubmitLabel="Delete"
                dialogTitle={dialogTitle}
            />
        );
    }
}
