import { compact, get, isPlainObject } from 'lodash';
import { withStyles } from 'wonderland-ui-material';
import AttributeChip from './AttributeChip';
import PropTypes from 'prop-types';
import React from 'react';

/* istanbul ignore next */
const sheet = () => ({
    chips: {
        paddingLeft: '8px'
    }
});

@withStyles(sheet)
export default class AttributeChipsComponent extends React.Component {
    static propTypes = {
        asset: PropTypes.object,
        item: PropTypes.object.isRequired,
        pickerKey: PropTypes.string,
        defaultPickerChips: PropTypes.array,
        defaultColor: PropTypes.string,
        pickerChips: PropTypes.object,
        classes: PropTypes.object.isRequired
    };

    chips = (attr, i) => {
        const { item, asset, defaultColor } = this.props;
        const key = isPlainObject(attr) ? attr.key : attr;
        const label = isPlainObject(attr) && attr.label;
        const fieldValue = get(item, key) || get(asset, key);
        if (!fieldValue) {
            return false;
        }

        return Array.isArray(fieldValue) ? (
            fieldValue.map((value, j) => (
                <AttributeChip
                    defaultColor={defaultColor}
                    attr={attr}
                    value={value}
                    label={label}
                    key={`${attr}.${i}.${j}`}
                />
            ))
        ) : (
            <AttributeChip
                defaultColor={defaultColor}
                attr={attr}
                value={fieldValue}
                label={label}
                key={`${attr}.${i}`}
            />
        );
    };

    getPickerChips() {
        const { item, pickerKey, defaultPickerChips, pickerChips } = this.props;
        const nestedChips = get(item, pickerKey);
        const chips = get(pickerChips, nestedChips, []);
        return compact([...defaultPickerChips, ...chips]);
    }

    doRender() {
        const { classes } = this.props;
        const chips = this.getPickerChips();
        if (!chips.length) {
            return false;
        }
        return <div className={classes.chips}>{chips.map(this.chips)}</div>;
    }

    render() {
        try {
            return this.doRender();
        } catch (e) {
            /* istanbul ignore next */
            console.error('AttributeChipsComponent::render', e);
        }
        return null;
    }
}
