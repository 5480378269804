const facetsState = [
    { name: 'delivery_type', active: true },
    { name: 'type', active: true }
];

const indexType = 'destination';

const defaultCriteria = [
    {
        type: 'filter',
        field: 'type',
        operation: 'is_one_of_keyword',
        negate: true,
        value: ['Contact', 'Distribution List']
    },
    {
        type: 'filter',
        field: 'delivery_type',
        operation: 'is_one_of_keyword',
        negate: true,
        value: ['physical', 'manual']
    },
    {
        type: 'filter',
        field: 'active',
        negate: true,
        operation: 'is_one_of_keyword',
        value: ['No']
    },
    {
        type: 'filter',
        field: 'destination_type',
        operation: 'is_one_of_keyword',
        negate: true,
        value: ['storage', 'origin']
    }
];

export { facetsState, indexType, defaultCriteria };
