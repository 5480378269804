import { urlParams, xhr } from 'wonderland-ui-commons';
import config from 'app/config';
import isEmpty from 'lodash/isEmpty';
const { prefix } = config.destinationManager;
const indexName = 'destination';
const discoveryHost = `${config.apiHostname}/${prefix}/${indexName}`;
const destinationsHost = `${config.apiHostname}/${prefix}`;

function getSearch(criteria, activeFacets, skip, limit) {
    const query = urlParams({
        skip,
        limit,
        facets: activeFacets.join(',') || 'false'
    });
    return xhr.json(`${discoveryHost}/search?${query}`, {
        method: 'post',
        body: JSON.stringify({ criteria })
    });
}

const lookupFacetValues = (field, searchText) => {
    return xhr.json(`${discoveryHost}/lookup/${field}?filter=${searchText}`, {
        method: 'post',
        body: JSON.stringify({ criteria: [{ type: 'recent' }] })
    });
};

function getIndexConfig() {
    return xhr.json(`${discoveryHost}/config`, {
        method: 'get'
    });
}

function getDestinationDetails(destinationId) {
    return xhr.json(`${destinationsHost}/destinations/${destinationId}`, {
        method: 'get'
    });
}

function getDestinationDetailsWithContacts(destinationId) {
    return xhr.json(`${destinationsHost}/destinations/${destinationId}?with_contact_details=true`, {
        method: 'get'
    });
}

function getLocationsDetails(locationIds) {
    return xhr.json(`${destinationsHost}/locations/ids`, {
        method: 'post',
        body: JSON.stringify(locationIds)
    });
}

function getDistributionListContacts(distributionList) {
    return xhr.json(`${destinationsHost}/distribution-lists/${distributionList}?with_contact_details=true`, {
        method: 'get'
    });
}

function getDistributionLists({ skip, limit, includeInactive, filter }) {
    const params = urlParams({
        skip,
        limit,
        includeInactive,
        filter
    });
    return xhr.json(`${destinationsHost}/distribution-lists?${params}`, {
        method: 'get'
    });
}

function getDistributionListsByIds(ids) {
    return xhr.json(`${destinationsHost}/distribution-lists/ids`, {
        method: 'post',
        body: JSON.stringify(ids)
    });
}

function requestDelivery(data) {
    return xhr.json(`${config.apiHostname}/${config.delivery.prefix}/delivery`, {
        method: 'post',
        body: JSON.stringify(data)
    });
}

function getS3BucketContent(destinationID, folder, nextToken, limit, searchTerm) {
    const query = urlParams({ folder, nextToken: nextToken && encodeURIComponent(nextToken), limit, searchTerm });
    return xhr.json(`${destinationsHost}/destinations/${destinationID}/content?${query}`, {
        method: 'get',
        encodeUrl: false
    });
}

function getContainerContent(container, folder, searchTerm, limit, skip) {
    const query = urlParams({ limit, skip, facets: ['container'] });
    const data = {
        criteria: [
            { type: 'term', term: searchTerm },
            { type: 'wildcard', name: 'parent_key', values: !isEmpty(folder) ? `${folder}\\/*` : '*' },
            { type: 'facet', name: 'container', values: container }
        ]
    };
    return xhr.json(`${destinationsHost}/containers/search?${query}`, {
        method: 'post',
        body: JSON.stringify(data)
    });
}

export default {
    getSearch,
    lookupFacetValues,
    getDistributionListContacts,
    getDistributionLists,
    getDestinationDetails,
    getDestinationDetailsWithContacts,
    getLocationsDetails,
    getIndexConfig,
    requestDelivery,
    getDistributionListsByIds,
    getS3BucketContent,
    getContainerContent
};
