import { DestinationsAPI, SecurityAPI } from 'core/apis';
import { formatDistributionLists, formatNonSystemUsers } from './RecipientSearchUtils';
import { ToastController } from 'wonderland-ui-commons/lib/Toast';

const { getDistributionLists } = DestinationsAPI;
const { getUsers } = SecurityAPI;
const { showError } = ToastController;

const search = params =>
    Promise.all([getDistributionLists(params), getUsers(params)]).then(
        ([{ results: distributionLists }, { results: users }]) => [
            { label: 'Distribution Lists', options: [...formatDistributionLists(distributionLists)] },
            { label: 'Users', options: [...formatNonSystemUsers(users)] }
        ],
        error => {
            showError(error);
        }
    );

export default {
    search
};
