import { compose, withHandlers } from 'recompose';
import { get, set } from 'lodash';
import { palette } from 'lib/mui';
import { withProfile } from 'core/Profile';

const thumbnailBackgroundColorPath = 'preferences.imageThumbnailPreferences.backgroundColor';

export default () => {
    return compose(
        withProfile(({ profile }) => {
            const thumbnailBackgroundColor = get(profile, thumbnailBackgroundColorPath, palette.backgroundImage);
            return {
                thumbnailBackgroundColor,
                preferencesThumbnailStyles: { backgroundColor: thumbnailBackgroundColor }
            };
        }),
        withHandlers({
            updateThumbnailBackgroundColor:
                ({ saveProfile, thumbnailBackgroundColor: prevThumbnailBackgroundColor }) =>
                nextThumbnailBackgroundColor => {
                    if (nextThumbnailBackgroundColor !== prevThumbnailBackgroundColor) {
                        return saveProfile(set({}, thumbnailBackgroundColorPath, nextThumbnailBackgroundColor));
                    }
                }
        })
    );
};
