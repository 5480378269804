import { get } from 'lodash';
import { palette } from 'lib/mui';
import { validation } from 'lib/form';

const validEmail = (user, props) => {
    const email = get(user, 'email', user.value);
    return validation.isEmail(email);
};

export const getSchema = props => {
    const { fetchLocations, handleUsersInputChange } = props;
    return [
        {
            available: true,
            blurInputOnSelect: true,
            getOptionLabel: option => option.name,
            isAsync: true,
            label: 'Location Name',
            loadOptions: fetchLocations,
            minLength: 1,
            name: 'locationName',
            required: true,
            type: 'typeahead'
        },
        {
            available: true,
            creatable: true,
            getOptionLabel: ({ email, first_name: firstName, last_name: lastName, value }) => {
                return email ? `${firstName} ${lastName} <${email}>` : value;
            },
            getOptionValue: v => get(v, 'email', v),
            getStylesForChip: user => {
                const hasEmail = get(user, 'email');
                const isInvalid = !hasEmail && !validEmail(user, props);
                return isInvalid
                    ? { backgroundColor: palette.danger }
                    : {
                          backgroundColor: user.email ? null : palette.warning
                      };
            },
            hideSelectedOptions: true,
            label: 'Additional Notification Recipients',
            loadOptions: filter => handleUsersInputChange(filter.trim()),
            isAsync: true,
            multiple: true,
            minLength: 1,
            name: 'usersToNotify',
            styles: { menuList: base => ({ ...base, maxHeight: '250px' }) },
            type: 'typeahead',
            validators: ['validEmails']
        },
        { available: true, name: 'notes', type: 'text', multiline: true, label: 'Add a note' }
    ];
};
