import { flatten, get, has, isBoolean, isEmpty, isNumber } from 'lodash';
import { getFieldSpecFlag, getTemplateFieldSpec } from './templateUtils';
import config from 'app/config';
const { assetTypes } = config;

export const fieldsToExclude = ['content_categories', 'filepicker', 'imp'];

export const isEmptyNullOrUndefined = x => x === '' || x === null || typeof x === 'undefined';

const isRequired = (fieldSchema, templateFieldSpec) => {
    const templateRequired = getFieldSpecFlag(templateFieldSpec, 'required', false);
    const fieldSchemaRequired =
        fieldSchema.required || (fieldSchema.validators && fieldSchema.validators.some(v => v.includes('required')));
    return templateRequired || fieldSchemaRequired;
};

export const isDefinedInTemplate = (fieldSchema, templateMetadataSpec) => {
    const templateFieldSpec = getTemplateFieldSpec(templateMetadataSpec, fieldSchema.name);
    return templateFieldSpec || isRequired(fieldSchema, templateFieldSpec);
};

export const generateAvailableFlag = (field, isStrictTemplateCompliant) =>
    (field.fieldData.parent && {
        type: 'available_for',
        field: field.fieldData.parent
    }) ||
    fieldsToExclude.includes(field.fieldData.type) ||
    isStrictTemplateCompliant;

export const isTitlesField = name => name === 'related_titles';

export const mapValidators = field => get(field, 'validators', []).map(({ type }) => type);

export const maybeGenerateTitleHostname = name =>
    isTitlesField(name) && { hostname: config.titleManager.previewHostname };

export const maybeGenerateParent = (hasParent, parent) => hasParent && { parent };

export const maybeGenerateTabularFieldProps = type => type === 'tabular' && { edit: true };

export const maybeGenerateEmailProps = (name, { handleUsersInputChange }) =>
    name === 'on_behalf_of' && {
        creatable: true,
        getOptionLabel: ({ email, first_name: firstName, last_name: lastName, value }) => {
            return email ? `${firstName} ${lastName} <${email}>` : value;
        },
        getOptionValue: v => get(v, 'email', v),
        hideSelectedOptions: true,
        loadOptions: filter => handleUsersInputChange(filter.trim()),
        isAsync: true,
        minLength: 1,
        styles: { menuList: base => ({ ...base, maxHeight: '250px' }) },
        validators: ['validEmails']
    };

export const maybeGenerateTextFieldProps = (type, isDraggable) => type === 'text' && { updateOnBlur: !isDraggable };

export const maybeGenerateNameLabel = (fieldName, isCollection) =>
    fieldName === 'name' && { label: isCollection ? 'Collection Name' : 'Asset Name' };

const otherFields = type => [
    {
        label: 'Security',
        name: 'content_categories',
        fields: [
            {
                name: 'content_categories',
                uiType: 'content_categories',
                label: 'Content Categories',
                inputable: true,
                editable: true,
                multiple: true,
                required: true,
                available: true
            }
        ]
    },
    {
        label: 'Files',
        name: 'files',
        fields: [
            {
                inputable: true,
                label: 'File(s)',
                name: 'files',
                uiType: type === assetTypes.composition.name ? 'imp' : 'filepicker',
                validators:
                    type === assetTypes.composition.name ? ['singleFolder'] : ['validFilesOrFolders', 'singleFile']
            }
        ]
    }
];

export const mapSchemaGroups = schema => [
    ...schema.groups.map(g => ({
        ...g,
        fields: g.fields.map(f => ({
            ...f,
            fields: get(f, 'fields', []).map(f => ({ ...f, validators: mapValidators(f) })),
            validators: mapValidators(f)
        }))
    })),
    ...otherFields(schema.name)
];

export const buildType = ({ type, uiType }) => {
    return type === 'cv' ? `${type}${uiType}` : uiType;
};

export const findField = (fields, child) => fields.find(({ name }) => name === child);

export const getAllFields = schema => flatten(schema.groups.map(({ fields }) => fields));

export const getCvOptions = (cvValues, availableValues) => {
    if (cvValues && !isEmpty(availableValues)) {
        const templateAvailableValueIds = availableValues.map(value => get(value, 'id'));
        return cvValues.filter(({ id }) => templateAvailableValueIds.includes(id));
    }
    return cvValues;
};

export const getDefaultValue = field => (field.multiple ? [] : field.type === 'cv' ? {} : '');

export const hasValue = value => !isEmpty(value) || isNumber(value) || isBoolean(value);

export const isFieldToggled = (fieldState, fieldValue, template, view) => {
    const { checked, existsInPrefs, required } = fieldState;
    const shouldBeToggled = required || checked || existsInPrefs;
    const toggleByView = {
        assetEdit: () => !!fieldValue || required,
        bulkMetadata: () => (template ? shouldBeToggled : existsInPrefs)
    };
    return has(toggleByView, view) ? toggleByView[view]() : shouldBeToggled;
};

export const updateEditableTabulars = (tabularField, assetType, viewType) => {
    const editable = ['qc_date', 'qc_source', 'qc_type'];
    const updatedTabular = { ...tabularField };
    if (viewType === 'assetEdit' && assetType === 'composition' && editable.includes(tabularField.name)) {
        updatedTabular.editable = true;
    }
    return updatedTabular;
};

export const getFieldSource = (field, fieldName) => get(field, 'source.field', fieldName);
