import { excludeAsset, typeNames, types } from 'core/Relationship/RelationshipUtils';
import { getSelectedItemsSize } from 'core/Cart/CartUtils';
import { partition, unionBy } from 'lodash';
import { registerSliceReducer, sliceReducer, subscribe } from 'react-redux-boilerout';
import { reducerRegistry as registry, store } from 'lib/redux';

@registerSliceReducer({ store, registry })
@sliceReducer('Relationship')
@subscribe({ namespace: 'Relationship' })
export default class RelationshipSlice {
    initialState() {
        return {
            assetIds: [],
            assets: [],
            childAssets: [],
            childLoading: false,
            config: {},
            disabledAssets: [],
            loading: true,
            parentAssets: [],
            relationshipType: 'conformed_against',
            selectedChildIds: [],
            selectedChildItemsSize: 0,
            selectedIds: [],
            selectedItemsSize: 0,
            selectedParentId: null,
            typeNames,
            types
        };
    }

    onAddParents(slice, assets) {
        const { parentAssets: oldParentAssets, selectedIds: oldSelectedIds } = slice;
        const parentAssets = unionBy(assets, oldParentAssets, 'id');
        const parentIds = parentAssets.map(({ id }) => id);
        const selectedIds = oldSelectedIds.filter(id => !parentIds.includes(id));

        return { ...slice, parentAssets, selectedIds };
    }

    onDeselectAll(slice) {
        const selectedIds = [];

        return { ...slice, selectedIds };
    }

    onDeselectAllChildren(slice) {
        const selectedChildIds = [];

        return { ...slice, selectedChildIds };
    }

    onRemoveParent(slice, parentId) {
        const { parentAssets: oldParentAssets } = slice;
        const parentAssets = oldParentAssets.filter(({ id }) => id !== parentId);

        return { ...slice, parentAssets, selectedParentId: null };
    }

    onRequestChildren(slice) {
        return { ...slice, childLoading: true };
    }

    onReset() {
        return this.initialState();
    }

    onSelectAll(slice, selectedIds) {
        const { assets } = slice;
        const selectedItemsSize = getSelectedItemsSize(assets, selectedIds);

        return { ...slice, selectedIds, selectedItemsSize };
    }

    onSelectAllChildren(slice, selectedChildIds) {
        const { childAssets } = slice;
        const selectedChildItemsSize = getSelectedItemsSize(childAssets, selectedChildIds);

        return { ...slice, selectedChildIds, selectedChildItemsSize };
    }

    onSelectAsset(slice, selectedId) {
        const { assets, selectedIds: oldSelectedIds } = slice;
        const selectedIds = oldSelectedIds.includes(selectedId)
            ? oldSelectedIds.filter(id => id !== selectedId)
            : [...oldSelectedIds, selectedId];
        const selectedItemsSize = getSelectedItemsSize(assets, selectedIds);

        return { ...slice, selectedIds, selectedItemsSize };
    }

    onSelectChild(slice, selectedChildId) {
        const { childAssets, selectedChildIds: oldSelectedChildIds } = slice;
        const selectedChildIds = oldSelectedChildIds.includes(selectedChildId)
            ? oldSelectedChildIds.filter(id => id !== selectedChildId)
            : [...oldSelectedChildIds, selectedChildId];
        const selectedChildItemsSize = getSelectedItemsSize(childAssets, selectedChildIds);

        return { ...slice, selectedChildIds, selectedChildItemsSize };
    }

    onSelectParent(slice, parentId) {
        const { selectedParentId: oldSelectedParentId } = slice;
        const selectedParentId = oldSelectedParentId === parentId ? null : parentId;

        return { ...slice, childAssets: [], selectedIds: [], selectedParentId };
    }

    onSelectRelationshipType(slice, relationshipType) {
        return { ...slice, relationshipType, selectedIds: [] };
    }

    onSetConfig(slice, config) {
        const { assets } = config;
        const allAssets = partition(assets, asset => excludeAsset(asset));
        const assetIds = allAssets[1].map(({ id }) => id);

        return { ...slice, assetIds, assets: allAssets[1], config, disabledAssets: allAssets[0], loading: false };
    }

    onUpdateChildren(slice, assets) {
        return { ...slice, childAssets: assets, childLoading: false };
    }
}
