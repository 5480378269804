import 'app/IMFReplacement/IMFReplacementSlice';
import { autobind } from 'wonderland-ui-commons';
import { endsWith, get } from 'lodash';
import { filterS3ResourcesByExt, getS3ResourceNamesByExt, STEPS } from 'lib/impUtils';
import { listen } from 'lib/events';
import BaseAssetEditActions from './BaseAssetEditActions';
import DestinationsController from 'core/Destinations/DestinationsController';

@autobind
class BaseAssetEditController {
    @listen('imfIngest')
    receivedIngestNotification(notification) {
        BaseAssetEditActions.receivedIngestNotification(notification);
    }

    @listen('imfPropagation')
    receivedPropagationNotification(notification) {
        BaseAssetEditActions.receivedPropagationNotification(notification);
    }

    @listen('imfValidation')
    receivedValidationNotification(notification) {
        BaseAssetEditActions.receivedValidationNotification(notification);
    }

    getFilenames(uploadAssetMap, folder) {
        BaseAssetEditActions.updateImpStep(STEPS.FETCH_FILENAMES);
        uploadAssetMap(folder).then(result => BaseAssetEditActions.updateJobId(get(result, 'job.id')));
    }

    validateXMLs(uploadXMLFilesToValidate, filenames) {
        BaseAssetEditActions.validating();
        const xmlFilenames = filenames.filter(name => endsWith(name, 'xml'));
        uploadXMLFilesToValidate(xmlFilenames).then(result => BaseAssetEditActions.updateJobId(get(result, 'job.id')));
    }

    validateXMLResources(uploadXMLResourcesToValidate, folderResource, destinationID, assetId, ingestData) {
        BaseAssetEditActions.updateImpStep(STEPS.VALIDATING);
        return DestinationsController.getAllResultsInBucket(destinationID, get(folderResource, '0.location'))
            .then(results => {
                BaseAssetEditActions.updateFilenames(results.map(res => res.name));
                const xmlResources = filterS3ResourcesByExt(results, 'xml');
                const essenceFileNames = getS3ResourceNamesByExt(results, 'mxf');
                return uploadXMLResourcesToValidate(xmlResources, essenceFileNames, assetId, ingestData);
            })
            .then(job => BaseAssetEditActions.updateJobId(get(job, 'job.id')));
    }

    uploadIMP(submitForm) {
        BaseAssetEditActions.uploading();
        submitForm();
    }
}

export default new BaseAssetEditController();
