let asyncValidationPromises = new Map();
let asyncValidationValues = new Map();

export function asyncValidator(validator) {
    return function (...args) {
        if (asyncValidationValues.has(validator)) {
            return asyncValidationValues.get(validator);
        }

        if (!asyncValidationPromises.has(validator)) {
            asyncValidationPromises.set(
                validator,
                validator(...args).then(value => asyncValidationValues.set(validator, value))
            );
        }
        return true;
    };
}

function asyncValidationsQueued() {
    return asyncValidationPromises.size;
}

async function waitForAsyncValidations() {
    await Promise.all(Array.from(asyncValidationPromises.values()));
}

function clearAsyncValidationsQueue() {
    asyncValidationPromises.clear();
}

function clearAsyncValidationsValues() {
    asyncValidationValues.clear();
}

export default {
    asyncValidationsQueued,
    waitForAsyncValidations,
    clearAsyncValidationsQueue,
    clearAsyncValidationsValues
};
