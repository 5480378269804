import { defaultsDeep, get } from 'lodash';

const localOverrides = {};

function parseConfig(cfg) {
    return JSON.parse(atob(cfg ?? btoa('{}')));
}

function retrieveConfig(name) {
    return document.head.querySelector(`[name="${name}"][content]`)?.content;
}

function getConfig() {
    const [GLOBAL_EXTERNAL_CONFIG, GLOBAL_USER_CONFIG] = ['global_external_config', 'global_user_config']
        .map(retrieveConfig)
        .map(parseConfig);

    return defaultsDeep(
        localOverrides,
        GLOBAL_USER_CONFIG,
        get(GLOBAL_EXTERNAL_CONFIG, ['dynamicHostnameMapping', window.location.hostname]),
        GLOBAL_EXTERNAL_CONFIG
    );
}

if (!window.GLOBAL_EXTERNAL_CONFIG) {
    window.GLOBAL_EXTERNAL_CONFIG = getConfig();
}

export default window.GLOBAL_EXTERNAL_CONFIG;
