import BulkSelectionToggle from './BulkSelectionToggle';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import React from 'react';

const BulkSelectionCtrlComponent = ({
    classes: { container },
    currentlySelectedAssetsCount,
    deselectCurrentItems,
    hideCount,
    selectCurrentItems,
    selectedAssetsCount,
    selectedItemsSize,
    totalAssetsCount,
    totalAssetsSize,
    ...rest
}) => (
    <div data-qa="multi-checkbox" className={container}>
        {!!(selectedAssetsCount && !hideCount) && <Chip label={selectedAssetsCount} />}
        {!!(selectedAssetsCount && !hideCount && selectedItemsSize) && <Chip label={totalAssetsSize} />}
        <BulkSelectionToggle
            {...rest}
            selectCurrentItems={selectCurrentItems}
            deselectCurrentItems={deselectCurrentItems}
            selectedAssetsCount={currentlySelectedAssetsCount}
            totalAssetsCount={totalAssetsCount}
        />
    </div>
);

BulkSelectionCtrlComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    currentlySelectedAssetsCount: PropTypes.number.isRequired,
    deselectCurrentItems: PropTypes.func.isRequired,
    hideCount: PropTypes.bool,
    selectCurrentItems: PropTypes.func.isRequired,
    selectedAssetsCount: PropTypes.number.isRequired,
    selectedItemsSize: PropTypes.any,
    totalAssetsCount: PropTypes.number.isRequired,
    totalAssetsSize: PropTypes.string
};

export default BulkSelectionCtrlComponent;
