import { fieldValidators } from 'lib/validation';
import { toDateString } from 'lib/formatter';
import _ from 'lodash';
import config from 'app/config';
const getEidrl1 = val => _.get(val, 'eidrl1');
const getReleaseStatus = val => _.get(config.releaseStatuses, val, _.upperFirst(_.kebabCase(val)));

const fields = {
    // Create asset
    defaultFiles: {
        label: 'File(s)',
        name: 'files',
        type: 'filepicker',
        validators: ['validatorFunction:validFilesOrFolders'],
        singleFileValidator: fieldValidators.singleFileSizeNonZero,
        singleFolderValidator: fieldValidators.singleFolder,
        errorMessages: [
            'Selection must contain either files or folders. All file sizes must be greater than zero bytes'
        ]
    },
    sidecarFiles: {
        label: 'Sidecar Files',
        name: 'sidecar_files',
        type: 'filepicker',
        validators: ['validatorFunction:validSidecarPairings'],
        errorMessages: 'Files could not be paired.'
    },

    // Administrative Metadata
    created_at: {
        group: 'Administrative Metadata',
        label: 'Create Date',
        name: 'created_at',
        readonly: true,
        type: 'timeago',
        formatter: toDateString
    },
    created_by: {
        group: 'Administrative Metadata',
        label: 'Created By',
        name: 'created_by',
        readonly: true,
        type: 'label'
    },
    updated_at: {
        group: 'Administrative Metadata',
        label: 'Last Modified',
        name: 'updated_at',
        readonly: true,
        type: 'timeago',
        formatter: toDateString
    },
    last_modified_by: {
        group: 'Administrative Metadata',
        label: 'Last Modified By',
        name: 'last_modified_by',
        readonly: true,
        type: 'label'
    },
    destinations: {
        group: 'Administrative Metadata',
        label: 'Destination Name',
        name: 'destinations',
        readonly: true,
        type: 'label'
    },

    // Descriptive Metadata (General)
    id: (assetType, ctx) => ({
        group: 'Administrative Metadata',
        label: `${assetType === 'collection' ? 'Collection' : 'Asset'} ID`,
        name: 'id',
        readonly: true,
        type: 'label'
    }),
    collections: {
        group: 'Administrative Metadata',
        label: 'Collections',
        name: 'collections',
        readonly: true,
        type: 'links',
        formatter: v => v.name,
        routeFormatter: v => `/collection/${v.id}`
    },
    name: (assetType, ctx) => ({
        group: 'Descriptive Metadata (General)',
        editable: true,
        label: `${assetType === 'collection' ? 'Collection' : 'Asset'} Name`,
        name: 'name',
        type: 'label',
        required: assetType === 'collection',
        validators: assetType === 'collection' ? ['trim'] : []
    }),
    asset_type: {
        group: 'Descriptive Metadata (General)',
        label: 'Asset Type',
        name: 'asset_type',
        readonly: true,
        type: 'label',
        validators: ['required'],
        errorMessages: ['Asset Type is required']
    },
    // Associated Title Data
    related_titles: assetType => ({
        label: 'Title(s)',
        name: 'related_titles',
        required: true,
        type: 'titles',
        errorMessages: assetType === 'collection' ? [] : ['Title is required'],
        schema: [
            {
                label: 'Title',
                name: 'name',
                type: 'text',
                link: metadata => `${config.titleManager.previewHostname}/title/${metadata.id}/metadata`
            },
            {
                label: 'Title Format',
                name: 'title_format',
                type: 'text'
            },
            {
                label: 'Title Short ID',
                name: 'short_id',
                type: 'text'
            },
            {
                label: 'RADAR Product ID',
                name: 'radar_product_id',
                type: 'text',
                multiple: true
            },
            {
                label: 'RADAR Group Number',
                name: 'radar_group_id',
                type: 'text',
                multiple: true
            },
            {
                label: 'RADAR Product Number',
                name: 'radar_product_num',
                type: 'text',
                multiple: true
            },
            {
                label: 'Episode Number',
                name: 'episode_number',
                type: 'text'
            },
            {
                label: 'Domestic Episode Number',
                name: 'domestic_episode_number',
                type: 'text'
            },
            {
                label: 'Foreign Episode Number',
                name: 'foreign_episode_number',
                type: 'text'
            },
            {
                label: 'UPC',
                name: 'upc',
                type: 'text'
            },
            {
                label: 'ISRC',
                name: 'isrc',
                type: 'text'
            },
            {
                label: 'Version Name',
                name: 'version_name',
                type: 'text'
            },
            {
                label: 'English Name',
                name: 'english_name',
                type: 'text'
            },
            {
                label: 'Language',
                name: 'language',
                type: 'text'
            },
            {
                label: 'Duration',
                name: 'duration',
                type: 'text'
            },
            {
                label: 'Record Label',
                name: 'label',
                type: 'text'
            },
            {
                label: 'Recording Type',
                name: 'recording_type',
                type: 'text'
            },
            {
                label: 'Asset Type',
                name: 'asset_type',
                type: 'text'
            },
            {
                label: 'Parental Advisory',
                name: 'parental_advisory',
                type: 'text'
            },
            {
                label: '℗ Year',
                name: 'phonorecord_copyright_year',
                type: 'text'
            },
            {
                label: '℗ Notice',
                name: 'phonorecord_copyright_notice',
                type: 'text'
            },
            {
                label: 'Primary Artist(s)',
                name: 'primary_artist',
                type: 'text',
                multiple: true
            },
            {
                label: 'ISBN',
                name: 'isbn',
                type: 'text'
            },
            {
                label: 'Embargo Date',
                name: 'embargo_date',
                type: 'datepicker'
            },
            {
                label: 'Catalog Number',
                name: 'catalog_number',
                type: 'text'
            },
            {
                label: 'Release Type',
                name: 'release_type',
                type: 'text'
            },
            {
                label: 'Configuration',
                name: 'configuration',
                type: 'text'
            },
            {
                label: '© Year',
                name: 'copyright_year',
                type: 'text'
            },
            {
                label: '© Notice',
                name: 'copyright_notice',
                type: 'text'
            },
            {
                label: 'EIDR Level 1',
                name: 'products',
                type: 'text',
                multiple: true,
                formatter: getEidrl1
            },
            {
                label: 'EIDR Level 2',
                name: 'eidrl2',
                type: 'text'
            },
            {
                label: 'Version ID',
                name: 'twdc_version_id',
                type: 'text'
            },
            {
                label: 'Edit Class',
                name: 'edit_class',
                type: 'text'
            },
            {
                label: 'Edit Use',
                name: 'edit_use',
                type: 'text'
            },
            {
                label: 'Marketing Release ID',
                name: 'marketing_release_id',
                type: 'text'
            },
            {
                label: 'Description',
                name: 'description',
                type: 'text'
            },
            {
                label: 'Edit Details',
                name: 'edit_details',
                type: 'text'
            },
            {
                label: 'Edit Territories',
                name: 'edit_territories',
                type: 'text',
                multiple: true
            },
            {
                label: 'Release Status',
                name: 'release_status',
                type: 'text',
                multiple: true,
                formatter: getReleaseStatus
            },
            {
                label: 'Abbreviation',
                name: 'abbreviation',
                type: 'text'
            }
        ]
    })
};

export function extractFields(fieldKeys, assetType, ctx = {}) {
    return fieldKeys.map(fk => (typeof fields[fk] === 'function' ? fields[fk](assetType, ctx) : fields[fk]));
}
