import { autobind, ToastController } from 'wonderland-ui-commons';
import { get } from 'lodash';
import { listen } from 'lib/events';
import AppEventsActions from './AppEvents/AppEventsActions';

@autobind
class SystemNotificationController {
    @listen('systemNotification')
    async redirectSystemNotification(notification) {
        const step = get(notification, ['payload', 'step'], '');

        switch (step) {
            case 'ingest':
                AppEventsActions.imfIngest(notification);
                break;
            case 'propagation':
                AppEventsActions.imfPropagation(notification);
                break;
            case 'validation':
                AppEventsActions.imfValidation(notification);
                break;
            default:
                ToastController.showError(`Unknown system notification step ${notification.step} found.`);
        }
    }
}

export default new SystemNotificationController();
