import { every, get, isEmpty, isString, last, trim } from 'lodash';
import { hasMatch } from './sidecarUtils';
import { ValidatorForm } from 'react-material-ui-form-validator';
import assetValidationContext from './assetValidationContext';

// Example for Async validator
// import { asyncValidator } from './asyncValidation';
// failIn5Seconds: asyncValidator(async value => {
//     console.log('SPAM before failIn5Seconds');
//     await new Promise(resolve => setTimeout(() => resolve(), 5000));
//     console.log('SPAM after failIn5Seconds');
//     return false;
// })

export const fieldValidators = {
    singleForeignEpisodeNumber: function (value) {
        return !value || /^F(?!000$)1?\d{3}$/.test(value);
    },
    foreignEpisodeNumber: function (values) {
        return !values || values.every(fieldValidators.singleForeignEpisodeNumber);
    },
    singleFileSizeNonZero: function (file) {
        return file && file.size > 0;
    },
    singleFolder: function (folder) {
        const type = get(folder, 'type');
        return get(folder, 'folder') || (type && last(type.split('/')) === 'directory');
    },
    fileSizesNonZero: function (values) {
        return !values || values.every(fieldValidators.singleFileSizeNonZero);
    },
    allFolders: function (values) {
        return !values || values.every(fieldValidators.singleFolder);
    },
    validFilesOrFolders: function (values) {
        return fieldValidators.fileSizesNonZero(values) || fieldValidators.allFolders(values);
    },
    required: function (value) {
        return !isEmpty(isString(value) ? trim(value) : value);
    },
    assetValidatorMapper: (v, field) => {
        return assetValidationContext.get(field);
    },
    validSidecarPairings: function (values) {
        return fieldValidators.fileSizesNonZero(values) && every(values, file => hasMatch(file, values));
    }
};

export async function assetValidator() {
    return {};
}

function init() {
    ValidatorForm.addValidationRule('validatorFunction', (value, validatorAndArgs) => {
        const [validator, ...args] = validatorAndArgs.split('|');
        if (!fieldValidators[validator]) {
            throw new Error(`Validator '${validator}' not found`);
        }
        return fieldValidators[validator](value, ...args);
    });
}

export default {
    init
};
