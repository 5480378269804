import { getActionEnabled } from 'lib/assetUtils';
import AddToQueueIcon from '@material-ui/icons/AddToQueue';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import DeleteAssetDialog from 'app/shared/DeleteAssetDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import FeatureFlag from 'app/shared/FeatureFlag';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import ForwardIcon from '@material-ui/icons/Forward';
import get from 'lodash/get';
import history from 'lib/history';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import ImportExport from '@material-ui/icons/ImportExport';
import LayersIcon from '@material-ui/icons/Layers';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import RemoveFromQueueIcon from '@material-ui/icons/RemoveFromQueue';
import ShareIcon from '@material-ui/icons/Share';
import Tooltip from '@material-ui/core/Tooltip';
import TuneIcon from '@material-ui/icons/Tune';
import withUser from 'wonderland-ui-security/lib/lib/withUser';

@withUser()
export default class ButtonBarComponent extends PureComponent {
    static propTypes = {
        addCartItems: PropTypes.func,
        asset: PropTypes.object,
        bulkDownload: PropTypes.func,
        collectionId: PropTypes.string,
        collectionType: PropTypes.string,
        currentTab: PropTypes.string,
        deleteCallback: PropTypes.func,
        downloadAsset: PropTypes.func,
        isLockableByUser: PropTypes.func,
        navigateToBulkExport: PropTypes.func,
        navigateToShare: PropTypes.func,
        removeFromCollection: PropTypes.func,
        showAddToCollectionModal: PropTypes.func,
        showDuplicateAssetModal: PropTypes.func,
        showSelectLocationModal: PropTypes.func,
        toggleLightbox: PropTypes.func,
        toggleLock: PropTypes.func
    };

    state = {
        anchorEl: null
    };

    closeMore = () => this.setState({ anchorEl: null });

    handleCart = () => {
        const {
            addCartItems,
            asset: { id }
        } = this.props;

        this.closeMore();
        addCartItems([id]);
    };

    handleCollection = () => {
        const { asset, showAddToCollectionModal } = this.props;

        this.closeMore();
        showAddToCollectionModal(asset.id);
    };

    handleDelete = ({ onPull }) => {
        const { asset } = this.props;
        const assetActionEnabled = getActionEnabled(asset);

        return (
            <MenuItem
                data-qa="Delete"
                disabled={!assetActionEnabled['deleteAsset']}
                onClick={() => {
                    this.closeMore();
                    onPull();
                }}
            >
                <ListItemIcon>
                    <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText inset primary="Delete" />
            </MenuItem>
        );
    };

    handleDownload = () => {
        const {
            asset: { asset_type, id, multifile, name },
            bulkDownload,
            downloadAsset
        } = this.props;

        this.closeMore();
        asset_type === 'collection'
            ? bulkDownload({ type: 'collection', assetIds: [id] })
            : downloadAsset({ assetId: id, multifile: get(multifile, 0) === 'Yes', assetName: name });
    };

    handleDuplicate = () => {
        const { asset, showDuplicateAssetModal } = this.props;

        this.closeMore();
        showDuplicateAssetModal(asset.id, asset.name);
    };

    handleEdit = () => {
        const {
            asset,
            asset: { asset_type, id },
            currentTab,
            isLockableByUser
        } = this.props;
        const tab = currentTab || '';
        const type = asset_type === 'collection' ? asset_type : 'asset';

        this.closeMore();
        isLockableByUser(asset).then(() => history.push(`/${type}/edit/${id}/${tab}`));
    };

    handleExport = () => {
        const {
            asset: { asset_type, id, grants },
            navigateToBulkExport
        } = this.props;

        navigateToBulkExport({
            type: asset_type === 'collection' ? 'collection' : 'ids',
            asset_ids: [id],
            asset_grants: { [id]: grants }
        });
    };

    handleLightbox = async () => {
        const { asset, deleteCallback, toggleLightbox } = this.props;

        this.closeMore();
        await toggleLightbox(asset);
        deleteCallback();
    };

    handleLock = async () => {
        const { asset, deleteCallback, toggleLock } = this.props;

        this.closeMore();
        await toggleLock(asset);
        deleteCallback();
    };

    handleLocation = () => {
        this.closeMore();
        this.props.showSelectLocationModal(this.props.asset.id);
    };

    handleMore = event => this.setState({ anchorEl: event.currentTarget });

    handleRemoveFromCollection = async () => {
        const { asset, collectionId, deleteCallback, removeFromCollection } = this.props;

        this.closeMore();
        await removeFromCollection(collectionId, [asset.id]);
        deleteCallback();
    };

    handleShare = () => {
        const {
            asset: { asset_type, id, grants },
            navigateToShare
        } = this.props;

        navigateToShare({
            type: asset_type === 'collection' ? 'collection' : 'ids',
            asset_ids: [id],
            asset_grants: { [id]: grants }
        });
    };

    doRender() {
        const {
            asset,
            asset: { asset_type, lightbox, locked, serviceable },
            collectionId,
            deleteCallback
        } = this.props;
        const { anchorEl } = this.state;
        const assetActionEnabled = getActionEnabled(asset);
        const open = Boolean(anchorEl);

        return (
            <React.Fragment>
                <Tooltip aria-label="Edit" title="Edit" placement="top">
                    <span>
                        <IconButton disabled={!assetActionEnabled['edit']} onClick={this.handleEdit}>
                            <TuneIcon fontSize="small" />
                        </IconButton>
                    </span>
                </Tooltip>
                {serviceable ? (
                    <FeatureFlag feature="ASSET_SERVICING">
                        <Tooltip aria-label="Service" title="Service" placement="top">
                            <span>
                                <IconButton disabled={!assetActionEnabled['service']} onClick={this.handleLocation}>
                                    <ForwardIcon fontSize="small" />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </FeatureFlag>
                ) : (
                    <Tooltip aria-label="Download" title="Download" placement="top">
                        <span>
                            <IconButton disabled={!assetActionEnabled['download']} onClick={this.handleDownload}>
                                <CloudDownloadIcon fontSize="small" />
                            </IconButton>
                        </span>
                    </Tooltip>
                )}
                <Tooltip title="Lightbox" placement="top">
                    <span>
                        <IconButton
                            disabled={!assetActionEnabled['lightbox']}
                            aria-label="lightbox"
                            onClick={this.handleLightbox}
                        >
                            {lightbox ? <RemoveFromQueueIcon fontSize="small" /> : <AddToQueueIcon fontSize="small" />}
                        </IconButton>
                    </span>
                </Tooltip>
                {collectionId ? (
                    <Tooltip aria-label="Remove From Collection" title="Remove From Collection" placement="top">
                        <span>
                            <IconButton
                                disabled={!assetActionEnabled['collection']}
                                onClick={this.handleRemoveFromCollection}
                            >
                                <FolderOpenIcon fontSize="small" />
                            </IconButton>
                        </span>
                    </Tooltip>
                ) : (
                    <Tooltip aria-label="Add To Collection" title="Add To Collection" placement="top">
                        <span>
                            <IconButton disabled={!assetActionEnabled['collection']} onClick={this.handleCollection}>
                                <CreateNewFolderIcon fontSize="small" />
                            </IconButton>
                        </span>
                    </Tooltip>
                )}
                <FeatureFlag feature="CART">
                    <Tooltip title="Cart" placement="top">
                        <span>
                            <IconButton
                                aria-label="cart"
                                disabled={!assetActionEnabled['edit']}
                                onClick={this.handleCart}
                            >
                                <Icon fontSize="small">add_shopping_cart</Icon>
                            </IconButton>
                        </span>
                    </Tooltip>
                </FeatureFlag>
                <IconButton aria-owns={open ? 'more-menu' : ''} aria-haspopup="true" onClick={this.handleMore}>
                    <MoreVertIcon fontSize="small" color="secondary" />
                </IconButton>
                <Menu id="more-menu" anchorEl={anchorEl} open={open} onClose={this.closeMore}>
                    <MenuItem
                        data-qa="Duplicate"
                        disabled={!assetActionEnabled['duplicate']}
                        onClick={this.handleDuplicate}
                    >
                        <ListItemIcon>
                            <LayersIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText inset primary="Duplicate" />
                    </MenuItem>
                    <DeleteAssetDialog asset={asset} onDone={deleteCallback} trigger={this.handleDelete} />
                    <FeatureFlag feature="EXPORT_TRANSCODING">
                        <MenuItem data-qa="Export" disabled={!assetActionEnabled['export']} onClick={this.handleExport}>
                            <ListItemIcon>
                                <ImportExport fontSize="small" />
                            </ListItemIcon>
                            <ListItemText inset primary={'Export'} />
                        </MenuItem>
                    </FeatureFlag>
                    <MenuItem data-qa="Check-in-out" disabled={!assetActionEnabled['lock']} onClick={this.handleLock}>
                        <ListItemIcon>
                            {locked ? <LockIcon fontSize="small" /> : <LockOpenIcon fontSize="small" />}
                        </ListItemIcon>
                        <ListItemText inset primary={locked ? 'Check In' : 'Check Out'} />
                    </MenuItem>
                    {asset_type !== 'collection' && (
                        <MenuItem data-qa="Share" disabled={!assetActionEnabled['share']} onClick={this.handleShare}>
                            <ListItemIcon>
                                <ShareIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText inset primary="Share" />
                        </MenuItem>
                    )}
                </Menu>
            </React.Fragment>
        );
    }

    render() {
        try {
            const { asset, collectionType } = this.props;

            return asset && collectionType !== 'staged' ? this.doRender() : null;
        } catch (e) {
            console.error('ButtonBarComponent::render', e);
        }
        return null;
    }
}
