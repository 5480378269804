import { autobind, depopulateMeta, withProgress } from 'wonderland-ui-commons';
import { compact, get, includes, mapValues } from 'lodash';
import { UserDataAPI, WonderlandDomainAPI } from 'core/apis';
import config from 'app/config';
import ProfileActions from './ProfileActions';
const prefsWithTitleObjs = ['assetUploadPreferences', 'bulkUploadPreferences'];
const depopulateObj = { ...config.cvSaasMapping, related_titles: 'related_titles' };

function adjustFieldPreferenceToBeRemoved({ value, ...rest }) {
    return { ...rest, value: value ? compact(value.map(title => get(title, 'id'))) : null };
}

function adjustFieldPreference(depopulater, { value, name, ...rest }) {
    return { ...rest, name, value: value ? depopulater(value, name) : null };
}

function adjustPreferenceType(prefType) {
    const depopulater = depopulateMeta(depopulateObj);
    return mapValues(prefType, fieldPrefsArray =>
        fieldPrefsArray.map(fieldPref => {
            if (config.cvSaas.enable) {
                return depopulateObj[fieldPref.name] ? adjustFieldPreference(depopulater, fieldPref) : fieldPref;
            } else {
                return fieldPref.name === 'related_titles' ? adjustFieldPreferenceToBeRemoved(fieldPref) : fieldPref;
            }
        })
    );
}

function adjustPreferences(allPrefs) {
    return mapValues(allPrefs, (prefTypeValues, prefTypeLabel) =>
        includes(prefsWithTitleObjs, prefTypeLabel) ? adjustPreferenceType(prefTypeValues) : prefTypeValues
    );
}

@autobind
class ProfileController {
    @withProgress({ label: 'fetch_profile' })
    fetchProfile = async defaultPreferences => {
        const profile = await WonderlandDomainAPI.fetchProfile();
        ProfileActions.profileFetched(profile, defaultPreferences);
    };

    @withProgress({ label: 'update_profile' })
    saveProfile = async (defaultPreferences, data) => {
        const adjustedData = {
            ...data,
            ...(data.preferences && { preferences: adjustPreferences(data.preferences) }),
            namespace: config.namespace
        };
        const profile = await WonderlandDomainAPI.updateProfile(adjustedData);
        ProfileActions.profileFetched(profile, defaultPreferences);
    };

    @withProgress({ label: 'bulk_update_users_profiles' })
    bulkSaveUserProfiles = async (otherPreferences, data) => {
        const adjustedData = {
            ...data,
            namespace: config.namespace
        };
        const profiles = await UserDataAPI.bulkUpdateUsersProfiles(adjustedData);
        ProfileActions.bulkProfilesFetched(profiles, otherPreferences);
    };

    @withProgress({ label: 'update_profile_by_name' })
    saveProfileByName = async (defaultPreferences, data, name) => {
        const adjustedData = {
            ...data,
            ...(data.preferences && { preferences: adjustPreferences(data.preferences) }),
            namespace: config.namespace
        };
        const profile = await UserDataAPI.updateUserProfile(name, adjustedData);
        ProfileActions.profileFetched(profile, defaultPreferences);
    };
}

export default new ProfileController();
