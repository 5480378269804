import { Grid, Paper, Typography } from '@material-ui/core';
import NotificationRecipients from 'app/shared/NotificationRecipients';
import PropTypes from 'prop-types';
import React from 'react';
import RecipientSearch from 'app/shared/RecipientSearch';
import withStyles from '@material-ui/core/styles/withStyles';

const sheet = () => ({ paper: { marginTop: '16px', padding: '0px 16px 24px 16px' } });

const UploadNotification = ({ addNotificationRecipient, classes: { paper } }) => (
    <Grid container direction="column">
        <Grid item>
            <Typography gutterBottom variant="h5">
                Email Notifications
            </Typography>
        </Grid>
        <Grid item>
            <Paper classes={{ root: paper }} elevation={0}>
                <Grid container direction="column" spacing={16}>
                    <Grid item>
                        <RecipientSearch addNotificationRecipient={addNotificationRecipient} />
                    </Grid>
                    <Grid item>
                        <NotificationRecipients />
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    </Grid>
);

UploadNotification.propTypes = {
    addNotificationRecipient: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(sheet)(UploadNotification);
