import { compose, withHandlers } from 'recompose';
import MetadataMode from 'app/shared/MetadataMode';
import withMetadataMode from 'core/Profile/withMetadataMode';

export default compose(
    withMetadataMode(),
    withHandlers({
        toggleMode:
            ({ metadataMode, updateMetadataMode }) =>
            () => {
                updateMetadataMode(!metadataMode);
            }
    })
)(MetadataMode);
