import { compose, withHandlers } from 'recompose';
import ImageMode from 'app/shared/ImageMode';
import withImageMode from 'core/Profile/withImageMode';

export default compose(
    withImageMode(),
    withHandlers({
        toggleMode:
            ({ imageMode, updateImageMode }) =>
            () => {
                updateImageMode(!imageMode);
            }
    })
)(ImageMode);
