import { AsperaService } from 'wonderland-ui-aspera';
import { compose, lifecycle, withHandlers, withProps } from 'recompose';
import { connectSlice, ToastController, withPageDrawer } from 'wonderland-ui-commons';
import { createSelector } from 'reselect';
import { STEPS as DESTINATION_STEPS } from 'app/shared/S3BrowserSideSheet/S3FileSelectorUtils';
import { filesAreEqual } from 'lib/fileUtils';
import { FILETYPE } from 'lib/fileTypes';
import { formatNotifications } from 'lib/assetUploadUtils';
import { get, includes, isEmpty, keys, omit } from 'lodash';
import { STEPS as IMP_STEPS, MESSAGES } from 'lib/impUtils';
import { withFeatureFlaggedAssetTypes, withFeatureFlagsLite } from 'core/Cache/withFeatureFlags';
import { withFormHooks } from 'app/shared/FormHooks';
import { withRouteParams } from 'wonderland-ui-router';
import AssetEditController from 'app/AssetEdit/AssetEditController';
import BaseAssetEditActions from 'app/shared/BaseAssetEdit/BaseAssetEditActions';
import BaseAssetEditController from 'app/shared/BaseAssetEdit/BaseAssetEditController';
import BaseAssetEditSlice from 'app/shared/BaseAssetEdit/BaseAssetEditSlice';
import config from 'app/config';
import history from 'lib/history';
import IMFReplacementController from 'app/IMFReplacement/IMFReplacementController';
import S3BrowserSideSheet from 'app/shared/S3BrowserSideSheet/S3BrowserSideSheetContainer';
import UploadAssetActions from './UploadAssetActions';
import UploadAssetComponent from './UploadAssetComponent';
import UploadAssetSlice from './UploadAssetSlice';
import withIMPValidationUpload from 'core/withIMPValidationUpload';
import withNotificationRecipients from 'core/Destinations/withNotificationRecipients';
import withStyles from '@material-ui/core/styles/withStyles';
import withUploadAsset from 'core/withUploadAsset';
import WonderlandDomainAPI from 'core/WonderlandDomain/WonderlandDomainAPI';
import WonderlandDomainController from 'core/WonderlandDomain/WonderlandDomainController';

const { assetTypes } = config;
const { navigateToAssetDetail, navigateToIMFReplacement } = IMFReplacementController;

function buildReplacementMetadata(replacementMetadata) {
    if (!isEmpty(replacementMetadata)) {
        return {
            extraMetadata: {
                replacementMetadata: {
                    ...replacementMetadata,
                    replacement_reason: get(replacementMetadata, 'replacement_reason', []).map(({ id }) => id)
                }
            }
        };
    }
    return {};
}

function includesRevisionRestriction(asset, fileType) {
    const replacementAsset = asset.hasRecords && fileType === FILETYPE.ORIGINAL;
    const revisions = get(asset, ['revision'], []);
    const replacementJustification = revisions.find(revision => get(revision, 'type') === 'replacement_justification');
    return replacementAsset && _.get(replacementJustification, 'enabled');
}

function isNewAsset(fileType, asset) {
    if ([FILETYPE.THUMBNAIL, FILETYPE.PREVIEW].includes(fileType)) {
        return true;
    }
    return (
        !asset.hasRecords &&
        fileType === FILETYPE.ORIGINAL &&
        ['NO_FILE', 'FAILED'].includes(get(asset, 'ingest.status'))
    );
}

const cancelEditSelector = createSelector(
    slice => slice.asset,
    asset => () => {
        UploadAssetActions.hide();
        AssetEditController.cancel(asset, '');
    }
);

const selectDestination = createSelector(
    props => props.updateDestination,
    props => props.updateRemoteOrigin,
    (updateDestination, updateRemoteOrigin) => destination => {
        updateRemoteOrigin(true);
        UploadAssetActions.fileSelected([]);
        updateDestination(destination);
    }
);

const addS3FilesOrFolderSelector = createSelector(
    props => props.updateSelected,
    props => props.selected,
    (updateSelected, selected) => filesOrFolder => {
        const fileOrFolder = filesOrFolder.length && filesOrFolder[0];
        const nextFilesOrFolders = isEmpty(fileOrFolder) || filesAreEqual(selected, fileOrFolder) ? [] : [fileOrFolder];
        updateSelected(nextFilesOrFolders[0]);
        UploadAssetActions.fileSelected(nextFilesOrFolders);
    }
);

export default compose(
    withUploadAsset(),
    withFeatureFlaggedAssetTypes,
    withFeatureFlagsLite(),
    withFormHooks(),
    withNotificationRecipients(),
    withRouteParams(['id']),
    connectSlice({ slice: BaseAssetEditSlice }),
    connectSlice({ slice: UploadAssetSlice }, (slice, props) => {
        const { folderSelectionAvailable } = props;
        const { asset, fileType } = slice;
        return {
            ...UploadAssetActions,
            ...slice,
            folderSelectionAvailable: folderSelectionAvailable && fileType === FILETYPE.ORIGINAL,
            ...(fileType !== FILETYPE.ORIGINAL && {
                folderSelectionText: `Multifile assets cannot be uploaded as ${fileType}s`
            }),
            isComposition: get(asset, 'asset_type') === assetTypes.composition.name,
            onCancel: cancelEditSelector(slice),
            type: asset.type,
            clearDestination: BaseAssetEditActions.clearDestination,
            updateDestination: BaseAssetEditActions.updateDestination,
            updateFolderSelection: BaseAssetEditActions.updateFolderSelection,
            updateRemoteOrigin: BaseAssetEditActions.updateRemoteOrigin,
            updateSelected: BaseAssetEditActions.updateSelected,
            destinationSelectionStep: isEmpty(slice.destination)
                ? DESTINATION_STEPS.SELECT_DESTINATION
                : DESTINATION_STEPS.SELECT_FOLDER,
            remoteOriginAvailable: props.isFlagEnabled('S3_BROWSER'),
            asperaAvailable: AsperaService.connected,
            canMultiFileUpload: props.isFlagEnabled('MULTI_FILE')
        };
    }),
    withIMPValidationUpload(),
    withProps(
        ({
            asset,
            asset_type,
            assetTypes,
            destination,
            files,
            fileType,
            hasFile,
            isComposition,
            isFlagEnabled,
            replacementMetadata
        }) => {
            const assetType = get(asset, 'asset_type', asset_type);
            return {
                cvType: assetType,
                revisionJustification: includesRevisionRestriction(asset, fileType),
                isSequential: window.location.pathname.startsWith('/sequential/edit'),
                originalUploadAvailable:
                    includes(keys(assetTypes), assetType) &&
                    (!isComposition || !hasFile || isFlagEnabled('IMF_REPLACE')),
                isAttachment: fileType === FILETYPE.ATTACHMENT,
                ingestData: {
                    assetId: asset.id,
                    destinationId: destination.id,
                    resource: files[0],
                    ...buildReplacementMetadata(replacementMetadata)
                }
            };
        }
    ),
    withHandlers({
        hide:
            ({ hide }) =>
            () => {
                BaseAssetEditActions.resetSelection();
                hide();
            },
        clearDestination:
            ({ clearDestination }) =>
            () => {
                UploadAssetActions.fileRemoved();
                clearDestination();
            },
        onSubmit:
            ({
                asset,
                attachmentMetadata,
                files,
                hide,
                isAttachment,
                isComposition,
                isFlagEnabled,
                isFormValid,
                isSequential,
                notifications,
                remoteOrigin,
                replacementMetadata,
                sequentialCallback,
                fileType,
                uploadAsset,
                destination
            }) =>
            async () => {
                if (await isFormValid()) {
                    if (isAttachment) {
                        const attachmentData = {
                            meta: {
                                attachment_type: get(attachmentMetadata, 'attachment_type.id')
                            }
                        };
                        WonderlandDomainAPI.createAttachment(attachmentData, asset.id)
                            .then(({ child_asset_id: attachmentId }) => {
                                if (remoteOrigin) {
                                    WonderlandDomainController.resourceIngest({
                                        unifiedIngest: isFlagEnabled('UNIFIED_INGEST_REMOTE'),
                                        resource: files[0],
                                        asset: {
                                            id: attachmentId,
                                            created_by: asset.created_by
                                        },
                                        type: FILETYPE.ORIGINAL,
                                        destination,
                                        opts: { isAttachment, parentId: asset.id }
                                    });
                                } else {
                                    uploadAsset({
                                        id: attachmentId,
                                        parentId: asset.id,
                                        isAttachment,
                                        files,
                                        imf: false,
                                        type: FILETYPE.ORIGINAL
                                    });
                                }

                                setTimeout(() => {
                                    history.push(`/asset/${asset.id}`);
                                    hide();
                                }, 0);
                                ToastController.show(
                                    `Attachment (ID: ${attachmentId}) now uploading. You can view or edit it from the Attachments tab.`
                                );
                            })
                            .catch(e => ToastController.showError(`Error uploading attachment: ${e}`));
                    } else {
                        const formattedNotifications = formatNotifications(notifications);
                        const imf = isComposition && fileType === FILETYPE.ORIGINAL;
                        const commonPayload = {
                            newAsset: isNewAsset(fileType, asset),
                            resource: files[0],
                            ...buildReplacementMetadata(replacementMetadata)
                        };
                        const managedUploadArgs = {
                            id: asset.id,
                            files,
                            imf,
                            notifications: formattedNotifications,
                            type: fileType,
                            ...omit(commonPayload, ['resource'])
                        };
                        const remoteArgs = {
                            ...commonPayload,
                            id: asset.id,
                            notifications: formattedNotifications,
                            type: fileType,
                            resource: files[0],
                            destination,
                            unifiedIngest: isFlagEnabled('UNIFIED_INGEST_REMOTE'),
                            asset
                        };

                        const uploadArgs = remoteOrigin ? remoteArgs : managedUploadArgs;
                        const uploader = remoteOrigin ? WonderlandDomainController.resourceIngest : uploadAsset;
                        const redirectDelay = imf ? 1500 : 0;

                        uploader(uploadArgs).then(() =>
                            isSequential
                                ? sequentialCallback(asset.id)
                                : setTimeout(() => history.push(`/asset/${asset.id}`), redirectDelay)
                        );
                        setTimeout(() => hide(), redirectDelay);
                    }
                }
            },
        startIMPValidationAndUpload:
            ({
                uploadAssetMap,
                asset,
                files,
                fileType,
                ingestData,
                isFormValid,
                remoteOrigin,
                uploadXMLResourcesToValidate,
                destination
            }) =>
            async () => {
                const revisionJustification = includesRevisionRestriction(asset, fileType);
                const formIsValid = revisionJustification ? await isFormValid() : true;

                if (formIsValid) {
                    return remoteOrigin
                        ? BaseAssetEditController.validateXMLResources(
                              uploadXMLResourcesToValidate,
                              files,
                              destination.id,
                              asset.id,
                              ingestData
                          )
                        : BaseAssetEditController.getFilenames(uploadAssetMap, files);
                }
            }
    }),
    withPageDrawer([
        {
            name: 's3Browser',
            Component: S3BrowserSideSheet,
            mapProps: props => ({
                addDestination: selectDestination(props),
                addS3FilesOrFolder: addS3FilesOrFolderSelector(props),
                anchor: 'right',
                closeDrawer: props.closeDrawer,
                clearDestination: props.clearDestination,
                destination: props.destination,
                modal: true,
                size: 'medium',
                selected: props.selected,
                variant: get(props, 'asset.asset_type') === 'composition' ? 'imf' : props.isFolder ? 'folder' : 'file'
            })
        }
    ]),
    withStyles(({ palette }) => ({
        button: {
            '&:hover': {
                backgroundColor: palette.background.alternative
            }
        },
        snackBarAction: {
            margin: 0,
            padding: 0
        },
        snackBarRoot: {
            borderRadius: 0,
            justifyContent: 'center',
            maxWidth: 850,
            minWidth: 300
        }
    })),
    lifecycle({
        componentDidMount() {
            const { asset, changeType, fileType, id, isSequential, originalUploadAvailable, sequentialCallback } =
                this.props;
            if (fileType === FILETYPE.ORIGINAL && !originalUploadAvailable) {
                changeType(FILETYPE.PREVIEW);
            }
            window.scrollTo(0, 0);

            if (isEmpty(asset)) {
                isSequential ? sequentialCallback(id) : history.push(`/asset/${id}`);
            }
        },
        componentDidUpdate() {
            const {
                asset: { id },
                errors,
                files,
                filenames,
                hide,
                impStep,
                ingestData,
                isFlagEnabled,
                isSequential,
                onSubmit,
                replaceComparison,
                sequentialCallback,
                startPropagating,
                startRedirecting,
                startUploading,
                startValidating,
                uploadXMLFilesToValidate
            } = this.props;

            if ((impStep === IMP_STEPS.VALIDATING || IMP_STEPS.FETCH_FILENAMES_COMPLETE) && startValidating) {
                const folderPath = get(files, '[0].name');
                const xmlFilenames = filenames.map(f => `${folderPath}/${f}`);

                BaseAssetEditController.validateXMLs(uploadXMLFilesToValidate, xmlFilenames);
            }

            if (impStep === IMP_STEPS.VALIDATION_COMPLETE && startPropagating) {
                if (isFlagEnabled('IMF_PROPAGATE')) {
                    navigateToIMFReplacement(id, ingestData, isSequential, replaceComparison, sequentialCallback);
                    hide();
                }
            }

            if (impStep === IMP_STEPS.VALIDATION_COMPLETE && startUploading) {
                BaseAssetEditController.uploadIMP(onSubmit);
            }

            if (impStep === IMP_STEPS.INGESTING && startRedirecting) {
                navigateToAssetDetail(id, isSequential, MESSAGES['INGEST']['SUCCESS'], sequentialCallback);
                hide();
            }

            if (impStep === IMP_STEPS.INGEST_FAILED) {
                const message = !isEmpty(errors[0]) ? errors[0] : MESSAGES['INGEST']['FAILED'];
                navigateToAssetDetail(id, isSequential, message, sequentialCallback, true);
                hide();
            }
        },
        componentWillUnmount() {
            UploadAssetActions.reset();
        }
    })
)(UploadAssetComponent);
