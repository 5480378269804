import { compose, withHandlers } from 'recompose';
import { dispatchResize } from 'lib/utils';
import FormDialogComponent from './SchemaFormDialogComponent';
import withStyles from '@material-ui/core/styles/withStyles';

export default compose(
    withHandlers({
        onSubmit:
            ({ onSubmit }) =>
            () => {
                onSubmit();
                dispatchResize();
            }
    }),
    withStyles(theme => ({
        dialogPaperRoot: {
            maxHeight: '50%',
            maxWidth: '50%',
            minHeight: '25%',
            minWidth: '25%'
        }
    }))
)(FormDialogComponent);
