import { recipientTypes } from 'core/Destinations/DeliveryFormConstants';
const { internal, external, dl } = recipientTypes;

export const formatContactDetails = (contactDetails = []) => {
    return contactDetails.reduce((acc, curr) => {
        const { id, first_name, last_name, email, type } = curr;
        acc[id] = {
            id,
            first_name,
            last_name,
            email,
            type: type === 'external' ? external : internal,
            exclude: false,
            filter: true
        };
        return acc;
    }, {});
};

export const formatDlBody = (distributionList = {}, { userAdded, fromDestination } = {}) => {
    const { name, id, contactDetails, preferences } = distributionList;
    return {
        id,
        name,
        type: dl,
        preferences,
        userAdded,
        fromDestination,
        contactDetails: formatContactDetails(contactDetails)
    };
};
