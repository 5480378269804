import { compose, lifecycle, withHandlers, withProps } from 'recompose';
import { connectSlice, ToastController } from 'wonderland-ui-commons';
import { grey } from '@material-ui/core/colors';
import { withImageMode, withMetadataMode } from 'core/Profile';
import history from 'lib/history';
import RelationshipActions from 'core/Relationship/RelationshipActions';
import RelationshipComponent from './RelationshipComponent';
import RelationshipController from 'core/Relationship/RelationshipController';
import RelationshipSlice from 'core/Relationship/RelationshipSlice';
import withStyles from '@material-ui/core/styles/withStyles';

const { reset } = RelationshipActions;
const {
    addChildren,
    addParents,
    deselectAll,
    deselectAllChildren,
    removeChildren,
    removeParent,
    selectAll,
    selectAllChildren,
    selectAsset,
    selectChild,
    selectParent,
    selectRelationshipType
} = RelationshipController;

export default compose(
    connectSlice({ slice: RelationshipSlice, actions: RelationshipActions }, slice => {
        return { ...slice };
    }),
    withImageMode(),
    withMetadataMode(),
    withProps(({ childAssets, parentAssets, types }) => ({
        filteredChildAssets: childAssets.filter(({ type }) => types.includes(type)),
        parentIds: parentAssets.map(({ id }) => id)
    })),
    withProps(
        ({
            assets,
            filteredChildAssets,
            parentAssets,
            parentIds,
            relationshipType,
            selectedChildIds,
            selectedIds,
            selectedParentId
        }) => ({
            childIds: filteredChildAssets.filter(({ type }) => type === relationshipType).map(({ id }) => id),
            errorMessage: 'ERROR: Your selection does not contain any assets that can be related.',
            filteredAssets: selectedParentId
                ? assets.filter(
                      ({ id }) =>
                          !filteredChildAssets.map(({ id, type }) => type === relationshipType && id).includes(id) &&
                          selectedParentId !== id
                  )
                : assets.filter(({ id }) => !parentIds.includes(id)),
            filteredParentAssets: selectedParentId
                ? parentAssets.filter(({ id }) => selectedParentId === id)
                : parentAssets,
            hasSelected: { asset: !!selectedIds.length, child: !!selectedChildIds.length, parent: !!selectedParentId }
        })
    ),
    withProps(({ filteredAssets, childIds }) => ({
        selectableAssets: filteredAssets.filter(({ id }) => !childIds.includes(id))
    })),
    withHandlers({
        handleAddChildren:
            ({ assets, selectedIds, selectedParentId, relationshipType }) =>
            ({ currentTarget: { value } }) => {
                const selectedChildren = assets.filter(({ id }) => (value ? id === value : selectedIds.includes(id)));

                addChildren(selectedChildren, selectedParentId, relationshipType);
            },
        handleAddParents:
            ({ assets, selectedIds }) =>
            ({ currentTarget: { value } }) => {
                const selectedParents = assets.filter(({ id }) => (value ? id === value : selectedIds.includes(id)));

                addParents(selectedParents);
            },
        handleDeselectAll: () => () => deselectAll(),
        handleDeselectAllChildren: () => () => deselectAllChildren(),
        handleRemoveChildren:
            ({ childAssets, selectedChildIds, selectedParentId }) =>
            ({ currentTarget }) => {
                const childId = currentTarget.value;
                const childType = currentTarget.getAttribute('data-type');
                const selectedChildren = childAssets.filter(({ id, type }) =>
                    childId ? id === childId && type === childType : selectedChildIds.includes(id)
                );

                removeChildren(selectedChildren, selectedParentId);
            },
        handleRemoveParent: () => id => removeParent(id),
        handleSelectAll:
            ({ selectableAssets }) =>
            () => {
                const selectedIds = selectableAssets.map(({ id }) => id);

                selectAll(selectedIds);
            },
        handleSelectAllChildren:
            ({ childAssets }) =>
            () => {
                const childIds = childAssets.map(({ id }) => id);

                selectAllChildren(childIds);
            },
        handleSelectAsset: () => id => selectAsset(id),
        handleSelectChild: () => id => selectChild(id),
        handleSelectRelationshipType: () => relationshipType => {
            selectRelationshipType(relationshipType);
        },
        handleSelectParent:
            ({ selectedParentId }) =>
            id =>
                selectParent(id, selectedParentId)
    }),
    withStyles(
        ({
            palette: {
                action: { active },
                assetTypeColor,
                secondary: { main }
            }
        }) => ({
            ...assetTypeColor,
            card: {
                cursor: 'pointer',
                height: 'calc(100% - 8px)',
                margin: 4,
                '&.hidden': { display: 'none' },
                '&.selected': { boxShadow: `0 0 0 2px ${active}` }
            },
            cardActions: {
                display: 'flex',
                paddingTop: 0,
                '&.asset': { justifyContent: 'flex-end' }
            },
            cardMedia: { backgroundColor: '#bdbdbd', backgroundSize: 'contain', height: 0, paddingTop: '57%' },
            checkbox: { paddingRight: 16 },
            column: { width: 342 },
            columnFill: { minWidth: 342, width: 'calc(100% - 684px)' },
            container: { paddingBottom: '80px' },
            expansionPanel: {
                '&:first-child': { marginTop: 8 }
            },
            gridList: {
                height: 'calc(100vh - 200px)',
                overflowX: 'hidden'
            },
            grids: {
                width: '100%'
            },
            icon: { color: 'dodgerblue' },
            loader: {
                alignItems: 'center',
                color: 'white',
                display: 'flex',
                fontWeight: 100,
                height: '85vh',
                justifyContent: 'center',
                textShadow: '2px 1px 2px #333'
            },
            noAssetsMessage: {
                color: 'darkgray',
                marginTop: 32
            },
            progress: {
                color: 'dodgerblue',
                marginLeft: '45%',
                marginTop: 40
            },
            root: {
                backgroundColor: 'white',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-around',
                overflow: 'hidden',
                padding: 8
            },
            snackBar: {
                '& button:hover': { backgroundColor: grey[800] },
                borderRadius: 0,
                justifyContent: 'center',
                maxWidth: 580,
                width: 580
            },
            snackBarAction: { margin: 0, padding: 8 },
            subheader: { wordBreak: 'break-all' },
            title: { wordBreak: 'break-all' }
        })
    ),
    lifecycle({
        componentDidMount() {
            const { assets, errorMessage } = this.props;
            if (!assets.length) {
                ToastController.showError(errorMessage);
                history.push('/');
            }
        },
        componentWillUnmount() {
            reset();
        }
    })
)(RelationshipComponent);
