// Polyfills ==> KEEP IN SYNC WITH KARMA CONF
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import 'core-js/stable';
import 'js-polyfills/url';
import 'whatwg-fetch';
import { compose } from 'recompose';
import { enableOnConfig } from 'app/withConfig';
import { enableOnProfileOption } from 'core/Profile';
import { muiTheme } from 'lib/mui';
import { store } from 'lib/redux';
import { withWonderlandPlatform } from 'wonderland-ui-commons/lib/wonderlandPlatformProvider';
import AppLayout from 'app/shared/AppLayout';
import AppLayoutLite from 'app/shared/AppLayoutLite';
import config from 'app/config';
import CsrfController from 'core/Csrf/CsrfController';
import enableOnTags from 'wonderland-ui-commons/lib/LazyTags/enableOnTags';
import EventsController from 'core/Events/EventsController';
import history from 'lib/history';
import NewRelic from 'wonderland-ui-commons/lib/lib/newrelic';
import NotFound from 'app/NotFound';
import pluginContainer from 'wonderland-ui-commons/lib/pluginContainer';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import redirectFactory from 'wonderland-ui-router/lib/lib/redirectFactory';
import reset from './styles/jss_reset';
import Router from 'wonderland-ui-router/lib/Router/RouterContainer';
import routes from './routes';
import validation from 'lib/validation';
import withAnnouncementsPlugin from 'wonderland-ui-announcements/lib/AnnouncementBanner/withAnnouncementsPlugin';
import withAppContainer from 'wonderland-ui-commons/lib/withAppContainer';
import withAppSwitcherPlugin from 'wonderland-ui-app-switcher/lib/lib/withAppSwitcherPlugin';
import withAsperaPlugin from 'wonderland-ui-aspera/lib/Aspera/withAsperaPlugin';
import withCommonsPlugin from 'wonderland-ui-commons/lib/lib/withCommonsPlugin';
import withNotificationsPlugin from 'wonderland-ui-notifications/lib/Notification/withNotificationsPlugin';
import withProvider from 'wonderland-ui-commons/lib/withProvider';
import withRouterPlugin from 'wonderland-ui-router/lib/Router/withRouterPlugin';
import withS3Plugin from 'wonderland-ui-s3/lib/S3/withS3Plugin';
import withSecurityPlugin from 'wonderland-ui-security/lib/lib/withSecurityPlugin';
import withStyles from '@material-ui/core/styles/withStyles';
import withVideoPlayerPlugin from 'wonderland-ui-video/lib/component/withVideoPlayerPlugin';
import withWonderlandTheme from 'wonderland-ui-material/lib/WonderlandTheme';

validation.init();
EventsController.init();
if (CsrfController) {
    CsrfController.init();
}

const redirect = redirectFactory(history);
const s3Plugin = withS3Plugin({ signedUrlsEnabled: true });
const asperaPlugin = withAsperaPlugin({
    applicationId: config.asperaApplicationId,
    connectLaunchWaitTimeoutMs: config.asperaConnectLaunchWaitTimeoutMs
});

const asperaPluginExternal = compose(enableOnTags('external'), enableOnConfig('asperaExternalEnabled'))(asperaPlugin);

const asperaPluginInternal = compose(
    enableOnTags('authenticated'),
    enableOnProfileOption('preferences.transferPreferences.asperaEnabled', {
        defaultEnabled: config.asperaPreferenceDefaultEnabled
    })
)(asperaPlugin);

const s3PluginExternal = compose(enableOnTags('external'), enableOnConfig('s3SignedUrlsExternalEnabled'))(s3Plugin);

const s3PluginInternal = compose(
    enableOnTags('authenticated'),
    enableOnProfileOption('preferences.transferPreferences.s3SignedUrlsEnabled', {
        defaultEnabled: config.s3SignedUrlsPreferenceDefaultEnabled
    })
)(s3Plugin);

@withAppContainer
@withProvider({ store })
@withWonderlandTheme({ muiTheme })
@withStyles(reset)
@withWonderlandPlatform({
    hostname: config.platformHostname,
    redirect: redirect,
    history: history,
    appKey: config.namespace
})
@pluginContainer(
    withAppSwitcherPlugin(),
    withAnnouncementsPlugin(),
    withCommonsPlugin({
        helpCDNLinks: config.helpCDNLinks
    }),
    withRouterPlugin({
        externalLoginURL: `${config.appHostname}${config.externalLoginURI}`,
        Layout: AppLayout,
        LiteLayout: AppLayoutLite,
        localLoginURL: `${config.appHostname}${config.localLoginURI}`,
        loginCallbackURL: `${config.appHostname}${config.loginCallbackURI}`,
        NotFound: NotFound
    }),
    withSecurityPlugin({
        hostname: config.platformHostname,
        appHostname: config.appHostname,
        authCookieDomain: config.authCookieDomain,
        authCookieName: config.authCookieName,
        externalTermsAndConditionsURL: `${config.appHostname}${config.externalTermsAndConditionsURI}`,
        loginCallbackURL: `${config.appHostname}${config.loginCallbackURI}`,
        logoutURL: `${config.appHostname}${config.logoutURI}`,
        termsAndConditionsURL: `${config.appHostname}${config.termsAndConditionsURI}`,
        allowDomains: config.allowRedirectDomains,
        namespace: config.namespace,
        issuesToolURL: config.issuesToolURI,
        queenOfHeartsImageURL: config.cdnAssets.images.queenOfHearts
    }),
    withNotificationsPlugin({
        socketHostname: config.rabbitHostname,
        publishEventForActions: ['download'],
        notificationsConfig: config.notifications
    }),
    withVideoPlayerPlugin({
        playerKey: config.video.playerKey,
        drmUrl: config.video.drmUrl,
        shouldLogEvents: config.video.shouldLogEvents,
        useEntitlement: config.video.useEntitlement,
        watermarkOptions: config.video.watermarkOptions
    }),
    asperaPluginExternal,
    asperaPluginInternal,
    s3PluginExternal,
    s3PluginInternal
)
export default class App extends React.Component {
    static propTypes = {
        children: PropTypes.node
    };

    render() {
        const { enableNewRelic } = config;
        const { licenseKey, applicationID, agentID, trustKey, accountID, nonce } = config.newRelic;
        return (
            <React.Fragment>
                <NewRelic
                    {...{
                        licenseKey,
                        applicationID,
                        agentID,
                        trustKey,
                        accountID,
                        nonce,
                        activateNewRelic: enableNewRelic
                    }}
                />
                <Router>{routes}</Router>
            </React.Fragment>
        );
    }
}

const render = () => {
    ReactDOM.render(<App />, document.getElementById('app'));
};

render();
