import Button from '@material-ui/core/Button';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

/* istanbul ignore next */
const sheet = ({ spacing }) => ({
    button: {
        padding: '0 8px',
        marginBottom: '8px',
        textAlign: 'left'
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTon: spacing.unit,
        '&.nextOnly': {
            justifyContent: 'flex-end'
        }
    }
});

@withStyles(sheet)
export default class PreviousOrNextComponent extends React.PureComponent {
    static propTypes = {
        previousItems: PropTypes.bool,
        moreItems: PropTypes.bool,
        seeNextResults: PropTypes.func,
        seePreviousResults: PropTypes.func,
        classes: PropTypes.object.isRequired
    };

    render() {
        const { classes, moreItems, previousItems, seeNextResults, seePreviousResults } = this.props;
        return (
            <div className={classnames(classes.container, { nextOnly: !previousItems })}>
                {previousItems && (
                    <Button onClick={seePreviousResults} className={classes.button} size={'small'}>
                        <ChevronLeft />
                        Previous
                    </Button>
                )}
                {moreItems && (
                    <Button onClick={seeNextResults} className={classes.button} size={'small'}>
                        Next
                        <ChevronRight />
                    </Button>
                )}
            </div>
        );
    }
}
