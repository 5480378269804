import { urlParams, xhr } from 'wonderland-ui-commons';
import config from 'app/config';

const prefix = '/external/v1';

function findAssetById(id, statusCheck) {
    return xhr.json(`${config.apiHostname}${prefix}/asset/${id}?${urlParams({ statusCheck })}`, {
        method: 'get'
    });
}

async function getCollectionAssets(id, { skip, limit, skipCount = 'false' } = {}) {
    return xhr
        .json(`${config.apiHostname}${prefix}/collection/${id}?${urlParams({ skip, limit, skipCount })}`, {
            method: 'get'
        })
        .then(response => {
            if (skipCount === 'false' && response.total === -1) {
                return xhr
                    .json(
                        `${config.apiHostname}${prefix}/collection/${id}?${urlParams({
                            skip: 0,
                            limit: 1,
                            skipCount
                        })}`,
                        {
                            method: 'get'
                        }
                    )
                    .then(r => ({ ...response, total: r.total }));
            }
            return response;
        });
}

function getAssetPreviewUrl({ id }, collectionId) {
    return `${config.apiHostname}${prefix}/collection/${collectionId}/asset/preview/${id}`;
}

function getAssetThumbnailUrl({ id }, collectionId) {
    return `${config.apiHostname}${prefix}/collection/${collectionId}/asset/thumbnail/${id}`;
}

function getAssetDownloadUrl(collectionId, assetId) {
    return `${config.apiHostname}${prefix}/collection/${collectionId}/asset/download/${assetId}`;
}

function getHttpDownloadTransferSpec({ collectionId, assetId }) {
    return {
        urls: [getAssetDownloadUrl(collectionId, assetId)]
    };
}

async function getHttpBulkDownloadTransferSpec({ collectionId, assetIds }) {
    const { url } = await xhr.json(`${config.apiHostname}${prefix}/collection/${collectionId}/download`, {
        method: 'post',
        body: JSON.stringify({ asset_ids: assetIds })
    });
    return { urls: [url] };
}

function getManagedDownloadTransferSpec(type, { collectionId, assetId }) {
    return getManagedBulkDownloadTransferSpec(type, { collectionId, assetIds: [assetId] });
}

function getManagedBulkDownloadTransferSpec(type, { collectionId, assetIds }) {
    return xhr.json(`${config.apiHostname}${prefix}/collection/${collectionId}/asset/managed/download`, {
        method: 'post',
        body: JSON.stringify({
            type,
            assetIds
        })
    });
}

export default {
    findAssetById,
    getAssetPreviewUrl,
    getAssetThumbnailUrl,
    getCollectionAssets,
    getHttpDownloadTransferSpec,
    getHttpBulkDownloadTransferSpec,
    getManagedDownloadTransferSpec,
    getManagedBulkDownloadTransferSpec
};
