import { compose, withHandlers } from 'recompose';
import AppHeaderDrawerComponent from './AppHeaderDrawerComponent';
import history from 'lib/history';
import withStyles from '@material-ui/core/styles/withStyles';

export default compose(
    withHandlers({
        handleCheckedOut:
            ({ closeDrawer }) =>
            () => {
                closeDrawer();
                history.push('/checkedout');
            },
        handleHome:
            ({ closeDrawer }) =>
            () => {
                closeDrawer();
                history.push('/');
            },
        handleLightbox:
            ({ closeDrawer }) =>
            () => {
                closeDrawer();
                history.push('/lightbox');
            },
        handleShared:
            ({ closeDrawer }) =>
            () => {
                closeDrawer();
                history.push('/shared');
            },
        handleSmartCollections:
            ({ closeDrawer }) =>
            () => {
                closeDrawer();
                history.push('/smartCollections');
            }
    }),
    withStyles(({ spacing }) => ({
        drawerOverride: {
            marginTop: -8
        },
        gridRoot: {
            marginLeft: spacing.unit
        }
    }))
)(AppHeaderDrawerComponent);
