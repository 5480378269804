import { CRITERIA_TYPE, DELIMITER_TYPE, FIELD_LABEL } from './SearchFormConstants';

export const searchFields = [
    {
        fieldCriteria: { ...CRITERIA_TYPE.term, field: 'term' },
        fieldDelimiter: DELIMITER_TYPE.text,
        fieldLabel: FIELD_LABEL.term
    },
    {
        fieldCriteria: { ...CRITERIA_TYPE.one_of, field: 'id' },
        fieldDelimiter: DELIMITER_TYPE.id,
        fieldLabel: FIELD_LABEL.id
    },
    {
        fieldCriteria: { ...CRITERIA_TYPE.one_of, field: 'short_id' },
        fieldDelimiter: DELIMITER_TYPE.id,
        fieldLabel: FIELD_LABEL.short_id
    },
    {
        fieldCriteria: { ...CRITERIA_TYPE.one_of, field: 'radar_title_id' },
        fieldDelimiter: DELIMITER_TYPE.id,
        fieldLabel: FIELD_LABEL.radar_title_id
    },
    {
        fieldCriteria: { ...CRITERIA_TYPE.one_of, field: 'twdc_version_id' },
        fieldDelimiter: DELIMITER_TYPE.id,
        fieldLabel: FIELD_LABEL.twdc_version_id
    },
    {
        fieldCriteria: { ...CRITERIA_TYPE.one_of, field: 'fox_financial_title_id' },
        fieldDelimiter: DELIMITER_TYPE.id,
        fieldLabel: FIELD_LABEL.fox_financial_title_id
    },
    {
        fieldCriteria: { ...CRITERIA_TYPE.one_of, field: 'source_system_asset_id' },
        fieldDelimiter: DELIMITER_TYPE.id,
        fieldLabel: FIELD_LABEL.source_system_asset_id
    },
    {
        fieldCriteria: { ...CRITERIA_TYPE.one_of, field: 'file_name' },
        fieldDelimiter: DELIMITER_TYPE.text,
        fieldLabel: FIELD_LABEL.file_name
    },
    {
        fieldCriteria: { ...CRITERIA_TYPE.one_of, field: 'name' },
        fieldDelimiter: DELIMITER_TYPE.text,
        fieldLabel: FIELD_LABEL.name
    },
    {
        fieldCriteria: { ...CRITERIA_TYPE.one_of, field: 'asset_language' },
        fieldDelimiter: DELIMITER_TYPE.text,
        fieldLabel: FIELD_LABEL.asset_language
    },
    {
        fieldCriteria: { ...CRITERIA_TYPE.one_of, field: 'legacy_media_depot_path' },
        fieldDelimiter: DELIMITER_TYPE.text,
        fieldLabel: FIELD_LABEL.legacy_media_depot_path
    },
    {
        fieldCriteria: { ...CRITERIA_TYPE.one_of, field: 'media_depot_legacy_file_path' },
        fieldDelimiter: DELIMITER_TYPE.text,
        fieldLabel: FIELD_LABEL.media_depot_legacy_file_path
    }
];

export const getDelimitedList = (curatedInputTerm, fieldDelimiter) => {
    const parseString = curatedInputTerm
        .replace(DELIMITER_TYPE.standard, DELIMITER_TYPE.replacement)
        .replace(fieldDelimiter, DELIMITER_TYPE.replacement)
        .split(DELIMITER_TYPE.replacement)
        .filter(item => item !== DELIMITER_TYPE.replacement)
        .filter(Boolean);

    return [...new Set(parseString)].map(item => item.trim());
};
