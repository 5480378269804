import { find, mapValues, pickBy, unionBy } from 'lodash';
import { replaceEmptyStringsWithNull } from 'lib/handleEmptyStrings';
import objDiff from 'lib/objDiff';

export const convertAssetToFormFields = asset => {
    const getFormFields = (value, name) => ({ value, name, operation: 'replace' });
    const nonEmptyArray = value => !Array.isArray(value) || value.length;
    return mapValues(pickBy(asset, nonEmptyArray), getFormFields);
};

export const getPartialUpdateForMeta = (initialAsset, changes) => {
    return replaceEmptyStringsWithNull(objDiff(mapValues(initialAsset, 'value'), changes));
};

export const resolveInitialAssetValues = (prevMeta, newMeta, initialAsset) => {
    const initialAssetValue = change => initialAsset[change.name] || change;
    const alreadyPresentChange = change => prev => prev.name === change.name;
    const resolveWithInitialAsset = change =>
        find(prevMeta, alreadyPresentChange(change)) ? change : initialAssetValue(change);
    return mapValues(newMeta, resolveWithInitialAsset);
};

//RELATED TITLES UTILS
const addTitlesToValue = (fieldObj, titles) => {
    return {
        ...fieldObj,
        value: unionBy(fieldObj.value, titles, 'id')
    };
};

export const onAddRelatedTitles = (meta, titles, updateMetaFn) => {
    const oldRelatedTitles = meta.related_titles || {};
    const newMeta = { ...meta, related_titles: addTitlesToValue(oldRelatedTitles, titles) };
    return updateMetaFn(newMeta);
};

export const onAddRelatedTitlesToArray = (meta, titles, updateMetaFn) => {
    const updatedMeta = meta.map(fieldObj =>
        fieldObj.name === 'related_titles' ? addTitlesToValue(fieldObj, titles) : fieldObj
    );
    return updateMetaFn(updatedMeta);
};
