import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import React from 'react';

export default class RegularDropzoneComponent extends React.Component {
    static propTypes = {
        updateSelection: PropTypes.func,
        children: PropTypes.func,
        multiple: PropTypes.bool
    };

    static defaultProps = {
        disableClick: true,
        multiple: false
    };

    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    render() {
        try {
            return this.doRender();
        } catch (e) {
            console.error('RegularDropzone::render', e);
        }
    }

    showSelectFileDialog = () => this.ref.current.open();

    doRender() {
        const { updateSelection: onDrop, children: render, ...otherProps } = this.props;
        const { showSelectFileDialog } = this;
        return (
            <Dropzone style={{}} ref={this.ref} {...{ onDrop, ...otherProps }}>
                {render({ showSelectFileDialog })}
            </Dropzone>
        );
    }
}
