import { Button, Grid, Toolbar, Typography } from '@material-ui/core';
import { ErrorBoundary } from 'wonderland-ui-commons';
import { FILETYPE } from 'lib/fileTypes';
import { isEmpty } from 'lodash';
import { STEPS } from 'lib/impUtils';
import AsyncValidatorForm from 'app/shared/AsyncValidatorForm';
import CancelIcon from '@material-ui/icons/Cancel';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FormDialog from 'app/shared/FormDialog';
import PropTypes from 'prop-types';
import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SystemNotificationController from 'core/SystemNotificationController'; // eslint-disable-line no-unused-vars
import UploadAssetForm from './UploadAssetForm';
import UploadNotification from 'app/shared/UploadNotification';

export default class UploadAssetComponent extends React.Component {
    static propTypes = {
        addNotificationRecipient: PropTypes.func.isRequired,
        asperaAvailable: PropTypes.bool,
        attachmentMetadata: PropTypes.object,
        canMultiFileUpload: PropTypes.bool,
        changeAttachmentMeta: PropTypes.func,
        changeReplacementMeta: PropTypes.func,
        changeType: PropTypes.func,
        classes: PropTypes.object.isRequired,
        clearDestination: PropTypes.func,
        cvType: PropTypes.string,
        destination: PropTypes.object,
        destinationSelectionStep: PropTypes.string,
        Dropzone: PropTypes.func,
        files: PropTypes.array,
        filenames: PropTypes.array,
        fileSelected: PropTypes.func,
        fileRemoved: PropTypes.func,
        fileType: PropTypes.string,
        folderSelectionAvailable: PropTypes.bool,
        folderSelectionText: PropTypes.string,
        formRef: PropTypes.object.isRequired,
        hide: PropTypes.func,
        impStep: PropTypes.string,
        isAttachment: PropTypes.bool,
        isComposition: PropTypes.bool,
        isFolder: PropTypes.bool,
        isModal: PropTypes.bool,
        onCancel: PropTypes.func.isRequired,
        onSubmit: PropTypes.func,
        openDrawer: PropTypes.func,
        originalUploadAvailable: PropTypes.bool,
        remoteOrigin: PropTypes.bool,
        remoteOriginAvailable: PropTypes.bool,
        renderFilesOrFolder: PropTypes.func,
        replacementMetadata: PropTypes.object,
        revisionJustification: PropTypes.bool,
        sequentialCallback: PropTypes.func,
        startIMPValidationAndUpload: PropTypes.func,
        startUploading: PropTypes.bool,
        startValidating: PropTypes.bool,
        updateFolderSelection: PropTypes.func,
        updateRemoteOrigin: PropTypes.func,
        uploadAsset: PropTypes.func,
        uploadMultiple: PropTypes.bool,
        visible: PropTypes.bool,
        assetmap: PropTypes.string,
        cpl: PropTypes.string,
        errors: PropTypes.array,
        essences: PropTypes.array,
        pkl: PropTypes.string
    };

    render() {
        return <ErrorBoundary>{this.doRender()}</ErrorBoundary>;
    }

    doRender() {
        const { isModal, visible } = this.props;

        return isModal ? this.renderModal() : visible ? this.renderFullPage() : null;
    }

    getSubmitLabel = () => {
        const { isComposition, impStep } = this.props;
        return isComposition && !(impStep === STEPS.SELECT_FOLDER || impStep === STEPS.VALIDATION_FAILED)
            ? 'Validating'
            : 'Upload';
    };

    renderForm() {
        const {
            attachmentMetadata,
            changeAttachmentMeta,
            changeType,
            changeReplacementMeta,
            cvType,
            files,
            fileSelected,
            fileType,
            folderSelectionAvailable,
            folderSelectionText,
            impStep,
            isAttachment,
            isComposition,
            isFolder,
            originalUploadAvailable,
            replacementMetadata,
            updateFolderSelection,
            asperaAvailable,
            canMultiFileUpload,
            revisionJustification,
            clearDestination,
            destination,
            destinationSelectionStep,
            Dropzone,
            openDrawer,
            remoteOrigin,
            remoteOriginAvailable,
            renderFilesOrFolder,
            updateRemoteOrigin,
            uploadMultiple,
            assetmap,
            cpl,
            errors,
            essences,
            pkl,
            filenames
        } = this.props;

        return (
            <UploadAssetForm
                asperaAvailable={asperaAvailable}
                assetmap={assetmap}
                assetType={fileType}
                attachmentMetadata={attachmentMetadata}
                canMultiFileUpload={canMultiFileUpload}
                changeAttachmentMeta={changeAttachmentMeta}
                changeReplacementMeta={changeReplacementMeta}
                clearDestination={clearDestination}
                cpl={cpl}
                destination={destination}
                destinationSelectionStep={destinationSelectionStep}
                Dropzone={Dropzone}
                errors={errors}
                essences={essences}
                filenames={filenames}
                filePickerVariant={isComposition ? 'imf' : isFolder ? 'folder' : 'file'}
                files={files}
                folderSelectionAvailable={folderSelectionAvailable}
                folderSelectionText={folderSelectionText}
                impStep={impStep}
                isAttachment={isAttachment}
                isComposition={isComposition}
                isFolder={isFolder}
                onChangeType={changeType}
                onFileChange={fileSelected}
                openDrawer={openDrawer}
                originalUploadAvailable={originalUploadAvailable}
                pkl={pkl}
                remoteOrigin={remoteOrigin}
                remoteOriginAvailable={remoteOriginAvailable}
                renderFilesOrFolder={renderFilesOrFolder}
                replacementMetadata={replacementMetadata}
                revisionJustification={revisionJustification}
                type={cvType}
                updateFolderSelection={updateFolderSelection}
                updateRemoteOrigin={updateRemoteOrigin}
                uploadMultiple={uploadMultiple}
            />
        );
    }

    renderModal() {
        const { files, hide, impStep, isComposition, onSubmit, fileType, startIMPValidationAndUpload, visible } =
            this.props;

        return (
            <FormDialog
                visible={visible}
                hide={hide}
                onSubmit={isComposition && fileType === FILETYPE.ORIGINAL ? startIMPValidationAndUpload : onSubmit}
                submitLabel={this.getSubmitLabel()}
                formLabel="Upload Asset"
                disabled={isComposition && impStep !== STEPS.SELECT_FOLDER && !isEmpty(files)}
            >
                {this.renderForm()}
            </FormDialog>
        );
    }

    renderFullPage() {
        const {
            addNotificationRecipient,
            classes,
            formRef,
            isComposition,
            onCancel,
            onSubmit,
            fileType,
            startIMPValidationAndUpload
        } = this.props;
        return (
            <>
                <Typography gutterBottom variant="h4">
                    Upload
                </Typography>
                <Grid container direction="row-reverse" spacing={32}>
                    <Grid item xs={12} lg={6}>
                        <UploadNotification addNotificationRecipient={addNotificationRecipient} classes={classes} />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <AsyncValidatorForm ref={formRef}>{this.renderForm()}</AsyncValidatorForm>
                    </Grid>
                </Grid>
                <Snackbar
                    action={
                        <Toolbar>
                            <Button
                                className={classes.button}
                                color="primary"
                                data-qa="Clear"
                                onClick={onCancel}
                                size="large"
                            >
                                <CancelIcon />
                                &nbsp;CANCEL
                            </Button>
                            <Button
                                className={classes.button}
                                color="primary"
                                data-qa="Upload"
                                onClick={
                                    isComposition && fileType === FILETYPE.ORIGINAL
                                        ? startIMPValidationAndUpload
                                        : onSubmit
                                }
                                size="large"
                            >
                                <CloudUploadIcon />
                                &nbsp;UPLOAD
                            </Button>
                        </Toolbar>
                    }
                    ContentProps={{
                        classes: {
                            action: classes.snackbarAction,
                            message: classes.snackbarMessage,
                            root: classes.snackbarRoot
                        }
                    }}
                    open={true}
                />
            </>
        );
    }
}
