import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import Slide from '@material-ui/core/Slide';

const slidesState = {
    slides: [
        {
            slide: false,
            direction: 'left',
            key: 1,
            content: null
        },
        {
            slide: true,
            direction: 'left',
            duration: { enter: 0 },
            key: 2,
            content: null
        },
        {
            slide: false,
            direction: 'left',
            key: 3,
            content: null
        }
    ]
};

const styles = theme => ({
    overflowHidden: {
        overflow: 'hidden'
    },
    container: {
        flexGrow: 1
    }
});

class ContentSlider extends React.Component {
    state = slidesState;

    constructor(props) {
        super();
        if (!React.Children.count(props.children) === 1 || !props.children.key) {
            throw new Error('A single children with `key` attribute must be provided');
        }
    }

    render() {
        const { slides } = this.state;
        const { classes } = this.props;
        return (
            <div className={classes.container}>
                {slides.map(slide => (
                    <Slide
                        key={slide.key}
                        direction={slide.direction}
                        in={slide.slide}
                        timeout={slide.duration}
                        mountOnEnter
                        unmountOnExit
                    >
                        <div>{slide.content}</div>
                    </Slide>
                ))}
            </div>
        );
    }

    setCurrentContent(content) {
        this.setState(state => ({
            slides: [
                state.slides[0],
                {
                    ...state.slides[1],
                    content
                },
                state.slides[2]
            ]
        }));
    }

    componentDidMount() {
        const { children } = this.props;
        this.setCurrentContent(children);
    }

    componentDidUpdate(prevProps) {
        const { children, slide } = this.props;
        if (children !== prevProps.children) {
            if (children.key !== prevProps.children.key) {
                if (slide === 'right') {
                    this.slideRight(children);
                } else {
                    this.slideLeft(children);
                }
            } else {
                this.setCurrentContent(children);
            }
        }
    }

    slideRight = content => {
        const { duration } = this.props;
        this.setState(state => {
            const [slide1, slide2, slide3] = state.slides;

            const newSlide1 = {
                ...slide2,
                slide: false,
                direction: 'right',
                duration
            };
            const newSlide2 = {
                ...slide3,
                slide: true,
                direction: 'left',
                duration,
                content
            };
            const newSlide3 = {
                ...slide1,
                slide: false,
                direction: 'left',
                duration
            };

            return { slides: [newSlide1, newSlide2, newSlide3] };
        });
    };

    slideLeft = content => {
        const { duration } = this.props;
        this.setState(state => {
            const [slide1, slide2, slide3] = state.slides;

            const newSlide1 = {
                ...slide3,
                slide: false,
                direction: 'right',
                duration
            };
            const newSlide2 = {
                ...slide1,
                slide: true,
                direction: 'right',
                duration,
                content
            };
            const newSlide3 = {
                ...slide2,
                slide: false,
                direction: 'left',
                duration
            };
            return { slides: [newSlide1, newSlide2, newSlide3] };
        });
    };
}

ContentSlider.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    slide: PropTypes.oneOf(['right', 'left']),
    duration: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.shape({
            enter: PropTypes.number,
            exit: PropTypes.number
        })
    ])
};

export default withStyles(styles)(ContentSlider);
