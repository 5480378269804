import { destinationTypeConfig } from 'lib/priorityConfig';
import { FacetsList } from 'wonderland-ui-discovery';
import { withStyles } from 'wonderland-ui-material';
import classnames from 'classnames';
import ClearIcon from '@material-ui/icons/HighlightOff';
import CloseIcon from '@material-ui/icons/Close';
import ContentSlider from 'app/shared/ContentSlider';
import Drawer from '@material-ui/core/Drawer';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import QuickFiltersComponent from 'app/shared/QuickFilters';
import React, { Component } from 'react';
import ResultsList from './ResultsList';
import Tooltip from '@material-ui/core/Tooltip';
import TopBar from 'app/shared/TopBar';
import Typography from '@material-ui/core/Typography';

/* istanbul ignore next */
const sheet = theme => ({
    appBarToplevel: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '65px',
        width: theme.spacing.unit * 96,
        height: '100%'
    },
    appBar: {
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        paddingBottom: theme.spacing.unit * 2,
        paddingLeft: theme.spacing.unit,
        top: theme.spacing.unit * 19
    },
    content: {
        display: 'flex',
        height: `calc(100% - ${theme.spacing.unit * 13}px)`,
        overflowY: 'auto',
        padding: theme.spacing.unit * 2
    },
    drawerPaper: {
        borderLeft: 'none',
        left: 0,
        padding: `0 1px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 2 + 1}px`,
        position: 'absolute',
        right: 0,
        width: theme.spacing.unit * 38
    },
    drawerRoot: {
        position: 'relative',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.short
        }),
        width: theme.spacing.unit * 40,
        '&.closed': {
            overflowX: 'hidden',
            padding: 0,
            width: 0
        }
    },
    paper: {
        height: '100%',
        overflowY: 'hidden'
    },
    results: {
        flexGrow: 1,
        overflow: 'auto'
    }
});

@withStyles(sheet)
export default class DestinationPickerSideSheetComponent extends Component {
    static propTypes = {
        onSelectDestination: PropTypes.func,
        breadCrumbs: PropTypes.arrayOf(PropTypes.object).isRequired,
        classes: PropTypes.object,
        lastDrilledItem: PropTypes.object.isRequired,
        clearAllCriteria: PropTypes.func,
        criteria: PropTypes.arrayOf(PropTypes.object).isRequired,
        customContentStyles: PropTypes.object,
        dense: PropTypes.bool,
        drillIntoItem: PropTypes.func.isRequired,
        isDestinationSelected: PropTypes.func,
        facetsDrawerIsOpen: PropTypes.bool,
        facetsDrawer: PropTypes.bool,
        loading: PropTypes.bool,
        onChangeTextFieldInputValue: PropTypes.func,
        popBreadCrumb: PropTypes.func,
        showQuickFilters: PropTypes.bool,
        results: PropTypes.arrayOf(PropTypes.object).isRequired,
        selectQuickFilter: PropTypes.func.isRequired,
        setFacetsDrawer: PropTypes.func.isRequired,
        slide: PropTypes.string.isRequired,
        slideIndex: PropTypes.number.isRequired,
        submitSearchTerm: PropTypes.func,
        textFieldInputValue: PropTypes.string,
        formatFacetOptions: PropTypes.arrayOf(PropTypes.object),
        closeDrawer: PropTypes.func,
        title: PropTypes.string,
        namingMap: PropTypes.object
    };
    render() {
        const {
            onSelectDestination,
            breadCrumbs,
            classes,
            clearAllCriteria,
            criteria,
            customContentStyles,
            dense,
            drillIntoItem,
            facetsDrawer = true,
            facetsDrawerIsOpen,
            isDestinationSelected,
            loading,
            onChangeTextFieldInputValue,
            popBreadCrumb,
            showQuickFilters = true,
            results,
            selectQuickFilter,
            setFacetsDrawer,
            slide,
            slideIndex,
            submitSearchTerm,
            textFieldInputValue,
            lastDrilledItem,
            formatFacetOptions,
            closeDrawer,
            title = 'Add a Destination',
            namingMap = {}
        } = this.props;

        const facetsDrawerAttributes = { closed: !facetsDrawerIsOpen };
        return (
            <div className={classes.appBarToplevel}>
                <div className={classes.appBar}>
                    <Typography variant="h5">{title}</Typography>
                    <div>
                        <Tooltip aria-label="Clear All" title="Clear All">
                            <IconButton onClick={clearAllCriteria}>
                                <ClearIcon color="secondary" />
                            </IconButton>
                        </Tooltip>
                        {facetsDrawer && (
                            <Tooltip aria-label="Filters" title="Filters">
                                <IconButton
                                    onClick={() => {
                                        setFacetsDrawer(!facetsDrawerIsOpen);
                                    }}
                                >
                                    <FilterListIcon color="secondary" />
                                </IconButton>
                            </Tooltip>
                        )}
                        <Tooltip aria-label="Close" title="Close">
                            <IconButton onClick={closeDrawer}>
                                <CloseIcon fontSize="small" color="secondary" />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>

                <Paper className={classes.paper}>
                    <TopBar
                        value={textFieldInputValue}
                        onChange={onChangeTextFieldInputValue}
                        onSubmit={submitSearchTerm}
                    />
                    {showQuickFilters && (
                        <QuickFiltersComponent
                            criteria={criteria}
                            selectQuickFilter={selectQuickFilter}
                            options={formatFacetOptions}
                            priorityMap={destinationTypeConfig}
                            buttons={['Provider', 'Location', 'Destination']}
                            namingMap={namingMap}
                        />
                    )}
                    <div className={classes.content} style={customContentStyles}>
                        <div className={classes.results}>
                            {loading && <LinearProgress key={slideIndex} />}
                            <ContentSlider slide={slide} duration={300}>
                                <ResultsList
                                    onSelectDestination={onSelectDestination}
                                    breadCrumbs={breadCrumbs}
                                    isDestinationSelected={isDestinationSelected}
                                    dense={dense}
                                    drillIntoItem={drillIntoItem}
                                    key={slideIndex}
                                    loading={loading}
                                    lastDrilledItem={lastDrilledItem}
                                    openFacets={() => setFacetsDrawer(true)}
                                    popBreadCrumb={popBreadCrumb}
                                    results={results}
                                />
                            </ContentSlider>
                        </div>
                        {facetsDrawer && (
                            <Drawer
                                variant="permanent"
                                anchor="right"
                                classes={{
                                    root: classnames(classes.drawerRoot, facetsDrawerAttributes),
                                    paper: classes.drawerPaper
                                }}
                                open={facetsDrawerIsOpen}
                            >
                                <FacetsList />
                            </Drawer>
                        )}
                    </div>
                </Paper>
            </div>
        );
    }
}
