import { autobind, depopulateMeta, ToastController, withProgress } from 'wonderland-ui-commons';
import { get, groupBy, includes, isEmpty, isNil, keyBy, mapValues, pick, set, tap, union } from 'lodash';
import { getSpecificTitles } from 'lib/smartCollectionUtils';
import { META_SOURCE, populateReplacement, RENDITIONS, TRANSFER_TYPE } from 'lib/assetUploadUtils';
import { WonderlandDomainAPI } from 'core/apis';
import config from 'app/config';
import pathLib from 'path';
import WonderlandDomainActions from './WonderlandDomainActions';

@autobind
class WonderlandDomainController {
    removeFromCollection(collectionId, assetId) {
        WonderlandDomainActions.removeFromCollectionRequested(collectionId, assetId);

        return WonderlandDomainAPI.removeFromCollection(collectionId, assetId).then(
            r => {
                ToastController.show(`Successfully removed asset '${r.name}' from the collection`);
            },
            e => {
                ToastController.showError(e);
                WonderlandDomainActions.removeFromCollectionFailed(e);
            }
        );
    }

    getRemoveAssetResult({ id, asset_type, collection_type }, parentId) {
        if (asset_type === 'attachment') {
            return WonderlandDomainAPI.deleteAttachment(id, parentId);
        } else if (asset_type !== 'collection') {
            return WonderlandDomainAPI.removeAsset(id);
        } else {
            switch (collection_type) {
                case 'shared': {
                    return WonderlandDomainAPI.removeSharedCollection(id);
                }
                case 'smart':
                    return WonderlandDomainAPI.removeSmartCollection(id);
                default:
                    return WonderlandDomainAPI.removeAsset(id);
            }
        }
    }

    removeAsset(asset, parentId) {
        const { id: assetId } = asset;
        WonderlandDomainActions.removeAssetRequested(assetId);

        const removeResult = this.getRemoveAssetResult(asset, parentId);
        return removeResult.then(
            () => WonderlandDomainActions.removeAssetCompleted(assetId),
            e => {
                ToastController.showError(e);
                WonderlandDomainActions.removeAssetFailed(e);
            }
        );
    }

    addToCollection(collectionId, assetId, label) {
        return WonderlandDomainAPI.addToCollection(collectionId, assetId).then(
            r => ToastController.show(`Successfully added asset '${r.name}' to collection '${label}'`),
            e => ToastController.showError(e)
        );
    }

    bulkAddToCollection(collectionId, type, label) {
        return WonderlandDomainAPI.bulkAddToCollection(collectionId, type).then(
            r =>
                ToastController.show(
                    `Your assets are being added to ${label} now. You'll be notified when this is complete.`
                ),
            e => ToastController.showError(e)
        );
    }

    shareAssets(collectionName, collectionDesc, assetIds, internalUsers, externalUsers, expirationDate) {
        return WonderlandDomainAPI.shareAssets(
            collectionName,
            collectionDesc,
            assetIds,
            internalUsers,
            externalUsers,
            expirationDate
        ).then(
            response => ToastController.show(`Successfully shared assets`),
            error => ToastController.showError(error)
        );
    }

    duplicateAsset(assetId, data) {
        return WonderlandDomainAPI.duplicateAsset(assetId, data).catch(e => {
            ToastController.showError(e);
            throw e;
        });
    }

    restoreAssetOptions(assetId, data) {
        return WonderlandDomainAPI.restoreAssetOptions(assetId).catch(e => {
            ToastController.showError(e);
            throw e;
        });
    }

    restoreAssetBulk(assets) {
        const asset_ids = assets.asset_ids;
        return WonderlandDomainAPI.restoreAssetBulk(asset_ids)
            .then(() => {
                ToastController.show('Restore request submitted.');
            })
            .catch(e => {
                ToastController.showError(e);
            });
    }

    async saveAsset(id, meta, security, rules, titleOptions, preCalculatedChanges = {}) {
        const depopulateObj = {
            ...config.cvSaasMapping,
            related_titles: 'related_titles'
        };

        const data = {};
        if (!isEmpty(meta)) {
            data.meta = {
                ...preCalculatedChanges,
                ...(config.cvSaas.enable
                    ? mapValues(meta, depopulateMeta(depopulateObj))
                    : {
                          ...meta,
                          ...(meta.related_titles ? { related_titles: meta.related_titles.map(t => t.id) } : {})
                      }),
                id
            };
        }
        if (!isNil(security)) {
            security.namespace = config.namespace;
            security.category = config.category.assets;
        }

        if (!isNil(get(security, 'aces'))) {
            data.security = {
                ...data.security,
                aces: security.aces.map(ace =>
                    pick(ace, ['namespace', 'category', 'subject_name', 'subject_type', 'access_type', 'grants'])
                )
            };
        }

        if (!isNil(get(security, 'policies'))) {
            data.security = {
                ...data.security,
                policies: security.policies.map(policy => policy.id)
            };
        }

        if (!isNil(get(security, 'rules'))) {
            data.security = {
                ...data.security,
                rules: security.rules
            };
        }

        if (!isEmpty(rules)) {
            set(data, 'meta.rules', rules);
        }

        const metaSpecificTitles = getSpecificTitles(get(meta, 'related_titles', []));
        if (!isEmpty(titleOptions) || !isEmpty(metaSpecificTitles)) {
            const computedTitleOptions = {
                ...titleOptions,
                specificTitles: union(get(titleOptions, 'specificTitles', []), metaSpecificTitles)
            };
            set(data, 'meta.titleOptions', computedTitleOptions);
        }

        if (!isEmpty(data)) {
            try {
                return await WonderlandDomainAPI.saveAsset(id, data);
            } catch (e) {
                ToastController.showError(e);
                throw new Error('Could not save asset.');
            }
        } else {
            return null;
        }
    }

    fetchAssetBase(id, opts = {}) {
        WonderlandDomainActions.fetchAssetBaseRequested(id);
        return WonderlandDomainAPI.findAssetById(id, opts).then(
            r => tap(r, () => WonderlandDomainActions.fetchAssetBaseCompleted(r)),
            e => {
                WonderlandDomainActions.fetchAssetBaseFailed(e);
                throw e;
            }
        );
    }
    @withProgress({ label: 'asset_details' })
    fetchAsset(id, opts = {}) {
        WonderlandDomainActions.fetchAssetRequested(id);
        return this.fetchAssetBase(id, opts).then(
            r =>
                tap(r, () => {
                    WonderlandDomainActions.fetchAssetCompleted(r);
                }),
            e => {
                ToastController.showError(e);
                WonderlandDomainActions.fetchAssetFailed(e);
            }
        );
    }

    exportReport(criteria, savedView, filteredFields) {
        const data = isNil(savedView)
            ? { ...criteria, filteredFields }
            : {
                  ...criteria,
                  mappedFields: mapValues(
                      keyBy(
                          savedView.tableListFields.filter(f => f.checked),
                          'property'
                      ),
                      'label'
                  ),
                  filteredFields
              };

        const message =
            "Your report is being prepared for download. You'll be notified when you can start the download.";
        return WonderlandDomainAPI.exportReport(data).then(
            () => {
                ToastController.show(message);
            },
            e => {
                ToastController.showError(e);
            }
        );
    }

    downloadArchiveFiles(assetId, fileNames) {
        WonderlandDomainActions.downloadArchiveFilesRequested(assetId, fileNames);

        return WonderlandDomainAPI.downloadArchiveFiles(assetId, fileNames).then(
            resp => {
                WonderlandDomainActions.downloadArchiveFilesCompleted(resp);
                ToastController.show(
                    "Your files are being prepared for download. You'll be notified when you can start the download."
                );
            },
            e => {
                WonderlandDomainActions.downloadArchiveFilesFailed(e);
                ToastController.showError(e);
            }
        );
    }

    @withProgress({ label: 'bulk_publish' })
    async bulkPublish(collectionId, asset_ids) {
        WonderlandDomainActions.startBulkPublishRequested();
        ToastController.show(
            "Your assets are being published now. You'll be notified when they're publicly available."
        );
        try {
            const resp = await WonderlandDomainAPI.bulkPublish(collectionId, asset_ids);
            ToastController.hide();
            WonderlandDomainActions.startBulkPublishCompleted(resp);
        } catch (e) {
            ToastController.showError(e);
            WonderlandDomainActions.startBulkPublishFailed(e);
        }
    }

    fetchServiceStatus() {
        WonderlandDomainActions.fetchServiceStatusRequested();

        return WonderlandDomainAPI.fetchStatus().then(
            r =>
                tap(r, () => {
                    WonderlandDomainActions.fetchServiceStatusCompleted(r);
                }),
            e => {
                ToastController.showError(e);
                WonderlandDomainActions.fetchServiceStatusFailed(e);
            }
        );
    }

    requestService(locationId, assetId, label, notes, users) {
        return WonderlandDomainAPI.requestService(locationId, assetId, notes, users).then(
            r => ToastController.show(`Successfully requested assets to location '${label}'`),
            e => ToastController.showError(e)
        );
    }

    generateSidecars(data) {
        return WonderlandDomainAPI.generateSidecars(data).then(
            r =>
                ToastController.show(
                    "Your sidecar(s) are being generated now. You'll be notified when you can start the download."
                ),
            e => ToastController.showError(e)
        );
    }

    bulkQc(asset_ids) {
        return WonderlandDomainAPI.bulkQc(asset_ids).then(
            r => ToastController.show(`${asset_ids.length} assets are now being processed for QC.`),
            e => ToastController.showError(e)
        );
    }

    resourceIngest({
        resource,
        asset,
        type,
        destination,
        notifications,
        unifiedIngest,
        extraMetadata,
        newAsset = true,
        opts: { isAttachment, parentId } = {}
    }) {
        if (!unifiedIngest) {
            return WonderlandDomainAPI.resourceIngest(
                {
                    resource,
                    id: asset.id,
                    type,
                    extraMetadata
                },
                { isAttachment, parentId }
            );
        }
        const assetIngest = {
            rendition: type,
            transfer_type: TRANSFER_TYPE.DESTINATION,
            ingest_specs: [{ resource, metadata: { source: META_SOURCE.ASSET_ID, asset_id: asset.id } }],
            notifications,
            user_name: asset.created_by,
            origin_id: destination.id,
            attributes: { new: newAsset, isAttachment, parentId }
        };

        if (!isEmpty(extraMetadata)) {
            populateReplacement(extraMetadata, assetIngest.ingest_specs[0].metadata, 'replacement');
        }
        return WonderlandDomainAPI.unifiedIngest(assetIngest);
    }

    async bulkResourceIngest({
        resources,
        uploadData,
        destination,
        files,
        user_name,
        meta,
        template_id,
        security,
        unifiedIngest
    }) {
        if (!unifiedIngest) {
            return await WonderlandDomainAPI.bulkResourceIngest({
                resources,
                ...uploadData
            });
        }
        const assetIngest = {
            rendition: RENDITIONS.ORIGINAL,
            transfer_type: TRANSFER_TYPE.DESTINATION,
            metadata: {
                source: template_id ? META_SOURCE.TEMPLATE : META_SOURCE.BULK,
                ...(template_id && { template_id }),
                meta,
                security
            },
            ingest_specs: files.map(file => ({
                resource: { name: file.name, location: file.location, folder: file.folder }
            })),
            user_name,
            origin_id: destination.id
        };
        return await WonderlandDomainAPI.unifiedIngest(assetIngest);
    }

    getMetaSourceForUploadType(uploadType, assetId, meta, security, template_id) {
        switch (uploadType) {
            case 'multi':
                return {
                    source: template_id ? META_SOURCE.TEMPLATE : META_SOURCE.BULK,
                    ...(template_id && { template_id }),
                    meta,
                    security
                };
            case 'single':
                return { source: META_SOURCE.ASSET_ID, asset_id: assetId };
            default:
                break;
        }
    }

    setResource(location, name, folder, ingest_spec) {
        if (includes(name, 'yml')) {
            ingest_spec['metadata'] = {
                source: 'sidecar',
                resource: {
                    location,
                    name,
                    folder
                }
            };
        } else {
            ingest_spec['resource'] = {
                location,
                name,
                folder
            };
        }
    }

    groupResources(resources) {
        return groupBy(resources, resource => {
            const source = resource?.source || resource?.name;
            const name = pathLib.parse(source).name;
            if (source.includes(name)) {
                return name;
            }
        });
    }

    async unifiedManagedIngest(data) {
        const { transferData, sidecar, imf, imfOptions, isAttachment, parentId } = data;
        const {
            assetId,
            transfer_type,
            fileType,
            spec,
            uploadType,
            meta,
            notifications,
            security,
            template_id,
            user_name
        } = transferData;
        const { paths, sources, id: transfer_id } = spec;
        const extraMetadata = _.get(transferData, 'extraMetadata');
        let assetIngest = {
            rendition: fileType || RENDITIONS.ORIGINAL,
            ...(!assetId &&
                !sidecar && {
                    metadata: this.getMetaSourceForUploadType(uploadType, assetId, meta, security, template_id)
                }),
            ingest_specs: [],
            notifications,
            user_name,
            transfer_id,
            attributes: {
                isAttachment,
                parentId,
                transferData,
                sidecar,
                imf,
                imfOptions,
                new: get(data, 'newAsset', get(transferData, 'newAsset', false))
            }
        };
        switch (transfer_type) {
            case 'aspera_connect':
                assetIngest.transfer_type = TRANSFER_TYPE.ASPERA_CONNECT;
                if (!sidecar) {
                    assetIngest.ingest_specs = paths.map(path => ({
                        resource: {
                            location: path.destination,
                            name: pathLib.basename(path.destination),
                            folder: path.folder
                        }
                    }));
                } else {
                    const groupedPath = this.groupResources(paths);
                    for (const key in groupedPath) {
                        let ingest_spec = {};
                        groupedPath[key].forEach(path => {
                            this.setResource(
                                path.destination,
                                pathLib.basename(path.destination),
                                path.folder,
                                ingest_spec
                            );
                        });
                        assetIngest.ingest_specs.push(ingest_spec);
                    }
                }
                break;
            case 'signed_s3':
                assetIngest.transfer_type = TRANSFER_TYPE.SIGNED_S3;
                if (!sidecar) {
                    assetIngest.ingest_specs = sources.map(source => ({
                        resource: {
                            location: source.key,
                            name: source.source,
                            folder: source.folder
                        }
                    }));
                } else {
                    const groupedSource = this.groupResources(sources);
                    for (const key in groupedSource) {
                        let ingest_spec = {};
                        groupedSource[key].forEach(source => {
                            this.setResource(source.key, source.source, source.folder, ingest_spec);
                        });
                        assetIngest.ingest_specs.push(ingest_spec);
                    }
                }
                break;
            default:
                break;
        }
        assetId &&
            set(
                assetIngest,
                'ingest_specs[0].metadata',
                this.getMetaSourceForUploadType(uploadType, assetId, meta, security, template_id)
            );
        if (!isEmpty(extraMetadata)) {
            populateReplacement(extraMetadata, assetIngest.ingest_specs[0].metadata, 'replacement');
        }
        return await WonderlandDomainAPI.unifiedIngest(assetIngest);
    }

    sidecarBulkUpload({ files, destination, unifiedIngest, user_name }) {
        if (!unifiedIngest) {
            return WonderlandDomainAPI.sidecarResourceIngest({
                resources: files,
                type: 'original'
            });
        }

        const assetIngest = {
            rendition: RENDITIONS.ORIGINAL,
            transfer_type: TRANSFER_TYPE.DESTINATION,
            origin_id: _.get(destination, 'id'),
            ingest_specs: [],
            user_name
        };

        const groupedSource = this.groupResources(files);

        for (const key in groupedSource) {
            let ingest_spec = {};
            groupedSource[key].forEach(source => {
                this.setResource(source.location, source.name, source.folder, ingest_spec);
            });
            assetIngest.ingest_specs.push(ingest_spec);
        }
        return WonderlandDomainAPI.unifiedIngest(assetIngest);
    }
}

export default new WonderlandDomainController();
