import { compose } from 'recompose';
import DistributionListContactsHeaderComponent from './DistributionListContactsHeaderComponent';
import withStyles from '@material-ui/core/styles/withStyles';

export default compose(
    withStyles(({ spacing: { unit } }) => ({
        buttonGroup: {
            padding: 0,
            width: unit * 18
        }
    }))
)(DistributionListContactsHeaderComponent);
