import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import RelatedCard from 'app/Relationship/RelatedCard';
import Typography from '@material-ui/core/Typography';

const RelatedParentsComponent = ({
    assets,
    classes,
    handleRemoveParent,
    handleSelectParent,
    handleSelectRelationshipType,
    imageMode,
    metadataMode,
    relationshipType,
    selectedParentId,
    typeNames,
    types
}) => {
    const { gridList, noAssetsMessage } = classes;

    return (
        <Grid alignItems="center" className={gridList} container direction="column" wrap="nowrap">
            {assets.length ? (
                assets.map((asset, idx) => {
                    const { id } = asset;
                    const hidden = selectedParentId && id !== selectedParentId;
                    const selected = id === selectedParentId;
                    const removeParent = () => handleRemoveParent(id);
                    const toggleSelected = () => handleSelectParent(id);

                    return (
                        !hidden && (
                            <Grid item key={idx}>
                                <RelatedCard
                                    asset={asset}
                                    classes={classes}
                                    group={'parent'}
                                    handleRemoveParent={removeParent}
                                    handleSelectRelationshipType={handleSelectRelationshipType}
                                    toggleSelected={toggleSelected}
                                    imageMode={imageMode}
                                    metadataMode={metadataMode}
                                    relationshipType={relationshipType}
                                    selected={selected}
                                    typeNames={typeNames}
                                    types={types}
                                />
                            </Grid>
                        )
                    );
                })
            ) : (
                <Typography align="center" className={noAssetsMessage} variant="h6">
                    No Parents to Display
                </Typography>
            )}
        </Grid>
    );
};

RelatedParentsComponent.propTypes = {
    assets: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    handleRemoveParent: PropTypes.func.isRequired,
    handleSelectParent: PropTypes.func.isRequired,
    handleSelectRelationshipType: PropTypes.func.isRequired,
    imageMode: PropTypes.bool.isRequired,
    metadataMode: PropTypes.bool.isRequired,
    relationshipType: PropTypes.string,
    selectedParentId: PropTypes.string,
    typeNames: PropTypes.object.isRequired,
    types: PropTypes.array.isRequired
};

export default RelatedParentsComponent;
