import { AsperaService, withAsperaDownload } from 'wonderland-ui-aspera';
import { compose, mapProps, withHandlers, withProps } from 'recompose';
import { enableOnTags, withHttpDownload } from 'wonderland-ui-commons';
import { omit } from 'lodash';
import { withS3Download } from 'wonderland-ui-s3';

import DownloadController from './DownloadController';
import withFallback from './withFallback';

export default ({
    key = 'download',
    httpDownloadTransferSpecProvider,
    httpDownloadSettings,
    managedDownloadTransferSpecProvider,
    disableAsperaForRequest,
    disableSignedUrlsForRequest
} = {}) =>
    compose(
        enableOnTags('external')(withProps({ externalUser: true })),
        withAsperaDownload({ downloadTransferSpecProvider: managedDownloadTransferSpecProvider }),
        withS3Download({ downloadTransferSpecProvider: managedDownloadTransferSpecProvider }),
        withHttpDownload({ downloadTransferSpecProvider: httpDownloadTransferSpecProvider, httpDownloadSettings }),
        withHandlers({
            [key]:
                ({ signedUrlsEnabled, downloadWithAspera, downloadWithS3, downloadWithHttp, externalUser }) =>
                ({ multifile, ...args }) => {
                    const useAspera = !disableAsperaForRequest && AsperaService.connected;
                    const ignoreSizeValidation = useAspera || externalUser;
                    const downloadWithS3WithFallback = multifile
                        ? DownloadController.multifileResourceDownload
                        : withFallback(
                              !disableSignedUrlsForRequest && signedUrlsEnabled,
                              downloadWithS3,
                              downloadWithHttp
                          );
                    const downloadWithAsperaWithFallback = withFallback(
                        useAspera,
                        downloadWithAspera,
                        downloadWithS3WithFallback,
                        { fallbackOnError: true }
                    );
                    const opts = { ...args, type: multifile ? 'multifileResource' : 'ids' };
                    return DownloadController.validateAndDownload(
                        opts,
                        downloadWithAsperaWithFallback,
                        ignoreSizeValidation
                    );
                }
        }),
        mapProps(props => omit(props, ['downloadWithAspera', 'downloadWithS3', 'downloadWithHttp']))
    );
