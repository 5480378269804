import { compact, get, isEmpty, mapValues } from 'lodash';
import baseCriteriaSchemaValidator from '../lib/jsonSchemas/baseCriteriaSchema.json';
import baseThumbnailCriteriaSchemaValidator from '../lib/jsonSchemas/baseThumbnailCriteriaSchema.json';
import ControlledVocabularyController from 'core/ControlledVocabulary/ControlledVocabularyController';
import optionsCriteriaSchemaValidator from '../lib/jsonSchemas/optionsCriteriaSchema.json';
import prioritizedCriteriaSchemaValidator from '../lib/jsonSchemas/prioritizedCriteriaSchema.json';
import React from 'react';
import SmartTagOption from 'app/shared/SmartCollection/SmartTagPriorityRuleOption';
import SmartTagTabularValue from 'app/shared/SmartCollection/SmartTagPriorityRuleTabularValue';
import ValidationHandler from 'wonderland-ui-commons/lib/Forms/Handler/ValidationHandler';
import ValidationProvider from 'wonderland-ui-commons/lib/Forms/Provider/ValidationProvider';

export class MinPopulateRuleHandler extends ValidationHandler {
    constructor() {
        super('hasMinPopulateRule');
        this.requiredRuleTypes = ['priorityCriteria', 'smartTagPriorityRule'];
    }

    hasAtLeastOnePopulateRule(value) {
        return isEmpty(value) || value.some(({ type }) => this.requiredRuleTypes.includes(type));
    }

    _getValidator(context, field) {
        return value =>
            !this.hasAtLeastOnePopulateRule(value)
                ? `Collection must have at least one rule of the following types ${this.requiredRuleTypes.join(', ')} `
                : null;
    }
}

const customValidationProvider = new ValidationProvider();
customValidationProvider.registerHandlers([new MinPopulateRuleHandler()]);
export const smartCollectionRuleProviders = [customValidationProvider];

export const smartTagOptionFormatter = value => value && <SmartTagOption {...value} />;
export const smartTagTabularFormatter = value => value && <SmartTagTabularValue {...value} />;

const titleFormatFacetMap = {
    Feature: 'feature_series_title',
    Series: 'feature_series_title',
    Season: 'season_title',
    Episode: 'episode_title',
    Version: 'version_title'
};

export function getTitleCriteria(ancestors = [], hide = false) {
    return ancestors.map(({ name, title_format }) => ({
        hide,
        type: 'facet',
        name: titleFormatFacetMap[title_format] || 'title_name',
        values: [name]
    }));
}

export function isSmartCollection(asset) {
    return get(asset, 'asset_type', '') === 'collection' && get(asset, 'collection_type', '') === 'smart';
}

export const SMART_COLLECTION_TITLES_LABEL = 'Collection Title(s)';

export function getSpecificTitles(relatedTitles) {
    return compact(relatedTitles.map(({ id, isSpecific }) => isSpecific && id));
}

export function formatRules(rules = []) {
    return rules.map(rule => {
        const { type, config } = rule;
        switch (type) {
            case 'priorityCriteria':
            case 'thumbnailCriteria':
                return mapValues(rule, ruleValue => get(ruleValue, 'jsObject', ruleValue));
            case 'smartTagPriorityRule':
                const {
                    smartTagPriorityRule: { id }
                } = rule;
                return { type, id, config };
            default:
                return rule;
        }
    });
}

export const collectionRulesFormField = {
    name: 'rules',
    label: 'Collection Rules',
    type: 'tabular',
    displayNonPopulatedFields: false,
    validators: ['hasMinPopulateRule'],
    schema: [
        {
            name: 'type',
            label: 'Type',
            type: 'dropdown',
            options: [
                'excludeAssets',
                'includeAssets',
                'priorityCriteria',
                'thumbnailCriteria',
                'smartTagPriorityRule'
            ],
            fullWidth: true,
            required: true,
            available: true
        },
        {
            name: 'name',
            label: 'Name',
            type: 'text',
            fullWidth: true,
            required: {
                type: 'required_for',
                field: 'type',
                value: ['excludeAssets', 'includeAssets', 'priorityCriteria', 'thumbnailCriteria']
            },
            available: {
                type: 'available_for',
                field: 'type',
                value: ['excludeAssets', 'includeAssets', 'priorityCriteria', 'thumbnailCriteria']
            }
        },
        {
            group: 'Smart Tag P Rule',
            name: 'smartTagPriorityRule',
            formatter: smartTagTabularFormatter,
            required: { type: 'required_for', field: 'type', value: ['smartTagPriorityRule'] },
            available: { type: 'available_for', field: 'type', value: ['smartTagPriorityRule'] },
            label: 'Smart Tag Priority Rule',
            closeMenuOnSelect: true,
            defaultOptions: true,
            loadOptions: async filter => {
                return ControlledVocabularyController.fetchSmartTagPriorityRules(filter);
            },
            getOptionLabel: smartTagOptionFormatter,
            getOptionValue: rule => rule.id,
            type: 'typeahead'
        },
        {
            name: 'ids',
            label: 'Asset Ids',
            type: 'tags',
            required: { type: 'required_for', field: 'type', value: ['excludeAssets', 'includeAssets'] },
            fullWidth: true,
            available: { type: 'available_for', field: 'type', value: ['excludeAssets', 'includeAssets'] }
        },
        {
            name: 'config',
            label: 'Config',
            type: 'json',
            fullWidth: true,
            required: false,
            available: false,
            options: {
                background: '#F3F5F6',
                height: '130px',
                placeholder: {}
            }
        },
        {
            name: 'options',
            label: 'Options',
            type: 'json',
            fullWidth: true,
            required: false,
            available: { type: 'available_for', field: 'type', value: ['priorityCriteria'] },
            validators: ['validateJsonSchema'],
            options: {
                background: '#F3F5F6',
                height: '130px',
                placeholder: { groupByField: null },
                validationSchema: optionsCriteriaSchemaValidator
            }
        },
        {
            name: 'baseCriteria',
            label: 'Base Criteria',
            type: 'json',
            fullWidth: true,
            required: { type: 'required_for', field: 'type', value: ['priorityCriteria'] },
            available: { type: 'available_for', field: 'type', value: ['priorityCriteria'] },
            validators: ['validateJsonSchema'],
            options: {
                background: '#F3F5F6',
                height: '130px',
                placeholder: {
                    match_metadata: {}
                },
                validationSchema: baseCriteriaSchemaValidator
            }
        },
        {
            name: 'baseThumbnailCriteria',
            label: 'Base Criteria',
            type: 'json',
            fullWidth: true,
            required: false,
            available: { type: 'available_for', field: 'type', value: ['thumbnailCriteria'] },
            validators: ['validateJsonSchema'],
            options: {
                background: '#F3F5F6',
                height: '130px',
                defaultPlaceholderValue: true,
                placeholder: {
                    match_metadata: {
                        asset_type: 'image',
                        file_type: { '@regex': { pattern: '.*image.*' } }
                    }
                },
                validationSchema: baseThumbnailCriteriaSchemaValidator
            }
        },
        {
            name: 'prioritizedCriteria',
            label: 'Prioritized Criteria',
            type: 'json',
            fullWidth: true,
            required: {
                type: 'required_for',
                field: 'type',
                value: ['priorityCriteria', 'thumbnailCriteria']
            },
            available: {
                type: 'available_for',
                field: 'type',
                value: ['priorityCriteria', 'thumbnailCriteria']
            },
            validators: ['validateJsonSchema'],
            options: {
                background: '#F3F5F6',
                height: '250px',
                placeholder: [
                    {
                        name: 'Rule 1',
                        priority: 1,
                        criteria: {
                            match_metadata: {}
                        }
                    }
                ],
                validationSchema: prioritizedCriteriaSchemaValidator
            }
        }
    ],
    required: true,
    available: true,
    edit: true
};

export const formatToChildLableMap = {
    Feature: 'Children',
    Series: 'Seasons',
    Season: 'Episodes',
    Episode: 'Versions'
};
