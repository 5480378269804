import { xhr } from 'wonderland-ui-commons';
import config from 'app/config';

const prefix = '/inventory/v1';

function getPreview(id) {
    return xhr.json(`${config.apiHostname}${prefix}/inventory/preview/${id}`, {
        method: 'get'
    });
}

export default {
    getPreview
};
