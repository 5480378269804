import { compose, withHandlers } from 'recompose';
import { get, set } from 'lodash';
import { withProfile } from 'core/Profile';

const imageModePath = 'preferences.searchResultPreferences.imageMode';

export default () =>
    compose(
        withProfile(({ profile }) => ({
            imageMode: get(profile, imageModePath)
        })),
        withHandlers({
            updateImageMode:
                ({ saveProfile, imageMode: prevImageMode }) =>
                nextImageMode => {
                    if (prevImageMode !== nextImageMode) {
                        return saveProfile(set({}, imageModePath, nextImageMode));
                    }
                }
        })
    );
