import { compose, withHandlers } from 'recompose';
import { get, isEqual, set } from 'lodash';
import { withProfile } from 'core/Profile';

export default (facetsPreferencesPath = 'preferences.facetsPreferences') =>
    compose(
        withProfile(({ profile }) => ({
            facetsPreferences: get(profile, facetsPreferencesPath)
        })),
        withHandlers({
            updateFacetsPreferences:
                ({ saveProfile, facetsPreferences: prevFacetsPreferences }) =>
                nextFacetsPreferences => {
                    if (!isEqual(prevFacetsPreferences !== nextFacetsPreferences)) {
                        return saveProfile(set({}, facetsPreferencesPath, nextFacetsPreferences));
                    }
                }
        })
    );
