import { autobind, withProgress } from 'wonderland-ui-commons';
import { noop } from 'lodash';
import { ValidatorForm } from 'react-material-ui-form-validator';
import asyncValidation from 'lib/asyncValidation';
import PropTypes from 'prop-types';
import React from 'react';

@autobind
export default class AsyncValidatorFormContainer extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func
    };

    static defaultProps = {
        onSubmit: noop
    };

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
    }

    @withProgress({ label: 'async_validation' })
    isFormValid = () =>
        (async () => {
            asyncValidation.clearAsyncValidationsQueue();
            asyncValidation.clearAsyncValidationsValues();

            let valid = this.formRef.current.isFormValid(false);

            if (!valid) {
                return false;
            }

            if (asyncValidation.asyncValidationsQueued()) {
                await asyncValidation.waitForAsyncValidations();
                asyncValidation.clearAsyncValidationsQueue();
                valid = this.formRef.current.isFormValid(false);
                asyncValidation.clearAsyncValidationsValues();
            }

            return valid;
        })();

    submit = () =>
        (async () => {
            if (await this.isFormValid()) {
                this.formRef.current.submit();
            }
        })();

    render() {
        return <ValidatorForm instantValidate={false} ref={this.formRef} {...this.props} />;
    }
}
