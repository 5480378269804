import { buildType, updateEditableTabulars } from 'lib/schemaUtils';
import { compact, filter, flatMap, get, isEmpty, keyBy, map, union, uniqBy } from 'lodash';
import getBulkEditFields from 'lib/getBulkEditFields';
import getDetailFields from 'lib/getDetailFields';
import getEditFields from 'lib/getEditFields';

const fieldTransformFnMap = {
    assetCreate: getEditFields,
    assetView: getDetailFields,
    assetEdit: getEditFields,
    collectionView: getDetailFields,
    collectionEdit: getEditFields,
    bulkEdit: getBulkEditFields
};

const fieldDataMapper = (indexedFieldSchemas, prefix) => change =>
    indexedFieldSchemas[change.name] && {
        ...change,
        fieldData: {
            ...indexedFieldSchemas[change.name],
            name: prefix ? `${prefix}.${change.name}` : change.name,
            schema: get(indexedFieldSchemas[change.name], 'schema', []).map(f => ({ ...f, formatted: true }))
        }
    };

export const defaultOrderedFields = (indexedFieldSchemas, orderedMeta, prefix) =>
    compact(orderedMeta.map(fieldDataMapper(indexedFieldSchemas, prefix)));

export const getFormFieldData = (schemas, asset, editType, orderedMeta, prefix) => {
    const assetType = get(asset, 'asset_type');
    if (!assetType) {
        return [];
    }
    const indexedFieldSchemas = getIndexedFieldSchemas(
        editType,
        assetType,
        asset,
        get(schemas, [assetType, 'groups'], [])
    );
    const updatedOrderedMeta = uniqBy(
        [
            ...orderedMeta.map(field =>
                indexedFieldSchemas[field.name] ? { ...field, ...indexedFieldSchemas[field.name] } : field
            ),
            ...map(indexedFieldSchemas)
        ],
        m => m.name
    );
    return defaultOrderedFields(indexedFieldSchemas, updatedOrderedMeta, prefix);
};

export function getIndexedFieldSchemas(viewType, assetType, ctx, fieldGroups = []) {
    if (isEmpty(fieldGroups)) {
        return {};
    }
    const viewability = {
        assetCreate: field => field.inputable,
        assetEdit: field => field.editable,
        assetView: field => field.readonly || field.editable || field.inputable,
        bulkEdit: field => field.editable,
        collectionEdit: field => field.editable,
        collectionView: field => field.readonly || field.editable || field.inputable
    };

    const indexedFields = flatMap(fieldGroups, ({ label, fields }) => {
        return fields.filter(viewability[viewType]).map(f => ({
            ...f,
            schema: compact(
                get(f, 'fields', []).map(tabularField => {
                    const updatedTabularField = updateEditableTabulars(tabularField, assetType, viewType);
                    return viewability[viewType](updatedTabularField)
                        ? { ...updatedTabularField, type: buildType(updatedTabularField) }
                        : null;
                })
            ),
            group: label,
            type: buildType(f)
        }));
    });

    const fieldSchemas =
        viewType !== 'assetCreate'
            ? fieldTransformFnMap[viewType](indexedFields, assetType, ctx).filter(
                  f => !['content_categories', 'files'].includes(f.name)
              )
            : fieldTransformFnMap[viewType](indexedFields, assetType, ctx);
    return keyBy(
        fieldSchemas.map((field, index) => ({ ...field, sortOrder: index + 1 })),
        'name'
    );
}

export function isRequired(schema) {
    return schema.required;
}

export function getRequiredIndexedFieldSchemas(viewType, assetType, ctx, fieldGroups) {
    return filter(getIndexedFieldSchemas(viewType, assetType, ctx, fieldGroups), isRequired);
}

export function extractFieldSchemas(groupSchemas) {
    return union(...groupSchemas.map(({ fields }) => fields));
}
