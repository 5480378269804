import { xhr } from 'wonderland-ui-commons';
import config from 'app/config';

const prefix = '/user-data/v1';

function createSavedSearch(data) {
    return xhr.json(`${config.apiHostname}${prefix}/search`, {
        method: 'post',
        body: JSON.stringify(data)
    });
}

function createSavedView(data) {
    return xhr.json(`${config.apiHostname}${prefix}/view`, {
        method: 'post',
        body: JSON.stringify(data)
    });
}

function fetchSavedSearches() {
    return xhr.json(`${config.apiHostname}${prefix}/search/${config.namespace}`, {
        method: 'get'
    });
}

function fetchFeatureFlags() {
    return xhr.json(`${config.platformHostname}${prefix}/feature/flag`, { method: 'get' });
}

function fetchSavedViews() {
    return xhr.json(`${config.apiHostname}${prefix}/view/${config.namespace}`, {
        method: 'get'
    });
}

function removeSavedSearch(id) {
    return xhr.json(`${config.apiHostname}${prefix}/search/${id}`, {
        method: 'delete'
    });
}

function removeSavedView(id) {
    return xhr.json(`${config.apiHostname}${prefix}/view/${id}`, {
        method: 'delete'
    });
}

function subscribeToSavedSearch(subscriptionInfo) {
    const { saved_search, frequency } = subscriptionInfo;
    return xhr.json(`${prefix}/search/${saved_search}/subscribe`, {
        method: 'post',
        body: JSON.stringify({ frequency })
    });
}

function unsubscribeToSavedSearch(savedSearchId) {
    return xhr.json(`${prefix}/search/${savedSearchId}/unsubscribe`, {
        method: 'delete'
    });
}

function updateSavedSearch(id, data) {
    return xhr.json(`${config.apiHostname}${prefix}/search/${id}`, {
        method: 'put',
        body: JSON.stringify(data)
    });
}

function updateUserProfile(name, data) {
    return xhr.json(`${config.appHostname}${prefix}/profile/${name}/wonderland`, {
        method: 'put',
        body: JSON.stringify(data)
    });
}

function bulkUpdateUsersProfiles(data) {
    return xhr.json(`${config.appHostname}${prefix}/usersprofiles/${config.namespace}`, {
        method: 'put',
        body: JSON.stringify(data)
    });
}

export default {
    createSavedSearch,
    createSavedView,
    fetchFeatureFlags,
    fetchSavedSearches,
    fetchSavedViews,
    removeSavedSearch,
    removeSavedView,
    subscribeToSavedSearch,
    unsubscribeToSavedSearch,
    updateSavedSearch,
    updateUserProfile,
    bulkUpdateUsersProfiles
};
