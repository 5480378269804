import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import RelatedChildrenByType from 'app/Relationship/RelatedChildren/RelatedChildrenByType';
import Typography from '@material-ui/core/Typography';

const RelatedChildrenComponent = ({
    assets,
    childLoading,
    classes,
    handleRemoveChildren,
    handleSelectChild,
    imageMode,
    metadataMode,
    relationshipType,
    selectedChildIds,
    selectedParentId,
    typeNames,
    types
}) => {
    const { gridList, noAssetsMessage, progress } = classes;
    const childCount = assets.length;
    const noAssetMessage = !childCount && !selectedParentId ? 'No Parent Selected' : 'No Children to Display';
    const showChildren = !childLoading && !!selectedParentId && !!childCount;
    const showNoAssetsMessage = !childLoading && !childCount;

    return (
        <Grid className={gridList} container direction="column" wrap="nowrap">
            <Grid item xs={12}>
                {showChildren ? (
                    types.map((currType, key) => (
                        <RelatedChildrenByType
                            assets={assets}
                            classes={classes}
                            currType={currType}
                            handleRemoveChildren={handleRemoveChildren}
                            handleSelectChild={handleSelectChild}
                            imageMode={imageMode}
                            key={key}
                            metadataMode={metadataMode}
                            noAssetMessage={noAssetMessage}
                            relationshipType={relationshipType}
                            selectedChildIds={selectedChildIds}
                            selectedParentId={selectedParentId}
                            typeNames={typeNames}
                            types={types}
                        />
                    ))
                ) : showNoAssetsMessage ? (
                    <Typography align="center" className={noAssetsMessage} variant="h6">
                        {noAssetMessage}
                    </Typography>
                ) : childLoading ? (
                    <CircularProgress className={progress} color="secondary" size={60} />
                ) : null}
            </Grid>
        </Grid>
    );
};

RelatedChildrenComponent.propTypes = {
    assets: PropTypes.array,
    childLoading: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    handleRemoveChildren: PropTypes.func.isRequired,
    handleSelectChild: PropTypes.func.isRequired,
    imageMode: PropTypes.bool.isRequired,
    metadataMode: PropTypes.bool.isRequired,
    relationshipType: PropTypes.string,
    selectedChildIds: PropTypes.array,
    selectedParentId: PropTypes.string,
    typeNames: PropTypes.object.isRequired,
    types: PropTypes.array.isRequired
};

export default RelatedChildrenComponent;
