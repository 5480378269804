import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { DynamicSliceReducer, storeHolder } from 'react-redux-boilerout';
import { reducer as formReducer } from 'redux-form';

import { actionsToEventsMiddleware } from './events';

import { actionStream as asperaActionStream, eventsNamespace as asperaEventsNamespace } from 'wonderland-ui-aspera';
import {
    actionStream as commonsActionStream,
    eventsNamespace as commonsEventsNamespace,
    importActionsEnhancer,
    reduxDevtools
} from 'wonderland-ui-commons';
import {
    actionStream as notificationsActionStream,
    eventsNamespace as notificationsEventsNamespace
} from 'wonderland-ui-notifications';
import { actionStream as s3ActionStream, eventsNamespace as s3EventsNamespace } from 'wonderland-ui-s3';
import {
    actionStream as securityActionStream,
    eventsNamespace as securityEventsNamespace
} from 'wonderland-ui-security';
import config from 'app/config';

const { eventsNamespace: appEventsNamespace } = config;

const enhancers = compose(
    storeHolder,
    importActionsEnhancer({
        actionStreams: [
            securityActionStream,
            commonsActionStream,
            asperaActionStream,
            s3ActionStream,
            notificationsActionStream
        ]
    }),
    applyMiddleware(
        actionsToEventsMiddleware({
            namespaces: [
                appEventsNamespace,
                securityEventsNamespace,
                commonsEventsNamespace,
                asperaEventsNamespace,
                s3EventsNamespace,
                notificationsEventsNamespace
            ]
        })
    ),

    // MUST BE LAST ENHANCER OTHERWISE BAD THINGS MIGHT HAPPEN
    reduxDevtools()
);

const reducerRegistry = new DynamicSliceReducer();
const store = createStore(combineReducers({ slices: reducerRegistry.reducer(), form: formReducer }), enhancers);

export { store, reducerRegistry };
