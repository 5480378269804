import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import RelatedChild from 'app/Relationship/RelatedChildren/RelatedChild';
import Typography from '@material-ui/core/Typography';

const RelatedChildrenByTypeComponent = ({
    assets,
    classes,
    currType,
    handleRemoveChildren,
    handleSelectChild,
    imageMode,
    metadataMode,
    noAssetMessage,
    selectedChildIds,
    relationshipType,
    selectedParentId,
    typeNames,
    types
}) => {
    const { expansionPanel, noAssetsMessage } = classes;
    const assetsByType = assets.filter(({ type }) => type === currType);
    const assetCount = assetsByType.length;
    const expanded = relationshipType === currType;

    return (
        <ExpansionPanel classes={{ root: expansionPanel }} expanded={expanded}>
            <ExpansionPanelSummary expandIcon={<Icon>expand_more</Icon>}>
                <Typography color="textSecondary" variant="h6">
                    {typeNames[currType]} ({assetCount})
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                {assetCount ? (
                    <Grid container>
                        {assetsByType.map((asset, key) => (
                            <RelatedChild
                                asset={asset}
                                classes={classes}
                                handleRemoveChildren={handleRemoveChildren}
                                handleSelectChild={handleSelectChild}
                                imageMode={imageMode}
                                key={key}
                                metadataMode={metadataMode}
                                relationshipType={relationshipType}
                                selectedChildIds={selectedChildIds}
                                selectedParentId={selectedParentId}
                                typeNames={typeNames}
                                types={types}
                            />
                        ))}
                    </Grid>
                ) : (
                    <Typography align="center" className={noAssetsMessage} variant="subtitle1">
                        {noAssetMessage}
                    </Typography>
                )}
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

RelatedChildrenByTypeComponent.propTypes = {
    assets: PropTypes.array,
    classes: PropTypes.object.isRequired,
    currType: PropTypes.string.isRequired,
    handleRemoveChildren: PropTypes.func.isRequired,
    handleSelectChild: PropTypes.func.isRequired,
    imageMode: PropTypes.bool.isRequired,
    metadataMode: PropTypes.bool.isRequired,
    noAssetMessage: PropTypes.string.isRequired,
    relationshipType: PropTypes.string,
    selectedChildIds: PropTypes.array,
    selectedParentId: PropTypes.string,
    typeNames: PropTypes.object.isRequired,
    types: PropTypes.array.isRequired
};

export default RelatedChildrenByTypeComponent;
