import _ from 'lodash';
import assetTypeAvatar from 'lib/assetTypeAvatar';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import withStyles from '@material-ui/core/styles/withStyles';

/* istanbul ignore next */
const sheet = theme => ({
    ...theme.palette.assetTypeColor,
    chipLabel: {
        fontSize: '0.625rem',
        lineHeight: 1,
        maxWidth: theme.spacing.unit * 34,
        overflow: 'hidden',
        padding: theme.spacing.unit / 2,
        textTransform: 'uppercase',
        userSelect: 'all'
    },
    chipRoot: {
        borderRadius: theme.spacing.unit / 2,
        height: 'auto',
        marginBottom: theme.spacing.unit / 2,
        marginRight: theme.spacing.unit / 2
    }
});

@withStyles(sheet)
export default class AttributeChipComponent extends React.Component {
    static propTypes = {
        assetType: PropTypes.string,
        attr: PropTypes.string,
        label: PropTypes.string,
        classes: PropTypes.object,
        desc: PropTypes.string,
        value: PropTypes.any,
        tooltip: PropTypes.bool
    };

    doRender() {
        const { assetType, attr, classes, desc, value, label, tooltip } = this.props;
        const color = assetTypeAvatar.getColor(assetType);
        let newValue = _.get(value, 'value', value);

        if (desc) {
            newValue = `${newValue}: ${desc}`;
        }
        const chip = (
            <Chip
                className={classes[color]}
                classes={{
                    label: classes.chipLabel,
                    root: classes.chipRoot
                }}
                color="primary"
                key={`${attr}-${newValue}`}
                label={newValue}
            />
        );
        return tooltip ? <Tooltip title={label}>{chip}</Tooltip> : chip;
    }

    render() {
        try {
            return this.doRender();
        } catch (e) {
            console.error('AttributeChipComponent::render', e);
        }
        return null;
    }
}
