import { urlParams, xhr } from 'wonderland-ui-commons';
import config from 'app/config';
import isEmpty from 'lodash/isEmpty';

const prefix = '/asset-store/v1';

function thumbnailUrl({ asset_type, id, thumbnail_id, updated_at = '' }, cache_ok = true) {
    const thumbnailId = thumbnail_id || id;
    const params = urlParams({ __c: updated_at, asset_type, cache_ok });
    return `${config.apiHostname}${prefix}/thumbnail/${thumbnailId}?${params}`;
}

function previewUrl({ id, updated_at = '', version = '' }) {
    const params = urlParams({ __c: updated_at, version });
    return `${config.apiHostname}${prefix}/preview/${id}?${params}`;
}

function attachmentPreviewUrl({ parentId, attachmentId }) {
    return `${prefix}/preview/${parentId}/attachments/${attachmentId}`;
}

function getPreview(previewUrl) {
    return xhr.json(previewUrl, {
        method: 'get'
    });
}

function downloadUrl(assetId, version, assetName) {
    const params = urlParams({ version, assetName });
    return `${config.apiHostname}${prefix}/download/${assetId}?${params}`;
}

function downloadPreviewUrl(assetId) {
    return `${config.apiHostname}${prefix}/downloadpreview/${assetId}`;
}

function getHttpDownloadTransferSpec({ assetId, version, assetName }) {
    return getHttpBulkDownloadTransferSpec({
        assetIds: [assetId],
        version,
        assetNames: { [assetId]: assetName }
    });
}

function getHttpBulkDownloadTransferSpec({ assetIds, version, assetNames }) {
    return { urls: assetIds.map(assetId => downloadUrl(assetId, version, assetNames[assetId])) };
}

function getManagedDownloadTransferSpec(type, { assetId, version, assetName, attachmentId }) {
    return getManagedBulkDownloadTransferSpec(type, {
        asset_ids: [assetId],
        version,
        assetNames: { [assetId]: assetName },
        ...(attachmentId ? { attachmentIds: [attachmentId] } : {})
    });
}

function getManagedBulkDownloadTransferSpec(type, { asset_ids: assetIds, version, assetNames, attachmentIds }) {
    const query = urlParams({ original_version: version });

    return xhr.json(`${config.apiHostname}${prefix}/managed/download?${query}`, {
        method: 'post',
        body: JSON.stringify({
            type,
            assetIds,
            assetNames,
            ...(!isEmpty(attachmentIds) ? { attachmentIds } : {})
        })
    });
}

function getHttpUploadTransferSpec({ httpUrl: url, formData, progressFn }) {
    return { url, formData, progressFn };
}

function getUploadTransferSpec(type, args, keepInSameFolder = false) {
    const query = urlParams({ keepInSameFolder });
    const url = args.isAttachment
        ? `${config.apiHostname}${prefix}/${args.parentId}/attachment/managed/upload?${query}`
        : `${config.apiHostname}${prefix}/managed/upload?${query}`;
    return xhr.json(url, {
        method: 'post',
        body: JSON.stringify({
            type,
            sources: args.sources
        })
    });
}

function getUploadTransferSpecForIMP(type, args) {
    return getUploadTransferSpec(type, args, true);
}

function fetchLocations({ filter } = {}) {
    return xhr.json(`${config.apiHostname}${prefix}/location?${urlParams({ filter })}`, {
        method: 'get'
    });
}

function fetchResources(assetId, version) {
    return xhr.json(`${config.apiHostname}${prefix}/records/${assetId}/resources?${urlParams({ version })}`, {
        method: 'get'
    });
}

function getVersions(assetId) {
    return xhr.json(`${config.apiHostname}${prefix}/versions/${assetId}`, {
        method: 'get'
    });
}
function getRecords(assetId, original_version) {
    return xhr.json(`${config.apiHostname}${prefix}/records/${assetId}?${urlParams({ original_version })}`, {
        method: 'get'
    });
}

function getAllRecords(assetIds, types = 'original') {
    const ids = JSON.stringify(assetIds);
    return xhr.json(`${prefix}/records?${urlParams({ types })}`, {
        method: 'post',
        body: ids
    });
}

export default {
    thumbnailUrl,
    previewUrl,
    attachmentPreviewUrl,
    getPreview,
    downloadUrl,
    downloadPreviewUrl,
    getHttpDownloadTransferSpec,
    getHttpBulkDownloadTransferSpec,
    getManagedDownloadTransferSpec,
    getManagedBulkDownloadTransferSpec,
    getUploadTransferSpec,
    getUploadTransferSpecForIMP,
    getHttpUploadTransferSpec,
    fetchLocations,
    fetchResources,
    getRecords,
    getVersions,
    getAllRecords
};
