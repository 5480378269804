import { groupBy, omit } from 'lodash';
import moment from 'moment';

export function formatDate(d) {
    return d && moment(d).format('ddd, MMM D YYYY, h:mm:ss a');
}
export const formatTitle = title => {
    const children = groupBy(title.children, 'title_format');
    const parents = groupBy(title.parents, 'title_format');

    const metadata = omit(title, ['children', 'parents']);

    return { children, parents, metadata };
};
export function toDateString(d) {
    return d && moment(d).format('MM/DD/YYYY');
}

export function momentFromNow(d) {
    return d && moment(d).fromNow();
}

export function extractCVValue(asset, attribute) {
    if (!asset[attribute]) {
        return null;
    }
    return Array.isArray(asset[attribute]) ? asset[attribute].map(o => o.value) : asset[attribute].value;
}
