import { dispatchResize } from 'lib/utils';
import DestinationPickerSideSheetActions from './DestinationPickerSideSheetActions';

class DestinationPickerSideSheetController {
    setFacetsDrawer(value) {
        DestinationPickerSideSheetActions.setFacetsDrawer(value);
        setTimeout(() => dispatchResize(), 250);
    }
}

export default new DestinationPickerSideSheetController();
