import { List } from '@material-ui/core';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import MetadataItem from './MetadataItem';
import PictureInPictureIcon from '@material-ui/icons/PictureInPicture';
import PropTypes from 'prop-types';
import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

const icons = {
    'bonus title': <CardMembershipIcon color="disabled" fontSize="small" />,
    id: <PictureInPictureIcon color="disabled" fontSize="small" />,
    uuid: <FingerprintIcon color="disabled" fontSize="small" />
};

const sheet = ({ spacing: { unit } }) => ({
    list: { display: 'flex', flexWrap: 'wrap' },
    item: { padding: unit / 4, paddingRight: unit * 2, width: 'auto' },
    icon: { marginRight: unit },
    text: { fontSize: '0.75rem', padding: 0 },
    textDense: { lineHeight: 1.1, wordBreak: 'break-word' }
});

const MetadataListComponent = ({ classes, metadataItems }) => {
    const { list } = classes;
    return (
        <List classes={{ root: list }} dense disablePadding>
            {metadataItems.map(({ label, value }, index) => (
                <MetadataItem classes={classes} icons={icons} key={index} label={label} value={value} />
            ))}
        </List>
    );
};

MetadataListComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    metadataItems: PropTypes.array.isRequired
};

export default withStyles(sheet)(MetadataListComponent);
