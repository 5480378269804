import PropTypes from 'prop-types';
import React from 'react';

class SelectLocationFormComponent extends React.PureComponent {
    doRender() {
        const { WonderlandSchemaForm } = this.props;
        return <WonderlandSchemaForm />;
    }

    render() {
        try {
            return this.doRender();
        } catch (e) {
            console.error('SelectLocationFormComponent::render', e);
        }
        return null;
    }
}

SelectLocationFormComponent.propTypes = {
    WonderlandSchemaForm: PropTypes.func.isRequired
};

export default SelectLocationFormComponent;
