import { autobind, ToastController, withProgress } from 'wonderland-ui-commons';
import { getPartialUpdateForMeta } from 'lib/assetEditUtils';
import { InvalidFormError } from 'lib/errors';
import { WonderlandDomainAPI } from 'core/apis';
import AssetEditActions from './AssetEditActions';
import history from 'lib/history';
import MetaController from 'core/Meta';
import SecurityAPI from 'core/Security/SecurityAPI';
import WonderlandDomainController from 'core/WonderlandDomain/WonderlandDomainController';

@autobind
class AssetEditController {
    @withProgress({ label: 'fetch_asset_dependencies' })
    fetchDependencies(id) {
        AssetEditActions.reset();
        return MetaController.lockIfUnlocked({ id })
            .then(() => WonderlandDomainAPI.findAssetById(id))
            .then(r => AssetEditActions.doneLoading(r))
            .then(() => SecurityAPI.getAcl(id))
            .then(a => AssetEditActions.getAclCompleted(a));
    }

    async save(submitForm, asset, initialAsset, meta, security, rules, titleOptions, preCalculatedChanges) {
        const formIsValid = await submitForm();
        if (!formIsValid) {
            throw new InvalidFormError();
        }
        const updatedMeta = getPartialUpdateForMeta(initialAsset, meta);
        return WonderlandDomainController.saveAsset(
            asset.id,
            updatedMeta,
            security,
            rules,
            titleOptions,
            preCalculatedChanges
        );
    }

    async saveAndView(submitForm, asset, initialAsset, meta, security, rules, titleOptions, preCalculatedChanges) {
        const segment =
            asset.asset_type === 'collection'
                ? asset.collection_type === 'smart'
                    ? 'smartCollection'
                    : 'collection'
                : 'asset';
        return this.save(submitForm, asset, initialAsset, meta, security, rules, titleOptions, preCalculatedChanges)
            .then(res => history.push(`/${segment}/${res.id}`))
            .catch(e => {
                if (!(e instanceof InvalidFormError)) {
                    ToastController.showError(e);
                }
            });
    }

    cancel(asset, tab) {
        const type = asset.asset_type === 'collection' ? 'collection' : 'asset';
        history.push(`/${type}/${asset.id}/${tab}`);
    }
}

export default new AssetEditController();
