import { compose, withHandlers } from 'recompose';
import { get, set } from 'lodash';
import { withProfile } from 'core/Profile';

const sideSheetModePath = 'preferences.searchResultPreferences.sideSheetMode';

export default () =>
    compose(
        withProfile(({ profile }) => ({
            sideSheetMode: get(profile, sideSheetModePath)
        })),
        withHandlers({
            updateSideSheetMode:
                ({ saveProfile, sideSheetMode }) =>
                nextSideSheetMode => {
                    if (sideSheetMode !== nextSideSheetMode) {
                        return saveProfile(set({}, sideSheetModePath, nextSideSheetMode));
                    }
                }
        })
    );
