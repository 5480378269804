import { Link } from 'react-router-dom';
import FeatureFlag from 'app/shared/FeatureFlag';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

const AppHeaderMenuComponent = ({ classes: { listRoot, listItemRoot } }) => (
    <List classes={{ root: listRoot }}>
        <Tooltip aria-label="Home" title="Home" placement="right">
            <Link to="/">
                <ListItem button classes={{ root: listItemRoot }} key={'HOME'}>
                    <ListItemIcon>
                        <Icon>home</Icon>
                    </ListItemIcon>
                </ListItem>
            </Link>
        </Tooltip>
        <Tooltip aria-label="Lightbox" title="Lightbox" placement="right">
            <Link to="/lightbox">
                <ListItem button classes={{ root: listItemRoot }} key={'LIGHTBOX'}>
                    <ListItemIcon>
                        <Icon>tv</Icon>
                    </ListItemIcon>
                </ListItem>
            </Link>
        </Tooltip>
        <Tooltip aria-label="Checked Out" title="Checked Out" placement="right">
            <Link to="/checkedout">
                <ListItem button classes={{ root: listItemRoot }} key={'CHECKED OUT'}>
                    <ListItemIcon>
                        <Icon>checkbox</Icon>
                    </ListItemIcon>
                </ListItem>
            </Link>
        </Tooltip>
        <Tooltip aria-label="Shared" title="Shared" placement="right">
            <Link to="/shared">
                <ListItem button classes={{ root: listItemRoot }} key={'SHARED'}>
                    <ListItemIcon>
                        <Icon>folder_shared</Icon>
                    </ListItemIcon>
                </ListItem>
            </Link>
        </Tooltip>
        <FeatureFlag feature="SMART_COLLECTIONS">
            <Tooltip aria-label="Smart Collections" title="Smart Collections" placement="right">
                <Link to="/smartCollections">
                    <ListItem button classes={{ root: listItemRoot }} key={'Smart Collections'}>
                        <ListItemIcon>
                            <Icon>folder_special</Icon>
                        </ListItemIcon>
                    </ListItem>
                </Link>
            </Tooltip>
        </FeatureFlag>
    </List>
);

AppHeaderMenuComponent.propTypes = {
    classes: PropTypes.object.isRequired
};

export default AppHeaderMenuComponent;
