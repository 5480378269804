import ChevronRight from '@material-ui/icons/ChevronRight';
import classnames from 'classnames';
import FileCheckedIcon from '@material-ui/icons/InsertDriveFile';
import FileUnCheckedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import FolderCheckedIcon from '@material-ui/icons/Folder';
import FolderUnCheckedIcon from '@material-ui/icons/FolderOpen';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

/* istanbul ignore next */
const sheet = ({ palette, spacing }) => ({
    disabledIcon: {
        color: palette.text.disabled
    },
    listItemTextPrimary: {
        display: 'flex'
    },
    listItemTextRoot: {
        padding: 0,
        overflowWrap: 'anywhere'
    },
    listItemTitle: {
        marginTop: 'auto',
        marginBottom: 'auto',
        '&.isDisabled': {
            color: palette.text.secondary
        },
        wordBreak: 'break-all'
    },
    listItemDate: {
        '&.isDisabled': {
            color: palette.text.disabled
        }
    },
    resultsListItem: {
        border: `1px solid ${palette.disabledColor}`,
        padding: '8px',
        display: 'flex',
        minHeight: spacing.unit * 8,
        backgroundColor: palette.background.default,
        '&.isSelected': {
            backgroundColor: palette.background.active
        }
    }
});

@withStyles(sheet)
export default class ResultsListItemComponent extends React.PureComponent {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        drillIntoFolder: PropTypes.func.isRequired,
        fileOrFolder: PropTypes.object.isRequired,
        isFolder: PropTypes.bool.isRequired,
        isDisabled: PropTypes.bool.isRequired,
        isSelected: PropTypes.bool.isRequired,
        onSelectFileOrFolder: PropTypes.func.isRequired
    };

    selectFileOrFolder = fileOrFolder => e => {
        const { isDisabled, onSelectFileOrFolder } = this.props;
        return !isDisabled && onSelectFileOrFolder(e, fileOrFolder);
    };
    openFolder = () => {
        const { drillIntoFolder, fileOrFolder } = this.props;
        return drillIntoFolder(fileOrFolder.location);
    };
    getIcon = () => {
        const { isFolder, isSelected } = this.props;
        if (isFolder) {
            return isSelected ? <FolderCheckedIcon /> : <FolderUnCheckedIcon />;
        }
        return isSelected ? <FileCheckedIcon /> : <FileUnCheckedIcon />;
    };

    getSecondaryText = () => {
        const { fileOrFolder } = this.props;
        let text = '';

        if (fileOrFolder.lastModified) {
            text = moment(fileOrFolder.lastModified).format('MMM D YYYY, h:mm a');
        }
        if (fileOrFolder.parent_key) {
            text += ` (${fileOrFolder.parent_key})`;
        }
        return text;
    };

    render() {
        const { fileOrFolder, isDisabled, isFolder, isSelected, classes } = this.props;

        return (
            <ListItem button className={classnames(classes.resultsListItem, { isSelected })}>
                <ListItemIcon
                    className={classnames({ [classes.disabledIcon]: isDisabled })}
                    onClick={this.selectFileOrFolder(fileOrFolder)}
                >
                    {this.getIcon()}
                </ListItemIcon>
                <ListItemText
                    classes={{ primary: classes.listItemTextPrimary, root: classes.listItemTextRoot }}
                    disableTypography={true}
                    onClick={this.selectFileOrFolder(fileOrFolder)}
                    disabled={isDisabled}
                    primary={
                        <Typography variant="body1" className={classnames(classes.listItemTitle, { isDisabled })}>
                            {fileOrFolder.name}
                        </Typography>
                    }
                    secondary={
                        <Typography variant="caption" className={classnames(classes.listItemDate, { isDisabled })}>
                            {this.getSecondaryText()}
                        </Typography>
                    }
                />
                {isFolder && (
                    <IconButton onClick={this.openFolder}>
                        <ChevronRight />
                    </IconButton>
                )}
            </ListItem>
        );
    }
}
