import { get } from 'lodash';
import { reducerRegistry } from 'lib/redux';
import { registerSliceReducer, sliceReducer, subscribe } from 'react-redux-boilerout';
import config from 'app/config';

const types = Object.keys(config.export);

function getProfilesForOption(profile, optionSupportTranscoding) {
    if (!optionSupportTranscoding) {
        return null;
    }

    const optionProfiles = config.export[profile];

    return {
        profiles: optionProfiles.map(({ name, type, autoSelect }) => ({
            name,
            type,
            checked: Boolean(autoSelect)
        }))
    };
}

function getTransformedOption(option, optionSupportTranscoding) {
    const { option: name, count } = option;

    return {
        name,
        count,
        type: name,
        ...getProfilesForOption(name, optionSupportTranscoding)
    };
}

function getFilteredOptions(stats) {
    const baseOptions = {
        options: [],
        nonTranscodableOptions: []
    };

    return stats.options.reduce((options, currentOption) => {
        const optionSupportTranscoding = types.includes(currentOption.option);

        const transformedOptions = getTransformedOption(currentOption, optionSupportTranscoding);
        const optionCategory = optionSupportTranscoding ? 'options' : 'nonTranscodableOptions';

        options[optionCategory].push(transformedOptions);

        return options;
    }, baseOptions);
}

function countSelectedAssets(slice) {
    const transcodableAssetsCount = slice.options.reduce((amount, option) => {
        const optionTotalAssets = option.count;
        const optionSelected = Object.values(option.profiles).some(value => value.checked);

        return optionSelected ? amount + optionTotalAssets : amount;
    }, 0);

    const nonTranscodableAssetsCount = slice.exportOriginalsForNonTranscodableAssets
        ? slice.nonTranscodableOptions.reduce((amount, option) => amount + option.count, 0)
        : 0;

    return transcodableAssetsCount + nonTranscodableAssetsCount;
}

@registerSliceReducer({ registry: reducerRegistry })
@sliceReducer('BulkExport')
@subscribe({ namespace: 'BulkExport' })
export default class BulkExportSliceReducer {
    initialState() {
        return {
            config: null,
            options: [],
            totalAssets: 0,
            totalTranscodableAssets: 0,
            nonTranscodableOptions: [],
            exportOriginalsForNonTranscodableAssets: false,
            removeWatermark: false,
            watermark: ''
        };
    }

    onSetConfig(slice, config) {
        const assetsIds = get(config, 'asset_ids', []);
        const totalAssets = assetsIds.length;

        return { ...slice, config, totalAssets };
    }

    onLoadStatsCompleted(slice, stats) {
        const { options, nonTranscodableOptions } = getFilteredOptions(stats);

        const newSlice = { ...slice, options, nonTranscodableOptions };
        const totalAssets = countSelectedAssets(newSlice);

        return { ...newSlice, totalAssets };
    }

    onToggleChecked(slice, optionIndex, profileIndex) {
        const checkedValue = !slice.options[optionIndex].profiles[profileIndex].checked;

        const options = slice.options.map((mediaType, index) => ({
            ...mediaType,
            profiles:
                index !== optionIndex
                    ? mediaType.profiles
                    : mediaType.profiles.map((option, idx) => ({
                          ...option,
                          checked: idx !== profileIndex ? option.checked : checkedValue
                      }))
        }));

        const newSlice = { ...slice, options };
        const totalAssets = countSelectedAssets(newSlice);

        return { ...newSlice, totalAssets };
    }

    onToggleExportOriginalsForNonTranscodableAssets(slice) {
        const newSlice = {
            ...slice,
            exportOriginalsForNonTranscodableAssets: !slice.exportOriginalsForNonTranscodableAssets
        };

        const totalAssets = countSelectedAssets(newSlice);

        return { ...newSlice, totalAssets };
    }

    onChangeWatermark(slice, watermark) {
        return { ...slice, watermark };
    }

    onToggleRemoveWatermark(slice) {
        return { ...slice, removeWatermark: !slice.removeWatermark };
    }

    onClear(slice) {
        const options = slice.options.map(mediaType => ({
            ...mediaType,
            profiles: mediaType.profiles.map(option => ({ ...option, checked: false }))
        }));

        const newSlice = { ...slice, options };
        const totalAssets = countSelectedAssets(newSlice);

        return { ...newSlice, totalAssets };
    }

    onReset() {
        return this.initialState();
    }
}
