import { actionDispatcher } from 'react-redux-boilerout';

@actionDispatcher({
    namespace: 'Share',
    actions: [
        'changeBurnIn',
        'clear',
        'fieldChange',
        'invalid',
        'reset',
        'setConfig',
        'setTab',
        'statsCompleted',
        'toggleChecked',
        'toggleDownload',
        'toggleForensic',
        'toggleNonTrans',
        'toggleOriginals',
        'togglePreview',
        'validateCompleted',
        'burnInDisable',
        'burnInEnable'
    ]
})
export default class ShareActions {}
