import { urlParams, xhr } from 'wonderland-ui-commons';
import config from 'app/config';
const indexName = 'titles';
const metaPrefix = 'titles';
const discoveryHost = `${config.apiHostname}/${config.titleManager.prefixes.discovery}/${indexName}`;
const metaHost = `${config.apiHostname}/${config.titleManager.prefixes.domain}/${metaPrefix}`;

function getSearch(criteria, activeFacets, skip, limit, populateThumbnails) {
    const query = urlParams({
        skip,
        limit,
        facets: activeFacets.join(',') || 'false',
        populateThumbnails
    });
    return xhr.json(`${discoveryHost}/search?${query}`, {
        method: 'post',
        body: JSON.stringify({ criteria })
    });
}

const lookupFacetValues = (field, searchText, criteria) => {
    return xhr.json(`${discoveryHost}/lookup/${field}?filter=${searchText}`, {
        method: 'post',
        body: JSON.stringify({ criteria: [{ type: 'recent' }] })
    });
};

function getIndexConfig() {
    return xhr.json(`${discoveryHost}/config`, {
        method: 'get'
    });
}

function getAncestors(titleId) {
    return xhr.json(`${metaHost}/${titleId}/ancestors`, {
        method: 'get'
    });
}

function getByParentId(parentId) {
    return xhr.json(`${metaHost}/parent/${parentId}`, {
        method: 'get'
    });
}

function getTitle(titleId) {
    return xhr.json(`${metaHost}/${titleId}`, {
        method: 'get'
    });
}

export default {
    getSearch,
    getTitle,
    lookupFacetValues,
    getAncestors,
    getByParentId,
    getIndexConfig
};
