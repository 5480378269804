import {
    compact,
    differenceBy,
    filter,
    findIndex,
    flatMap,
    get,
    groupBy,
    isBoolean,
    keyBy,
    map,
    mapValues,
    omit,
    set,
    values
} from 'lodash';
import { getFieldSpecFlag, getTemplateFieldSpec } from 'lib/templateUtils';
import { getRequiredIndexedFieldSchemas } from 'core/Schemas';
import config from 'app/config';
import defaultAssetUploadPreferences from 'lib/defaultAssetUploadPreferences';

export const STEPS = {
    FILES: 'selectFiles',
    METADATA: 'setMetadata',
    TEMPLATE: 'selectTemplate'
};

export const RENDITIONS = {
    PREVIEW: 'preview',
    THUMBNAIL: 'thumbnail',
    ORIGINAL: 'original'
};
export const TRANSFER_TYPE = {
    DESTINATION: 'destination',
    ASPERA_CONNECT: 'aspera_connect',
    SIGNED_S3: 'signed_s3',
    HTTP: 'http'
};
export const META_SOURCE = {
    METADATA: 'metadata',
    TEMPLATE: 'template',
    ASSET_ID: 'asset_id',
    SIDECAR: 'sidecar',
    BULK: 'bulk',
    UUID_MAP: 'asset_uuid_map',
    FILENAME_MAP: 'asset_filename_map'
};

const NOTIFICATION_RECIPIENT_TYPES = {
    distributionList: { key: 'dl_id', type: 'distribution_list' },
    externalUser: { key: 'email', type: 'external' },
    internalUser: { key: 'name', type: 'user' }
};

export const getEmptyFormValue = field => {
    return { name: field, value: null };
};

const byName = field => field.name;

export const defaultOrderedMeta = defaultAssetUploadPreferences.map(getEmptyFormValue);

export const defaultMeta = keyBy(defaultOrderedMeta, 'name');

export const formatNotifications = (notifications = {}) => {
    const contacts = [];

    for (let [key] of Object.entries(notifications)) {
        const { email = '', exclude, id, type } = notifications[key];
        const keyType = NOTIFICATION_RECIPIENT_TYPES[type].key;
        const keyValue = keyType === 'dl_id' ? id : email.toLowerCase();

        if (!exclude) {
            contacts.push({ [keyType]: keyValue, type: NOTIFICATION_RECIPIENT_TYPES[type].type });
        }
    }

    return [{ type: 'email', contacts }];
};

export const getNewOrderedMeta = (newMeta, oldOrderedMeta) => {
    const updatedFields = compact(oldOrderedMeta.map(field => newMeta[field.name]));
    const newFields = differenceBy(values(newMeta), updatedFields, byName);
    return [...updatedFields, ...newFields].map(field => omit(field, 'operation'));
};

export const getNewTemplateOrderedMeta = metadata => {
    return map(
        filter(metadata, ({ state_flags: { checked, available } }) => checked || available),
        ({ name, default_value: value = null }) => ({
            name,
            value
        })
    );
};

export const getAsset = (asset_type, meta) => ({ asset_type, ...mapValues(meta, 'value') });

export const addRequiredFieldsIfNotPresent = (viewType, assetType, meta, fieldGroups) => {
    return {
        newOrderedMeta: getRequiredIndexedFieldSchemas(viewType, assetType, getAsset(assetType, meta), fieldGroups)
            .filter(({ name }) => name !== 'content_categories')
            .map(field => ({ name: field.name, value: null }))
    };
};

export function getMetaFromOrderedMeta(orderedMeta) {
    return keyBy(orderedMeta, 'name');
}

export function generateFieldState(field, templateMetadataSpec, existsInPrefs = false) {
    const templateFieldSpec = getTemplateFieldSpec(templateMetadataSpec, field.name);
    const required = !!(getFieldSpecFlag(templateFieldSpec, 'required') || field.required);
    const locked = getFieldSpecFlag(templateFieldSpec, 'locked');
    const visible = isFieldVisible(templateFieldSpec);
    const checked = getFieldSpecFlag(templateFieldSpec, 'checked');
    const availableValues = get(templateFieldSpec, 'available_values');
    return { existsInPrefs, required, checked, locked, visible, availableValues };
}

export function isFieldVisible(templateFieldSpec) {
    return !!getFieldSpecFlag(templateFieldSpec, 'visible', true);
}

export function groupByParentChild(schema) {
    const parents = schema.filter(field => !field.parent);
    const groupedChildren = groupBy(schema, 'parent');
    return flatMap(parents, parent => [parent, ...get(groupedChildren, parent.name, [])]);
}

export function mapFormContext(getField, assetType) {
    return ({ toggled, value }, key) => {
        const parsedKey = assetType === 'collection' && key === 'name' ? 'collection_name' : key;
        if (parsedKey === 'files') {
            return value;
        }
        if (parsedKey === 'content_categories') {
            return { policies: value };
        }
        if (parsedKey === 'destination') {
            return value.id;
        }
        if (parsedKey === 'on_behalf_of') {
            return get(value, 'email', value.value);
        }
        if (toggled) {
            if (getField(parsedKey, assetType).uiType === 'toggle') {
                return isBoolean(value) ? value : false;
            }
            return value;
        }
    };
}

export function getInitialFormFields(formValues, template) {
    return Object.entries(formValues).reduce((acc, [fieldName, value]) => {
        const templateMetadata = get(template, 'metadata', []);
        const index = findIndex(templateMetadata, ({ name }) => name === fieldName);
        const toggled = !!templateMetadata[index];
        return {
            ...acc,
            [fieldName]: {
                ...(index !== -1 && { index }),
                toggled,
                value
            }
        };
    }, {});
}

export function getInitialFormValues(template) {
    const initialMetadataValues = get(template, 'metadata', []).reduce((acc, field) => {
        if (get(field, ['state_flags', 'checked'])) {
            acc[field.name] = field.default_value || '';
        }
        return acc;
    }, {});
    initialMetadataValues.content_categories = get(template, ['security', 'default_value']);
    return initialMetadataValues;
}

export function populateReplacement(extraMetadata, obj, path) {
    const replacement = {};
    config.replacementMetadata.forEach(field => {
        replacement[field.ingestSpec] = extraMetadata.replacementMetadata[field.name];
    });
    set(obj, path, replacement);
}
