import { compose, withHandlers } from 'recompose';
import { connectSlice, withPageDrawer } from 'wonderland-ui-commons';
import { withCartItems, withImageMode, withMetadataMode } from 'core/Profile';
import CartActions from 'core/Cart/CartActions';
import CartComponent from 'app/Cart';
import CartSlice from 'core/Cart/CartSlice';

export default function withCart() {
    return compose(
        connectSlice({ slice: CartSlice, actions: CartActions }, slice => ({ ...slice })),
        withCartItems(),
        withImageMode(),
        withMetadataMode(),
        withPageDrawer([
            {
                name: 'CartDrawer',
                Component: CartComponent,
                mapProps: ({
                    addCartItems,
                    cartAssets,
                    cartItemCount,
                    cartItems,
                    cartLoading,
                    closeDrawer,
                    imageMode,
                    metadataMode,
                    removeCartItems,
                    selectAll,
                    selectedIds,
                    selectedItemsSize
                }) => {
                    return {
                        addCartItems,
                        anchor: 'right',
                        cartAssets,
                        cartItemCount,
                        cartItems,
                        cartLoading,
                        closeDrawer,
                        imageMode,
                        metadataMode,
                        modal: true,
                        removeCartItems,
                        selectAll,
                        selectedIds,
                        selectedItemsSize,
                        title: `Cart (${cartItemCount})`
                    };
                }
            }
        ]),
        withHandlers({
            handleCart:
                ({ openDrawer }) =>
                () =>
                    openDrawer('CartDrawer')
        })
    );
}
