import { actionDispatcher } from 'react-redux-boilerout';

@actionDispatcher({
    namespace: 'BulkExport',
    actions: [
        'changeWatermark',
        'clear',
        'loadStatsCompleted',
        'removeWatermark',
        'reset',
        'setConfig',
        'toggleChecked',
        'toggleExportOriginalsForNonTranscodableAssets',
        'toggleRemoveWatermark'
    ]
})
export default class BulkExportActions {}
