import { get, isEmpty } from 'lodash';
import { priorityToColor } from 'lib/priorityConfig';
import { withStyles } from 'wonderland-ui-material';
import PropTypes from 'prop-types';
import QuickFilterButton from './QuickFilterButton';
import React from 'react';

/* istanbul ignore next */
const sheet = () => ({
    container: {
        display: 'flex',
        justifyContent: 'center'
    }
});

@withStyles(sheet)
export default class QuickFiltersComponent extends React.PureComponent {
    static propTypes = {
        options: PropTypes.arrayOf(PropTypes.object),
        criteria: PropTypes.array.isRequired,
        selectQuickFilter: PropTypes.func.isRequired,
        priorityMap: PropTypes.object.isRequired,
        buttons: PropTypes.array.isRequired,
        namingMap: PropTypes.object,
        classes: PropTypes.object.isRequired
    };

    button = (value, label, i) => {
        const { selectQuickFilter, options, criteria, priorityMap, namingMap } = this.props;
        const buttonLabel = get(namingMap, label, label);
        const valueFormattingInfo = get(priorityMap, value, {});
        const color = priorityToColor(valueFormattingInfo.priority);

        const opt = (options || []).find(({ key }) => key === value) || {};
        return (
            !isEmpty(opt) && (
                <QuickFilterButton
                    key={i}
                    criteria={criteria}
                    value={value}
                    facetByItemFormat={selectQuickFilter}
                    color={color}
                    selected={opt.selected}
                >
                    {buttonLabel}
                </QuickFilterButton>
            )
        );
    };

    render() {
        const { classes, buttons } = this.props;
        return <div className={classes.container}>{buttons.map((button, i) => this.button(button, button, i))}</div>;
    }
}
