import { get } from 'lodash';

const colorMap = {
    1: 'blue',
    2: 'purple',
    3: 'green',
    4: 'red',
    default: 'orange'
};

export const destinationTypeConfig = {
    Provider: { priority: 1, abbreviation: 'P' },
    Location: { priority: 2, abbreviation: 'L' },
    Destination: { priority: 3, abbreviation: 'D' }
};

export const priorityToColor = typePriority => get(colorMap, typePriority, colorMap.default);

export const titleFormatConfig = {
    Feature: { priority: 1, abbreviation: 'FE', plural: 'Features' },
    Series: { priority: 1, abbreviation: 'SR', plural: 'TV Series' },
    Season: { priority: 2, abbreviation: 'SE', plural: 'Seasons' },
    Episode: { priority: 3, abbreviation: 'EP', plural: 'Episodes' },
    Bonus: { priority: 4, abbreviation: 'BN', plural: 'Bonuses' },
    Book: { priority: 1, abbreviation: 'BK', plural: 'Books' },
    Short: { priority: 1, abbreviation: 'SH', plural: 'Shorts' },
    'TV Movie': { priority: 1, abbreviation: 'TE', plural: 'TV Movies' },
    'Online Content/Games': { priority: 1, abbreviation: 'OG', plural: 'Online Content/Games' },
    Interstitial: { priority: 1, abbreviation: 'IN', plural: 'Interstitial Titles' },
    'Console Game': { priority: 1, abbreviation: 'CG', plural: 'Console Games' },
    Comic: { priority: 1, abbreviation: 'CO', plural: 'Comics' },
    'Home Video': { priority: 1, abbreviation: 'HV', plural: 'Home Videos' },
    Special: { priority: 2, abbreviation: 'SP', plural: 'Specials' },
    Music: { priority: 1, abbreviation: 'MU', plural: 'Music' },
    Other: { priority: 4, abbreviation: 'OT', plural: 'Other Titles' },
    'Live Entertainment': { priority: 1, abbreviation: 'LE', plural: 'Live Entertainment' },
    Featurette: { priority: 1, abbreviation: 'FT', plural: 'Featurettes' },
    'Mini Series': { priority: 1, abbreviation: 'MS', plural: 'Mini Series' },
    Pilot: { priority: 3, abbreviation: 'PI', plural: 'Pilots' },
    Magazine: { priority: 1, abbreviation: 'MA', plural: 'Magazines' },
    'Theme Parks & Resorts': { priority: 1, abbreviation: 'TR', plural: 'Theme Parks & Resorts' },
    'Digital Application/Game': { priority: 1, abbreviation: 'DG', plural: 'Digital Applications/Games' },
    Radio: { priority: 1, abbreviation: 'RA', plural: 'Radio' },
    'Art Pool/Style Guide': { priority: 1, abbreviation: 'AS', plural: 'Art Pools/Style Guides' },
    Story: { priority: 1, abbreviation: 'ST', plural: 'Stories' },
    'Comic Series': { priority: 1, abbreviation: 'CS', plural: 'Comic Series' },
    Unverified: { priority: 4, abbreviation: 'UN', plural: 'Unverified Titles' },
    'Book Series': { priority: 1, abbreviation: 'BS', plural: 'Book Series' },
    'Marketing Campaign': { priority: 1, abbreviation: 'MC', plural: 'Marketing Campaigns' },
    Product: { priority: 1, abbreviation: 'PR', plural: 'Products' },
    'Music Product': { priority: 1, abbreviation: 'MP', plural: 'Music Products' },
    'Music Master': { priority: 1, abbreviation: 'MM', plural: 'Music Masters' },
    Version: { priority: 2, abbreviation: 'VE', plural: 'Versions' },
    'Marketing Release': { priority: 2, abbreviation: 'MR', plural: 'Marketing Releases' }
};
