import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Icon from '@material-ui/core/Icon';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';
import React from 'react';
import SearchFormMenu from './SearchFormMenu';
import Typography from '@material-ui/core/Typography';

const SearchFormComponent = ({
    anchorEl,
    classes: { button, white },
    handleChange,
    handleClose,
    handleMenu,
    handleMenuItem,
    handleSubmit,
    inputTerm,
    open,
    searchFields,
    searchLabel
}) => (
    <form onSubmit={handleSubmit}>
        <FormControl fullWidth>
            <Input
                autoComplete="off"
                classes={{ input: white }}
                disableUnderline
                name="searchTerm"
                onChange={handleChange}
                placeholder="search"
                startAdornment={
                    <InputAdornment position="start">
                        <Button
                            classes={{ root: white, sizeSmall: button }}
                            onClick={handleMenu}
                            size="small"
                            type="button"
                            variant="text"
                        >
                            <Typography color="inherit" noWrap variant="button">
                                {searchLabel}
                            </Typography>
                            <Icon classes={{ root: white }}>arrow_drop_down</Icon>
                        </Button>
                        <SearchFormMenu
                            anchorEl={anchorEl}
                            open={open}
                            handleClose={handleClose}
                            handleMenuItem={handleMenuItem}
                            searchFields={searchFields}
                        />
                    </InputAdornment>
                }
                value={inputTerm}
            />
        </FormControl>
    </form>
);

SearchFormComponent.propTypes = {
    anchorEl: PropTypes.any,
    classes: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleMenu: PropTypes.func.isRequired,
    handleMenuItem: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    inputTerm: PropTypes.string,
    open: PropTypes.bool.isRequired,
    searchFields: PropTypes.array.isRequired,
    searchLabel: PropTypes.any.isRequired
};

export default SearchFormComponent;
