import { destinationTypeConfig, priorityToColor } from 'lib/priorityConfig';
import { fromPairs, get } from 'lodash';
import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';
import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

const avatarStyle = (palette, priority) => ({
    margin: '10px',
    width: 30,
    height: 30,
    fontSize: '.9rem',
    color: '#fff',
    ...palette.assetTypeColor[priorityToColor(priority)]
});

/* istanbul ignore next */
const sheet = ({ palette }) => ({
    ...fromPairs([1, 2, 3, 4, 5].map(v => [v, avatarStyle(palette, v)]))
});

function DestinationTypeAvatar(props) {
    const { destinationType, classes } = props;
    const config = get(destinationTypeConfig, destinationType, {});
    return <Avatar className={classes[config.priority]}>{config.abbreviation}</Avatar>;
}

DestinationTypeAvatar.propTypes = {
    classes: PropTypes.object.isRequired,
    destinationType: PropTypes.string.isRequired
};

export default withStyles(sheet)(DestinationTypeAvatar);
