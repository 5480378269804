import { isEmpty } from 'lodash';

const asId = ({ id }) => id;

const byId = id => term => +id === term.id;

const byParentId = parentId => term => parentId === term.parentId;

const byMigrationOnlyValues = x => !x || x.id === -1;

const bySelectable = value => a =>
    a.selectable || (value && (value.id === a.id || (Array.isArray(value) && value.some(v => v && v.id === a.id))));

const defaultEmptyValue = multi => (multi ? [] : '');

const defaultValueForSingleItemList = (vocabulary, { multi }) => {
    if (isEmpty(vocabulary) || vocabulary.length > 1) {
        return defaultEmptyValue(multi);
    }
    return asId(vocabulary[0]);
};

export {
    asId,
    byId,
    byParentId,
    byMigrationOnlyValues,
    bySelectable,
    defaultEmptyValue,
    defaultValueForSingleItemList
};
