import { compose, withHandlers } from 'recompose';
import { get, set } from 'lodash';
import { withProfile } from 'core/Profile';

const metadataModePath = 'preferences.searchResultPreferences.metadataMode';

export default () =>
    compose(
        withProfile(({ profile }) => ({
            metadataMode: get(profile, metadataModePath)
        })),
        withHandlers({
            updateMetadataMode:
                ({ saveProfile, metadataMode: prevMetadataMode }) =>
                nextMetadataMode => {
                    if (prevMetadataMode !== nextMetadataMode) {
                        return saveProfile(set({}, metadataModePath, nextMetadataMode));
                    }
                }
        })
    );
