import { ControlledVocabularyAPI } from 'core/apis';
import { isEmpty } from 'lodash';
import SearchCriterionBuilder from 'wonderland-ui-discovery/lib/Search/SearchCriterionBuilder';

function fetchCV(assetType) {
    return ControlledVocabularyAPI.lookup(assetType);
}

function fetchFieldCV(assetType, field, { filter, parentId }) {
    return ControlledVocabularyAPI.lookup(assetType, field, { filter, parentId }).then(({ options }) => options);
}

function fetchSmartTagPriorityRules(filter) {
    const ruleCriteria = [SearchCriterionBuilder.buildValueFacetCriterion('type', ['smartTagPriorityRule'])];
    const searchCriteria = isEmpty(filter.trim())
        ? []
        : [SearchCriterionBuilder.buildSearchTermCriterion(filter.trim())];
    return ControlledVocabularyAPI.search([...searchCriteria, ...ruleCriteria], 0).then(({ results: cvs }) => cvs);
}

export default {
    fetchCV,
    fetchFieldCV,
    fetchSmartTagPriorityRules
};
