import { memoize } from 'lodash';
import { sliceContainer } from 'wonderland-ui-commons';

import { ControlledVocabularyAPI } from 'core/apis';

import CacheActions from './CacheActions';
import CacheSlice, { getControlledVocabulary } from './CacheSlice';

const cacheType = 'controlledVocabulary';

export const fetchControlledVocabulary = memoize(function _fetchControlledVocabulary(assetType) {
    CacheActions.loadingRequested(cacheType);
    ControlledVocabularyAPI.lookup(assetType).then(
        data => {
            CacheActions.fetchControlledVocabulary(assetType, data);
            return data;
        },
        () => {
            fetchControlledVocabulary.cache.clear();
            CacheActions.loadingFailed(cacheType);
        }
    );
});

export default function withControlledVocabulary(Component) {
    @sliceContainer({
        slice: CacheSlice,
        component: Component
    })
    class WithControlledVocabularyContainer {
        static mapSliceStateToProps(slice, props) {
            return {
                controlledVocabulary: getControlledVocabulary(slice, props.type)
            };
        }

        componentDidMount() {
            if (this.props.type) {
                fetchControlledVocabulary(this.props.type);
            }
        }

        componentDidUpdate(oldProps) {
            const newProps = this.props;
            if (newProps.type && oldProps.type !== newProps.type) {
                fetchControlledVocabulary(newProps.type);
            }
        }
    }

    return WithControlledVocabularyContainer;
}
