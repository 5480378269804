import { compose, withHandlers, withProps } from 'recompose';
import { get, set } from 'lodash';
import { withProfile } from 'core/Profile';

const listViewModePath = 'preferences.searchResultPreferences.listViewMode';

export default () =>
    compose(
        withProfile(({ profile }) => ({
            listViewMode: get(profile, listViewModePath)
        })),
        withHandlers({
            updateListViewMode:
                ({ listViewMode: prevListViewMode, saveProfile }) =>
                nextListViewMode => {
                    if (prevListViewMode !== nextListViewMode) {
                        return saveProfile(set({}, listViewModePath, nextListViewMode));
                    }
                }
        }),
        withProps({
            defaultModes: [
                { name: 'Card', icon: 'view_module' },
                { name: 'List', icon: 'view_stream' },
                { name: 'Table', icon: 'table_chart' }
            ]
        })
    );
