import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import React from 'react';

const FormDialogComponent = ({ children, classes, disabled, formLabel, hide, onSubmit, submitLabel, visible }) => (
    <Dialog open={visible} PaperProps={{ classes: { root: classes.dialogPaperRoot } }}>
        <DialogTitle>{formLabel}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
            <Button color="secondary" data-qa="cancel" onClick={hide}>
                Cancel
            </Button>
            <Button data-qa="general-create" disabled={disabled} onClick={onSubmit}>
                {submitLabel}
            </Button>
        </DialogActions>
    </Dialog>
);

FormDialogComponent.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    classes: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    formLabel: PropTypes.string.isRequired,
    hide: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    submitLabel: PropTypes.string,
    visible: PropTypes.bool
};

FormDialogComponent.defaultProps = {
    disabled: false,
    visible: false
};

export default FormDialogComponent;
