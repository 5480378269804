import { compose, withHandlers } from 'recompose';
import { get, isUndefined, set } from 'lodash';
import { withProfile } from 'core/Profile';

const limitPath = 'preferences.searchResultPreferences.limit';

export default () =>
    compose(
        withProfile(({ profile }) => ({
            limit: +get(profile, limitPath)
        })),
        withHandlers({
            updateLimit:
                ({ saveProfile, limit: prevLimit }) =>
                nextLimit => {
                    if (!isUndefined(nextLimit) && prevLimit !== nextLimit) {
                        return saveProfile(set({}, limitPath, nextLimit));
                    }
                }
        })
    );
