import { withStyles } from 'wonderland-ui-material';
import Button from '@material-ui/core/Button';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import PropTypes from 'prop-types';
import React from 'react';

/* istanbul ignore next */
const sheet = () => ({
    button: {
        padding: '0 8px',
        marginBottom: '8px',
        textAlign: 'left'
    }
});

@withStyles(sheet)
export default class BreadCrumbsComponent extends React.PureComponent {
    static propTypes = {
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        classes: PropTypes.object.isRequired
    };

    render() {
        const { label, onClick, classes } = this.props;
        return (
            <Button onClick={onClick} className={classes.button} size={'small'}>
                <ChevronLeft />
                {label}
            </Button>
        );
    }
}
