import { AssetStoreAPI } from 'core/apis';
import { get } from 'lodash';
import { urlParams, xhr } from 'wonderland-ui-commons';
import config from 'app/config';

const prefix = '/wonderland-domain/v1';

const bulkUploadUrl = `${config.apiHostname}${prefix}/bulk/upload`;
const sidecarBulkUploadUrl = `${config.apiHostname}${prefix}/bulk/sidecarupload`;
const assetUploadUrl = `${config.apiHostname}${prefix}/asset/upload`;
const unifiedIngestUrl = `${config.apiHostname}${prefix}/asset/unified-ingest`;

function getCollectionState(collectionId) {
    return xhr.json(`${config.apiHostname}${prefix}/collection/${collectionId}/state`, {
        method: 'get'
    });
}

function removeFromCollection(collectionId, assetId) {
    return xhr.json(`${config.apiHostname}${prefix}/collection/${collectionId}/asset/${assetId}`, {
        method: 'delete'
    });
}

function removeAsset(id) {
    return xhr.json(`${config.apiHostname}${prefix}/asset/${id}`, {
        method: 'delete'
    });
}

function deleteAttachment(id, parentId) {
    return xhr.json(`${config.apiHostname}${prefix}/asset/${parentId}/attachment/${id}`, {
        method: 'delete'
    });
}

function removeSharedCollection(id) {
    return xhr.json(`${config.apiHostname}${prefix}/share/${id}`, {
        method: 'delete'
    });
}

function removeSmartCollection(id) {
    return xhr.json(`${config.apiHostname}${prefix}/collection/smart/${id}`, {
        method: 'delete'
    });
}

function addToCollection(collId, assetId) {
    return xhr.json(`${config.apiHostname}${prefix}/collection/${collId}/asset/${assetId}`, {
        method: 'post'
    });
}

function bulkAddToCollection(collectionId, data) {
    return xhr.json(`${config.apiHostname}${prefix}/bulk/collection/${collectionId}`, {
        method: 'post',
        body: JSON.stringify(data)
    });
}

function duplicateAsset(id, data) {
    return xhr.json(`${config.apiHostname}${prefix}/asset/duplicate/${id}`, {
        method: 'post',
        body: JSON.stringify(data)
    });
}

function restoreAssetBulk(asset_ids) {
    const payload = { asset_ids };
    return xhr.json(`${config.apiHostname}${prefix}/bulk/restore`, {
        method: 'post',
        body: JSON.stringify(payload)
    });
}

function restoreAssetOptions(id) {
    return xhr.json(`${config.apiHostname}${prefix}/asset/${id}/restore/options`, { method: 'get' });
}

function createAsset(data) {
    return xhr.json(`${config.apiHostname}${prefix}/asset`, {
        method: 'post',
        body: JSON.stringify(data)
    });
}

function createAttachment(data, parentId) {
    return xhr.json(`${config.apiHostname}${prefix}/asset/${parentId}/attachment`, {
        method: 'post',
        body: JSON.stringify(data)
    });
}

function saveAsset(id, data) {
    return xhr.json(`${config.apiHostname}${prefix}/asset/${id}`, {
        method: 'put',
        body: JSON.stringify(data)
    });
}

function fetchAsset(id) {
    const url = AssetStoreAPI.downloadUrl(id);
    return xhr
        .attachment(url, {
            method: 'get'
        })
        .then(response => {
            return { data: response, url: url };
        });
}

function findAssetById(id, opts = {}) {
    return xhr.json(
        `${config.apiHostname}${prefix}/asset/${id}?${urlParams(opts)}`,
        {
            method: 'get'
        },
        true // include clientIp
    );
}
function findAttachmentAssetsByIds(parentId, ids, opts = {}) {
    return xhr.json(`${prefix}/asset/${parentId}/attachment/ids?${urlParams(opts)}`, {
        method: 'post',
        body: JSON.stringify(ids)
    });
}

function fetchAttachments(id, version) {
    return xhr.json(`${prefix}/asset/attachments/${id}?version=${version}`, {
        method: 'get'
    });
}

function findPreviews(id) {
    return xhr.json(`${prefix}/asset/${id}/previews`, {
        method: 'get'
    });
}

function exportReport(report) {
    return xhr.json(`${config.apiHostname}${prefix}/export`, {
        method: 'post',
        body: JSON.stringify(report)
    });
}

function exportAssets(
    selections,
    transcodingOptions,
    exportOriginalsForNonTranscodableAssets,
    watermark,
    removeWatermark
) {
    return xhr.json(`${config.apiHostname}${prefix}/bulk/export/assets`, {
        method: 'post',
        body: JSON.stringify({
            selections,
            transcodingOptions,
            exportOriginalsForNonTranscodableAssets,
            watermark,
            removeWatermark
        })
    });
}

function createEvent(event) {
    return xhr.json(`${config.apiHostname}${prefix}/event`, {
        method: 'post',
        body: JSON.stringify(event),
        ignore401: true
    });
}

function downloadArchiveFiles(assetId, fileNames) {
    return xhr.json(`${config.apiHostname}${prefix}/bulk/download/${assetId}/archivefiles`, {
        method: 'post',
        body: JSON.stringify(fileNames)
    });
}

function bulkDownload(type, data) {
    return xhr.json(`${config.apiHostname}${prefix}/bulk/download`, {
        method: 'post',
        body: JSON.stringify({ type, ...data })
    });
}

function bulkPublish(collectionId, asset_ids = [], check_policies = true) {
    return xhr.json(`${config.apiHostname}${prefix}/bulk/publish/${collectionId}`, {
        method: 'post',
        body: JSON.stringify({ asset_ids, check_policies })
    });
}

function bulkEdit({ type, asset_ids = [], criteria, criteria_type, meta, security }) {
    return xhr.json(`${config.apiHostname}${prefix}/bulk/edit`, {
        method: 'put',
        body: JSON.stringify({
            type,
            asset_ids,
            criteria,
            criteria_type,
            meta,
            security
        })
    });
}

function bulkQc(asset_ids = []) {
    return xhr.json(`${config.apiHostname}${prefix}/bulk/qc`, {
        method: 'put',
        body: JSON.stringify({ asset_ids })
    });
}

function bulkStats({ asset_ids = [], field = 'asset_type', ...opts }) {
    const assetIds = asset_ids.map(id => get(id, 'id', id));
    return xhr.json(`${config.apiHostname}${prefix}/bulk/stats`, {
        method: 'post',
        body: JSON.stringify({ asset_ids: assetIds, field, ...opts })
    });
}

function getExternalMetadata(assetId) {
    return xhr.json(`${config.apiHostname}${prefix}/asset/${assetId}/externalMetadata`, {
        method: 'get'
    });
}

function validateExternalMetadata(source, type, criteria) {
    return xhr.json(`${config.apiHostname}${prefix}/asset/externalMetadata/${source}/${type}/${criteria}`, {
        method: 'get'
    });
}

function share(shareData) {
    return xhr.json(`${config.apiHostname}${prefix}/share`, {
        method: 'post',
        body: JSON.stringify(shareData)
    });
}

function validateShare(assetIds) {
    return xhr.json(`${config.apiHostname}${prefix}/share/validate`, {
        method: 'post',
        body: JSON.stringify(assetIds)
    });
}

function updateShare(collectionId, rules, type) {
    return xhr.json(`${config.apiHostname}${prefix}/share/${collectionId}`, {
        method: 'put',
        body: JSON.stringify({ rules, type })
    });
}

function shareAssets(shareData) {
    return xhr.json(`${config.apiHostname}${prefix}/collection/share`, {
        method: 'post',
        body: JSON.stringify(shareData)
    });
}

function fetchProfile() {
    return xhr.json(`${config.apiHostname}${prefix}/profile/${config.namespace}`, {
        method: 'get'
    });
}

function updateProfile(profile) {
    return xhr.json(`${config.apiHostname}${prefix}/profile/${config.namespace}`, {
        method: 'put',
        body: JSON.stringify(profile)
    });
}

function fetchStatus() {
    return xhr.json(`${config.apiHostname}${prefix}/status`, {
        method: 'get'
    });
}

function validateAssetsToShare(assetIds) {
    return xhr.json(`${config.apiHostname}${prefix}/collection/share/validate`, {
        method: 'post',
        body: JSON.stringify(assetIds)
    });
}

function managedUpload(data) {
    return xhr.json(`${config.apiHostname}${prefix}/asset/managedupload`, {
        method: 'post',
        body: JSON.stringify(data)
    });
}

function requestService(location, asset_ids = [], notes, users = []) {
    return xhr.json(`${config.apiHostname}${prefix}/asset/servicing`, {
        method: 'post',
        body: JSON.stringify({ location, asset_ids, notes, users })
    });
}

function generateSidecars(data) {
    return xhr.json(`${config.apiHostname}${prefix}/sidecar`, {
        method: 'post',
        body: JSON.stringify(data)
    });
}

function imfIngestFlowManager(data) {
    return xhr.json(`${config.apiHostname}${prefix}/asset/imfingestflowmanager`, {
        method: 'post',
        body: JSON.stringify(data)
    });
}

function ingestIMF(ingestData) {
    return imfIngestFlowManager({ type: 'imfingestflowmanager', ingestData });
}

function validateIMF(validationData) {
    return imfIngestFlowManager({ type: 'imfingestflowmanager', validationData });
}

// expects { ingestData: {}, validationData: {} }
function validateIngestOrPropagateIMF(data) {
    const payload = { type: 'imfingestflowmanager', ...data };

    return imfIngestFlowManager(payload);
}

function resourceIngest(data, { isAttachment, parentId }) {
    const url = isAttachment ? `${config.apiHostname}${prefix}/asset/${parentId}/attachment/upload` : assetUploadUrl;
    return xhr.json(url, {
        method: 'post',
        body: JSON.stringify(data)
    });
}

function unifiedIngest(data) {
    const url = data.attributes?.isAttachment
        ? `${config.apiHostname}${prefix}/asset/${data.attributes.parentId}/attachment/unified-ingest`
        : unifiedIngestUrl;
    return xhr.json(url, {
        method: 'post',
        body: JSON.stringify(data)
    });
}

function sidecarResourceIngest(data) {
    return xhr.json(sidecarBulkUploadUrl, {
        method: 'post',
        body: JSON.stringify(data)
    });
}

function bulkResourceIngest(data) {
    return xhr.json(bulkUploadUrl, {
        method: 'post',
        body: JSON.stringify(data)
    });
}

function resolveAttachmentPreviewUrl(parentId, attachmentPreview) {
    const previewUrl = attachmentPreview ? attachmentPreview.preview_url : '';
    return `${config.apiHostname}${prefix}/asset/${parentId}${previewUrl}`;
}

function getAttachmentHttpDownloadTransferSpec(spec) {
    const { assetId, attachmentId, version, assetName } = spec;
    const params = urlParams({ version, assetName });
    return { urls: [`${config.apiHostname}${prefix}/asset/${assetId}/attachment/download/${attachmentId}?${params}`] };
}

function getAttachmentManagedDownloadTransferSpec(type, { assetId, attachmentId, version, assetName }) {
    const query = urlParams({ original_version: version });
    return xhr.json(`${config.apiHostname}${prefix}/asset/${assetId}/attachment/managed/download${query}`, {
        method: 'post',
        body: JSON.stringify({
            type,
            assetIds: [attachmentId],
            assetNames: { [assetId]: assetName }
        })
    });
}
export default {
    bulkUploadUrl,
    sidecarBulkUploadUrl,
    assetUploadUrl,
    unifiedIngestUrl,
    removeFromCollection,
    removeAsset,
    deleteAttachment,
    removeSharedCollection,
    removeSmartCollection,
    addToCollection,
    duplicateAsset,
    createAsset,
    createAttachment,
    saveAsset,
    fetchAsset,
    findAssetById,
    findAttachmentAssetsByIds,
    exportReport,
    exportAssets,
    createEvent,
    downloadArchiveFiles,
    bulkAddToCollection,
    bulkDownload,
    bulkEdit,
    bulkPublish,
    bulkQc,
    bulkStats,
    getExternalMetadata,
    validateExternalMetadata,
    restoreAssetBulk,
    restoreAssetOptions,
    share,
    updateShare,
    validateShare,
    shareAssets,
    fetchProfile,
    updateProfile,
    fetchStatus,
    validateAssetsToShare,
    managedUpload,
    getCollectionState,
    requestService,
    generateSidecars,
    resourceIngest,
    sidecarResourceIngest,
    fetchAttachments,
    bulkResourceIngest,
    unifiedIngest,
    findPreviews,
    resolveAttachmentPreviewUrl,
    getAttachmentHttpDownloadTransferSpec,
    getAttachmentManagedDownloadTransferSpec,
    ingestIMF,
    validateIMF,
    validateIngestOrPropagateIMF
};
