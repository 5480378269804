import { isEmpty } from 'lodash';
import Button from '@material-ui/core/Button';
import ChevronRight from '@material-ui/icons/ChevronRight';
import PropTypes from 'prop-types';
import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

/* istanbul ignore next */
const sheet = () => ({
    button: {
        padding: '0 8px',
        textAlign: 'left',
        width: 'min-content'
    },
    buttonAndIcon: {
        display: 'flex',
        alignItems: 'center'
    },
    container: {
        display: 'flex',
        overflow: 'auto'
    }
});

@withStyles(sheet)
export default class BreadCrumbsListComponent extends React.PureComponent {
    static propTypes = {
        breadCrumbs: PropTypes.arrayOf(PropTypes.string).isRequired,
        selectBreadCrumb: PropTypes.func.isRequired,
        root: PropTypes.string.isRequired,
        reset: PropTypes.func.isRequired,
        classes: PropTypes.object.isRequired
    };

    selectCrumb = crumb => () => this.props.selectBreadCrumb(crumb);

    render() {
        const { root, reset, breadCrumbs, classes } = this.props;
        return !isEmpty(breadCrumbs) ? (
            <div className={classes.container}>
                <Button onClick={reset} className={classes.button} size={'small'}>
                    {root}
                </Button>
                {breadCrumbs.map((crumb, i) => (
                    <div className={classes.buttonAndIcon} key={i}>
                        <ChevronRight />
                        <Button
                            onClick={this.selectCrumb(crumb)}
                            className={classes.button}
                            size={'small'}
                            disabled={i === breadCrumbs.length - 1}
                        >
                            {crumb}
                        </Button>
                    </div>
                ))}
            </div>
        ) : null;
    }
}
