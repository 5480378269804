import { dropRight, findIndex, slice, split, trimEnd } from 'lodash';
import { reducerRegistry } from 'lib/redux';
import { registerSliceReducer, sliceReducer, subscribe } from 'react-redux-boilerout';

@registerSliceReducer({ registry: reducerRegistry })
@sliceReducer('S3Browser')
@subscribe({ namespace: 'S3Browser' })
export default class S3BrowserSideSheetSlice {
    initialState() {
        return {
            //general
            isOpen: false,
            slide: 'right',
            slideIndex: 0,
            limit: 1000, //in the future we can make this mutable
            loading: true,
            currentFolderPath: '',
            searchTerm: '',

            //pagination within a folder level,
            error: null,
            results: [],
            continuationTokens: [],
            nextToken: '',
            moreItems: false,
            previousItems: false,

            //enhanced search
            useEnhancedSearch: false,
            enhancedSearchLimit: 25,
            enhancedSearchSkip: 0
        };
    }

    onReset() {
        return this.initialState();
    }

    onSelectBreadCrumb(state, crumb) {
        const breadcrumbs = split(state.currentFolderPath, '/');
        const crumbIndex = findIndex(breadcrumbs, c => c === crumb);
        if (crumbIndex < 0) {
            throw new Error(`Cannot navigate to breadcrumb "${crumb}"`);
        }
        return {
            ...state,
            currentFolderPath: slice(breadcrumbs, 0, crumbIndex + 1).join('/'),
            continuationTokens: [],
            previousItems: false,
            slide: 'left',
            slideIndex: state.slideIndex - 1
        };
    }

    onDrillIntoFolder(slice, folder) {
        const currentFolderPath = trimEnd(folder, '/');
        return {
            ...slice,
            currentFolderPath,
            slideIndex: slice.slideIndex + 1,
            slide: 'right',
            continuationTokens: [],
            previousItems: false,
            searchTerm: ''
        };
    }

    onSeeNextResults(slice) {
        return {
            ...slice,
            continuationTokens: [...slice.continuationTokens, slice.nextToken],
            previousItems: true
        };
    }

    onSeePreviousResults(slice) {
        const continuationTokens = dropRight(slice.continuationTokens);
        return {
            ...slice,
            continuationTokens,
            previousItems: !!continuationTokens.length
        };
    }

    onGetS3BucketContentRequested(slice) {
        return {
            ...slice,
            loading: true
        };
    }

    onGetS3BucketContentCompleted(slice, { results, moreItems, nextToken }) {
        return {
            ...slice,
            moreItems,
            results,
            loading: false,
            nextToken,
            useEnhancedSearch: false
        };
    }

    onGetS3BucketContentNotAuthorized(slice, error) {
        return {
            ...slice,
            results: [],
            loading: false,
            error: error.message
        };
    }

    onGetContainerContentRequested(slice) {
        return {
            ...slice,
            loading: true
        };
    }

    onGetContainerContentCompleted(slice, { results, skip, limit, total }) {
        const formattedResults = results.map(({ container, folder, updated_at, key, name, size, parent_key }) => {
            return {
                container,
                folder,
                lastModified: updated_at,
                location: key,
                name,
                pageSize: slice.enhancedSearchLimit,
                size,
                source_type: 's3',
                parent_key
            };
        });
        return {
            ...slice,
            results: formattedResults,
            loading: false,
            enhancedSearchLimit: limit,
            moreItems: total > skip + limit,
            useEnhancedSearch: true
        };
    }

    onSeeEnhancedSearchNextResults(slice) {
        return {
            ...slice,
            enhancedSearchSkip: slice.enhancedSearchSkip + slice.enhancedSearchLimit,
            previousItems: true
        };
    }

    onSeeEnhancedSearchPreviousResults(slice) {
        return {
            ...slice,
            enhancedSearchSkip: slice.enhancedSearchSkip - slice.enhancedSearchLimit,
            previousItems: slice.enhancedSearchSkip - slice.enhancedSearchLimit > 0
        };
    }

    onClearSearchTerm(slice) {
        return {
            ...slice,
            searchTerm: ''
        };
    }

    onUpdateSearchTerm(slice, searchTerm) {
        return {
            ...slice,
            searchTerm
        };
    }
}
