import { get, omit } from 'lodash';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import { withStyles } from 'wonderland-ui-material';
import config from 'app/config';
import FilePicker from 'wonderland-ui-commons/lib/Forms/FormFields/FilePickerFormField';
import PropTypes from 'prop-types';
import React from 'react';

const apiHostName = get(config, 'apiHostname');

/* istanbul ignore next */
const sheet = ({ palette }) => ({
    fileValidationError: {
        fontSize: '12px',
        color: palette.dangerColor,
        position: 'absolute',
        marginTop: '-25px'
    }
});

@withStyles(sheet)
class FilePickerValidatorComponent extends ValidatorComponent {
    static propTypes = {
        classes: PropTypes.object.isRequired
    };

    render() {
        const { value: files, ...other } = omit(this.props, [
            'errorMessages',
            'validators',
            'validatorListener',
            'classes'
        ]);

        return (
            <div>
                <FilePicker {...other} files={files} available={true} apiHostName={apiHostName} />
                {this.errorText()}
            </div>
        );
    }

    errorText() {
        const { classes } = this.props;
        const { isValid } = this.state;

        if (isValid) {
            return null;
        }

        return <div className={classes.fileValidationError}>{this.getErrorMessage()}</div>;
    }
}

export default FilePickerValidatorComponent;
