import { cloneDeep, get } from 'lodash';
import { formatDlBody } from './NotificationRecipientsUtils';
import { recipientTypes } from 'core/Destinations/DeliveryFormConstants';
import { reducerRegistry } from 'lib/redux';
import { registerSliceReducer, sliceReducer, subscribe } from 'react-redux-boilerout';
const { internal, external, dl } = recipientTypes;

@registerSliceReducer({ registry: reducerRegistry })
@sliceReducer('NotificationRecipients')
@subscribe({ namespace: 'NotificationRecipients' })
export default class NotificationRecipientsSliceReducer {
    initialState() {
        return {
            notifications: {},
            distributionListSelected: null,
            searchQuery: '',
            showExcluded: true
        };
    }

    reset() {
        return this.initialState();
    }

    onAddNewDistributionLists(slice, newDLs, physicalDigitalType) {
        const notifications = cloneDeep(slice.notifications);

        Object.entries(notifications).forEach(([key, val]) => {
            if (get(val, 'fromDestination') === physicalDigitalType) {
                delete notifications[key];
            }
        });

        newDLs.forEach(distributionList => {
            const formattedDL = formatDlBody(distributionList, { fromDestination: physicalDigitalType });
            notifications[distributionList.id] = formattedDL;
        });

        return { ...slice, notifications };
    }

    onAddNotificationRecipient(slice, item, itemType) {
        const notifications = cloneDeep(slice.notifications);
        switch (itemType) {
            case internal: {
                item.value.type = itemType;
                notifications[item.value.email] = item.value;
                break;
            }
            case external: {
                notifications[item.value] = {
                    email: item.value,
                    name: item.value,
                    type: itemType
                };
                break;
            }
            case dl: {
                item.type = itemType;
                const formattedDL = formatDlBody(item, { userAdded: true });
                if (!notifications[formattedDL.id]) {
                    notifications[formattedDL.id] = formattedDL;
                }
                break;
            }
            default: {
                break;
            }
        }

        return { ...slice, notifications };
    }

    onDeleteNotificationRecipient(slice, itemIdOrEmail) {
        const notifications = cloneDeep(slice.notifications);
        delete notifications[itemIdOrEmail];
        return { ...slice, notifications };
    }

    onExcludeContactToggle(slice, contactId) {
        const dlId = slice.distributionListSelected.id;
        const notifications = cloneDeep(slice.notifications);
        notifications[dlId].contactDetails[contactId].exclude = !notifications[dlId].contactDetails[contactId].exclude;
        return { ...slice, notifications };
    }

    onSelectDistributionList(slice, distributionListSelected) {
        return { ...slice, distributionListSelected };
    }

    onSearchInput(slice, searchQuery) {
        return { ...slice, searchQuery };
    }

    onFilterContacts(slice, searchQuery) {
        const q = searchQuery.toLowerCase().trim();
        const dlId = slice.distributionListSelected.id;
        const notifications = cloneDeep(slice.notifications);
        const contacts = notifications[dlId].contactDetails;
        Object.keys(contacts).forEach(contactId => {
            const { email } = contacts[contactId];
            notifications[dlId].contactDetails[contactId].filter = email.toLowerCase().includes(q);
        });
        return { ...slice, notifications };
    }

    setShowExcluded(slice, showExcluded) {
        return { ...slice, showExcluded };
    }
}
