import { fade } from 'wonderland-ui-material/lib/styles/colorManipulator';
import amber from '@material-ui/core/colors/amber';
import blue from '@material-ui/core/colors/blue';
import blueGrey from '@material-ui/core/colors/blueGrey';
import brown from '@material-ui/core/colors/brown';
import createMuiTheme from 'wonderland-ui-material/lib/styles/createMuiTheme';
import cyan from '@material-ui/core/colors/cyan';
import deepOrange from '@material-ui/core/colors/deepOrange';
import grey from '@material-ui/core/colors/grey';
import indigo from '@material-ui/core/colors/indigo';
import lightGreen from '@material-ui/core/colors/lightGreen';
import red from '@material-ui/core/colors/red';
import teal from '@material-ui/core/colors/teal';

const wonderlandPalette = {
    accent: '#0098dc',
    active: '#4ec1e0',
    activeLite: '#B8CCE1',
    background: '#fff',
    backgroundActive: '#B8CCE1',
    backgroundAlt: '#F3F5F6',
    backgroundImage: '#C0C0C0',
    border: '#b9bfc6',
    disabled: '#b9bfc6',
    divider: '#efefef',
    emptyIconColor: '#E6F4FC',
    primary: '#1B344D',
    secondaryBtn: '#f6a716',
    secondary: '#2D5072',
    textPrimary: '#262626',
    textDisabled: '#b9bfc6',
    textSecondary: '#70767D',
    textReversed: '#fff',
    danger: '#d03e3b',
    info: '#4ec1e0',
    success: '#80ba4c',
    warning: '#eab24c',
    lite: '#efefef',
    primary1Color: '#1B344D',
    primary2Color: '#2D5072',
    primary3Color: '#2D5072',
    accent1Color: '#0098dc',
    accent2Color: '#4ec1e0',
    accent3Color: '#B8CCE1',
    textColor: '#262626',
    secondaryTextColor: '#70767D',
    alternateTextColor: '#fff',
    canvasColor: '#fff',
    alternateCanvasColor: '#F3F5F6',
    borderColor: '#b9bfc6',
    disabledColor: '#b9bfc6'
};

const marginSizes = {
    huge: '120px',
    large: '40px',
    medium: '30px',
    default: '20px',
    small: '10px',
    tiny: '5px'
};

const textSizes = {
    xxLarge: '36px',
    xLarge: '30px',
    large: '24px',
    medium: '18px',
    small: '14px',
    tiny: '12px',
    xTiny: '10px',
    default: '14px'
};

// shared wonderland layouts
const layout = {
    appLeftColumn: {
        flex: '1 1 20%',
        minWidth: '300px'
    },
    appMainColumn: {
        marginLeft: marginSizes.default,
        flex: '3 1 80%'
    },
    container: {
        justifyContent: 'center'
    },
    controls: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-end',
        marginTop: marginSizes.large
    },
    mainContent: {
        padding: `${marginSizes.default} ${marginSizes.default} 0`
    },
    tabContent: {
        margin: `${marginSizes.large} 0 ${marginSizes.default} 0`
    }
};

const hoverOpacity = 0.6;

const muiThemeV4 = createMuiTheme({
    layout: layout,
    marginSizes: marginSizes,
    textSizes: textSizes,
    typography: {
        useNextVariants: true
    },
    tab: {
        fontSize: 16
    },
    palette: {
        ...wonderlandPalette,
        primary: {
            main: wonderlandPalette.primary,
            contrastText: wonderlandPalette.backgroundAlt
        },
        secondary: {
            main: wonderlandPalette.secondary
        },
        error: {
            main: wonderlandPalette.danger
        },
        text: {
            primary: wonderlandPalette.textPrimary,
            secondary: wonderlandPalette.textSecondary,
            disabled: wonderlandPalette.textDisabled
        },
        divider: wonderlandPalette.divider,
        background: {
            default: wonderlandPalette.background,
            active: wonderlandPalette.backgroundActive,
            alternative: wonderlandPalette.backgroundAlt,
            image: wonderlandPalette.backgroundImage
        },
        action: {
            active: wonderlandPalette.accent,
            activeLite: wonderlandPalette.activeLite,
            selected: wonderlandPalette.active,
            disabled: wonderlandPalette.disabled
        },
        assetTypeColor: {
            black: {
                backgroundColor: grey[900]
            },
            teal: {
                backgroundColor: teal[300]
            },
            brown: {
                backgroundColor: brown[300]
            },
            blue: {
                backgroundColor: blue[300]
            },
            green: {
                backgroundColor: lightGreen[300]
            },
            grey: {
                backgroundColor: blueGrey[300]
            },
            amber: {
                backgroundColor: amber[300]
            },
            purple: {
                backgroundColor: indigo[300]
            },
            orange: {
                backgroundColor: deepOrange[300]
            },
            red: {
                backgroundColor: red[300]
            },
            cyan: {
                backgroundColor: cyan[300]
            }
        },
        disabledColor: wonderlandPalette.disabled,
        dangerColor: wonderlandPalette.danger,
        dividerColor: wonderlandPalette.divider,
        infoColor: wonderlandPalette.info,
        successColor: wonderlandPalette.success,
        warningColor: wonderlandPalette.warning,
        emptyIconColor: wonderlandPalette.emptyIconColor
    },
    overrides: {
        // Name of the component ⚛️ / style sheet
        MuiInputBase: {
            // Name of the rule
            input: {
                fontSize: 18,
                // increase the specificity for the pseudo class
                '&$inputMultiline': {
                    borderBottom: 'none'
                }
            }
        },
        MuiInput: {
            underline: {
                '&$focused': {
                    '&:after': { borderBottom: `2px solid ${wonderlandPalette.accent}` }
                }
            }
        },
        MuiInputLabel: {
            root: {
                '&$focused': {
                    color: wonderlandPalette.accent
                }
            }
        },
        MuiFormLabel: {
            root: {
                fontSize: 16,
                color: wonderlandPalette.textDisabled
            },
            asterisk: {
                color: wonderlandPalette.danger
            }
        },
        MuiButton: {
            text: {
                color: wonderlandPalette.accent,
                '&:hover': {
                    color: wonderlandPalette.active
                }
            },
            textPrimary: {
                color: wonderlandPalette.accent
            },
            containedSecondary: {
                backgroundColor: wonderlandPalette.secondaryBtn,
                '&:hover': {
                    backgroundColor: fade(wonderlandPalette.secondaryBtn, hoverOpacity),

                    // Reset on touch devices, it doesn't add specificity
                    '@media (hover: none)': {
                        backgroundColor: wonderlandPalette.secondaryBtn
                    }
                }
            }
        },
        MuiChip: {
            deleteIcon: {
                fontSize: 16
            },
            icon: {
                fontSize: 16
            },
            label: {
                display: 'inline-block',
                textOverflow: 'ellipsis',
                overflow: 'hidden'
            },
            root: {
                borderRadius: 4,
                fontSize: 12,
                height: 24,
                margin: 2,
                maxWidth: 8 * 28
            }
        },
        MuiExpansionPanel: {
            root: {
                '&:before': {
                    height: 0
                }
            },
            expanded: { margin: 0 }
        },
        MuiIconButton: {
            colorPrimary: {
                color: wonderlandPalette.accent
            }
        },
        MuiPickersClock: {
            pin: {
                backgroundColor: wonderlandPalette.secondary
            }
        },
        MuiPickersClockNumber: {
            clockNumber: {
                color: wonderlandPalette.textPrimary
            }
        },
        MuiPickersClockPointer: {
            pointer: {
                backgroundColor: wonderlandPalette.secondary
            },
            thumb: {
                border: `14px solid ${wonderlandPalette.secondary}`
            }
        },
        MuiPickersDay: {
            day: {
                color: wonderlandPalette.textPrimary
            },
            current: {
                color: wonderlandPalette.secondary
            }
        },
        MuiPickerDTTabs: {
            tabs: {
                backgroundColor: wonderlandPalette.background,
                color: wonderlandPalette.secondary
            }
        },
        MuiPickersModal: {
            dialogAction: {
                color: wonderlandPalette.secondaryBtn
            }
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: wonderlandPalette.active
            }
        },
        MuiTab: {
            label: {
                fontSize: 16,
                fontWeight: 400
            }
        },
        MuiDrawer: {
            modal: {
                zIndex: 1500
            }
        },
        MuiTypography: {
            h4: {
                fontWeight: 300
            },
            h5: {
                fontWeight: 300
            },
            h6: {
                fontWeight: 300
            },
            subtitle1: {
                lineHeight: 1.3
            },
            overline: {
                color: blueGrey[300],
                lineHeight: 'initial'
            }
        }
    },
    props: {
        MuiButtonBase: {
            disableRipple: isIE()
        }
    }
});

function isIE() {
    return /Trident\/|MSIE/.test(window.navigator.userAgent);
}

const muiTheme = muiThemeV4;
const palette = wonderlandPalette;

export { muiTheme, palette };
