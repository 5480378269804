import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

const MetadataItemComponent = ({ classes: { icon, item, text, textDense }, icons, label, value }) => {
    const itemIcon = icons[label];

    return (
        <ListItem classes={{ root: item }} dense disabled disableGutters>
            <Tooltip title={label}>
                <ListItemIcon classes={{ root: icon }}>{itemIcon}</ListItemIcon>
            </Tooltip>
            <ListItemText classes={{ root: text, textDense: textDense }} secondary={value} />
        </ListItem>
    );
};

MetadataItemComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    icons: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
};

export default MetadataItemComponent;
