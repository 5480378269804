import { branch, compose, lifecycle, renderNothing } from 'recompose';
import { connectSlice } from 'wonderland-ui-commons';
import { createSelector } from 'reselect';
import { identity } from 'lodash';
import ProfileActions from './ProfileActions';
import ProfileController from './ProfileController';
import ProfileSlice from './ProfileSlice';
import withDefaultPreferences from './withDefaultPreferences';

const fetchProfileSelector = createSelector(
    props => props.defaultPreferences,
    defaultPreferences =>
        (...args) =>
            ProfileController.fetchProfile(defaultPreferences, ...args)
);

const saveProfileSelector = createSelector(
    props => props.defaultPreferences,
    defaultPreferences =>
        (...args) =>
            ProfileController.saveProfile(defaultPreferences, ...args)
);

const bulkSaveUserProfilesSelector = createSelector(
    props => props.otherPreferences,
    otherPreferences =>
        (...args) =>
            ProfileController.bulkSaveUserProfiles(otherPreferences, ...args)
);

export default function withProfile(mapSliceStateToProps = identity) {
    return compose(
        withDefaultPreferences,
        connectSlice({ slice: ProfileSlice, actions: ProfileActions }, (slice, props) => ({
            ...mapSliceStateToProps(slice),
            profile: slice.profile,
            fetchProfile: fetchProfileSelector(props),
            saveProfile: saveProfileSelector(props),
            bulkSaveUserProfiles: bulkSaveUserProfilesSelector(props)
        })),
        lifecycle({
            componentDidMount() {
                if (!this.props.profile) {
                    this.props.fetchProfile();
                }
            }
        }),
        branch(props => !props.profile, renderNothing)
    );
}
