import {
    Icon,
    IconButton,
    InputAdornment,
    LinearProgress,
    Paper,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import BreadCrumbsList from './ResultsList/BreadCrumbsList';
import ClearIcon from '@material-ui/icons/HighlightOff';
import CloseIcon from '@material-ui/icons/Close';
import ContentSlider from 'app/shared/ContentSlider';
import DestinationPickerSideSheet from 'app/shared/DestinationPickerSideSheet';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ResultsList from './ResultsList';

import withStyles from '@material-ui/core/styles/withStyles';

/* istanbul ignore next */
const sheet = theme => ({
    appBarToplevel: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '65px',
        width: theme.spacing.unit * 96
    },
    appBar: {
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        paddingBottom: theme.spacing.unit * 2,
        paddingLeft: theme.spacing.unit,
        top: theme.spacing.unit * 19
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: `${theme.spacing.unit * 4} 0`,
        width: '100%'
    },
    content: {
        display: 'flex',
        height: `calc(100% - ${theme.spacing.unit * 13}px)`,
        overflowY: 'auto',
        padding: theme.spacing.unit * 2,
        flexDirection: 'column'
    },
    errorText: {
        color: theme.palette.disabledColor
    },
    linearProgress: {
        width: '100%'
    },
    paper: {
        height: '100%',
        overflowY: 'hidden'
    }
});

@withStyles(sheet)
export default class S3BrowserSideSheetComponent extends Component {
    static propTypes = {
        breadcrumbs: PropTypes.arrayOf(PropTypes.string),
        classes: PropTypes.object,
        closeDrawer: PropTypes.func,
        continuationTokens: PropTypes.arrayOf(PropTypes.string),
        currentFolderPath: PropTypes.string,
        defaultCriteria: PropTypes.string.isRequired,
        destination: PropTypes.object,
        drillIntoFolder: PropTypes.func,
        enableMultiSelect: PropTypes.bool.isRequired,
        error: PropTypes.string,
        isOpen: PropTypes.bool,
        limit: PropTypes.number,
        loading: PropTypes.bool,
        modal: PropTypes.bool,
        moreItems: PropTypes.bool,
        nextToken: PropTypes.string,
        onSelectFilesOrFolder: PropTypes.func,
        previousItems: PropTypes.bool,
        reset: PropTypes.func,
        resetDrawerAndDestination: PropTypes.func,
        displayResults: PropTypes.arrayOf(PropTypes.object),
        searchTerm: PropTypes.string,
        updateSearchTerm: PropTypes.func,
        clearSearchTerm: PropTypes.func,
        seeNextResults: PropTypes.func,
        seePreviousResults: PropTypes.func,
        selectBreadCrumb: PropTypes.func,
        addDestination: PropTypes.func,
        selected: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
        slide: PropTypes.string,
        slideIndex: PropTypes.number,
        useEnhancedSearch: PropTypes.bool,
        variant: PropTypes.string
    };

    render() {
        const {
            addDestination,
            breadcrumbs,
            classes,
            clearSearchTerm,
            closeDrawer,
            defaultCriteria,
            destination,
            displayResults,
            drillIntoFolder,
            enableMultiSelect,
            error,
            loading,
            moreItems,
            modal,
            onSelectFilesOrFolder,
            previousItems,
            reset,
            resetDrawerAndDestination,
            searchTerm,
            seeNextResults,
            seePreviousResults,
            selectBreadCrumb,
            selected,
            slide,
            slideIndex,
            updateSearchTerm,
            variant = 'file' // file | folder | imf | sidecar
        } = this.props;

        return (
            <div>
                {isEmpty(destination) ? (
                    <DestinationPickerSideSheet
                        dense={true}
                        modal={true}
                        addDestination={addDestination}
                        closeDrawer={closeDrawer}
                        title={'Select A Remote Origin'}
                        namingMap={{ Destination: 'Origin' }}
                        overrideDefaultCriteria={defaultCriteria}
                    />
                ) : (
                    <div className={classes.appBarToplevel}>
                        <div className={classes.appBar}>
                            <Typography variant="h5">
                                Select {variant === 'imf' ? 'IMF' : ''} from {destination.name}
                            </Typography>
                            <div>
                                <Tooltip aria-label="Clear All" title="Clear All">
                                    <IconButton onClick={resetDrawerAndDestination}>
                                        <ClearIcon color="secondary" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip aria-label="Close" title="Close">
                                    <IconButton onClick={closeDrawer}>
                                        <CloseIcon fontSize="small" color="secondary" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                        <Paper className={classes.paper}>
                            <div className={classes.content}>
                                <BreadCrumbsList
                                    breadCrumbs={breadcrumbs}
                                    selectBreadCrumb={selectBreadCrumb}
                                    reset={reset}
                                    root={destination.name}
                                />
                                <TextField
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={clearSearchTerm} size="small">
                                                    <Icon fontSize="small">clear</Icon>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon>search</Icon>
                                            </InputAdornment>
                                        )
                                    }}
                                    onChange={updateSearchTerm}
                                    value={searchTerm}
                                    helperText={
                                        destination.enhanced_search === 'Yes'
                                            ? 'Type at least 4 characters to start a search'
                                            : ''
                                    }
                                />
                                {loading && <LinearProgress className={classes.linearProgress} />}
                                {error ? (
                                    <div className={classes.container}>
                                        <Typography variant={'h4'} align={'center'} className={classes.errorText}>
                                            {error}
                                        </Typography>
                                        <Typography variant={'h6'} align={'center'} className={classes.errorText}>
                                            You do not have authorization to view the contents of this bucket.
                                        </Typography>
                                    </div>
                                ) : (
                                    <ContentSlider slide={slide} duration={300}>
                                        <ResultsList
                                            drillIntoFolder={drillIntoFolder}
                                            enableMultiSelect={enableMultiSelect}
                                            key={slideIndex}
                                            loading={loading}
                                            modal={modal}
                                            moreItems={moreItems}
                                            onSelectFilesOrFolder={onSelectFilesOrFolder}
                                            previousItems={previousItems}
                                            results={displayResults}
                                            seeNextResults={seeNextResults}
                                            seePreviousResults={seePreviousResults}
                                            selected={selected}
                                            variant={variant}
                                        />
                                    </ContentSlider>
                                )}
                            </div>
                        </Paper>
                    </div>
                )}
            </div>
        );
    }
}
