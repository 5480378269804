export default class BaseFileSelectionSlice {
    initialState() {
        return {
            isFolder: false,
            destination: {},
            remoteOrigin: false,
            selected: {}
        };
    }

    onReset() {
        return this.initialState();
    }

    onClearDestination(slice) {
        return {
            ...slice,
            destination: {},
            selected: {}
        };
    }

    onUpdateDestination(slice, destination) {
        return {
            ...slice,
            destination
        };
    }

    onUpdateFolderSelection(slice, isFolder) {
        return {
            ...slice,
            isFolder
        };
    }

    onUpdateRemoteOrigin(slice, remoteOrigin) {
        return {
            ...slice,
            remoteOrigin
        };
    }

    onUpdateSelected(slice, selected) {
        return {
            ...slice,
            selected
        };
    }
}
