import { isPlainObject } from 'lodash';
import { reducerRegistry } from 'lib/redux';
import { registerSliceReducer, sliceReducer, subscribe } from 'react-redux-boilerout';

function objectDefaults(argTarget, argDefaults) {
    const target = argTarget || {};
    const defaults = argDefaults || {};
    const output = { ...defaults, ...target };

    Object.keys(defaults)
        .filter(key => isPlainObject(target[key]) && isPlainObject(defaults[key]))
        .forEach(key => {
            output[key] = { ...defaults[key], ...output[key] };
        });

    return output;
}

function withDefaultPreferences(profile, defaultPreferences) {
    return { ...profile, preferences: objectDefaults(profile.preferences, defaultPreferences) };
}

@registerSliceReducer({ registry: reducerRegistry })
@sliceReducer('Profile')
@subscribe({ namespace: 'Profile' })
export default class ProfileSlice {
    initialState() {
        return { profileUpdates: {}, profile: null, isProfileDirty: false };
    }

    onProfileFetched(slice, profile, defaultPreferences) {
        return { ...slice, profile: withDefaultPreferences(profile, defaultPreferences) };
    }

    onUpdateProfile(slice, changes) {
        return { ...slice, profileUpdates: { ...slice.profileUpdates, ...changes }, isProfileDirty: true };
    }

    onUpdatePreferences(slice, changes) {
        return {
            ...slice,
            profileUpdates: {
                ...slice.profileUpdates,
                preferences: { ...slice.profileUpdates.preferences, ...changes }
            },
            isProfileDirty: true
        };
    }

    onResetProfile(slice) {
        return { ...slice, profile: slice.profile, isProfileDirty: false };
    }
}
