import { urlParams, xhr } from 'wonderland-ui-commons';
import config from 'app/config';
import SearchCriterionBuilder from 'wonderland-ui-discovery/lib/Search/SearchCriterionBuilder';

const prefix = `${config.apiHostname}/meta/v1`;

async function getCollectionAssets(id, { skip, limit, skipCount = 'false' } = {}) {
    return xhr
        .json(`${prefix}/collection/${id}?${urlParams({ skip, limit, skipCount })}`, {
            method: 'get'
        })
        .then(response => {
            if (skipCount === 'false' && response.total === -1) {
                return xhr
                    .json(`${prefix}/collection/${id}?${urlParams({ skip: 0, limit: 1, skipCount })}`, {
                        method: 'get'
                    })
                    .then(r => ({ ...response, total: r.total }));
            }
            return response;
        });
}

function addManyToCollection(collectionId, assetIds) {
    return xhr.json(`${prefix}/collection/${collectionId}`, {
        method: 'post',
        body: JSON.stringify({ asset_ids: assetIds })
    });
}

function removeManyFromCollection(collectionId, assetIds) {
    return xhr.json(`${prefix}/collection/${collectionId}`, {
        method: 'delete',
        body: JSON.stringify({
            asset_ids: assetIds,
            criteria: [SearchCriterionBuilder.buildIdCriterion(assetIds)],
            updateCriteria: [SearchCriterionBuilder.buildPullCriterion('collections', [collectionId])]
        })
    });
}

function getSubscribe() {
    return xhr.json(`${prefix}/subscribe?`, {
        method: 'get'
    });
}

function addToSubscribes(id) {
    return xhr.json(`${prefix}/subscribe/${id}?`, {
        method: 'post'
    });
}

function removeFromSubscribes(id) {
    return xhr.json(`${prefix}/subscribe/${id}?`, {
        method: 'delete'
    });
}

function getLightbox({ skip, limit } = {}) {
    return xhr.json(`${prefix}/lightbox?${urlParams({ skip, limit })}`, {
        method: 'get'
    });
}

function addToLightbox(id) {
    return xhr.json(`${prefix}/lightbox/${id}`, {
        method: 'post'
    });
}

function addManyToLightbox(ids) {
    return xhr.json(`${prefix}/lightbox`, {
        method: 'post',
        body: JSON.stringify({ asset_ids: ids })
    });
}

function removeFromLightbox(id) {
    return xhr.json(`${prefix}/lightbox/${id}`, {
        method: 'delete'
    });
}

function removeManyFromLightbox(ids) {
    return xhr.json(`${prefix}/lightbox`, {
        method: 'delete',
        body: JSON.stringify({ asset_ids: ids })
    });
}

function getCheckout({ skip, limit } = {}) {
    return xhr.json(`${prefix}/checkout?${urlParams({ skip, limit })}`, {
        method: 'get'
    });
}

function addToCheckouts(id, auto) {
    return xhr.json(`${prefix}/checkout/${id}?${urlParams({ auto })}`, {
        method: 'post'
    });
}

function addManyToCheckouts(ids) {
    return xhr.json(`${prefix}/checkout`, {
        method: 'post',
        body: JSON.stringify(ids)
    });
}

function removeFromCheckouts(id) {
    return xhr.json(`${prefix}/checkout/${id}`, {
        method: 'delete'
    });
}

function removeManyFromCheckouts(ids) {
    return xhr.json(`${prefix}/checkout`, {
        method: 'delete',
        body: JSON.stringify(ids)
    });
}

function isLockableByUser(id) {
    return xhr.json(`${prefix}/checkout/${id}`, {
        method: 'get'
    });
}

function clearLightbox() {
    return xhr.json(`${prefix}/lightbox/clear`, {
        method: 'delete'
    });
}

function checkinAll() {
    return xhr.json(`${prefix}/checkout/clear`, {
        method: 'delete'
    });
}

function clearCollection(id) {
    return xhr.json(`${prefix}/collection/${id}/clear`, {
        method: 'delete',
        body: JSON.stringify({
            criteria: [SearchCriterionBuilder.buildCollectionCriterion('collections', [id])],
            updateCriteria: [SearchCriterionBuilder.buildPullCriterion('collections', [id])]
        })
    });
}

function getCollections({ skip, limit, filter } = {}) {
    return xhr.json(`${prefix}/collection?${urlParams({ skip, limit, filter })}`, {
        method: 'get'
    });
}

function getTitleThumbnails(titleId) {
    return xhr.json(`${prefix}/titlethumbnail/${titleId}`, {
        method: 'get'
    });
}

function getChildren(id, params = {}) {
    return xhr.json(`${prefix}/relationships/children/${id}?${urlParams(params)}`, { method: 'get' });
}

function getParents(id, params = {}) {
    return xhr.json(`${prefix}/relationships/parents/${id}?${urlParams(params)}`, { method: 'get' });
}

function createRelationships(relationships) {
    return xhr.json(`${prefix}/relationships`, { method: 'post', body: JSON.stringify(relationships) });
}

function createAttachments(attachments) {
    return xhr.json(`${prefix}/attachments`, { method: 'post', body: JSON.stringify(attachments) });
}

function deleteRelationships(relationships) {
    return xhr.json(`${prefix}/relationships`, { method: 'delete', body: JSON.stringify(relationships) });
}

export default {
    getCollectionAssets,
    addManyToCollection,
    removeManyFromCollection,
    getSubscribe,
    addToSubscribes,
    removeFromSubscribes,
    getLightbox,
    addToLightbox,
    addManyToLightbox,
    removeFromLightbox,
    removeManyFromLightbox,
    getCheckout,
    addToCheckouts,
    addManyToCheckouts,
    removeFromCheckouts,
    removeManyFromCheckouts,
    isLockableByUser,
    clearLightbox,
    checkinAll,
    clearCollection,
    getCollections,
    getTitleThumbnails,
    getChildren,
    getParents,
    createRelationships,
    deleteRelationships,
    createAttachments
};
