import { endsWith, get, isEmpty } from 'lodash';
import { getErrors, STEPS as IMP_STEPS } from './impUtils';
import BaseFileSelectionSlice from './BaseFileSelectionSlice';

export default class BaseIMPFileSelectionSlice extends BaseFileSelectionSlice {
    initialState() {
        return {
            ...super.initialState(),
            assetmap: '',
            cpl: '',
            errors: [],
            essences: [],
            filenames: [],
            jobId: '',
            impStep: IMP_STEPS.SELECT_FOLDER,
            pkl: '',
            replaceComparison: {},
            startPropagating: false,
            startRedirecting: false,
            startUploading: false,
            startValidating: false
        };
    }

    onClearDestination(slice) {
        return {
            ...super.onClearDestination(slice),
            assetmap: '',
            cpl: '',
            errors: [],
            essences: [],
            filenames: [],
            jobId: '',
            impStep: IMP_STEPS.SELECT_FOLDER,
            pkl: '',
            replaceComparison: {},
            startPropagating: false,
            startUploading: false,
            startValidating: false
        };
    }

    onPropagating(slice) {
        return { ...slice, startPropagating: false };
    }

    onReceivedIngestNotification(slice, notification) {
        const { impStep, jobId: sliceJobId } = slice;
        const { payload } = notification;
        const { jobId } = payload;
        const isJob = sliceJobId === jobId;

        if (isJob) {
            switch (impStep) {
                case IMP_STEPS.VALIDATION_COMPLETE:
                    return this.onUpdateStepIngest(slice, payload);
                default:
                    return slice;
            }
        }

        return slice;
    }

    onReceivedPropagationNotification(slice, notification) {
        const { impStep, jobId: sliceJobId } = slice;
        const { payload } = notification;
        const { jobId } = payload;
        const isJob = sliceJobId === jobId;

        if (isJob) {
            switch (impStep) {
                case IMP_STEPS.VALIDATION_COMPLETE:
                    return this.onUpdateStepPropagation(slice, payload);
                default:
                    return slice;
            }
        }

        return slice;
    }

    onReceivedValidationNotification(slice, notification) {
        const { impStep, jobId: sliceJobId } = slice;
        const { payload } = notification;
        const { jobId } = payload;
        const isJob = sliceJobId === jobId;

        if (isJob) {
            switch (impStep) {
                case IMP_STEPS.FETCH_FILENAMES:
                    return this.onUpdateStepFetchFilenames(slice, payload);
                case IMP_STEPS.FETCH_FILENAMES_COMPLETE:
                    return this.onFetchFilenamesSuccess(slice, payload);
                case IMP_STEPS.VALIDATING:
                    return this.onUpdateStepValidation(slice, payload);
                default:
                    return slice;
            }
        }

        return slice;
    }

    onReset() {
        return this.initialState();
    }

    onUpdateStepFetchFilenames(slice, payload) {
        const { impStep: currentImpStep } = slice;
        const { filenames: payloadFilenames, jobId, success } = payload;
        const errors = getErrors(payload);
        const filenames = ['ASSETMAP.xml', ...payloadFilenames];
        const updatedFilenames = this.onUpdateFilenames(slice, filenames);
        const startValidating = isEmpty(errors) && success;
        const impStep = startValidating ? IMP_STEPS.FETCH_FILENAMES_COMPLETE : currentImpStep;

        return { ...updatedFilenames, errors, impStep, jobId, startValidating };
    }

    onFetchFilenamesSuccess(slice, payload) {
        const stepValidation = this.onUpdateStepValidation(slice, payload);

        return { ...stepValidation, startUploading: true };
    }

    onUpdateStepIngest(slice, payload) {
        const { jobId, success } = payload;
        const errors = getErrors(payload);
        const impStep = isEmpty(errors) && success ? IMP_STEPS.INGESTING : IMP_STEPS.INGEST_FAILED;
        const startRedirecting = isEmpty(errors) && success;

        return { ...slice, errors, impStep, jobId, startRedirecting };
    }

    onUpdateStepPropagation(slice, payload) {
        const { replaceComparison: sliceReplaceComparison } = slice;
        const { comparison, skip, success } = payload;
        const errors = getErrors(payload);
        const startPropagating = isEmpty(errors) && !success;
        const replaceComparison = skip ? sliceReplaceComparison : comparison;

        return { ...slice, errors, replaceComparison, startPropagating };
    }

    onUpdateStepValidation(slice, payload) {
        const { files, jobId, success } = payload;
        const errors = getErrors(payload);
        const cpl = get(files, 'cpls[0]');
        const impStep = isEmpty(errors) && success ? IMP_STEPS.VALIDATION_COMPLETE : IMP_STEPS.VALIDATION_FAILED;
        const pkl = get(files, 'pkls[0]');

        return { ...slice, cpl, errors, impStep, jobId, pkl };
    }

    onUpdateFilenames(slice, filenames) {
        const xml = filenames.filter(file => endsWith(file, 'ASSETMAP.xml'));
        const assetmap = get(xml, '0', '');
        const essences = filenames.filter(file => endsWith(file, 'mxf'));

        return { ...slice, assetmap, essences, filenames };
    }

    onUpdateFilenamesAndAdvanceStep(slice, payload) {
        const { filenames: payloadFilenames } = payload;
        const filenames = ['ASSETMAP.xml', ...payloadFilenames];
        const impStep = IMP_STEPS.VALIDATING;
        const startValidating = true;
        const updatedFilenames = this.onUpdateFilenames(slice, filenames);

        return { ...updatedFilenames, impStep, startValidating };
    }

    onUpdateImpStep(slice, impStep) {
        return { ...slice, impStep };
    }

    onUpdateJobId(slice, jobId) {
        return { ...slice, jobId };
    }

    onIngestSuccess(slice) {
        return { ...slice, startRedirecting: true };
    }

    onRedirecting(slice) {
        return { ...slice, startRedirecting: false };
    }

    onUploading(slice) {
        return { ...slice, startUploading: false };
    }

    onValidating(slice) {
        return { ...slice, startValidating: false };
    }
}
