import { actionDispatcher } from 'react-redux-boilerout';

@actionDispatcher({
    namespace: 'S3Browser',
    actions: [
        'clearSearchTerm',
        'drillIntoFolder',
        'getContainerContentCompleted',
        'getContainerContentRequested',
        'getS3BucketContentCompleted',
        'getS3BucketContentRequested',
        'getS3BucketContentNotAuthorized',
        'popBreadCrumb',
        'reset',
        'seeEnhancedSearchNextResults',
        'seeEnhancedSearchPreviousResults',
        'seeNextResults',
        'seePreviousResults',
        'selectBreadCrumb',
        'updateSearchTerm'
    ]
})
export default class S3BrowserSideSheetActions {}
