import { isUndefined, some } from 'lodash';
import { reducerRegistry } from 'lib/redux';
import { registerSliceReducer, sliceReducer, subscribe } from 'react-redux-boilerout';
import assign from 'lodash/assign';
import cloneDeep from 'lodash/cloneDeep';
import config from 'app/config';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import set from 'lodash/set';
import toPairs from 'lodash/toPairs';

const defaultContentPolicyResults = {
    total: 0,
    watermarked: 0,
    watermarkedIds: []
};

function formatContentPolicyResults(rules, total) {
    const results = assign({}, defaultContentPolicyResults, { total });
    if (!isEmpty(rules)) {
        return toPairs(rules).reduce(
            (a, [id, rule]) => {
                if (get(rule, 'security_product', 'none') !== 'none') {
                    a.watermarked += 1;
                    a.watermarkedIds.push(id);
                }
                return a;
            },
            assign({}, results)
        );
    }
    return results;
}

function formatOptionValue(name, value) {
    return name === 'removeWatermark' ? !value : value;
}

@registerSliceReducer({ registry: reducerRegistry })
@sliceReducer('DeliveryForm')
@subscribe({ namespace: 'DeliveryForm' })
export default class DeliveryFormSliceReducer {
    initialState() {
        return {
            deliveryFormFields: get(config, 'deliveryFormFields', {}),
            assetGrants: {},
            selectedItems: [],
            onBehalfOf: [],
            onBehalfOfEmail: '',
            notes: '',
            destinationDigital: null,
            destinationPhysical: null,
            typeSelected: 'destinationDigital',
            physical_due_date: moment().add(90, 'days').toDate(),
            physicalReturnDate: moment().add(90, 'days').toDate(),
            userSearchResults: [],
            distributionListSearchResults: [],
            stats: null,
            destinationOptions: {},
            tab: get(config, 'deliveryAdvancedTabs.defaultTab', ''),
            tabs: get(config, 'deliveryAdvancedTabs.tabs', []),
            burnIn: false,
            burnInText: '',
            profilePipelines: [...get(config, 'profilePipelines', [])],
            profileSelected: false,
            forensic: false,
            priority: config.deliveryPriorityTypes.MEDIUM
        };
    }

    reset() {
        return this.initialState();
    }

    setSelectedItems(slice, selectedItems, assetGrants) {
        return { ...slice, selectedItems, assetGrants };
    }

    doneLoadingBulkStats(slice, response) {
        const stats = response.options.reduce((acc, curr) => {
            acc[curr.option] = curr.count;
            return acc;
        }, {});
        return { ...slice, stats };
    }

    onChangeDestination(slice, destination, rules) {
        return {
            ...slice,
            [slice.typeSelected]: assign({}, destination, {
                contentPolicyResults: formatContentPolicyResults(rules, get(slice, 'selectedItems.length'))
            })
        };
    }

    onDestinationClick(slice, typeSelected) {
        return { ...slice, typeSelected };
    }

    onNoteChange(slice, notes) {
        return { ...slice, notes };
    }

    onDateChange(slice, physicalReturnDate) {
        return { ...slice, physicalReturnDate };
    }

    onAddOnBehalfOf(slice, onBehalfOf, onBehalfOfEmail) {
        return { ...slice, onBehalfOf, onBehalfOfEmail };
    }

    onGetUsersPaginationCompleted(slice, userSearchResults) {
        return { ...slice, userSearchResults };
    }

    onGetDLSearchCompleted(slice, distributionListSearchResults) {
        return { ...slice, distributionListSearchResults };
    }

    onReturnDateChange(slice, physicalReturnDate) {
        return { ...slice, physicalReturnDate };
    }
    onDueDateChange(slice, physical_due_date) {
        return { ...slice, physical_due_date };
    }
    onPriorityChange(slice, priority) {
        return { ...slice, priority };
    }
    onAttentionToChange(slice, attention_to) {
        return { ...slice, attention_to };
    }
    onChargeNumberChange(slice, charge_number) {
        return { ...slice, charge_number };
    }
    onPONumberChange(slice, po_number) {
        return { ...slice, po_number };
    }

    onDestinationOptionInputChange(slice, action) {
        const { type, name, value, tab } = action;
        const inputChange = {};

        if (tab === 'original' || isUndefined(tab)) {
            inputChange.destinationOptions = set(
                cloneDeep(slice.destinationOptions),
                [type, name],
                formatOptionValue(name, value)
            );
        } else if (tab === 'profiles') {
            inputChange.forensic = value;
        }

        return { ...slice, ...inputChange };
    }

    onSetTab(slice, tab) {
        return { ...slice, tab };
    }

    onChangeBurnIn(slice, { burnInText = '', burnIn }) {
        return { ...slice, burnInText, burnIn };
    }

    onToggleChecked(slice, profileIndex) {
        const { profilePipelines } = slice;
        const checkedValue = !get(profilePipelines[profileIndex], 'checked');
        const updatedProfilePipelines = profilePipelines.map((profile, index) =>
            index === profileIndex ? { ...profile, checked: checkedValue } : profile
        );
        const profileSelected = some(updatedProfilePipelines, ['checked', true]);

        return { ...slice, profilePipelines: updatedProfilePipelines, profileSelected };
    }
}
