import { AnnouncementBanner } from 'wonderland-ui-announcements';
import { DestructiveActionDialog, Footer } from 'wonderland-ui-commons';
import { NewSavedSearchModal, NewSaveViewModal } from 'wonderland-ui-discovery';
import AddToCollectionModal from 'modals/AddToCollection';
import AppHeader from 'app/shared/AppHeader';
import AppHeaderMenu from 'app/shared/AppHeader/AppHeaderMenu';
import DuplicateAssetModal from 'modals/DuplicateAsset';
import IE11Flexbug3Fix from 'app/shared/IE11Flexbug3Fix';
import LinearProgress from 'app/shared/LinearProgress';
import PopUpNotifications from 'wonderland-ui-notifications/lib/PopUpNotifications';
import PropTypes from 'prop-types';
import React from 'react';
import SelectLocationModal from 'modals/SelectLocation';
import Toast from 'app/shared/Toast';
import TransferManager from 'lib/TransferManager';
import UserDataController from 'core/UserData/UserDataController';

const AppLayoutComponent = ({ children, classes, showFooter, userHasRole }) => {
    try {
        return (
            <IE11Flexbug3Fix>
                <AppHeader />
                <AppHeaderMenu />
                <LinearProgress />
                <div className={classes.announcement}>
                    <AnnouncementBanner appKey="wonderland" />
                </div>
                <main className={classes.main}>{children}</main>
                {showFooter() && <Footer userHasRole={userHasRole} />}
                <Toast />
                <AddToCollectionModal />
                <SelectLocationModal />
                <DuplicateAssetModal />
                <NewSavedSearchModal type="asset" UserDataController={UserDataController} />
                <NewSaveViewModal UserDataController={UserDataController} />
                <PopUpNotifications />
                <DestructiveActionDialog />
                <TransferManager />
            </IE11Flexbug3Fix>
        );
    } catch (e) {
        console.error('AppLayoutComponent::render', e);
    }
    return null;
};

AppLayoutComponent.propTypes = {
    children: PropTypes.any,
    classes: PropTypes.object.isRequired,
    showFooter: PropTypes.func.isRequired,
    userHasRole: PropTypes.func.isRequired
};

export default AppLayoutComponent;
