import { compose, withHandlers, withProps } from 'recompose';
import { connectSlice } from 'wonderland-ui-commons';
import { isArray, isNull, isPlainObject } from 'lodash';
import AddToCollectionModalActions from './AddToCollectionModalActions';
import AddToCollectionModalComponent from './AddToCollectionModalComponent';
import AddToCollectionModalSlice from './AddToCollectionModalSlice';
import assetTypeAvatar from 'lib/assetTypeAvatar';
import DiscoveryController from 'core/Discovery/DiscoveryController';
import MetaController from 'core/Meta/MetaController';
import moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';
import WonderlandDomainController from 'core/WonderlandDomain';

const { addManyToCollection } = MetaController;
const { addToCollection, bulkAddToCollection } = WonderlandDomainController;
const { getSearch } = DiscoveryController;

export default compose(
    connectSlice({ slice: AddToCollectionModalSlice, actions: AddToCollectionModalActions }),
    withProps(({ assetIdOrIds, collection }) => ({
        assetCount: isArray(assetIdOrIds) ? assetIdOrIds.length : 1,
        avatar: assetTypeAvatar.getAll('collection'),
        disabled: isNull(collection)
    })),
    withHandlers({
        handleChange:
            ({ setCollection }) =>
            collection =>
                setCollection(collection),
        handleClose:
            ({ hide }) =>
            () =>
                hide(),
        handleLoadOptions:
            ({ activeFacets, facetCriteria, index, limit, skip }) =>
            async term => {
                const criteria = [{ term, type: 'term' }, ...facetCriteria];
                const { results } = await getSearch(criteria, activeFacets, skip, limit, index);

                return results
                    .filter(result => result?.grants.includes('update'))
                    .map(({ id, name, created_at }) => ({
                        date: moment(created_at).format('MMM D YYYY, h:mm a'),
                        id,
                        name
                    }));
            },
        handleNoOptionsMessage: () => () => 'No collections found.',
        handleSubmit:
            ({ assetIdOrIds, collection, hide }) =>
            () => {
                const { id: collectionId, name: collectionName } = collection;

                if (isArray(assetIdOrIds)) {
                    addManyToCollection(collectionId, assetIdOrIds, collectionName);
                } else if (isPlainObject(assetIdOrIds)) {
                    bulkAddToCollection(collectionId, assetIdOrIds, collectionName);
                } else {
                    addToCollection(collectionId, assetIdOrIds, collectionName);
                }
                hide();
            }
    }),
    withStyles(({ palette: { assetTypeColor } }) => ({ ...assetTypeColor, listItemRoot: { width: 510 } }))
)(AddToCollectionModalComponent);
