import { compose, lifecycle, withProps } from 'recompose';
import { connectSlice } from 'wonderland-ui-commons';
import { extractFields } from 'lib/assetFields';
import { withFeatureFlagsLite } from 'core/Cache/withFeatureFlags';
import CacheSlice, { getControlledVocabulary } from 'core/Cache/CacheSlice';
import UploadAssetFormComponent from './UploadAssetFormComponent';
import withControlledVocabulary, { fetchControlledVocabulary } from 'core/Cache/withControlledVocabulary';
import withStyles from '@material-ui/core/styles/withStyles';

const [{ validators, singleFileValidator, singleFolderValidator, errorMessages }] = extractFields(['defaultFiles']);
const updatedValidators = [...validators, 'validatorFunction:required'];
const updatedErrorMessages = [...errorMessages, 'Please select a file.'];

export default compose(
    connectSlice({ slice: CacheSlice }, slice => ({
        ...slice,
        attachmentCv: getControlledVocabulary(slice, 'attachment')
    })),
    withProps({
        validators: updatedValidators,
        errorMessages: updatedErrorMessages,
        singleFileValidator,
        singleFolderValidator
    }),
    withFeatureFlagsLite(),
    lifecycle({
        componentDidMount() {
            fetchControlledVocabulary('attachment');
        }
    }),
    withStyles(theme => ({
        field: {
            display: 'flex',
            alignItems: 'center'
        },
        filePicker: {
            marginTop: theme.spacing.unit * 5
        },
        paper: {
            marginTop: theme.spacing.unit * 2,
            padding: theme.spacing.unit * 4
        },
        validationError: {
            color: theme.palette.error.main,
            marginTop: theme.spacing.unit
        }
    })),
    withControlledVocabulary
)(UploadAssetFormComponent);
