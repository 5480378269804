import { AssetStoreAPI } from 'core/apis';
import { autobind } from 'wonderland-ui-commons';
import { get } from 'lodash';
import { listen } from 'lib/events';
import { PopUpNotificationsActions } from 'wonderland-ui-notifications';
import config from 'app/config';
import PropTypes from 'prop-types';
import React from 'react';
import withDownload from 'core/withDownload';

@autobind
class TransferManager {
    @listen('asperaConnectionFailed')
    asperaConnectionFailed() {
        const installerUrl = get(config, ['asperaFailureSpec', 'installerUrl']);
        const message = get(
            config,
            ['asperaFailureSpec', 'message'],
            'Please make sure you have the latest version installed and running'
        );
        const title = get(config, ['asperaFailureSpec', 'title'], 'Unable to connect to Aspera');

        PopUpNotificationsActions.showNotification({
            actions: [
                {
                    link: installerUrl,
                    type: 'download_aspera',
                    icon: 'download',
                    label: 'Download Aspera',
                    available: true
                }
            ],
            created_at: new Date(),
            description: message,
            id: new Date(),
            status: 'successWithErrors',
            statusLabel: title
        });
    }

    @listen('notificationActionFired')
    notificationActionFired(...args) {
        this.externalNotificationActionFired(...args);
    }

    setExternalNotificationActionFired(externalNotificationActionFired) {
        this.externalNotificationActionFired = externalNotificationActionFired;
    }
}

const transferManager = new TransferManager();

@withDownload({
    key: 'downloadAsset',
    httpDownloadTransferSpecProvider: AssetStoreAPI.getHttpDownloadTransferSpec,
    managedDownloadTransferSpecProvider: AssetStoreAPI.getManagedDownloadTransferSpec
})
export default class TransferManagerComponent extends React.Component {
    static propTypes = {
        downloadAsset: PropTypes.func
    };

    constructor(props) {
        super(props);
        transferManager.setExternalNotificationActionFired(this.notificationActionFired);
    }

    render() {
        return null;
    }

    notificationActionFired = (notification, action) => {
        if (action.type === 'download') {
            const assetId = notification.body.meta_id;
            this.props.downloadAsset({ assetId });
        }
    };
}
