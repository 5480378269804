import { asId, byId, byMigrationOnlyValues, byParentId, bySelectable, defaultEmptyValue } from 'lib/vocabularyUtils';
import { isEmpty, negate } from 'lodash';
import { SelectValidator } from 'react-material-ui-form-validator';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';

const parseValue = (value, { multi }) => {
    // In order for `required` validation to work, `value` should be empty (e.g. empty array or empty object).
    if (isEmpty(value)) {
        return defaultEmptyValue(multi);
    }

    return multi ? (value || []).filter(negate(byMigrationOnlyValues)).map(asId) : asId(value || {});
};

const getSingleVocabValue = (vocabulary, prevValue, value) => {
    if (prevValue === value) {
        return null;
    } else {
        return vocabulary.find(byId(value));
    }
};

const adaptOnChange =
    (prevValue, onChange, { multi, vocabulary, name }) =>
    e => {
        const value = e.target.value;
        const parsedValue = multi
            ? value.map(id => vocabulary.find(byId(id)))
            : getSingleVocabValue(vocabulary, prevValue, value);
        onChange(name, parsedValue);
    };

const VocabularyDropdown = props => {
    const { floatingLabelText, value, onChange, multi, vocabulary = [], parentId, name, ...rest } = props;
    const filteredVocabulary = vocabulary.filter(byParentId(parentId)).filter(bySelectable(value));

    const parsedValue = parseValue(value, { multi });

    return (
        <SelectValidator
            value={parsedValue}
            onChange={adaptOnChange(parsedValue, onChange, { name, multi, vocabulary })}
            name={name}
            label={floatingLabelText}
            InputLabelProps={{ shrink: !isEmpty(value) }}
            SelectProps={{ multiple: multi }}
            {...rest}
        >
            {filteredVocabulary.map(({ id, value: label }) => (
                <MenuItem key={id} checked={multi && (parsedValue || []).includes(id)} value={id}>
                    {label}
                </MenuItem>
            ))}
        </SelectValidator>
    );
};

VocabularyDropdown.propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
    parentId: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    vocabulary: PropTypes.array,
    multi: PropTypes.bool,
    floatingLabelText: PropTypes.string
};

export default VocabularyDropdown;
