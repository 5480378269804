import { getEssenceMatchStatus, MESSAGES } from 'lib/impUtils';
import { orderBy } from 'lodash';
import { reducerRegistry } from 'lib/redux';
import { registerSliceReducer, sliceReducer, subscribe } from 'react-redux-boilerout';

@registerSliceReducer({ registry: reducerRegistry })
@sliceReducer('IMFReplacement')
@subscribe({ namespace: 'IMFReplacement' })
export default class IMFReplacementSliceReducer {
    initialState() {
        return {
            addedEssences: [],
            assetId: null,
            filterType: 'All',
            hideNew: false,
            hideOriginal: false,
            ingestData: {},
            isLoading: true,
            isPropating: false,
            isSequential: false,
            matchedAutoCount: 0,
            matchedEssences: [],
            message: '',
            newCplFileName: '',
            newCplUuid: '',
            newEssences: [],
            newUnresolvedEssences: [],
            notes: {},
            originalCplFileName: '',
            originalCplUuid: '',
            originalEssences: [],
            originalUnresolvedEssences: [],
            propagationData: {
                compositionUuid: null,
                essenceMappings: [],
                replaceAssetId: null
            },
            removedEssences: [],
            resolvedEssences: [],
            selectedNewEssences: [],
            selectedOriginalEssences: [],
            sequentialCallback: null,
            step: 'propagate',
            submit: false,
            unresolvedEssenceCount: 0
        };
    }

    onAdd(slice, essence) {
        const { addedEssences, newUnresolvedEssences, originalUnresolvedEssences, resolvedEssences } = slice;
        const { id: essenceId } = essence;
        essence.autoMatched = false;
        essence.operation = 'ADD';
        const added = [...addedEssences, essence];
        const resolved = [...resolvedEssences, essenceId];
        const newEssences = newUnresolvedEssences.filter(({ id }) => !resolved.includes(id));
        const originalEssences = originalUnresolvedEssences.filter(({ id }) => !resolved.includes(id));

        return {
            ...slice,
            addedEssences: added,
            filterType: 'All',
            newEssences,
            originalEssences,
            resolvedEssences: resolved,
            selectedNewEssences: [],
            selectedOriginalEssences: []
        };
    }

    onAddMany(slice) {
        const {
            addedEssences,
            newUnresolvedEssences,
            originalUnresolvedEssences,
            resolvedEssences,
            selectedNewEssences
        } = slice;

        const selectedEssences = selectedNewEssences.map(essence => {
            return { ...essence, autoMatched: false, operation: 'ADD' };
        });
        const added = [...addedEssences, ...selectedEssences];
        const essenceIds = selectedEssences.map(({ id }) => id);
        const resolved = [...resolvedEssences, ...essenceIds];
        const newEssences = newUnresolvedEssences.filter(({ id }) => !resolved.includes(id));
        const originalEssences = originalUnresolvedEssences.filter(({ id }) => !resolved.includes(id));

        return {
            ...slice,
            addedEssences: added,
            filterType: 'All',
            newEssences,
            originalEssences,
            resolvedEssences: resolved,
            selectedNewEssences: [],
            selectedOriginalEssences: []
        };
    }

    onAddNote(slice, name, value) {
        const { notes } = slice;
        const newNotes = { ...notes, [`${name}`]: value };

        return { ...slice, notes: newNotes };
    }

    onAddUndo(slice, essenceId) {
        const { addedEssences, newUnresolvedEssences, notes, originalUnresolvedEssences, resolvedEssences } = slice;
        const resolved = resolvedEssences.filter(id => id !== essenceId);
        const added = addedEssences.filter(({ id }) => id !== essenceId);
        const newEssences = newUnresolvedEssences.filter(({ id }) => !resolved.includes(id));
        const originalEssences = originalUnresolvedEssences.filter(({ id }) => !resolved.includes(id));
        const newNotes = Object.assign({}, notes);

        delete newNotes[essenceId];

        return {
            ...slice,
            addedEssences: added,
            filterType: 'All',
            newEssences,
            notes: newNotes,
            originalEssences,
            resolvedEssences: resolved,
            selectedNewEssences: [],
            selectedOriginalEssences: []
        };
    }

    onAutoMatch(slice, isFirstRun) {
        const { matchedAutoCount, matchedEssences, newEssences, originalEssences, resolvedEssences } = slice;
        let matched = [...matchedEssences];
        let resolved = [...resolvedEssences];

        newEssences.forEach(essence => {
            const { id: newId, metadata: newMetadata, resources: newResources } = essence;
            const { fileTypeDescription: newFileTypeDescription } = newMetadata;

            originalEssences.forEach(originalEssence => {
                const { id: originalId, metadata: originalMetadata, resources: originalResources } = originalEssence;
                const { fileTypeDescription: originalFileTypeDescription } = originalMetadata;

                if (
                    newFileTypeDescription === originalFileTypeDescription &&
                    !resolved.includes(originalId) &&
                    !resolved.includes(newId)
                ) {
                    const { match } = getEssenceMatchStatus(originalResources, newResources);

                    if (match) {
                        const newEssence = { ...essence, autoMatched: true, operation: 'REPLACE' };
                        matched.push({ newEssence, originalEssence });
                        resolved.push(...[newId, originalId]);
                    }
                }
            });
        });

        const newEssencesFiltered = newEssences.filter(({ id }) => !resolved.includes(id));
        const originalEssencesFiltered = originalEssences.filter(({ id }) => !resolved.includes(id));
        const newMatchedAutoCount = isFirstRun ? matched.length : matchedAutoCount;

        return {
            ...slice,
            matchedEssences: matched,
            matchedAutoCount: newMatchedAutoCount,
            newEssences: newEssencesFiltered,
            originalEssences: originalEssencesFiltered,
            resolvedEssences: resolved
        };
    }

    onCancel() {
        return this.initialState();
    }

    onClear(slice) {
        const { newUnresolvedEssences, originalUnresolvedEssences } = slice;

        return {
            ...slice,
            addedEssences: [],
            matchedEssences: [],
            newEssences: newUnresolvedEssences,
            notes: {},
            originalEssences: originalUnresolvedEssences,
            removedEssences: [],
            resolvedEssences: [],
            selectedNewEssences: [],
            selectedOriginalEssences: []
        };
    }

    onFilter(slice, filterType) {
        const { newUnresolvedEssences, originalUnresolvedEssences, resolvedEssences } = slice;
        const newEssences = newUnresolvedEssences.filter(({ id, metadata: { fileTypeDescription } }) =>
            filterType !== 'All'
                ? fileTypeDescription === filterType && !resolvedEssences.includes(id)
                : !resolvedEssences.includes(id)
        );
        const originalEssences = originalUnresolvedEssences.filter(({ id, metadata: { fileTypeDescription } }) =>
            filterType !== 'All'
                ? fileTypeDescription === filterType && !resolvedEssences.includes(id)
                : !resolvedEssences.includes(id)
        );

        return {
            ...slice,
            filterType,
            newEssences,
            originalEssences,
            selectedNewEssences: [],
            selectedOriginalEssences: []
        };
    }

    onInit(slice, assetId, ingestData, isSequential, replacementComparison, sequentialCallback) {
        const { propagationData } = slice;
        const {
            newCplFileName,
            newUnresolvedEssences,
            newCplUuid,
            originalCplFileName,
            originalUnresolvedEssences,
            originalCplUuid
        } = replacementComparison;
        const newPropagationData = { ...propagationData, compositionUuid: newCplUuid, replaceAssetId: assetId };
        const unresolvedEssenceCount = newUnresolvedEssences.length + originalUnresolvedEssences.length;

        return {
            ...slice,
            assetId,
            ingestData,
            isSequential,
            newCplFileName,
            newEssences: newUnresolvedEssences,
            newUnresolvedEssences,
            newCplUuid,
            propagationData: newPropagationData,
            originalCplFileName,
            originalEssences: originalUnresolvedEssences,
            originalUnresolvedEssences,
            originalCplUuid,
            sequentialCallback,
            unresolvedEssenceCount
        };
    }

    onInitComplete(slice) {
        return { ...slice, isLoading: false };
    }

    onMatch(slice) {
        const {
            matchedEssences,
            newUnresolvedEssences,
            originalUnresolvedEssences,
            resolvedEssences,
            selectedNewEssences: sNewEssence,
            selectedOriginalEssences: sOriginalEssence
        } = slice;
        const newEssence = sNewEssence[0];
        const originalEssence = sOriginalEssence[0];
        newEssence.autoMatched = false;
        newEssence.operation = 'REPLACE';
        const matched = [...matchedEssences, { newEssence, originalEssence }];
        const resolved = [...resolvedEssences, newEssence.id, originalEssence.id];
        const newEssences = newUnresolvedEssences.filter(({ id }) => !resolved.includes(id));
        const originalEssences = originalUnresolvedEssences.filter(({ id }) => !resolved.includes(id));

        return {
            ...slice,
            filterType: 'All',
            matchedEssences: matched,
            newEssences,
            originalEssences,
            resolvedEssences: resolved,
            selectedNewEssences: [],
            selectedOriginalEssences: []
        };
    }

    onMatchUndo(slice, newId, originalId) {
        const { matchedEssences, newUnresolvedEssences, notes, originalUnresolvedEssences, resolvedEssences } = slice;
        const resolved = resolvedEssences.filter(id => id !== newId && id !== originalId);
        const matched = matchedEssences.filter(({ newEssence: { id } }) => id !== newId);
        const newEssences = newUnresolvedEssences.filter(({ id }) => !resolved.includes(id));
        const originalEssences = originalUnresolvedEssences.filter(({ id }) => !resolved.includes(id));
        const newNotes = Object.assign({}, notes);

        delete newNotes[newId];
        delete newNotes[originalId];

        return {
            ...slice,
            filterType: 'All',
            matchedEssences: matched,
            newEssences,
            notes: newNotes,
            originalEssences,
            resolvedEssences: resolved,
            selectedNewEssences: [],
            selectedOriginalEssences: []
        };
    }

    onNotify(slice, notification) {
        const { payload = {} } = notification;
        const { message: payloadMessage = '', skip = false, step: payloadStep, success = false } = payload;
        const isPropagating = false;
        const isValid = skip || success;
        const key = payloadStep.toUpperCase();
        const message = isValid ? (skip ? MESSAGES[key]['COMPLETE'] : MESSAGES[key]['APPROVAL']) : payloadMessage;
        const step = isValid ? 'complete' : 'propagate';

        return { ...slice, isPropagating, message, step };
    }

    onPropagateAndIngest(slice) {
        return { ...slice, isPropagating: true, step: 'propagating' };
    }

    onPropagateAndIngestFailed(slice) {
        return { ...slice, isPropagating: false, step: 'propagate' };
    }

    onRemove(slice, essence) {
        const { newUnresolvedEssences, originalUnresolvedEssences, removedEssences, resolvedEssences } = slice;
        essence.autoMatched = false;
        essence.operation = 'REMOVE';
        const removed = [...removedEssences, essence];
        const resolved = [...resolvedEssences, essence.id];
        const newEssences = newUnresolvedEssences.filter(({ id }) => !resolved.includes(id));
        const originalEssences = originalUnresolvedEssences.filter(({ id }) => !resolved.includes(id));

        return {
            ...slice,
            filterType: 'All',
            newEssences,
            originalEssences,
            removedEssences: removed,
            resolvedEssences: resolved,
            selectedNewEssences: [],
            selectedOriginalEssences: []
        };
    }

    onRemoveMany(slice) {
        const {
            newUnresolvedEssences,
            originalUnresolvedEssences,
            removedEssences,
            resolvedEssences,
            selectedOriginalEssences
        } = slice;
        const selectedEssences = selectedOriginalEssences.map(essence => {
            return { ...essence, autoMatched: false, operation: 'REMOVE' };
        });
        const essenceIds = selectedEssences.map(({ id }) => id);
        const removed = [...removedEssences, ...selectedEssences];
        const resolved = [...resolvedEssences, ...essenceIds];
        const newEssences = newUnresolvedEssences.filter(({ id }) => !resolved.includes(id));
        const originalEssences = originalUnresolvedEssences.filter(({ id }) => !resolved.includes(id));

        return {
            ...slice,
            filterType: 'All',
            newEssences,
            originalEssences,
            removedEssences: removed,
            resolvedEssences: resolved,
            selectedNewEssences: [],
            selectedOriginalEssences: []
        };
    }

    onRemoveUndo(slice, essenceId) {
        const { newUnresolvedEssences, notes, originalUnresolvedEssences, removedEssences, resolvedEssences } = slice;
        const resolved = resolvedEssences.filter(id => id !== essenceId);
        const removed = removedEssences.filter(({ id }) => id !== essenceId);
        const newEssences = newUnresolvedEssences.filter(({ id }) => !resolved.includes(id));
        const originalEssences = originalUnresolvedEssences.filter(({ id }) => !resolved.includes(id));
        const newNotes = Object.assign({}, notes);

        delete newNotes[essenceId];

        return {
            ...slice,
            filterType: 'All',
            newEssences,
            notes: newNotes,
            originalEssences,
            removedEssences: removed,
            resolvedEssences: resolved,
            selectedNewEssences: [],
            selectedOriginalEssences: []
        };
    }

    onReset() {
        return this.initialState();
    }

    onSelectNewEssence(slice, selectedNewEssences) {
        const { originalUnresolvedEssences, resolvedEssences, selectedNewEssences: oldSelectedNewEssences } = slice;
        const isRemove = !!oldSelectedNewEssences.find(({ id }) => id === selectedNewEssences.id);

        const newEssence = isRemove
            ? oldSelectedNewEssences.filter(({ id }) => id !== selectedNewEssences.id)
            : [...oldSelectedNewEssences, selectedNewEssences];

        const originalEssences =
            newEssence.length === 1
                ? originalUnresolvedEssences.filter(
                      ({ id, metadata: { fileTypeDescription } }) =>
                          !resolvedEssences.includes(id) &&
                          fileTypeDescription === selectedNewEssences.metadata.fileTypeDescription
                  )
                : newEssence.length > 1
                ? []
                : originalUnresolvedEssences.filter(({ id }) => !resolvedEssences.includes(id));

        originalEssences.forEach(essence => {
            const { resources } = essence;
            const { resources: newResources } = selectedNewEssences;
            const { match, matchStatus } = getEssenceMatchStatus(resources, newResources);

            essence.matchStatus = matchStatus;
            essence.matched = match;
        });

        const orderedOriginalEssences = orderBy(originalEssences, ['matched'], ['desc']);

        return {
            ...slice,
            filterType: 'All',
            originalEssences: orderedOriginalEssences,
            selectedNewEssences: newEssence
        };
    }

    onSelectOriginalEssence(slice, selectedOriginalEssences) {
        const {
            newUnresolvedEssences,
            resolvedEssences,
            selectedOriginalEssences: oldselectedOriginalEssences
        } = slice;
        const isRemove = !!oldselectedOriginalEssences.find(({ id }) => id === selectedOriginalEssences.id);

        const originalEssence = isRemove
            ? oldselectedOriginalEssences.filter(({ id }) => id !== selectedOriginalEssences.id)
            : [...oldselectedOriginalEssences, selectedOriginalEssences];

        const newEssences =
            originalEssence.length === 1
                ? newUnresolvedEssences.filter(
                      ({ id, metadata: { fileTypeDescription } }) =>
                          !resolvedEssences.includes(id) &&
                          fileTypeDescription === selectedOriginalEssences.metadata.fileTypeDescription
                  )
                : originalEssence.length > 1
                ? []
                : newUnresolvedEssences.filter(({ id }) => !resolvedEssences.includes(id));

        return {
            ...slice,
            filterType: 'All',
            newEssences,
            selectedOriginalEssences: originalEssence
        };
    }

    onSetHideNew(slice, state) {
        return { ...slice, hideNew: state };
    }

    onSetHideOriginal(slice, state) {
        return { ...slice, hideOriginal: state };
    }

    onToggleHideNew(slice) {
        const { hideNew } = slice;

        return { ...slice, hideNew: !hideNew };
    }

    onToggleHideOriginal(slice) {
        const { hideOriginal } = slice;

        return { ...slice, hideOriginal: !hideOriginal };
    }
}
