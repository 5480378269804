import { difference, flow, isFunction, map, reduce, union } from 'lodash';

function mergeLegacyAndModernActions(LegacyActions, ModernActions) {
    const initialValue = {
        _legacyActions: LegacyActions,
        _modernActions: ModernActions
    };

    return union(Object.keys(LegacyActions), Object.keys(ModernActions))
        .filter(k => isFunction(LegacyActions[k]) || isFunction(ModernActions[k]))
        .reduce((mergedActions, key) => {
            const legacyAction = LegacyActions[key];
            const modernAction = ModernActions[key];

            mergedActions[key] = function (...args) {
                legacyAction && legacyAction(...args);
                modernAction && modernAction(...args);
            };
            Object.assign(mergedActions[key], { ...legacyAction });
            mergedActions[key].defer = function (...args) {
                legacyAction && legacyAction.defer(...args);
                modernAction && modernAction.defer(...args);
            };

            return mergedActions;
        }, initialValue);
}

function isSuperset(a, b) {
    return difference(b, a).length === 0;
}

function move(list, from, to) {
    const copy = [...list];
    copy.splice(to, 0, copy.splice(from, 1)[0]);
    return copy;
}

export function dispatchResize() {
    /*
        Dispatch resize event when after errors appear to re-adjust modal / tab position.
        Using timeout since setState is async.
        https://github.com/callemall/material-ui/issues/5793#issuecomment-301282845
        https://github.com/mui-org/material-ui/issues/9337
    */
    setTimeout(() => window.dispatchEvent(new Event('resize')), 0);
}

export function getSkipForLastPage(total, limit) {
    const mod = total % limit;
    let page = Math.floor(total / limit);
    page = mod === 0 ? (page -= 1) : page;
    page = page > 0 ? page : 0;
    page = limit !== total ? page : 0;
    return page * limit;
}

export function getUpdatedSkip(skip, limit, total) {
    const deletedAllOnLastPage = skip === total;
    return deletedAllOnLastPage ? getSkipForLastPage(total, limit) : skip;
}

export function getTimeFromSeconds(timeInSeconds) {
    const digitsPerUnit = 2;
    const formatUnit = unit => `000${unit}`.slice(-digitsPerUnit);
    const valueReducer = (acc, curr, index, list) => `${acc}${curr}${index < list.length - 1 ? ':' : ''}`;

    return flow(
        time => parseFloat(time).toFixed(digitsPerUnit),
        time => ({
            hh: Math.floor(time / 3600),
            mm: Math.floor(time / 60) % 60,
            ss: Math.floor(time - (Math.floor(time / 60) % 60) * 60),
            ms: time.slice(-digitsPerUnit)
        }),
        time => map(time, formatUnit),
        values => reduce(values, valueReducer, '')
    )(timeInSeconds);
}

export function getTimeFromMs(timeInMs) {
    const millisecondDigits = 3;
    const formatTime = time => {
        let hour = `${time.hh}`.padStart(2, '0');
        let minute = `${time.mm}`.padStart(2, '0');
        let second = `${time.ss}`.slice(-2).padStart(2, '0');
        let millisecond = time.ms.padEnd(millisecondDigits, '0');
        return `${hour}:${minute}:${second}.${millisecond}`;
    };
    return flow(
        time => parseFloat(time).toFixed(millisecondDigits),
        time => ({
            hh: Math.floor(time / 3600),
            mm: Math.floor(time / 60) % 60,
            ss: Math.floor(time - (Math.floor(time / 60) % 60) * 60),
            ms: time.slice(-millisecondDigits)
        }),
        time => formatTime(time)
    )(timeInMs);
}

export default {
    mergeLegacyAndModernActions,
    isSuperset,
    dispatchResize,
    move,
    getSkipForLastPage,
    getTimeFromSeconds,
    getTimeFromMs,
    getUpdatedSkip
};
