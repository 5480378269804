import { actionDispatcher } from 'react-redux-boilerout';

@actionDispatcher({
    namespace: 'UploadAsset',
    actions: [
        'show',
        'hide',
        'changeType',
        'changeAttachmentMeta',
        'changeReplacementMeta',
        'fileSelected',
        'fileRemoved',
        'clearFiles',
        'reset'
    ]
})
export default class UploadAssetActions {}
