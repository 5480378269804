class BaseError extends Error {
    constructor(message) {
        super(message);
        Error.captureStackTrace(this, this.constructor);
        this.name = this.constructor.name;
    }
}

export class InvalidFormError extends BaseError {
    constructor(message = 'Invalid Form') {
        super(message);
    }
}
