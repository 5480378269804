import { autobind, ToastController } from 'wonderland-ui-commons';
import { DiscoveryAPI, MetaAPI, WonderlandDomainAPI } from 'core/apis';
import history from 'lib/history';
import RelationshipActions from './RelationshipActions';

const { getSearch } = DiscoveryAPI;
const { createRelationships, deleteRelationships, getChildren, getParents } = MetaAPI;
const { findAttachmentAssetsByIds } = WonderlandDomainAPI;
const {
    addParents,
    deselectAll,
    deselectAllChildren,
    removeParent,
    requestChildren,
    selectAll,
    selectAllChildren,
    selectAsset,
    selectChild,
    selectParent,
    selectRelationshipType,
    setConfig,
    updateChildren
} = RelationshipActions;
const urlParams = { limit: 1000, skip: 0 };

@autobind
class RelationshipController {
    async addChildren(assets, selectedParentId, type) {
        let children = [];
        assets.forEach(({ id }) => {
            const child = { id: selectedParentId, childId: id, type };

            children = [...children, child];
        });

        try {
            await createRelationships(children);
            this.updateChildren(selectedParentId);
        } catch (error) {
            if (error.status === 401 || error.status === 403) {
                ToastController.showError(
                    'This account does not have access to create relationships for the parent asset'
                );
                return;
            }
            ToastController.showError('This asset is already related to the the parent');
        }
    }

    addParents(items) {
        addParents(items);
    }

    async deleteRelationships(children) {
        await deleteRelationships(children);
    }

    deselectAll() {
        deselectAll();
    }

    deselectAllChildren() {
        deselectAllChildren();
    }

    async getChildren(id) {
        try {
            const { results: children } = await getChildren(id, urlParams);
            const asset_ids = children.map(id => id);
            const { results } = await this.getAssets(asset_ids);
            const assets = children.map(child => {
                const details = results.find(result => result.id === child.id);

                return { ...details, ...child };
            });

            return assets;
        } catch (error) {
            ToastController.showError(error);
        }
    }

    async getPopulatedAttachments(id, queryParams) {
        const currentAttachments = await getChildren(id, queryParams);
        const attachmentIds = _.map(_.get(currentAttachments, 'results', []), 'id');
        const attachmentAssets = await findAttachmentAssetsByIds(id, attachmentIds, { preview: true });
        return _.map(attachmentAssets, attachmentAsset => ({
            ...attachmentAsset,
            asset: attachmentAsset.asset,
            grants: attachmentAsset.grants,
            flags: attachmentAsset.flags
        }));
    }

    async getAssets(ids, limit = 1000, skip = 0) {
        const criteria = [
            { field: 'id', operation: 'is_one_of_keyword', type: 'filter', value: ids },
            { type: 'sort', fields: [{ field: 'id', order: 'desc' }] }
        ];
        const assets = await getSearch(criteria, ['asset_type'], skip, limit, 'content');

        return assets;
    }

    async getParents(id) {
        const { results: parents } = await getParents(id);
        const asset_ids = parents.map(id => id);
        const { results } = await this.getAssets(asset_ids);
        const assets = parents.map(parent => {
            const details = results.find(result => result.id === parent.id);

            return { ...details, ...parent };
        });

        return assets;
    }

    async getRelationships(id) {
        const parents = await getParents(id);
        const children = await getChildren(id, urlParams);
        const attachments = children?.results?.filter(child => child.type === 'attachment') || [];
        const nonAttachments = children?.results?.filter(child => child.type !== 'attachment') || [];

        return { children: nonAttachments, parents: parents?.results || [], attachments };
    }

    async navigateToRelationship(config) {
        const { asset_grants, asset_ids } = config;
        const { results } = await this.getAssets(asset_ids);

        await setConfig({ assets: results, asset_grants });
        history.push(`/relationship`);
    }

    async removeChildren(assets, selectedParentId) {
        const id = selectedParentId;
        let children = [];

        assets.forEach(({ id: childId, type }) => {
            const child = { id, childId, type };

            children = [...children, child];
        });

        try {
            await deleteRelationships(children);
            this.deselectAllChildren();
            this.updateChildren(selectedParentId);
        } catch (error) {
            ToastController.showError(error);
        }
    }

    removeParent(id) {
        removeParent(id);
    }

    async selectAll(ids) {
        selectAll(ids);
    }

    async selectAllChildren(ids) {
        selectAllChildren(ids);
    }

    async selectAsset(id) {
        await selectAsset(id);
    }

    async selectChild(id) {
        await selectChild(id);
    }

    async selectParent(id, selectedParentId) {
        const select = id !== selectedParentId;

        await selectParent(id);

        if (select) {
            this.updateChildren(id);
        } else {
            this.deselectAllChildren();
        }
    }

    selectRelationshipType(relationshipType) {
        selectRelationshipType(relationshipType);
    }

    async updateChildren(id) {
        requestChildren();
        const { results: children } = await getChildren(id, urlParams);
        const asset_ids = children.map(id => id);
        const { results } = await this.getAssets(asset_ids);
        const assets = children.map(child => {
            const details = results.find(result => result.id === child.id);

            return { ...details, ...child };
        });

        updateChildren(assets);
    }
}

export default new RelationshipController();
