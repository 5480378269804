import { compose, withHandlers } from 'recompose';
import { connectSlice } from 'wonderland-ui-commons';
import { flow, get, map } from 'lodash';
import { recipientTypes } from './DeliveryFormConstants';
import DestinationsController from 'core/Destinations';
import NotificationRecipientsActions from 'app/shared/NotificationRecipients/NotificationRecipientsActions';
import NotificationRecipientsSlice from 'app/shared/NotificationRecipients/NotificationRecipientsSlice';

function buildDestinationDLs(dest) {
    return flow(
        _dest => get(_dest, 'distributionListDetails', []),
        _dest =>
            map(_dest, dl => ({ ...dl, contactDetails: dest.contactDetails.filter(c => dl.contacts.includes(c.id)) })),
        _dest =>
            map(_dest, dl => ({ ...dl, preferences: dest.distribution_lists.find(p => p.id === dl.id).preferences }))
    )(dest);
}

export default () =>
    compose(
        connectSlice({ slice: NotificationRecipientsSlice }),
        withHandlers({
            addNotificationRecipient: () => async (item, itemType) => {
                let userOrDL = item;
                if (itemType === recipientTypes.dl) {
                    userOrDL = await DestinationsController.getDistributionListContacts(item.value.id);
                }
                NotificationRecipientsActions.addNotificationRecipient(userOrDL, itemType);
            },
            getNotificationRecipients: () => async destination => {
                const { id, delivery_type } = destination;
                const physicalDigitalType = ['physical', 'manual'].includes(delivery_type) ? 'Physical' : 'Digital';

                const destWithDetails = await DestinationsController.getDestinationDetailsWithContacts(id);

                NotificationRecipientsActions.addNewDistributionLists(
                    buildDestinationDLs(destWithDetails),
                    physicalDigitalType
                );
            }
        })
    );
