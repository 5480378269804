import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import blue from '@material-ui/core/colors/blue';
import Logo from 'app/shared/Logo';
import PropTypes from 'prop-types';
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const sheet = theme => ({
    appBar: {
        backgroundColor: blue[400],
        boxShadow: '0 0 0 0, 0 0 0 0, 0 0 0 1px',
        zIndex: theme.zIndex.drawer + 1
    },
    root: {
        position: 'sticky',
        left: 0,
        top: 0,
        width: '100%',
        zIndex: 2000
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        }
    }
});

@withStyles(sheet)
export default class HeaderLiteComponent extends React.Component {
    static propTypes = {
        classes: PropTypes.object
    };

    render() {
        try {
            return this.doRender();
        } catch (e) {
            console.error('HeaderLiteComponent::render', e);
        }
        return null;
    }

    doRender() {
        const { classes } = this.props;

        return (
            <AppBar position="sticky" className={classes.appBar}>
                <Toolbar>
                    <Typography className={classes.title} color="inherit" variant="h6">
                        <Logo />
                    </Typography>
                </Toolbar>
            </AppBar>
        );
    }
}
