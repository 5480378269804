import { defaultCriteria, facetsState, indexType } from './initialDestinationPickerSearchConfig';
import { find, get, isEmpty, isEqual, last } from 'lodash';
import { reducerRegistry } from 'lib/redux';
import { registerSliceReducer, sliceReducer, subscribe } from 'react-redux-boilerout';
import SearchCriterionBuilder from 'wonderland-ui-discovery/lib/Search/SearchCriterionBuilder';

const isFormatFacet = ({ type, name }) => type === 'facet' && name === 'type';
const toggleFormatCriterion = (criteria, facetValue) => {
    const prevCriterion = criteria.find(isFormatFacet);
    const toggledCrit = prevCriterion
        ? SearchCriterionBuilder.updateValueFacetCriterion(prevCriterion, facetValue)
        : SearchCriterionBuilder.buildValueFacetCriterion('type', [facetValue]);
    const otherCriteria = criteria.filter(c => !isFormatFacet(c));
    return isEmpty(toggledCrit.values) ? otherCriteria : [...otherCriteria, toggledCrit];
};
const getSortCriterion = parentItem => {
    const field = parentItem.type === 'Location' ? 'delivery_type' : 'name';
    return SearchCriterionBuilder._buildCriterion('sort', { field, order: 'asc' });
};

@registerSliceReducer({ registry: reducerRegistry })
@sliceReducer('DestinationPickerSideSheet')
@subscribe({ namespace: 'DestinationPickerSideSheet' })
export default class DestinationPickerSideSheetSlice {
    initialState() {
        return {
            breadCrumbs: [],
            defaultCriteria,
            lastDrilledItem: {},
            facetsState,
            facetsDrawerIsOpen: false,
            indexType,
            isOpen: false,
            slide: 'right',
            slideIndex: 0,
            textFieldInputValue: '',
            clearDefaultResults: true
        };
    }

    onDiscoveryStateChange(slice, { criteria, facetsState, skip }) {
        return {
            ...slice,
            criteria,
            facetsState,
            skip
        };
    }

    onReset() {
        return this.initialState();
    }

    onPopBreadCrumb(slice) {
        const newState = last(slice.breadCrumbs);
        const termCriterion = find(newState.criteria, ({ type }) => type === 'term');

        return {
            ...slice,
            breadCrumbs: slice.breadCrumbs.filter(b => b !== newState),
            criteria: newState.criteria,
            lastDrilledItem: newState.item,
            skip: 0,
            slide: 'left',
            slideIndex: slice.slideIndex - 1,
            textFieldInputValue: get(termCriterion, 'term', '')
        };
    }

    onChangeTextFieldInput(slice, term) {
        const criteria = slice.criteria.filter(({ type }) => type !== 'sort');
        return {
            ...slice,
            criteria,
            textFieldInputValue: term
        };
    }

    onClearSearchTermCriteria(slice) {
        const clearedCriteria = slice.criteria.filter(({ type }) => type !== 'term');

        return {
            ...slice,
            criteria: isEmpty(slice.lastDrilledItem)
                ? clearedCriteria
                : [...clearedCriteria, getSortCriterion(slice.lastDrilledItem)],
            textFieldInputValue: ''
        };
    }

    onDrillIntoItem(slice, item) {
        const newCriteria = [
            ...slice.defaultCriteria,
            SearchCriterionBuilder.buildValueFacetCriterion('parent_ids', [item.id]),
            getSortCriterion(item)
        ];

        return {
            ...slice,
            breadCrumbs: [...slice.breadCrumbs, { criteria: slice.criteria, item: slice.lastDrilledItem }],
            criteria: newCriteria,
            lastDrilledItem: item,
            skip: 0,
            slideIndex: slice.slideIndex + 1,
            slide: 'right',
            textFieldInputValue: ''
        };
    }

    onSetFacetsDrawer(slice, value) {
        return {
            ...slice,
            facetsDrawerIsOpen: value
        };
    }

    onSetDefaultCriteria(slice, type) {
        // logic is reversed because negate = true in filter (need it this way for filtering to work correctly)
        defaultCriteria[1].value =
            type === 'destinationDigital' ? ['physical', 'manual'] : ['signiant', 's3', 'faspex'];
        return {
            ...slice,
            defaultCriteria
        };
    }

    onSelectQuickFilter(slice, criteria, facetValue) {
        return {
            ...slice,
            criteria: toggleFormatCriterion(criteria, facetValue),
            breadCrumbs: [],
            skip: 0
        };
    }

    onClearAllCriteria(slice) {
        return {
            ...slice,
            criteria: null,
            breadCrumbs: [],
            lastDrilledItem: {},
            skip: 0,
            textFieldInputValue: ''
        };
    }
    @subscribe({ namespace: 'Delivery' })
    onGetSearchRequested(slice) {
        return {
            ...slice,
            loading: true
        };
    }

    @subscribe({ namespace: 'Delivery' })
    onGetSearchCompleted(slice, requestedCriteria, { facets, results, total }) {
        return {
            ...slice,
            ...(isEqual(requestedCriteria, defaultCriteria) && slice.clearDefaultResults
                ? { facets, results: [], total: 0 }
                : { facets, results, total }),
            loading: false
        };
    }
}
