import { actionDispatcher } from 'react-redux-boilerout';

@actionDispatcher({
    namespace: 'Discovery',
    actions: [
        'fetchSearchCompleted',
        'getWonderlandVisualizationsCompleted',
        'getStatsRequested',
        'getStatsCompleted',
        'getStatsFailed',
        'getWonderlandVisualizationsRequested',
        'getWonderlandVisualizationsCompleted',
        'getWonderlandVisualizationsFailed'
    ]
})
export default class DiscoveryActions {}
