import { actionDispatcher } from 'react-redux-boilerout';

@actionDispatcher({
    namespace: 'DestinationPickerSideSheet',
    actions: [
        'changeTextFieldInput',
        'clearAllCriteria',
        'clearSearchTermCriteria',
        'discoveryStateChange',
        'drillIntoItem',
        'popBreadCrumb',
        'reset',
        'selectQuickFilter',
        'setFacetsDrawer',
        'setDefaultCriteria'
    ]
})
export default class DestinationPickerSideSheetActions {}
