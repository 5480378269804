import AssetStatus from 'app/shared/status/AssetStatus';
import DeliveryStatus from 'app/shared/status/DeliveryStatus';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import RestrictionStatus from 'app/shared/status/RestrictionStatus';

const CardStatusComponent = ({ asset, classes: { grid }, restrictions }) => {
    return (
        <Grid className={grid} container justify="space-between" spacing={8}>
            <Grid item xs={2}>
                <Grid container justify="flex-start" alignItems="flex-start">
                    <Grid item>
                        <AssetStatus asset={asset} />
                    </Grid>
                    <Grid item>
                        <DeliveryStatus asset={asset} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={10}>
                <RestrictionStatus restrictions={restrictions} />
            </Grid>
        </Grid>
    );
};

CardStatusComponent.propTypes = {
    asset: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    restrictions: PropTypes.object.isRequired
};

export default CardStatusComponent;
